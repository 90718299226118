import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Output, EventEmitter, Input } from '@angular/core';
import { AccountManagerService } from 'src/app/managers/account-manager.service';
import { PublicService } from 'src/app/public/public.service';
import { PopupService } from '../../service/popup.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit, OnChanges {
  @Output() goToParent2 = new EventEmitter;
  // @ViewChild(EditCategoryComponent) editCategory: EditCategoryComponent;
  @Input() obj2;
  @Input() projectId;
  @Input() limit;
  confirmCategories = []
  checkCategories = []
  selectedItems = []
  message: string;
  error: string;
  aditional: string;
  selectMessage: string;
  comingProjectId;
  comingCategoryName;
  categories = [];
  isLoading = false;
  addNew = false;
  itemsToSend = [];
  colors =
  [
    { color: '#1ABC9C' },
    { color: '#3498DB' },
    { color: '#2ECC71' },
    { color: '#9B59B6' },
    { color: '#34495E' },
    { color: '#16A085' },
    { color: '#F1C40F' },
    { color: '#F39C12' },
    { color: '#E67E22' },
    { color: '#7F8C8D' }
  ];

  constructor(private router: Router,
    public publicService: PublicService,
    private accountManagerService: AccountManagerService,
    private popupService: PopupService) {
  }

  ngOnInit(): void {
    this.getItems()
    
    this.popupService.categoryEdit.subscribe(result => {
      this.addNew = result;
    });
  }

  ngOnChanges(){}

  getItems() {
    this.isLoading = true;
    this.accountManagerService.getItems(this.projectId).then(resp => {
      if (resp) {
          this.isLoading = false;
          this.itemsToSend = resp;
          for(let i=0; i<this.categories.length; i++){
            this.categories[i].selected = false
          }
      }
    }, err => {
      this.isLoading = false;
    });
  }

  getCategories() {
    this.isLoading = true;
    this.accountManagerService.getCategories(this.projectId).then(resp => {
      if (resp) {
        this.isLoading = false;
        this.categories = resp;
        for(var i=0; i<this.categories.length; i++){
          for(var j=0; j<this.colors.length; j++){
            if(this.categories[i].colorIndex == j){
              this.categories[i].color = this.colors[j].color;
            }
          }
        }
      }
    }, err => {
      this.isLoading = false;
    });
  }

  showCategory() {
    this.getCategories();
    this.confirmCategories = this.obj2;
    this.selectedItems = []
    
    for (var i = 0; i < this.confirmCategories.length; i++) {
      if(this.confirmCategories[i].id) {
        this.selectedItems.push(this.confirmCategories[i]);
      }
    }

    this.message = ''
    this.error = ''
    this.aditional = '';
    this.selectMessage = "";
    document.getElementsByClassName("category-boxes-4")[0].removeAttribute("style");
  }

  add(id) {
    this.publicService.logAnalytics('add-btn-clicked-in-category')
    if(this.limit){
      if (this.selectedItems.length < this.limit) {
       this.addItem(id);
      } else {
        this.selectMessage = `You can only select ${this.limit} categories`;
        return;
      }
    } else{
      this.addItem(id);
    }
  }

  addItem(id){
    if (this.selectedItems.some(d=> d.id == id.id)) {
      this.selectMessage = "This category is already added";
      return;
    } else {
      this.selectedItems.push(id);
      this.selectMessage = "";
    }
  }

  delete(id) {
    this.publicService.logAnalytics('delete-btn-clicked-in-category')
    let index = this.selectedItems.indexOf(id);
    this.selectedItems.splice(index, 1);
  }

  confirm() {
    this.publicService.logAnalytics('confirm-btn-clicked-in-category')
    if(this.selectedItems != this.limit){
      this.goToParentScreenShot();
    } else {
      this.goToParentProduct();
    }
  }

  redirectCategory(id?) {
    this.addNew = true;
    this.popupService.showCategoryArrow.next(false);
    this.popupService.shopDetailsCategory.next(false);
    this.popupService.categoryEdit.next(true);
    if(id) {
      this.publicService.logAnalytics('edit-category-btn-clicked-in-category')
      this.popupService.categoryProjectId.next(this.projectId);
      this.popupService.categoryEditId.next(id);
      this.popupService.categoryName.next('');
    } else if (this.aditional) {
      this.popupService.categoryProjectId.next(this.projectId);
      this.popupService.categoryName.next(this.aditional);
      this.publicService.logAnalytics('add-new-category-btn-clicked-in-category-with-category-name')
    } else {
      this.publicService.logAnalytics('add-new-category-btn-clicked-in-category-without-category-name')
      this.comingProjectId = this.projectId;
      this.popupService.categoryProjectId.next(this.projectId);
      this.popupService.categoryEditId.next(null);
      this.popupService.categoryName.next('');
    }
  }

  goToParentScreenShot(){
    if (this.selectedItems.length >= this.limit + 1) {
      this.error = `Please select ${this.limit} categories`;
      this.message = '';
      document.getElementsByClassName("confirmbtn")[0].setAttribute("data-dismiss", "modal2");
      return;
    } else {
      this.confirmCategories = [];
      
      for (var i = 0; i < this.selectedItems.length; i++) {
        this.selectedItems[i].status = true;
        this.confirmCategories.push(this.selectedItems[i]);
      }

      if (this.confirmCategories.length == this.limit) {
        this.error = '';
        document.getElementsByClassName("confirmbtn")[0].setAttribute("data-dismiss", "modal");
      } else {
        this.error = '';
        document.getElementsByClassName("confirmbtn")[0].setAttribute("data-dismiss", "modal");
      }
      this.goToParent2.emit(this.confirmCategories);
    }
  }

  goToParentProduct(){
    this.goToParent2.emit(this.confirmCategories);
  }

  addCategoryToList($event){
    this.getCategories();
  }
}
