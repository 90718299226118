
<div class="top-header">
    <button (click)="showNav()" type="button" id="sidebarCollapse" class="fade-btn">
        <img src="/assets/images/menuIcon.svg" alt="menuIcon" />
    </button>
    <div class="main-logo">
        <img (click)="goToHome()" src="/assets/images/OrderAx-1.png"alt="logo" />
    </div>
    <div appOutsideclick (outsideClick)="close()" #menu [ngClass]="{'show-nav' : showNavbar && !closeMenu}" class="top-navbar">
        <ul>
            <li (click)="goToSection('home')" [ngClass]="{'active' : activeLink == 'home'}">Home</li>
            <li (click)="goToSection('apps')" [ngClass]="{'active' : activeLink == 'apps'}">Products</li>
            <li (click)="goToSection('contact')" [ngClass]="{'active' : activeLink == 'contact'}">Contact Us</li>
        </ul>
    </div>
    <div class="side-login">
        <button (click)="getStarted()">Get Started</button>
        <button (click)="goToLogin()">Login</button>
    </div>
</div>
<div class="container-fluid cf-xs">
    <div class="header-body" id="header-body">
        <div class="row align-items-center pb-no">
            <div class="col-lg-12">
                <div id="home" #home class="header">
                    <div class="img-fluid" class="home-section">
                        <div class="home-section-layer"> </div>
                        <img src="../../assets/images/headerImage.png" alt="Orderax Header Image">
                        <div class="text-button">
                            <h1>The marketplace for businesses that want their own custom mobile ordering apps to sell their products online.</h1>
                            <button (click)="getStarted()">Get Started
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20" stroke="#11A6E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14 6L20 12L14 18" stroke="#11A6E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

    
                <ng-container>
                    <div id="apps" #apps class="product-section">
                        <div class="header">
                            <h4>Apps Marketplace</h4>
                            <div class="search">
                                <input type="text" class="form-control" [(ngModel)]="searchedProduct" placeholder="Search Marketplace">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.875 13.5C10.9816 13.5 13.5 10.9816 13.5 7.875C13.5 4.7684 10.9816 2.25 7.875 2.25C4.7684 2.25 2.25 4.7684 2.25 7.875C2.25 10.9816 4.7684 13.5 7.875 13.5Z" stroke="#0073DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15.7501 15.7501L11.8501 11.8501" stroke="#0073DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>                                    
                            </div>
                        </div>
                        <div class="multi-box">
                            <div class="product-box" *ngFor='let product of filteredproducts | filter:searchedProduct'>
                                <div class="product-image">
                                    <img (click)="openDetails(product.code)" [src]="product.urlImage"/>
                                    <span *ngIf="!product.isAvailable" class="available-check">COMING SOON</span>
                                    <div class="box-text">
                                        <p class="white-text-name">{{product.name}}</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="product-box-text">
                                    <div class="heading-price">
                                        <h4 (click)="openDetails(product.code)">{{product.name}}</h4>
                                        <p>{{product.price == 0 ? 'Free' : '$'+product.price}}</p>
                                        <!-- <p>{{product.price ? product.price == 0 ? "Free" : '$'+product.price : "Not Available"}}</p> -->
                                    </div>
                                    <div class="description">
                                        <p>{{product.description}}</p>
                                    </div>
                                    <div class="theme-plan">
                                        <p (click)="goToDetails('theme', product.code)">See Theme</p>
                                        <p (click)="goToDetails('plan', product.code)">See Plan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                    <div id="contact" #contact class="bottom-divisions pt-2">
                        <app-footerpublic></app-footerpublic>
                    </div>
            </div>
        </div>
    </div>
</div>