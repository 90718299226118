import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/auth/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(private http: HttpClient,
    public analyticsService:AnalyticsService,
    public router: Router) {}

  images = [
    'iOSAppIcon',
    'androidAppIcon',
    'androidFeatureGraphics',
    'logo',
    'logoWithName',
    'smallLogo',
    'logoImage',
    'docURL',
    'image'
  ]
  getDetails(url) {
    return this.http.get(url, { headers: { skip: "true" } }).toPromise();
  }

  makeFormData(objFormData){
    let data = new FormData();
    for(var key in objFormData){
      if(this.images.includes(key)){
        data.append(key, objFormData[key], objFormData[key]['name']);
      } else if (key == 'data'){
        data.append(key, JSON.stringify(objFormData[key]));
      } else{
        data.append(key, objFormData[key]);
      }
    }
    return data;
  }
  
  getHours(){
    let hours = [];
    for(let i = 0; i< 24 ; i++){
      hours.push({
        key:  i   < 10 ? `0${i}` : i.toString(),
        value: i * 60 * 60
      })
    }
    return hours;
  }

  getMintues(){
    let mins = [];
    for(let i = 0; i< 60 ; i++){
      mins.push({
        key:  i   < 10 ? `0${i}` : i.toString(),
        value: i * 60 
      })
    }
    return mins;
  }

  logAnalyticsAndRedirect(name: string,route: string) {
    this.analyticsService.googleTag(name);
    this.router.navigate([route]);
  }

  logAnalytics(name: string) {
    this.analyticsService.googleTag(name);
  }

  getDeliveryMethodsName(type){
    if(type.toLowerCase() == 'pickup'){
      return 'Pick up'
    }
    else if (type.toLowerCase() == 'ondemanddelivery'){
      return 'On Demand Delivery '
    }
    else if (type.toLowerCase() == 'samedaydelivery'){
      return 'Same Day Delivery '
    }else{
      return ''
    }
  }
}
