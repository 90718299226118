import { Component, OnInit } from '@angular/core';
import { PublicService } from '../public.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css'],
})
export class TermsConditionsComponent implements OnInit {
  
  public tnc:any = [];

  constructor(private publicService: PublicService) {}

  ngOnInit(): void {
    this.publicService
      .getDetails('https://www.iubenda.com/api/terms-and-conditions/65640587')
      .then((tnc) => {
        this.tnc = tnc;
      });
  }
}
