import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsService } from 'src/app/auth/analytics.service';
import { PublicService } from 'src/app/public/public.service';
import { AccountManagerService } from '../../managers/account-manager.service';
import { MetaService } from '../../meta-service/meta.service'
import { UserData } from '../userData.model';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  isLoading = false;
  show: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    public analyticsService: AnalyticsService,
    public publicService: PublicService,
    private metaService: MetaService,
    private accountManagerService: AccountManagerService
  ) {
    this.show = false;
  }

  ngOnInit(): void {
    this.setTitleAndDescription();
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  setTitleAndDescription() {
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, login';
    let description = 'Login or Signup to OrderAx for businesses that want their own mobile ordering App, built and published to the iOS App and Android Play Store for them';
    this.metaService.setPageTitleAndMetaTags('Login to your Dashboard| OrderAx', description, keywords, 'https://orderax.com/login')
  }
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.analyticsService.googleTag("login btn clicked");
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.auth.login(this.loginForm.value).subscribe({
      next: () => {
        this.afterLoginSuccess();
      }, error: (error) => {
        this.isLoading = false;
        let errorMessage = error.error ? error.error.message : error.message;
        if (errorMessage === 'EMAIL_NOT_FOUND' || errorMessage === 'INVALID_PASSWORD') {
          errorMessage = 'Incorrect Email or Password'
        }
        this.toastr.error(errorMessage, '');
      }
    })
  }

  private redirectAfterLogin() {
    let userData: UserData = this.accountManagerService.getUserData()
    let user = userData.user;
    if (user.type === 1) {
      this.router.navigate([
        '/admin/user-listing'
      ]);
    } else if (
      user.business.info.brandName == '' &&
      user.business.info.businessName == ''
    ) {
      this.router.navigate(['/businessinfo']);
    } else if (
      user.business.address.line1 == '' &&
      user.business.address.postalCode == ''
    ) {
      this.router.navigate(['/businessinfo', 'businessaddress']);
    } else if (user.business.legal.type == '') {
      this.router.navigate(['/businessinfo', 'businesstype']);
    } else if (user.contact.firstName == '' && user.contact.lastName == '') {
      this.router.navigate(['/businessinfo', 'contactInfo']);
    } else {
      this.router.navigate([
        '/home/dashboard'
      ]);
    }
  }

  password() {
    this.show = !this.show;
  }

  goToNewTab(url: string, type) {
    this.analyticsService.googleTag(type);
    window.open(url, '_blank');
  }

  redirectTo(route) {
    this.analyticsService.googleTag(route);
    this.router.navigate([
      route
    ]);
  }

  googleLogin(credential: string) {
    console.log(credential, "credentails")
    this.isLoading = true;
    this.auth.signInWithIDP(credential).subscribe({
      next: () => {
        this.afterLoginSuccess();
      }, error: (error) => {
        this.isLoading = false;
        this.toastr.error('Something went wrong. Please try again later.', '');
      }
    })
  }

  afterLoginSuccess() {
    this.isLoading = false;
    this.toastr.success('Login Successful', '');
    this.redirectAfterLogin();
  }

}
