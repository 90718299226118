<div class="modal fade" id="emailVerfication" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content" id="modal-content">
            <div class="container">
                <div class="modal-header">
                    <button (click)="close()" #closebutton type="button" class="app-popup-close" data-dismiss="modal" aria-label="Close"
                        id="app-popup-close">
                        <img src="../../assets/images/close.png">
                    </button>
                </div>
                <div class="complete-profile businessinfo-box">
                    <div class="row no-gutters align-items-center justify-content-center">
                        <div class="col-sm-12 row businessinfo-box-inside">
                            <div class="col-sm-12 col-md-12 col-lg-12 bg-white">
                                <div class="title mb-5 mt-3">
                                    <h1>Email Verification</h1>
                                </div>
                                <div class="centered-content">
                                    <p class="mb-2">
                                        You need to verify your email to complete your app submission.
                                    </p>
                                    <p>
                                       Please click send email button below to receive the email verification link.
                                    </p>
                                    <div class="center">
                                        <button (click)="resendEmail()" [disabled]="isLoading" type="button" class="btn  btn-primary mb-3 mt-3 align-self-center">
                                            Send Email
                                            <span
                                            class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true" *ngIf="isLoading"></span>
                                        </button>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>