import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppService } from '../../user/app.service';

@Component({
  selector: 'app-footerpublic',
  templateUrl: './footerpublic.component.html',
  styleUrls: ['./footerpublic.component.css']
})
export class FooterpublicComponent implements OnInit {
  contactForm : FormGroup;
  isLoading = false;
  disableButton = false;
  
  constructor(
    private appService: AppService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService) { }
    
    createContactForm() {
      this.contactForm = this.fb.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        mobile: ['', [Validators.required, Validators.minLength(10)]],
        message: ['', [Validators.required]]
      })
    }

    sendContact(){
  
      this.contactForm.markAllAsTouched();
  
      if(this.contactForm.invalid){
        return; 
      }

      this.isLoading = true;
      this.contactForm.disable();
      this.disableButton = true;

      this.appService.addPublicContact(this.contactForm.value).then((result)=>{
        if(result.status){
          this.contactForm.enable();
          this.isLoading = false;
          this.disableButton = false;
          this.toastr.success('Your email has been sent. We will contact you very soon!', '');
          this.contactForm.reset();
        }
        },err => {
          this.contactForm.enable();
          this.isLoading = false;
          this.disableButton = false;
          this.toastr.error(err.error.message, '');
        })
      }
  
      contactvalidation(){
        return !this.contactForm.valid
      }

      ngOnInit(): void {
        this.createContactForm();
      }

      goToSocial(type) {
        if(type == 'instagram') {
          window.open(`https://www.instagram.com/orderaxapp/?hl=en`, '_blank', 'noopener');
        }
        if(type == 'facebook') {
          window.open(`https://web.facebook.com/orderax`, '_blank', 'noopener');
        }
        if(type == 'twitter') {
          window.open(`https://www.twitter.com/orderax1`, '_blank', 'noopener');
        }
        if(type == 'youtube') {
          window.open(`https://www.youtube.com/channel/UCnAEXlmsER86iLQwnysyHUQ`, '_blank', 'noopener');
        }
        if(type == 'google') {
          window.open(`https://g.page/r/CdxuoeNDxtG_EAE`, '_blank', 'noopener');
        }
      }
}
