import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MetaService } from '../meta-service/meta.service'

@Component({
  selector: 'app-themepublic',
  templateUrl: './themepublic.component.html',
  styleUrls: ['./themepublic.component.css']
})
export class ThemepublicComponent implements OnInit {
  @ViewChild('contact') contactElement: ElementRef;
  @ViewChild('theme') toolsElement: ElementRef;
  @ViewChild('home') homeElement: ElementRef;
  showNavbar = false;
  closeMenu : boolean = true;
  activeLink = "home";
  public homeOffset: any;
  public contactOffset: any;
  public toolsOffset: any;

  constructor(private route: Router,
    private metaService: MetaService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.setTitleAndDescription();
  }

  ngAfterViewInit() {
    this.setTitleAndDescription();
    this.contactOffset = this.contactElement.nativeElement.offsetTop;
    this.toolsOffset = this.toolsElement.nativeElement.offsetTop;
    this.homeOffset = this.homeElement.nativeElement.offsetTop;
  }

  setTitleAndDescription(){
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, tools';
    let description = 'OrderAx Tools Theme where you can get a theme of your Mobile Ordering IOS and android apps with logos';
    this.metaService.setPageTitleAndMetaTags(`Free Online Ordering Restaturant App Theme`,description,keywords, 'https://orderax.com/tools/free-restaurant-app-theme')
  }

  @HostListener('window:scroll', ['$event'])
  checkOffsetTop() {

    if (window.pageYOffset >= this.homeOffset && window.pageYOffset < this.toolsOffset-70) {
      this.activeLink = 'home';
    }
    else if (window.pageYOffset >= this.toolsOffset-70 && window.pageYOffset < this.contactOffset-80) {
      this.activeLink = 'apps';
    }
    else if (window.pageYOffset > this.contactOffset-80) {
      this.activeLink = 'contact';
    }
  }

  goToSection(type){
    if(type == "home"){
      this.activeLink = "home";
      var element = document.getElementById('home');
      var elementPosition = element.getBoundingClientRect().top;
      window.scrollTo({top :elementPosition, behavior : 'smooth'});
    }
    if(type == "apps"){
      this.activeLink = "apps";
      var element = document.getElementById('apps');
      var headerOffset = 82;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({top :offsetPosition, behavior : 'smooth'});
    }
    if(type == "contact"){
      this.activeLink = "contact";
      var element = document.getElementById('contact');
      var headerOffset = 82;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({top :offsetPosition, behavior : 'smooth'});
    }
  }
  
  goToSocial(type) {
    if(type == 'instagram') {
      window.open(`https://www.instagram.com/orderaxapp/?hl=en`, '_blank', 'noopener');
    }
    if(type == 'facebook') {
      window.open(`https://web.facebook.com/orderax`, '_blank', 'noopener');
    }
    if(type == 'twitter') {
      window.open(`https://www.twitter.com/orderax1`, '_blank', 'noopener');
    }
    if(type == 'youtube') {
      window.open(`https://www.youtube.com/channel/UCnAEXlmsER86iLQwnysyHUQ`, '_blank', 'noopener');
    }
    if(type == 'google') {
      window.open(`https://g.page/r/CdxuoeNDxtG_EAE`, '_blank', 'noopener');
    }
  }

  goToHome(){
    this.route.navigate(['/']);
  }

  getStarted(){
    this.route.navigate(['/registration']);
  }

  goToLogin(){
    this.route.navigate(['/login']);
  }

  showNav(){
    this.showNavbar = !this.showNavbar;
  }
  
  close(){
    if(!this.showNavbar){
      this.closeMenu = false;
    }else if(this.showNavbar){
      this.closeMenu = true;
    }
    else if(this.showNavbar && this.closeMenu){
      this.closeMenu = true;
      this.showNavbar = false;
    }
  }
}
