import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { AppService } from '../../user/app.service';
import { AccountManagerService } from '../../managers/account-manager.service';
import { Router } from '@angular/router';
import { PublicService } from 'src/app/public/public.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  openMenu : boolean = false;
  closeMenu : boolean = true;
  businessName;
  selectedWizard;
  showPopup = false;
  userInfo;
  constructor(private appService:AppService,
    private authService: AuthService,
    private accountManagerService: AccountManagerService,
    public publicService:PublicService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    public route: Router) {
    this.businessName = this.accountManagerService.getCurrentBusinessInfo();
  }

  ngOnInit(){
    this.appService.getAllProducts();
    this.getUserData();
  }
  
  getUserData(){
    this.userInfo = this.accountManagerService.getUserData().user;
    if(this.userInfo == undefined){
      this.ngxLoader.start("getUserData");
      this.accountManagerService.getUserInfo('normal').then(resp => {
        if (resp.status) {
          this.userInfo = resp.user
          this.ngxLoader.stop("getUserData");
        }
      }, err => {
        this.toastr.error(err.error.message, '');
        this.ngxLoader.stop("getUserData");
      });
    }
  }

  logOut(from) {
    this.publicService.logAnalytics(`logout-from-${from}`)
    this.authService.logOut();
  }

  checkProfileComplete(){
    this.selectedWizard = this.authService.checkProfileComplete();
   if(this.selectedWizard == 'complete'){
    this.publicService.logAnalytics('home-user-services-from-side-menu')
    this.route.navigate(['home/user-services'])
    }else{
      this.publicService.logAnalytics('profile-complete-modal-from-side-menu')
      this.showPopup = true;
    } 
  }

  dataEmit(data) {
    this.publicService.logAnalytics('home-user-services-from-side-menu')
    this.route.navigate(['home/user-services'])
    this.showPopup = false;
  }

  close(){
    if(!this.openMenu){
      this.closeMenu = false;
    }else if(this.openMenu){
      this.closeMenu = true;
    }
    else if(this.openMenu && this.closeMenu){
      this.closeMenu = true;
      this.openMenu = false;
    }    
  }

  onChange(type){
    console.log("1", type);
  }
  
  onClick(value){
    if(value == "1"){
      console.log("yes");
    } else{
      console.log("no");
    }
  }
}
