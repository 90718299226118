
<ng-container *ngIf="data.steps?.length > 0">
    <div class="modal-dialog">
            <div class="modal-content" id="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="serviceModalLabel">{{data.steps[selectedIndex].title}}</h5>
                
                <button id="cancel" type="button" (click)="closedModal()" class="close" data-dismiss="modal" aria-label="Close">
                  <span><img src="../../../assets/images/services/cross.png"></span>
                </button>
              </div>
              <div class="modal-body" id="modal-body">
                    <p class="lead">Step {{selectedIndex+1}}</p>
                    <p class="lead-1 mobile-bottom white-spaces" *ngIf="data.steps[selectedIndex].description">
                        {{replaceDescription(data.steps[selectedIndex].description)}}
                    </p>
                    <p class="lead-1 mobile-bottom" *ngIf="data.steps[selectedIndex].link">
                        <a  href="{{data.steps[selectedIndex].link}}" target="_blank" >{{data.steps[selectedIndex].link}}</a>
                    
                    </p>
                    <div class="row">
                            <img id="img-page" *ngIf='data.steps[selectedIndex]&&data.steps[selectedIndex].image' [src]="data.steps[selectedIndex].image" [alt]="data.steps[selectedIndex].image">
                    </div>
                    <div *ngIf='data.steps[selectedIndex] && data.steps[selectedIndex].fields?.length > 0 && data.inputs.length > 0'>
                        <div class="margin-top">
                            <div class="form-group" *ngFor="let di of data.steps[selectedIndex].fields; let i = index;">
                                   <ng-container *ngIf="checkId(di,'if')">
                                        <label class="semi-14 pl-1 m-0 c-tag-3 mb-3">{{data.inputs[checkId(di,'input')].name}}
                                            </label>
                                            <input class="p-4 form-control-input reg-14" type="text" [(ngModel)]="data.inputs[checkId(di,'input')].value" placeholder="{{data.inputs[checkId(di,'input')].name}}">
                                    </ng-container>
                                
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="[selectedIndex==0 ? 'mobile-footer' : '']" class="footer justify-content-between mt-4">
                    <button *ngIf="selectedIndex !=0 " [disabled]='selectedIndex==0'
                        (click)='selectedIndex = selectedIndex-1' class="btn btn-secondary button-prev mobile-bottom">
                         <span class="left-arrow">
                                <img
                                src="../../../assets/images/services/leftArrow.png"
                                alt="Left Arrow"
                            />
                        </span><span>PREV</span></button>
                        <button [hidden]='selectedIndex == data.steps.length - 1' (click)='selectedIndex = selectedIndex+1' class="btn btn-secondary button-next">
                           <span>NEXT</span>
                           <span class="right-arrow">
                                <img
                                src="../../../assets/images/services/rightArrow.png"
                                alt="Right Arrow"
                            />
                        </span>
                        </button>
                        <button [hidden]='selectedIndex != data.steps.length - 1' (click)='updateInputs()' class="btn btn-secondary button-next" data-dismiss="modal">
                            
                        <span>FINISH</span>
                        <span class="right-arrow">
                                <img
                                src="../../../assets/images/services/tick.png"
                                alt="Tick"
                            />
                        </span>
                        </button>
                    </div>
                
              </div>
            </div>
          </div>
</ng-container>