import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AccountManagerService } from '../../managers/account-manager.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  isLoading = false;
  constructor(private AuthService: AuthService,
    private accountManager: AccountManagerService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
  }
  close() {
    document.getElementById('emailVerfication').classList.remove("show")
    document.getElementById('emailVerfication').setAttribute("style", "display : none");
  }
  resendEmail() {
    this.isLoading = true;
    const resendEmailVerification = {
      email: this.accountManager.getCurrentEmail()
    }
    this.AuthService.verifyEmail(resendEmailVerification).subscribe({
      next: () => {
        this.isLoading = false;
        this.toastr.success('Verification email has been sent it to your email', '');
      }, error: (error) => {
        this.isLoading = false;
        let errorMessage = error.error ? error.error.message : error.message;
        this.toastr.error(errorMessage, '');
      }
    })
  }
}
