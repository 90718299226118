import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppService } from '../user/app.service';
import { MetaService } from '../meta-service/meta.service'

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  @ViewChild('contact') contactElement: ElementRef;
  @ViewChild('apps') appsElement: ElementRef;
  @ViewChild('home') homeElement: ElementRef;

  public homeOffset: any;
  public contactOffset: any;
  public appsOffset: any;
  activeLink = 'home';
  showNavbar = false;
  closeMenu : boolean = true;
  products;
  filteredproducts;
  searchedProduct;
  browser;


  constructor(
    private appService:AppService,
    private ngxService: NgxUiLoaderService,
    public metaService:MetaService,
    private route: Router) { }

    ngAfterViewInit() {
      this.homeOffset = this.homeElement.nativeElement.offsetTop;
      this.appsOffset = this.appsElement.nativeElement.offsetTop;
      this.contactOffset = this.contactElement.nativeElement.offsetTop;
    }

    @HostListener('window:scroll', ['$event'])
    checkOffsetTop() { 
      if (window.pageYOffset >= this.homeOffset && window.pageYOffset < this.appsOffset) {
        this.activeLink = 'home';
      } else if (window.pageYOffset >= this.appsOffset && window.pageYOffset < this.contactOffset+700) {
        this.activeLink = 'apps';
      } else if (window.pageYOffset > this.contactOffset+700) {
        this.activeLink = 'contact';
      }
    }

    goToSection(type){
      if(type == "home"){
        this.activeLink = "home";
        var element = document.getElementById('home');
        var elementPosition = element.getBoundingClientRect().top;
        window.scrollTo({top :elementPosition, behavior : 'smooth'});
      }

      if(type == "apps"){
        this.activeLink = "apps";
        var element = document.getElementById('apps');
        var headerOffset = 82;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({top :offsetPosition, behavior : 'smooth'});
      }

      if(type == "contact"){
        this.activeLink = "contact";
        var element = document.getElementById('contact');
        var headerOffset = 82;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({top :offsetPosition, behavior : 'smooth'});
      }
    }

    showNav(){
      this.showNavbar = !this.showNavbar;
    }
    
    close(){
      if(!this.showNavbar){
        this.closeMenu = false;
      }else if(this.showNavbar){
        this.closeMenu = true;
      }
      else if(this.showNavbar && this.closeMenu){
        this.closeMenu = true;
        this.showNavbar = false;
      }
    }

    ngOnInit(): void {
      this.setTitleAndDescription();
      this.appService.getAllProducts().then(response => {
        if(response){
          this.products = response;
          this.productResponeChanges();
        }
      })
      this.myBrowser();
    }

    setTitleAndDescription(){
      let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, product, listing';
      let description = 'Orderax Products where you can view all our Mobile Ordering Apps with name, price, availability and description';
      this.metaService.setPageTitleAndMetaTags('Choose your Mobile Ordering app Products', description,keywords, 'https://orderax.com/products')
    }

    myBrowser() {
      if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        this.browser = "Opera";
      } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
        this.browser = "Chrome";
      } else if(navigator.userAgent.indexOf("Safari") != -1) {
        this.browser = "Safari";
      } else if(navigator.userAgent.indexOf("Firefox") != -1 ) {
        this.browser = "Firefox";
      } else {
        this.browser = "unknown";
      }
    }

    productResponeChanges(){
      this.products.map((d,i) =>{
        d['index'] = i
      })
      this.products.sort(function(x, y) {
      return (x.isAvailable === y.isAvailable)? 0 : x? -1 : 1;
      });
      this.filteredproducts = this.products
    }


    goToLogin(){
      this.route.navigate(['/login']);
    }

    goToSocial(type) {
      if(type == 'instagram') {
        window.open(`https://www.instagram.com/orderaxapp/?hl=en`, '_blank', 'noopener');
      }
      if(type == 'facebook') {
        window.open(`https://web.facebook.com/orderax`, '_blank', 'noopener');
      }
      if(type == 'twitter') {
        window.open(`https://www.twitter.com/orderax1`, '_blank', 'noopener');
      }
      if(type == 'youtube') {
        window.open(`https://www.youtube.com/channel/UCnAEXlmsER86iLQwnysyHUQ`, '_blank', 'noopener');
      }
      if(type == 'google') {
        window.open(`https://g.page/r/CdxuoeNDxtG_EAE`, '_blank', 'noopener');
      }
    }

    getStarted(){
      this.route.navigate(['/registration']);
    }

    openDetails(name){
      if(name == 'res'){
        this.route.navigate(['product/restaurant']);
      }
      if(name == "blog"){
        this.route.navigate(['product/blog']);
      }
      if(name == "cim"){
        this.route.navigate(['product/chat&messenger']);
      }
      if(name == "pay"){
        this.route.navigate(['product/payment']);
      }
      if(name == "vod"){
        this.route.navigate(['product/videoOnDemand']);
      }
      if(name == "loy"){
        this.route.navigate(['product/loyalty']);
      }
      if(name == "odd"){
        this.route.navigate(['product/onDemandDelivery']);
      }
      if(name == "shp"){
        this.route.navigate(['product/shopping']);
      }
    }

    goToDetails(type, code){
      if(type == 'theme') {
        if(code == 'res') {
          this.route.navigate(['product/restaurant/theme']);
        }
        if(code == "blog"){
          this.route.navigate(['product/blog/theme']);
        }
        if(code == "cim"){
          this.route.navigate(['product/chat&messenger/theme']);
        }
        if(code == "pay"){
          this.route.navigate(['product/payment/theme']);
        }
        if(code == "vod"){
          this.route.navigate(['product/videoOnDemand/theme']);
        }
        if(code == "loy"){
          this.route.navigate(['product/loyalty/theme']);
        }
        if(code == "odd"){
          this.route.navigate(['product/onDemandDelivery/theme']);
        }
        if(code == "shp"){
          this.route.navigate(['product/shopping/theme']);
        }
      }
      if(type == 'plan'){
        if(code == 'res'){
          this.route.navigate(['product/restaurant/plan']);
        }
        if(code == "blog"){
          this.route.navigate(['product/blog/plan']);
        }
        if(code == "cim"){
          this.route.navigate(['product/chat&messenger/plan']);
        }
        if(code == "pay"){
          this.route.navigate(['product/payment/plan']);
        }
        if(code == "vod"){
          this.route.navigate(['product/videoOnDemand/plan']);
        }
        if(code == "loy"){
          this.route.navigate(['product/loyalty/plan']);
        }
        if(code == "odd"){
          this.route.navigate(['product/onDemandDelivery/plan']);
        }
        if(code == "shp"){
          this.route.navigate(['product/shopping/plan']);
        }
      }
    }

    goToHome(){
      this.route.navigate(['/']);
    }
}
