<div class="modal modal-backdrop1 fade" id="updateCategory" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-category" id="modal" role="document">
        <div class="modal-content modal-content-cate">
            <div *ngIf="!addNew" class="modal-body">
                <h6 class="d-inline-block mb-0 c-tag-1 bold uphead pl-2">Update Categories</h6>
                <div class="select-category mt-4">
                    <h6 class="bold selectcate pl-2">Selected Categories</h6>
                    <div class="text-danger pl-2">{{selectMessage}}</div>
                    <p class="countitem pl-2" *ngIf="limit">You need to select {{limit}} categories</p>
                    <div class="selected-categories">
                        <div class="row" [ngClass]="{'space-between' : selectedItems.length>1}">
                            <div *ngFor="let category of selectedItems"
                            [style.background]="'url('+category?.imageUrls[0]+')'"
                                class="category-boxes-top col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6"><i
                                    (click)="delete(category)" class="fas fa-trash-alt"></i>
                                <p class="boxname">{{category.name}}</p>
                                <div [style.background]="category?.color" class="img-filter"></div>
                            </div>
                            <div class="category-boxes-4 p-2">Add category from below</div>
                        </div>
                    </div>
                </div>
                <div class="select-category-down">
                    <h6 class="bold dcate pl-2 mb-2">Category</h6>
                    <ng-container *ngIf="isLoading">
                            <div class="to-selected-categories">
                                <div class="loader p-0">
                        
                                    <div class="col-12 font-weight-bold ml-2 mt-5">
                                        <span class="spinner-border spinner-border-sm justify-content-center" role="status"
                                            aria-hidden="true"></span>
                        
                                    </div>
                                </div>
                            </div>
                    </ng-container>
                    <ng-container *ngIf="!isLoading">
                        <div class="to-selected-categories">
                            <div class="h-70 p-0">
                                <div class="p-0" *ngIf="categories.length == 0">
                                    <div class="col-12 font-weight-bold mt-5">
                                        <h3>Click the Add Button below
                                            to add New Category</h3>
                                    </div>
                                </div>
                                <div class="row m-0" *ngIf="categories.length > 0">
                                    <div *ngFor="let category of categories"
                                        [style.background]="'url('+category?.imageUrls[0]+')'"
                                        class="category-boxes-down col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 plus"><i
                                            (click)="add(category)" class="fas fa-plus"></i>
                                        <p class="boxname">{{category.name}}</p>
                                        <span (click)="redirectCategory(category.id)" type="button" class="edit-button">
                                            <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.66667 1L6 2.33333L2.33333 6H1V4.66667L4.66667 1Z" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M1 8H7" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <div  class="edit-button-popup">EDIT</div>
                                        </span>
                                        <div [style.background]="category?.color" class="img-filter"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="add-new">
                        <h6 class="addnew pl-3">Not finding the right category?</h6>
                        <p class="addnewp pl-3">Add a new category</p>
                    </div>
                    <div class="form-group add-input d-flex pl-2">
                        <input maxlength="20" placeholder="Enter category" type="text" [(ngModel)]="aditional"
                            class="form-control input">
                        <div class="btn-back">
                            <span (click)="redirectCategory()">
                                <p>ADD +</p>
                            </span>
                        </div>
                    </div>
                    <div class="final-buttons">
                        <button data-dismiss="modal" aria-label="Close" class="btn cancel">Cancel <i
                                class="fas fa-times mx-1"></i></button>
                        <button (click)="confirm()" data-dismiss="modal" class="btn confirmbtn">Confirm <i
                                class="fas fa-check mx-1"></i></button>
                    </div>
                    <div *ngIf="error" class="text-danger d-block text-center m-0 pb-2">{{error}}</div>
                    <div *ngIf="message" class="text-success d-block text-center mb-0 pb-2">{{message}}</div>
                </div>
            </div>
            <div *ngIf="addNew" class="category-add-new">
                <app-edit-category (addCategoryIntoListItem)="addCategoryToList($event)" [itemsRec]="itemsToSend"></app-edit-category>
            </div>
        </div>
    </div>
</div>