import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { stores } from "../shared/store";
import { AccountManagerService } from '../managers/account-manager.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../user/app.service';
import { PublicService } from 'src/app/public/public.service';
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})

export class ShopComponent implements OnInit {
  search = '';
  isLoading = false;
  productLoader = false;
  products = []
  filteredProducts = []
  selectedProduct ;
  storeName = new FormControl('', [Validators.required, Validators.minLength(3)])
  
  constructor(private router:Router,
    private toastr: ToastrService,
    private accountManager: AccountManagerService,
    private appService:AppService,
    public publicService:PublicService,) { }

  ngOnInit(): void {
    this.getProducts();
  }
  
  getProducts() {
    this.productLoader = true;
      this.appService.getAllProducts().then(response => {
        if(response){
          this.products = Object.values(response);
        }
        this.productResponeChanges();
      })
    
    
  }
  productResponeChanges(){
    this.products.map((d,i) =>{
      d['index'] = i
    })
    this.products.sort(function(x, y) {
    return (x.isAvailable === y.isAvailable)? 0 : x? -1 : 1;
    });
    this.filteredProducts = this.products
    this.productLoader = false;
  }
  
  filterProducts() {
    this.filteredProducts = this.products.filter( product => {
      return product.title.includes(this.search)
    })
  }

  addProducts(product) {
    this.selectedProduct = product
  }

  createShop() {
    this.publicService.logAnalytics('create-shop-btn-clicked')
    if (this.storeName.valid && this.selectedProduct && this.selectedProduct?.isAvailable ) { 
      this.isLoading = true;
      this.accountManager.createShop(this.storeName.value, this.selectedProduct.id)
      .then((resp) => {
        if(resp){
          this.isLoading = false;
          this.toastr.success('Your Shop has been created', '');
          document.getElementById('app-popup-close').click();
          this.router.navigate([`home/shop-details/${resp.project.id}`])
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.toastr.error(error.error.message, '');
      });
    } else{
      if (!this.storeName.valid)
        this.toastr.error('Enter a name for shop', '');
      else if(!this.selectedProduct?.isAvailable)
        this.toastr.error(`${this.selectedProduct?.name} Product Coming Soon`, '');
      else
        this.toastr.error('Please select some product for your shop', '');
    }
  }
  selectionClass(product){
    let abc= this.selectedProduct && this.selectedProduct.index == product.index ? true : false;
    if(this.selectedProduct){
      if(this.selectedProduct.index == product.index){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
   
  }
}
