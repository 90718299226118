import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppService } from '../user/app.service';
import { MetaService } from '../meta-service/meta.service'

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {
  @ViewChild('contact') contactElement: ElementRef;
  @ViewChild('tools') toolsElement: ElementRef;
  @ViewChild('home') homeElement: ElementRef;
  public homeOffset: any;
  public toolsOffset: any;
  public contactOffset: any;
  home = ' ';
  activeLink = 'home';
  activeMarket = 'featured';
  productDetails;
  testimonials;
  showNavbar = false;
  closeMenu : boolean = true;
  toolBoxes = [1, 2, 3, 4, 5, 6, 7];
  resourcesPost = [];
  products

  constructor(
    private appService:AppService,
    private ngxService: NgxUiLoaderService,
    private metaService: MetaService,
    private route: Router) { }

  ngOnInit(): void {
    this.setTitleAndDescription();
    this.resourcesPost = [
      {id : 'custom-mobile-ordering-app-promo', name : "Custom Mobile Ordering App Promo", description : "Orderax Business Video Promo. If you are a Restaurant, Cafe, Coffee, Cannabis or store looking for a custom mobile ordering app for your business?", link : "https://www.youtube.com/watch?v=J_AWDX3WaUI"},
      {id : 'admin-app-for-businesses-looking-for-a-custom-mobile-ordering-app', name : "Admin App for Businesses looking for a Custom Mobile Ordering app", description : "A short demo of our Admin App. If you are a Restaurant, Cafe, Coffee, Cannabis or store looking for a custom mobile ordering app for your business?", link : "https://www.youtube.com/watch?v=iZjP9zSAAl0"},
      {id : 'customer-app-for-businesses-looking-for-a-custom-mobile-ordering-app', name : "Customer App for Businesses looking for a Custom Mobile Ordering app", description : "A short demo of our Customer App. If you are a Restaurant, Cafe, Coffee, Cannabis or store looking for a custom mobile ordering app for your business?", link : "https://www.youtube.com/watch?v=MOHomZc8BZ4"}
    ]
  }

  setTitleAndDescription(){
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, resources';
    let description = 'Use our Resources to learn about Custom Mobile Ordering App Promo video, Admin App for Businesses business, looking for a Custom Mobile Ordering app video';
    this.metaService.setPageTitleAndMetaTags(`See our resources to help you with your Ordering app | OrderAx`, description, keywords, 'https://orderax.com/resources')
  }

  ngAfterViewInit() {
    this.contactOffset = this.contactElement.nativeElement.offsetTop;
    this.toolsOffset = this.toolsElement.nativeElement.offsetTop;
    this.homeOffset = this.homeElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  checkOffsetTop() {
    
    if (window.pageYOffset >= this.homeOffset && window.pageYOffset < this.toolsOffset) {
      this.activeLink = 'home';
    }
    else if (window.pageYOffset >= this.toolsOffset && window.pageYOffset < this.contactOffset) {
      this.activeLink = 'tools';
    }
    else if (window.pageYOffset > this.contactOffset) {
      this.activeLink = 'contact';
    }
  }


  goToSection(type){
    if(type == "home"){
      this.activeLink = "home";
      var element = document.getElementById('home');
      var elementPosition = element.getBoundingClientRect().top;
      window.scrollTo({top :elementPosition, behavior : 'smooth'});
    }
    if(type == "tools"){
      this.activeLink = "tools";
      var element = document.getElementById('tools');
      var headerOffset = 82;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({top :offsetPosition, behavior : 'smooth'});
    }
    if(type == "contact"){
      this.activeLink = "contact";
      var element = document.getElementById('contact');
      var headerOffset = 82;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({top :offsetPosition, behavior : 'smooth'});
    }
  }

  openTool(type){
    if(type == 'theme'){
      this.route.navigate(['/tools/free-restaurant-app-theme']);
    } else if(type == 'screenshot'){
      this.route.navigate(['/tools/free-restaurant-app-screenshot-generator']);
    } else if(type == 'calculator') {
      this.route.navigate([`/tools/free-kitchen-calculator`]);
    } else {
      this.route.navigate([`/tools/${type}`])
    }
  }

  openResource(id){
    this.route.navigate([`/resources/${id}`]);
  }

  showNav(){
    this.showNavbar = !this.showNavbar;
  }
  
  close(){
    if(!this.showNavbar){
      this.closeMenu = false;
    }else if(this.showNavbar){
      this.closeMenu = true;
    }
    else if(this.showNavbar && this.closeMenu){
      this.closeMenu = true;
      this.showNavbar = false;
    }
  }

  goToLogin(){
    this.route.navigate([`/login`]);
  }

  getStarted(){
    this.route.navigate([`/registration`]);
  }

  goToHome(){
    this.route.navigate(['/'])
  }

  goToSocial(type) {
    if(type == 'instagram') {
      window.open(`https://www.instagram.com/orderaxapp/?hl=en`, '_blank', 'noopener');
    }
    if(type == 'facebook') {
      window.open(`https://web.facebook.com/orderax`, '_blank', 'noopener');
    }
    if(type == 'twitter') {
      window.open(`https://www.twitter.com/orderax1`, '_blank', 'noopener');
    }
    if(type == 'youtube') {
      window.open(`https://www.youtube.com/channel/UCnAEXlmsER86iLQwnysyHUQ`, '_blank', 'noopener');
    }
    if(type == 'google') {
      window.open(`https://g.page/r/CdxuoeNDxtG_EAE`, '_blank', 'noopener');
    }
  }

}
