import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Subject }  from 'rxjs';
import { environment }     from '../../environments/environment';
import { delay} from 'rxjs/operators';
declare const google: any;

@Directive({
    selector: '[googleLogin]'
})
export class GoogleLoginDirective implements AfterViewInit {

    @Output() googleLogin : EventEmitter<string> = new EventEmitter<string>();
    @Input() context: string = 'Register';

    private loadGoogleSubject$: Subject<void> = new Subject<void>();

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone
    ) {}

    ngAfterViewInit() {
        this.loadGoogleSubject$.pipe(delay(500)).subscribe(() => {
            google.accounts.id.initialize({
                client_id: environment.clientId,
                callback: (event: {clientId: string, client_id: string, credential: string, select_by: string}) => {
                    this.zone.run(() => { this.googleLogin.emit(event.credential) })
                },
                auto_select: false,
                cancel_on_tap_outside: true,
                context: 'Register'
            });
            google.accounts.id.renderButton(this.elementRef.nativeElement,
                { theme: "outline", size: "large", text: 'continue_with' }  // customization attributes
            );
            google.accounts.id.prompt(); // also display the One Tap dialog
        })
        this.loadGoogleSubject$.next();
    }
}
