<div class="container-fluid cf-xs">
    <div class="header-body" id="header-body">
        <div class="row align-items-center py-4">
            <div class="col-lg-12">
                <div class="header-title pb-sm-4 pb-0">
                    <h6 class="h2 d-inline-block mb-0 c-tag-1 bold-26">Create Shop
                    </h6>
                </div>
                <div>
                    <h2 class="bold-16 my-sm-4 mt-2 mb-4">
                        <span *ngIf="selectedTab == 'ios'">
                            Add iOS App Store Details
                        </span>
                        <span *ngIf="selectedTab == 'android'">
                            Add Android App Store Details
                        </span>
                        <span *ngIf="selectedTab == 'web'">
                            Add Web Store Details
                        </span>
                    </h2>
                </div>
                <ng-container *ngIf="isLoading">
                    <div class="col-12 font-weight-bold ml-5">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isLoading">
                    <div class="row m-0 flex-column-reverse1">
                        <div class="col-12 col-md-12 col-lg-7  col-sm-12 p-0">
                            <div class="pr-0 pr-lg-5 pr-sm-0 ">
                                <ul class="nav nav-tabs" id="protoTypeID" role="tablist">
                                    <li class="nav-item" role="presentation">

                                        <a [ngClass]="selectedTab == 'ios' ? 'active' : ''" class="nav-link border-0"
                                            id="app-store-tab" data-toggle="tab" (click)='switch("ios")' role="tab"
                                            aria-controls="app-store" aria-selected="true">App
                                            Store</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a [ngClass]="selectedTab == 'android' ? 'active' : ''" class="nav-link"
                                            id="playstore-tab" data-toggle="tab" (click)='switch("android")' role="tab"
                                            aria-controls="playstore" aria-selected="false">Playstore</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a [ngClass]="selectedTab == 'web' ? 'active' : ''" class="nav-link"
                                            id="website-tab" data-toggle="tab" (click)='switch("web")' role="tab"
                                            aria-controls="website" aria-selected="false">Website</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="pt-4">
                                <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="app-store-tab">
                                    <ng-container *ngIf="selectedTab == 'ios'">
                                        <div [formGroup]='iosForm' class="pt-3">
                                            <div class="">
                                                <app-image-upload (deleteImage)="deleteImage('ios')"
                                                    (sendImage)="onFileChangeslogoImage($event,'ios')" [data]="imagesData.ios"
                                                    [error]="ioslogoImageError" [imageUrl]="ioslogoImage">
                                                </app-image-upload>
                                            </div>
                                            <div class="pt-32">
                                                <label for="validationTextarea" class="fw-700 c-tag-3 mb-12">What’s
                                                    new in this version?</label>
                                                <textarea
                                                    [ngClass]="[iosForm.get('releaseNotes').hasError('required') && iosForm.get('releaseNotes').touched ? 'error' : '', iosForm.value['releaseNotes'] != '' ? 'valid-correct' : '']"
                                                    formControlName="releaseNotes" maxlength="4000"
                                                    class="form-control resizeNone h-148 bg-formInputColor"
                                                    id="validationTextarea"
                                                    placeholder="Describe what’s new in this version"
                                                    required></textarea>
                                                <div class="text-right pt-2 f-14">Maximum character 4000</div>

                                            </div>
                                            <div class="pt-32">
                                                <label for="validationTextarea1"
                                                    class=" fw-700 c-tag-3 mb-12">Promotional Text</label>
                                                <textarea
                                                    [ngClass]="[iosForm.get('promotionalText').hasError('required') && iosForm.get('promotionalText').touched ? 'error' : '', iosForm.value['promotionalText'] != '' ? 'valid-correct' : '']"
                                                    formControlName="promotionalText" maxlength="170"
                                                    class="form-control resizeNone h-148  bg-formInputColor"
                                                    id="validationTextarea1" placeholder="Enter Promotional Text"
                                                    required></textarea>
                                                <div class="text-right pt-2 f-14">Maximum character 170</div>
                                            </div>
                                            <div class="pt-32">
                                                <label for="validationTextarea2"
                                                    class=" fw-700 c-tag-3 mb-12">Description</label>
                                                <textarea
                                                    [ngClass]="[iosForm.get('description').hasError('required') && iosForm.get('description').touched ? 'error' : '', iosForm.value['description'] != '' ? 'valid-correct' : '']"
                                                    formControlName="description" maxlength="4000"
                                                    class="form-control resizeNone h-148  bg-formInputColor"
                                                    id="validationTextarea2" placeholder="Enter Description"
                                                    required></textarea>
                                                <div class="text-right pt-2 f-14">Maximum character 4000</div>
                                            </div>
                                            <div class="pt-32">
                                                <label for="validationServer01"
                                                    class=" fw-700 c-tag-3 mb-12">Name</label>
                                                <input type="text"
                                                    [ngClass]="[iosForm.get('appName').hasError('required') && iosForm.get('appName').touched ? 'error' : '', iosForm.value['appName'] != '' ? 'valid-correct' : '']"
                                                    formControlName="appName" maxlength="30"
                                                    class="form-control valid bg-formInputColor h-42"
                                                    id="validationServer01" placeholder="Enter Name" required>
                                                <div class="text-right pt-2 f-14">Maximum character 30</div>
                                            </div>
                                            <div class="pt-32">
                                                <label for="validationServer02"
                                                    class=" fw-700 c-tag-3 mb-12">Subtitle</label>
                                                <input type="text"
                                                    [ngClass]="[iosForm.get('subtitle').hasError('required') && iosForm.get('subtitle').touched ? 'error' : '', iosForm.value['subtitle'] != '' ? 'valid-correct' : '']"
                                                    formControlName="subtitle" maxlength="30"
                                                    class="form-control valid bg-formInputColor h-42"
                                                    id="validationServer02" placeholder="Enter Subtitle" required>
                                                <div class="text-right pt-2 f-14">Maximum character 30</div>
                                            </div>
                                            <div class="pt-32">

                                                <label for="ios" class="fw-700 c-tag-3 mb-12">Keywords</label>
                                                <mat-form-field
                                                    class="example-chip-list form-control valid bg-formInputColor height-auto"
                                                    [ngClass]="iosTagError ? 'error' : ''" appearance="none">
                                                    <mat-chip-list #chipList aria-label="Keywords selection">
                                                        <mat-chip *ngFor="let tag of tagsIos; let i = index"
                                                            [selectable]="selectable" [removable]="removable"
                                                            (removed)="remove(i,'ios')" class="bg-white">
                                                            {{tag}}
                                                            <button matChipRemove *ngIf="removable">
                                                                <img class="cross-icon"
                                                                    src="../../assets/images/cross.png">
                                                            </button>
                                                        </mat-chip>
                                                        <input
                                                            [placeholder]="tagsIos.length == 0 ? 'Enter Keywords' : ''"
                                                            [matChipInputFor]="chipList" formControlName="keywords"
                                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                            [matChipInputAddOnBlur]="addOnBlur"
                                                            maxlength="30"
                                                            (matChipInputTokenEnd)="add($event,'ios')">
                                                    </mat-chip-list>
                                                </mat-form-field>

                                                <div class="text-right pt-2 f-14">Please type keyword and press
                                                    enter to add it</div>
                                            </div>
                                            <div class="pt-32">
                                                <label for="validationServer4"
                                                    class=" fw-700 c-tag-3 mb-12">Category</label>

                                                <select formControlName="primaryCategory"
                                                    [ngClass]="[iosForm.get('primaryCategory').hasError('required') && iosForm.get('primaryCategory').touched ? 'error' : '', iosForm.value['primaryCategory'] != '' ? 'valid-correct' : '']"
                                                    class="form-control valid bg-formInputColor h-42">
                                                    <option disabled [value]="''"><span class="disable-color">Select
                                                            Category</span></option>
                                                    <option disabled [value]="category" *ngFor='let category of iosCategories'>
                                                        {{category}}</option>

                                                </select>


                                            </div>
                                            <div class="pt-32">
                                                <label for="validationServer4" class=" fw-700 c-tag-3 mb-12">Sub
                                                    Category</label>
                                                <select formControlName="secondaryCategory"
                                                    [ngClass]="[iosForm.get('secondaryCategory').hasError('required') && iosForm.get('secondaryCategory').touched ? 'error' : '', iosForm.value['secondaryCategory'] != '' ? 'valid-correct' : '']"
                                                    class="form-control valid bg-formInputColor h-42">
                                                    <option disabled [value]="''"><span class="disable-color">Select Sub
                                                            Category</span></option>
                                                    <option disabled [value]="category" *ngFor='let category of iosCategories'>
                                                        {{category}}</option>
                                                </select>



                                            </div>


                                            <div class="pt-32">
                                                <label for="validationServer06" class=" fw-700 c-tag-3 mb-12">Marketing
                                                    URL</label>
                                                <input type="text"
                                                    [ngClass]="[iosForm.get('marketingURL').hasError('required') && iosForm.get('marketingURL').touched ? 'error' : '',
                                                    iosForm['controls'].marketingURL.errors?.pattern && iosForm.get('marketingURL').touched ? 'error' : '',
                                                    iosForm.value['marketingURL'] != '' ? 'valid-correct' : '']"
                                                    formControlName="marketingURL"
                                                    class="form-control valid bg-formInputColor h-42"
                                                    id="validationServer06" placeholder="Add Marketing URL" required>

                                            </div>

                                        </div>

                                    </ng-container>
                                    <ng-container *ngIf="selectedTab == 'android'">
                                        <div [formGroup]='androidForm' class="pt-3">
                                            <div class="">
                                                <app-image-upload (deleteImage)="deleteImage('android')"
                                                    (sendImage)="onFileChangeslogoImage($event,'android')"
                                                    [data]="imagesData.android" [error]="androidlogoImageError"
                                                    [imageUrl]="androidlogoImage"></app-image-upload>
                                            </div>
                                            <div class="pt-32">
                                                <label for="validationServer01"
                                                    class=" fw-700 c-tag-3 mb-12">Title</label>
                                                <input type="text"
                                                    [ngClass]="[androidForm.get('title').hasError('required') && androidForm.get('title').touched ? 'error' : '', androidForm.value['title'] != '' ? 'valid-correct' : '']"
                                                    formControlName="title" maxlength="30"
                                                    class="form-control valid bg-formInputColor h-42"
                                                    id="validationServer01" placeholder="Enter Title" required>
                                                <div class="text-right pt-2 f-14">Maximum character 30</div>
                                            </div>
                                            <div class="pt-32">
                                                <label for="validationTextarea1" class=" fw-700 c-tag-3 mb-12">Short
                                                    Description</label>
                                                <textarea
                                                    [ngClass]="[androidForm.get('shortDescription').hasError('required') && androidForm.get('shortDescription').touched ? 'error' : '', androidForm.value['shortDescription'] != '' ? 'valid-correct' : '']"
                                                    formControlName="shortDescription" maxlength="80"
                                                    class="form-control resizeNone h-148  bg-formInputColor"
                                                    id="validationTextarea1" placeholder="Enter Short Description"
                                                    required></textarea>
                                                <div class="text-right pt-2 f-14">Maximum character 80</div>
                                            </div>
                                            <div class="pt-32">
                                                <label for="validationTextarea1" class=" fw-700 c-tag-3 mb-12">Full
                                                    Description</label>
                                                <textarea
                                                    [ngClass]="[androidForm.get('fullDescription').hasError('required') && androidForm.get('fullDescription').touched ? 'error' : '', androidForm.value['fullDescription'] != '' ? 'valid-correct' : '']"
                                                    formControlName="fullDescription" maxlength="4000"
                                                    class="form-control resizeNone h-148  bg-formInputColor"
                                                    id="validationTextarea1" placeholder="Enter Full Description"
                                                    required></textarea>
                                                <div class="text-right pt-2 f-14">Maximum character 4000</div>
                                            </div>

                                            <div class="pt-32">
                                                <app-image-upload (deleteImage)="deleteImage('feature')"
                                                    (sendImage)="onFileChangeslogoImage($event,'feature')"
                                                    [data]="imagesData.feature" [error]="feactureIconError"
                                                    [imageUrl]="featureIcon"></app-image-upload>
                                            </div>
                                            <div class="pt-32">

                                                <label class="fw-700 c-tag-3 mb-12">Tags</label>
                                                <mat-form-field
                                                    class="example-chip-list form-control valid bg-formInputColor height-auto"
                                                    [ngClass]="androidTagError ? 'error' : ''" appearance="none">
                                                    <mat-chip-list #chipList aria-label="Tags selection">
                                                        <mat-chip *ngFor="let tag of tagsAndroid; let i = index"
                                                            [selectable]="selectable" [removable]="removable"
                                                            (removed)="remove(i,'android')" class="bg-white">
                                                            {{tag}}
                                                            <button matChipRemove *ngIf="removable">
                                                                <img class="cross-icon"
                                                                    src="../../assets/images/cross.png">
                                                            </button>
                                                        </mat-chip>
                                                        <input
                                                            [placeholder]="tagsAndroid.length == 0 ? 'Enter Tags' : ''"
                                                            [matChipInputFor]="chipList" formControlName="tags"
                                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                            [matChipInputAddOnBlur]="addOnBlur"
                                                            (matChipInputTokenEnd)="add($event,'android')">
                                                    </mat-chip-list>
                                                </mat-form-field>

                                                <div class="text-right pt-2 f-14">Please type keyword and press
                                                    enter to add it</div>
                                            </div>
                                            <div class="pt-32">
                                                <label for="validationServer4"
                                                    class=" fw-700 c-tag-3 mb-12">Category</label>

                                                <select
                                                    [ngClass]="[androidForm.get('category').hasError('required') && androidForm.get('category').touched ? 'error' : '', androidForm.value['category'] != '' ? 'valid-correct' : '']"
                                                    formControlName="category"
                                                    class="form-control valid bg-formInputColor h-42">
                                                    <option disabled [value]="''">Select Category</option>
                                                    <option disabled [value]="category" *ngFor='let category of androidCategory'>
                                                        {{category}}</option>

                                                </select>


                                            </div>
                                            <div class="pt-32">
                                                <label for="validationServer4" class=" fw-700 c-tag-3 mb-12">App
                                                    Type</label>
                                                <select
                                                    [ngClass]="[androidForm.get('appType').hasError('required') && androidForm.get('appType').touched ? 'error' : '', androidForm.value['appType'] != '' ? 'valid-correct' : '']"
                                                    formControlName="appType"
                                                    class="form-control valid bg-formInputColor h-42">
                                                    <option disabled [value]="''">Select App Type</option>
                                                    <option disabled value="App">App</option>
                                                    <option disabled value="Game">Game</option>
                                                </select>



                                            </div>
                                            <div class="pt-32">
                                                <label for="validationServer4" class=" fw-700 c-tag-3 mb-12">Cost
                                                    Type</label>
                                                <select
                                                    [ngClass]="[androidForm.get('costType').hasError('required') && androidForm.get('costType').touched ? 'error' : '', androidForm.value['costType'] != '' ? 'valid-correct' : '']"
                                                    formControlName="costType"
                                                    class="form-control valid bg-formInputColor h-42">
                                                    <option disabled [value]="''">Select Cost Type</option>
                                                    <option disabled value="Free">Free</option>
                                                    <option disabled value="Paid">Paid</option>
                                                </select>



                                            </div>

                                            <div class="pt-32">
                                                <label for="validationServer06" class=" fw-700 c-tag-3 mb-12">Video
                                                    URL</label>
                                                <input type="text" formControlName="videoURL"
                                                    class="form-control valid bg-formInputColor h-42"
                                                    id="validationServer06" placeholder="Add Video URL" required
                                                    [ngClass]="[androidForm.get('videoURL').hasError('required') && androidForm.get('videoURL').touched ? 'error' : '',
                                                    androidForm['controls'].videoURL.errors?.pattern && androidForm.get('videoURL').touched ? 'error' : '', 
                                                    androidForm.value['videoURL'] != '' ? 'valid-correct' : '']">

                                            </div>
                                            <div class="pt-32">
                                                <label for="validationServer06" class=" fw-700 c-tag-3 mb-12">Website
                                                    URL</label>
                                                <input type="text" formControlName="website"
                                                    class="form-control valid bg-formInputColor h-42"
                                                    id="validationServer06" placeholder="Add Website URL" required
                                                    [ngClass]="[androidForm.get('website').hasError('required') && androidForm.get('website').touched ? 'error' : '', 
                                                    androidForm['controls'].website.errors?.pattern && androidForm.get('website').touched ? 'error' : '',
                                                    androidForm.value['website'] != '' ? 'valid-correct' : '']">

                                            </div>

                                        </div>

                                    </ng-container>
                                    <ng-container *ngIf="selectedTab == 'web'">
                                        <div class="text-primary h3 m-0 pt-5 text-center border-0 font-weight-bold">
                                            Coming Soon</div>
                                        <div class="pt-1  text-center">Please check back for this feature</div>
                                    </ng-container>
                                    <button (click)="next()" type="button" class="next-btn mt-5 mb-5"
                                        [disabled]="isSubmit">
                                        <span class="btn-text">NEXT</span>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                            *ngIf="isSubmit"></span>
                                    </button>
                                    <div class="error-block">
                                         <p class="error-field" *ngIf="iosForm?.controls?.marketingURL?.errors?.pattern">
                                            - Invalid Marketing Url in App Store
                                        </p>
                                        <p class="error-field" *ngIf="androidForm?.controls?.videoURL?.errors?.pattern">
                                            - Invalid video Url in Play Store
                                        </p>
                                        <p class="error-field" *ngIf="androidForm?.controls?.website?.errors?.pattern">
                                            - Invalid Webstie Url in Play Store
                                        </p>
                                       
                                        <p class="error-field" *ngIf="iosForm.status == 'INVALID' || ioslogoImageError || iosTagError">
                                            - Field Marked in Red in App Store are required
                                        </p>
                                        <p class="error-field" *ngIf="androidForm.status == 'INVALID' || androidlogoImageError || feactureIconError || androidTagError">
                                            - Field Marked in Red in Play Store are required
                                        </p>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
