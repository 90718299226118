import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PublicService } from 'src/app/public/public.service';
@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.css']
})

export class InstructionComponent implements OnInit {
  @Input() data;
  @Output() sendData = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Input() googleCloudId;
  selectedIndex;
  constructor(public publicService:PublicService) {}

  ngOnInit(){
    this.selectedIndex = 0;
  }
  
  updateInputs() {
    this.sendData.emit(this.data.inputs);
    this.selectedIndex = 0;
  }

  checkId(id,type){
    let value = '';
    this.data.inputs.map((d,i) => {
      if(d.id == id){
        value = type == 'if' ? d.id : i;
      }
    })

    if(type == 'if'){
      return value ? true : false
    } else {
      return value
    }
  }

  closedModal() {
    this.publicService.logAnalytics('close user service modal');
    this.closeModal.emit(true);
  }

  replaceDescription(value){
    let description = value;
    description = description.replace("{shopId}", this.googleCloudId ? this.googleCloudId : '');
    return description;
  }
}
