import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import { AppService } from '../../user/app.service';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { WizardComponent } from 'angular-archwizard';
import { AccountManagerService } from '../../managers/account-manager.service';
import { ToastrService } from 'ngx-toastr';
import { CountryDetails, PhoneNumberDetails } from 'src/app/auth/userData.model';
import { AnalyticsService } from 'src/app/auth/analytics.service';
import { PublicService } from '../../public/public.service';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.css']
})
export class CompleteProfileComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('businessAddress') businessAddress: ElementRef<HTMLElement>;
  @ViewChild(WizardComponent) public wizard: WizardComponent;
  @Input() routeFrom;
  @Input() selectedWizard;
  @Output() sendData = new EventEmitter();
  public file: File = null;
  snapshot: Observable<any>;
  businessForm: FormGroup;
  businessAddressSubmitted = false
  isLoading = false;
  countries: CountryDetails[] = [];
  shouldHidePostalCode = false;
  docUrl = '';
  flags: PhoneNumberDetails[];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private appService: AppService,
    private AuthService: AuthService,
    public route: ActivatedRoute,
    private accountManager: AccountManagerService,
    private toastr: ToastrService,
    private analyticsService: AnalyticsService,
    public publicService:PublicService
  ) {}

  ngOnInit(): void {
    this.getAppData()
    this.configureRouting()
    this.configureFormValidation()
    this.configurePage();
  }

  getAppData() {
    this.appService.getCountriesData()
    .then((res) => {
      this.countries = res;
      this.flags = this.appService.getFlagDetails();
      this.configurePage()
    })
    .catch(() => {
      this.configurePage()
    })
  }

  configureRouting() {
    if(this.selectedWizard == 'businessaddress' || this.selectedWizard == 'businesstype' || this.selectedWizard == 'contactInfo'){
      setTimeout(() => {
        this.wizard.goToStep(1);
        if(this.selectedWizard == 'businesstype' || this.selectedWizard == 'contactInfo'){
          setTimeout(() => {
            this.wizard.goToNextStep();
            if(this.selectedWizard == 'contactInfo'){
              setTimeout(() => {
                this.wizard.goToNextStep();
              }, 10);
            }
          }, 5);
        }
      }, 100);
    }
  }

  configureFormValidation() {
    this.businessForm = this.formBuilder.group({
        businessInfoGroup: this.formBuilder.group({
        businessName: ['', [Validators.required]],
        countryCode: ['', [Validators.required]],
        brandName: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        websiteURL: ['', [Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]],
      }),
      businessAddressGroup: this.formBuilder.group({
        line2: ['', ''],
        line1: ['', [Validators.required]],
        city: ['', [Validators.required]],
        state: ['', [Validators.required]],
        postalCode: ['', [Validators.required]],
        country: ['', Validators.required],
      }),
      businessTypeGroup: this.formBuilder.group({
        businessType: ['', [Validators.required]],
        businessNumber: ['', ''],
      }),
      
      aboutyouForm: this.formBuilder.group({
        firstname: ['', [Validators.required]],
        lastname: ['', [Validators.required]],
        countryCode: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        email: ['', [Validators.required, Validators.email]],
      }),
    });
  }

  private async configurePage() {
    let userInfo = this.accountManager.getUserData()
    if (userInfo.user.business.info == null) {
      this.accountManager.getUserInfo('normal')
      .then((userInfo) => {
        if (userInfo) {
          this.setFormData()
        }
      })
      .catch(() => {})
    } else {
      this.setFormData()
    }
  }

  setFormData() {
    let userInfo = this.accountManager.getUserData()
    let user = userInfo.user;
    let business = user.business
    let contact = user.contact
    let phone ;
    if(user.business.info.phone && this.flags) {
      phone = this.flags.find(flag => flag.callingCode == user.business.info.phone.callingCode);
    } else{
      phone = user.business?.info?.phone
    }
    if (userInfo.user.business.info != null) {
      this.businessForm.patchValue({
        businessInfoGroup: {
          businessName: business.info.businessName,
          brandName: business.info.brandName,
          phoneNumber: business.info.phoneNumber,
          websiteURL: business.info.websiteURL,
          countryCode: phone ? phone : '',
        },
      });
    }

    if (userInfo.user.business.address != null) {
      this.businessForm.patchValue({ 
        businessAddressGroup: {
          line1: business.address.line1,
          line2: business.address.line2,
          city: business.address.city,
          state: business.address.province,
          postalCode: business.address.postalCode,
          country: business.address.countryCode,
        },
      });
    }


    if (userInfo.user.business.legal != null) {
      this.businessForm.patchValue({ 
        businessTypeGroup: {
          businessNumber: business.legal.businessNumber,
          businessType: business.legal.type,
        },
      });
      this.docUrl = business.legal?.docURL;
    }

    if (userInfo.user.contact != null) {
      let contactPhoneNumber;
      if(user.contact.phone && this.flags){
        contactPhoneNumber = this.flags.find(flag => flag.callingCode == user.contact.phone.callingCode);
      }else{
        contactPhoneNumber = user.contact?.phone
      } 
      this.businessForm.patchValue({ 
        aboutyouForm: {
          firstname: contact.firstName,
          lastname: contact.lastName,
          phoneNumber: user.contact.phone.number,
          email: contact.email,
          countryCode: contactPhoneNumber ? contactPhoneNumber : '',
        },
      });
    }

    let countryData = this.countries.find(countryData => countryData.country.code == business.address.countryCode);
    if (countryData != null) {
      this.shouldHidePostalCode = !countryData.requiresPostalCode;
    }
  }

  get f() {
    return this.businessForm.controls;
  }

  getCountriesBusiness(countryName) {      
    let countryData = this.countries.find(countryData => countryData.country.name == countryName);

    if (countryData) {
      if (countryData.requiresPostalCode) {
        this.businessForm.get('businessAddressGroup').get('postalCode').enable();
        this.shouldHidePostalCode = false;
      } else {
        this.businessForm.get('businessAddressGroup').get('postalCode').disable();
        this.shouldHidePostalCode = true;
      }
    }
    return countryData;
  }

  setAddress(data) {
    let country = this.getCountriesBusiness(data.country);
    this.businessForm.patchValue({ 
      businessAddressGroup: {
        line1: data.line1 || '',
        line2: data.line2 || '',
        city: data.city || '',
        state: data.state || '',
        postalCode: data.postalCode || '',
        country: country.country.code || '',
      },
    });
  }

  onImagePicked(event: any): void {
    let fileType = event.target.files[0].name.split('.').pop();
    let fileTypes = ['docx', 'doc', 'pdf']
    if(!fileTypes.includes(fileType)){
      this.toastr.error('Only pdf and word doucments are allowed.', '');
      return;
    }else{
      this.file = event.target.files[0];
    }
  }

  logOut() {
    this.analyticsService.googleTag("logout");
    this.AuthService.logOut();
  }
  
  onSaveBusinessInfo() {
    this.analyticsService.googleTag("businessInfoSave");
    this.isLoading = true;
    const businessName = this.businessForm.value.businessInfoGroup.businessName;
    const brandName = this.businessForm.value.businessInfoGroup.brandName;
    const websiteURL = this.businessForm.value.businessInfoGroup.websiteURL;
    const countryCode = this.businessForm.value.businessInfoGroup.countryCode;
    const phoneNumber = this.businessForm.value.businessInfoGroup.phoneNumber;

    const phone: PhoneNumberDetails = {
      countryCode: countryCode?.countryCode,
      callingCode: countryCode?.callingCode,
      number: phoneNumber
    }

    this.accountManager
      .updateBusinessInfo(businessName, brandName, websiteURL, phone)
      .then(() => {
        this.isLoading = false;
        this.toastr.success(
            'Your business information has been updated.', '');
        this.setFormData()
        this.wizard.goToStep(1);
      })
      .catch((error) => {
        this.isLoading = false;
        this.toastr.error(error.error.message, '');
      });
  }

  onSaveBusinessAddress() {
    this.analyticsService.googleTag("businessAddressSave");
    this.isLoading = true;
    const line2 = this.businessForm.value.businessAddressGroup.line2;
    const line1 = this.businessForm.value.businessAddressGroup.line1;
    const city = this.businessForm.value.businessAddressGroup.city;
    const state = this.businessForm.value.businessAddressGroup.state;
    const country = this.businessForm.value.businessAddressGroup.country;

    var postalCode = this.businessForm.value.businessAddressGroup.postalCode;
     
    if (postalCode == null) {
      postalCode = ''
    }

    this.accountManager
      .updateBusinessAddress(line1, line2, city, state, postalCode, country)
      .then(() => {
        this.isLoading = false;
        this.toastr.success('Your business Address has been updated.', '');
        this.setFormData()
        this.wizard.goToStep(2);
      })
      .catch((error) => {
        this.isLoading = false;
        this.toastr.error(error.error.message, '');
      })
  }

  onSaveBusinessType() {
    this.analyticsService.googleTag("businessTypeSave");
    this.isLoading = true;

    this.updateBusinessTypeData();
  }

  private updateBusinessTypeData() {
    
    let obj = {
      data : {
        businessNumber: this.businessForm.value.businessTypeGroup.businessNumber,
        type:this.businessForm.value.businessTypeGroup.businessType
      }
      
    };
    if(this.file && this.file.name){
      obj['docURL'] = this.file;
    }else{
      obj.data['docURL'] = this.docUrl;
    }
  
    let businessTypeObj = this.publicService.makeFormData(obj);
    this.accountManager
    .updateBusinessType(businessTypeObj)
    .then(() => {
      this.isLoading = false;
      this.toastr.success('Your business information has been updated.', '');
      this.setFormData()
      this.wizard.goToStep(3);
    })
    .catch((error) => {
      this.isLoading = false;
      this.toastr.error(error.error.message, '');
    })
  }

  onSubmitAboutYou() {
    this.analyticsService.googleTag("contactInfoSave");
    this.isLoading = true;
    const firstName = this.businessForm.value.aboutyouForm.firstname;
    const lastName = this.businessForm.value.aboutyouForm.lastname;
    const email = this.businessForm.value.aboutyouForm.email;
    const countryCode = this.businessForm.value.aboutyouForm.countryCode;
    const phone: PhoneNumberDetails  = {
      countryCode: countryCode?.countryCode,
      callingCode:countryCode?.callingCode,
      number:this.businessForm.value.aboutyouForm.phoneNumber
    }
    this.accountManager
      .updateAboutYou(
        firstName,
        lastName,
        email,
        phone
      )
      .then(() => {
        this.isLoading = false;
        this.toastr.success('Your about info has been updated.', '');
        this.sendData.emit(true);
        this.closebutton.nativeElement.click();
      })
      .catch((error) => {
        this.isLoading = false;
        this.toastr.error(error.error.message, '');
      })
  }
  openUrl(url){
    window.open(url, '_blank');
  }
}


