import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  categoryEdit = new BehaviorSubject(false);
  productEdit = new BehaviorSubject(false);
  storeEdit = new BehaviorSubject(false);

  showCategoryArrow = new BehaviorSubject(false);
  showProductArrow = new BehaviorSubject(false);
  showStoreArrow = new BehaviorSubject(false);

  shopDetailsCategory = new BehaviorSubject(false);
  shopDetailsProduct = new BehaviorSubject(false);
  shopDetailsStore = new BehaviorSubject(false);

  shopCategoryEdit = new BehaviorSubject(false);
  shopProductEdit = new BehaviorSubject(false);
  shopStoreEdit = new BehaviorSubject(false);

  categoryProjectId = new BehaviorSubject(null);
  productProjectId = new BehaviorSubject(null);
  storeProjectId = new BehaviorSubject(null);
  
  
  categoryEditId = new BehaviorSubject(null);
  productEditId = new BehaviorSubject(null);
  storeEditId = new BehaviorSubject(null);


  categoryName = new BehaviorSubject('');
  productName = new BehaviorSubject('');
  storeName = new BehaviorSubject('');


  constructor() { }
}
