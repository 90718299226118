<div class="container-fluid cf-xs app-product" #myNameElem2>
    <div class="header-body" id="header-body">
        <div class="row align-items-center py-4">
            <div class="col-lg-12">
                <div class="header-title pb-sm-4 pb-0">
                    <h6 class="h2 d-inline-block mb-0 c-tag-1 bold-26">
                            <span *ngIf="!isEdit">
                                <svg *ngIf="!arrow" [ngClass]="{'displayNone' : fromLink}" (click)="goBack()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"/>
                                </svg>Add New Product
                            </span>
                            <span *ngIf="isEdit">
                                <svg *ngIf="!arrow" [ngClass]="{'displayNone' : fromLink}" (click)="goBack()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"/>
                                </svg>Edit Product
                            </span>
                    </h6>
                </div>
                <div *ngIf="isLoading" class="col-12 font-weight-bold ml-2 mt-5">
                    <div class="circle-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div *ngIf="!isLoading" class="row mt-2">
                    <div class="col-md-12 mb-4">
                        <form [formGroup]="productForm">
                            <div class="form-group">
                                <app-image-upload (deleteImage)="deleteImage()"
                                    (sendImage)="onFileChangeslogoImage($event,'cover')" [data]="cover"
                                    [error]="coverlogoImageError" [imageUrl]="coverlogoImage">
                                </app-image-upload>
                            </div>
                            <div class="form-group product-name">
                                <label class="">Item Name</label>
                                <input type="text" class="form-control" maxlength="20" placeholder="Enter Item Name" name="name"
                                    formControlName="name">
                            </div>
                            <div class="form-group product-textarea">
                                <label class="">Item Description</label>
                                <textarea class="form-control h-148" rows="3" formControlName="description"></textarea>
                            </div>
                            <div class="form-group product-name">
                                <label class="">Item Price</label>
                                <input type="number" class="form-control" maxlength="5" placeholder="Enter Item Price" name="name"
                                    formControlName="price">
                            </div>
                            <div class="form-group">
                                <label>Select Store</label>
                                <ul class="item-picker">
                                        
                                            <div>
                                                <div *ngIf="selectedStores.length<1"  class="empty-state">
                                                    <h3>You don't have any stores to associate with this product</h3>
                                                </div>
                                        <li class="mb-2 selected-card" [ngClass]="{'selected-item' : item.selected == true}"
                                         *ngFor="let item of selectedStores;" >
                                         <img (click)=" callStorePopup(item)" src="{{item['imageUrls'][0]}}" class="img-shadow">
                                         <img (click)="callStorePopup(item)" *ngIf="item.selected == false" src="../../assets/images/category/item_select.png"
                                                class="app-add-product">
                                                
                                                <svg (click)="callStorePopup(item)" *ngIf="item.selected == true" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="18.4998" cy="18.4998" r="17.8552" fill="#0073DE"/>
                                                    <path d="M26.5 12.5L15.5 23.5L10.5 18.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                
                                                <span class="item-title">{{item['name']}}</span></li>
                                              
                                                
                                            </div>
                                </ul>
                            </div>
                            <div class="form-group">
                                <label>Select Categories</label>
                                <ul class="item-picker">
                                        
                                            <div *ngIf="selectedCategories.length<1" class="empty-state">
                                                <h3>You don't have any categories to group with this product</h3>
                                            </div>
                                    <li class="mb-2 selected-card" *ngFor="let item of selectedCategories;" [ngClass]="{'selected-item' : item.selected == true}">
                           
                                        <img (click)="callCategoryPopup(item)" src="{{item.imageUrls[0]}}" class="img-shadow">
                                        <img (click)="callCategoryPopup(item)" *ngIf="item.selected == false" src="../../assets/images/category/item_select.png"
                                            class="app-add-product">
                                            <svg (click)="callCategoryPopup(item)" *ngIf="item.selected == true" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="18.4998" cy="18.4998" r="17.8552" fill="#0073DE"/>
                                                <path d="M26.5 12.5L15.5 23.5L10.5 18.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span class="item-title">{{item['name']}}</span>
                                    </li>
                                  
                                 
                                   
                                </ul>
                            </div>
                            <div class="options-group">
                                <div fxLayout="row">
                                    <div fxFlex="75" class="product-name">
                                        <label>Options</label>
                                    </div>
                                    <div fxFlex="25">
                                        <div class="btn-back float-left"><span (click)="addOptions()">
                                                <p>ADD MORE +</p>
                                            </span></div>
                                    </div>
                                </div>
                                <table width="100%" formArrayName="options">
                                    <tr *ngFor="let option of getLineOptionsControls().controls; let i = index;"
                                        [formGroupName]="i" class="mb-3" (keyup)="changeValue(getLineOptionsControls().controls, i)">
                                        <td>
                                            <div fxLayout="row" class="form-group">
                                                <div fxFlex="92" fxFlex.lt-sm="85">
                                                    <input [ngClass]="[productForm.value.options[i].name == '' ? 'remove-valition-image' : '']" type="text" class="form-control" placeholder="Options Name"
                                                        name="name" formControlName="name"
                                                        [ngModelOptions]="{standalone: true}">
                                                </div>
                                                <div fxFlex="8" fxFlex.lt-sm="15" class="form-group">
                                                    <button class="parent-options" (click)="removeOptions(i)"><img
                                                            src="../../assets/images/shop/close.png"></button>
                                                </div>
                                            </div>
                                            <table width="100%" formArrayName="items" class="item-option mt-3">
                                                <tr *ngFor="let item of getLineItemControls(i).controls; let itemIndex= index;"
                                                    [formGroupName]="itemIndex">
                                                    <td class="form-group">
                                                        <input [ngClass]="[productForm.value.options[i].name == '' ? 'remove-valition-image' : '']" type="text" class="form-control"                                             
                                                            placeholder="Options Value Name" name="option"
                                                            formControlName="option"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </td>
                                                    <td class="form-group">
                                                        <input [ngClass]="[productForm.value.options[i].name == '' ? 'remove-valition-image' : '']" type="number" class="form-control" placeholder="Price"
                                                            name="name" formControlName="price"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </td>
                                                    <td class="close-btn" style="width: 42px;">
                                                        <button class="parent-options"
                                                            (click)="removeSubOptions(i,itemIndex)"><img
                                                                src="../../assets/images/shop/close.png"
                                                                *ngIf="itemIndex > 1"></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <div class="btn-back btn-back-inverse float-right"><span
                                                                (click)="addItems(i)">
                                                                <p>ADD OPTION +</p>
                                                            </span></div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="form-check form-switch">
                                <label for="exampleFormControlFile1">Available</label>
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked name="isAvailable" formControlName="isAvailable">
                            </div>
                           <div class="buttons">
                            <button type="submit" (click)="cancel()" class="btn cancel">Cancel <i
                                class="fas fa-times mx-1"></i></button>
                            <button [disabled]="isSubmit" type="submit" class="btn save"
                                (click)="addProduct()"><span class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true" *ngIf="isSubmit"></span> 
                                    <span *ngIf="!isEdit">
                                            Add Item
                                    </span>
                                    <span *ngIf="isEdit">
                                            Save Item
                                    </span><i
                                    class="fas fa-check mx-1"></i></button>
                           </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
