<div class="top-header">
    <button (click)="showNav()" type="button" id="sidebarCollapse" class="fade-btn">
        <img src="/assets/images/menuIcon.svg" alt="menuIcon" />
    </button>
    <div class="main-logo">
        <img (click)="goToHome()" src="/assets/images/OrderAx-1.png" alt="logo" />
    </div>
    <div appOutsideclick (outsideClick)="close()" #menu [ngClass]="{'show-nav' : showNavbar && !closeMenu}" class="top-navbar">
        <ul>
            <li (click)="goToSection('home')" [ngClass]="{'active' : activeLink == 'home'}">Home</li>
            <li (click)="goToSection('apps')" [ngClass]="{'active' : activeLink == 'apps'}">Apps</li>
            <li (click)="goToSection('customers')" [ngClass]="{'active' : activeLink == 'customers'}">Customers</li>
            <li (click)="goToSection('blog')" [ngClass]="{'active' : activeLink == 'blog'}">Blog</li>
            <li (click)="goToSection('social')" [ngClass]="{'active' : activeLink == 'social'}">Social Media</li>
            <li (click)="goToSection('tools')" [ngClass]="{'active' : activeLink == 'tools'}">Tools</li>
            <li (click)="goToSection('contact')" [ngClass]="{'active' : activeLink == 'contact'}">Contact Us</li>
        </ul>
    </div>
    <div class="side-login">
        <button routerLink="/registration">Get Started</button>
        <button routerLink="/login">Login</button>
    </div>
</div>

<section class="page-not-found bg-white py-5">
    <div class="container text-center">
        <div class="not-found-content">
            <img src="/assets/images/page-404.png" alt="Page Not Found" width="400" height="400" class="page-not-found-image">
            <h1 class="my-3">Page Not Found</h1>
            <p>We can’t seem to find a page you are looking for</p>
            <a href="#" class="btn btn-primary back-btn">Go Back</a>
        </div>
    </div>
</section>