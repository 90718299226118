import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../meta-service/meta.service'
@Component({
  selector: 'app-confirm-page',
  templateUrl: './confirm-page.component.html',
  styleUrls: ['./confirm-page.component.css']
})
export class ConfirmPageComponent implements OnInit {

  constructor(private metaService: MetaService) { }

  ngOnInit(): void {
    this.setTitleAndDescription();
  }

  setTitleAndDescription(){
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, verification';
    let description = 'Your email has been verified, start making mobile ordering App for your own business';
    this.metaService.setPageTitleAndMetaTags('Email Verified | OrderAx', description, keywords, 'https://orderax.com/confirmpage')
  }
}
