import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Output, EventEmitter, Input } from '@angular/core';
import { AccountManagerService } from 'src/app/managers/account-manager.service';
import { PublicService } from 'src/app/public/public.service';
import { PopupService } from '../../service/popup.service';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit,OnChanges {
  @Output() goToParent = new EventEmitter;
  @Input() obj;
  @Input() projectId;
  @Input() limit;
  confirmStores = []
  checkStores = []
  selectedItems = [];
  comingProjectId;
  comingStoreId;
  comingStoreName;
  message: string;
  error: string;
  aditional: string;
  selectMessage: string;
  stores = [];
  isLoading = false;
  addNew = false;
  constructor(private router: Router,
    public publicService: PublicService,
    private accountManagerService: AccountManagerService,
    private popupService: PopupService) {
  }


  ngOnInit(): void {
    this.popupService.storeEdit.subscribe(result => {
      this.addNew = result;
    });
  }

  ngOnChanges(){}

  getStores() {
    this.isLoading = true;
    this.accountManagerService.getStores(this.projectId).then(resp => {
      if (resp) {
          this.isLoading = false;
          this.stores = resp;
      }
    }, err => {
      this.isLoading = false;
    });
  }

  showStore() {
    this.getStores();
    this.confirmStores = this.obj;
    this.selectedItems = []

    for (var i = 0; i < this.confirmStores.length; i++) {
      if(this.confirmStores[i].id){
        this.selectedItems.push(this.confirmStores[i]);
      }
    }

    this.message = ''
    this.error = ''
    this.aditional = '';
    this.selectMessage = "";
    document.getElementsByClassName("category-boxes-4")[0].removeAttribute("style");
  }

  add(id) {
   this.publicService.logAnalytics('add-btn-clicked-in-store')
   if(this.limit){
      if (this.selectedItems.length < this.limit) {
        this.addItem(id);
      } else {
        this.selectMessage = `You can only select ${this.limit} store`;
        return;
      }
    }else{
      this.addItem(id);
    }
  
  } 

  addItem(id){
    if (this.selectedItems.some(d=> d.id == id.id)) {
      this.selectMessage = "This store is already added";
      return;
    } else {
      this.selectedItems.push(id);
      this.selectMessage = "";
    }
  }

  delete(id) {
    this.publicService.logAnalytics('delete-btn-clicked-in-store')
    let index = this.selectedItems.indexOf(id);
    this.selectedItems.splice(index, 1);
  }

  confirm() {
    this.publicService.logAnalytics('confirm-btn-clicked-in-store')
    if(this.limit){
      this.goToParentScreenShot();
    }else{
      this.goToParentProduct();
    }
  }

  redirectStore(id?) {
    this.addNew = !this.addNew;
    this.popupService.showStoreArrow.next(false);
    this.popupService.shopDetailsStore.next(false);
    if(id) {
      this.publicService.logAnalytics('edit-store-btn-clicked-in-products')
      this.popupService.storeProjectId.next(this.projectId);
      this.popupService.storeEditId.next(id);
      this.popupService.storeName.next('');
    } else if (this.aditional) {
      this.publicService.logAnalytics('add-new-store-btn-clicked-in-store-with-store-name')
      this.popupService.storeProjectId.next(this.projectId);
      this.popupService.storeName.next(this.aditional);
      this.comingStoreName = this.aditional;
    } else {
      this.publicService.logAnalytics('add-new-store-btn-clicked-in-store-without-store-name')
      this.popupService.storeProjectId.next(this.projectId);
      this.popupService.storeEditId.next(null);
      this.popupService.storeName.next('');
    }
  }

  goToParentScreenShot(){
    if (this.selectedItems.length >= this.limit + 1) {
      this.error = `Please select ${this.limit} store`;
      this.message = '';
      document.getElementsByClassName("confirmbtn")[0].setAttribute("data-dismiss", "modal2");
      return;
    }
    else {
      this.checkStores = [];
      this.confirmStores = [];
      for (var i = 0; i < this.selectedItems.length; i++) {
        this.selectedItems[i].status = true;
        this.checkStores.push(this.selectedItems[i]);
        this.confirmStores.push(this.selectedItems[i]);
      }
      
      if (this.checkStores.length == this.limit) {
        this.error = '';
        document.getElementsByClassName("confirmbtn")[0].setAttribute("data-dismiss", "modal");
      } else {
        var len = this.checkStores.length;
        var remaining = this.limit - len;
        for (var i = 0; i < remaining; i++) {
          this.checkStores.push(i);
        }
        this.error = '';
        document.getElementsByClassName("confirmbtn")[0].setAttribute("data-dismiss", "modal");
      }
      this.goToParent.emit(this.checkStores);
    }
  }

  goToParentProduct(){
    this.goToParent.emit(this.selectedItems);
  }

  addStoreToList($event){
    this.getStores();
  }
}