<div class="top-header">
    <button (click)="showNav()" type="button" id="sidebarCollapse" class="fade-btn">
        <img src="/assets/images/menuIcon.svg" alt="menuIcon" />
    </button>
    <div class="main-logo">
        <img (click)="goToHome()" src="/assets/images/OrderAx-1.png"alt="logo" />
    </div>
    <div appOutsideclick (outsideClick)="close()" #menu [ngClass]="{'show-nav' : showNavbar && !closeMenu}" class="top-navbar">
      <ul>
          <li (click)="goToSection('home')" [ngClass]="{'active' : activeLink == 'home'}">Home</li>
          <li (click)="goToSection('apps')" [ngClass]="{'active' : activeLink == 'apps'}">Theme</li>
          <li (click)="goToSection('contact')" [ngClass]="{'active' : activeLink == 'contact'}">Contact Us</li>
      </ul>
  </div>
    <div class="side-login">
        <button (click)="getStarted()">Get Started</button>
        <button (click)="goToLogin()">Login</button>
    </div>
</div>
<div class="container-fluid cf-xs theme-public">
    <div class="header-body" id="header-body">
      <div class="align-items-center">
        <div id="home" #home class="header">
          <div class="img-fluid" class="home-section">
            <div class="home-section-layer"> </div>
              <img src="../../assets/images/headerImage.png" alt="Orderax Header Image">
              <div class="text-button">
                  <h1>Free Restaurant Mobile App Theme.</h1>
              </div>
            </div>
        </div>
        <div id="apps" #theme class="screenshot-wrapper">
            <!-- <div class="header-title pb-sm-3 pb-0">
                <h6 class="h5 d-inline-block mb-0 c-tag-1 bold-26">Create Shop
                </h6>
              </div> -->
            <app-sharedtheme></app-sharedtheme>
        </div>
        <div #contact class="bottom-divisions pt-2">
          <app-footerpublic></app-footerpublic>
      </div>
      </div>
    </div>
  </div>