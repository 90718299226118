import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class loginAndRegisterGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
          let currentUser = JSON.parse(localStorage.getItem('user'));
          let token = localStorage.getItem('token');
          if(token && currentUser && currentUser['type'] == 1){
            this.router.navigate(['/admin']);
            return false;
          } else if(token && currentUser && currentUser['type'] == 0) {
            this.router.navigate(['/home']);
            return false;
          } else {
            return true;
          }
  }
}
