<div class="modal-dialog">
    <div class="modal-content" id="modal-content">
        <div class="container">
                <div class="modal-header">
                        
                      <!--   <button #closebutton id="cancel" type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <img src="../../../assets/images/close.png">
                        </button> -->
                        <button  #closebutton type="button" class="app-popup-close" data-dismiss="modal" aria-label="Close"
                        id="app-popup-close">
                        <img src="../../assets/images/close.png">
                    </button>
                      </div>
            <div class="complete-profile businessinfo-box">
                <div class="row no-gutters align-items-center justify-content-center">
                    <div class="col-sm-12 row businessinfo-box-inside">
                        <div class="col-sm-12 col-md-12 col-lg-12 bg-white">
                            <div class="title mb-5 mt-3">
                                <h1>Enter Business Info</h1>
                            </div>
                            <form [formGroup]="businessForm">
                                <aw-wizard>
                                    <aw-wizard-step stepTitle="Business Info" [navigationSymbol]="{ symbol: '1' }"
                                        formGroupName="businessInfoGroup">
                                        <div class="centered-content">
                                            <div class="form-group">
                                                <label for="exampleFormControlInput1">Business Name*</label>
                                                <input type="text" class="form-control" id="businessName"
                                                    placeholder="Business Name" name="businessName"
                                                    formControlName="businessName" required>
                                                <div *ngIf="f.businessInfoGroup['controls'].businessName.invalid && (f.businessInfoGroup['controls'].businessName.dirty || f.businessInfoGroup['controls'].businessName.touched)"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="f.businessInfoGroup['controls'].businessName.errors.required">
                                                        This is required</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleFormControlInput1">Brand Name*</label>
                                                <input type="text" class="form-control" id="brandName"
                                                    placeholder="Brand Name" name="brandName"
                                                    formControlName="brandName" required>
                                                <div *ngIf="f.businessInfoGroup['controls'].brandName.invalid && (f.businessInfoGroup['controls'].brandName.dirty || f.businessInfoGroup['controls'].brandName.touched)"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="f.businessInfoGroup['controls'].brandName.errors.required">
                                                        This is required</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row phone-numbe-label">
                                                    <label for="exampleFormControlInput1">Phone Number*</label>
                                                </div>
                                                   <div class="row">
                                                    <div class="col-md-4">
                                                        <p-dropdown [options]="flags" formControlName="countryCode" optionLabel="name" [filter]="true" filterBy="name"
                                                        placeholder="Country">
                                                        <ng-template let-country pTemplate="item">
                                                            <div class="country-item">
                                                                <img class="flag" [src]="country.flag" />
                                                                {{country.name}}
                                                            </div>
                                                        </ng-template>
                                            </p-dropdown>
                                                    </div>
                                                    <div class="row col-md-8 ml-0">
                    
                                                        <input class="form-control col-md-3 disabled" 
                                                        [placeholder]="f.businessInfoGroup['controls'].countryCode && f.businessInfoGroup['controls'].countryCode.value ? f.businessInfoGroup['controls'].countryCode.value.callingCode : 'Code'" disabled>
                                                        <input maxlength="10" type="text" class="form-control col-md-8" id="phoneNumber" placeholder="Mobile Number"
                                                    name="phoneNumber" formControlName="phoneNumber" required>
                                                        </div>
                                                   </div>
                    
                                                   <div *ngIf="f.businessInfoGroup['controls'].countryCode.invalid && (f.businessInfoGroup['controls'].countryCode.dirty || f.businessInfoGroup['controls'].countryCode.touched)"
                                                   class="invalid-feedback">
                                                   <div *ngIf="f.businessInfoGroup['controls'].countryCode.errors.required">Country is required</div>
                                                
                                               </div>
                                              <div *ngIf="f.businessInfoGroup['controls'].phoneNumber.invalid && (f.businessInfoGroup['controls'].phoneNumber.dirty || f.businessInfoGroup['controls'].phoneNumber.touched)"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.businessInfoGroup['controls'].phoneNumber.errors.required">Mobile Number is required</div>
                                                    <div *ngIf="f.businessInfoGroup['controls'].phoneNumber.errors.pattern">Please, Enter 10 digit
                                                        Mobile Number.</div>
                                                </div>
                                            </div>
                                           
                                            <div class="form-group">
                                                <label for="exampleFormControlInput1">Website</label>
                                                <input type="text" class="form-control" id="websiteURL"
                                                    placeholder="Website" name="websiteURL"
                                                    formControlName="websiteURL">
                                                <div *ngIf="f.businessInfoGroup['controls'].websiteURL.invalid && (f.businessInfoGroup['controls'].websiteURL.dirty || f.businessInfoGroup['controls'].websiteURL.touched)"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="f.businessInfoGroup['controls'].websiteURL.errors.pattern">
                                                        Please, Enter a valid URL.</div>
                                                </div>
                                            </div>
                                            <button type="button"
                                                class="btn btn-primary mb-2 float-right business-info-btn"
                                                #businessAddress [disabled]="f.businessInfoGroup.invalid || isLoading"
                                                (click)="onSaveBusinessInfo()"><span
                                                    class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true" *ngIf="isLoading"></span>Continue</button>
                                        </div>
                                    </aw-wizard-step>
                                    <aw-wizard-step stepTitle="Business Address" [navigationSymbol]="{ symbol: '2' }"
                                        formGroupName="businessAddressGroup">
                                        <div class="centered-content">
                                            <div class="form-group">
                                                <label for="exampleFormControlInput1">Address Line 1*</label>
                                                <input  spellcheck="off" (keydown.enter)="$event.preventDefault()" type="text" google-places autocomplete="off"
                                                (onSelect)="setAddress($event)" class="form-control" placeholder="Line 1" name="line1" formControlName="line1" required>
                                                
                                                <div *ngIf="businessAddressSubmitted && f.businessAddressGroup['controls'].line1.invalid && (f.businessAddressGroup['controls'].line1.dirty || f.businessAddressGroup['controls'].line1.touched)"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="f.businessAddressGroup['controls'].line1.errors.required">
                                                        This is required</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleFormControlInput1">Line 2</label>
                                                <input type="text" class="form-control" id="line2"
                                                    placeholder="Line 2 (Optional)" name="line2"
                                                    formControlName="line2">
                                            </div>
                                            <div class="form-group form-row">
                                                <div class="col">
                                                    <label for="exampleFormControlInput1">City*</label>
                                                    <input type="text" class="form-control" placeholder="City"
                                                        name="city" formControlName="city" required>
                                                    <div *ngIf="businessAddressSubmitted && f.businessAddressGroup['controls'].city.invalid && (f.businessAddressGroup['controls'].city.dirty || f.businessAddressGroup['controls'].city.touched)"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="f.businessAddressGroup['controls'].city.errors.required">
                                                            This is required</div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <label for="exampleFormControlInput1">State/Province*</label>
                                                    <input type="text" class="form-control" placeholder="State"
                                                        name="state" formControlName="state" required>
                                                    <div *ngIf="businessAddressSubmitted && f.businessAddressGroup['controls'].state.invalid && (f.businessAddressGroup['controls'].state.dirty || f.businessAddressGroup['controls'].state.touched)"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="f.businessAddressGroup['controls'].state.errors.required">
                                                            This is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group form-row">
                                                <div class="col" [class.hide]="shouldHidePostalCode">
                                                    <label for="exampleFormControlInput1">Postal Code</label>
                                                    <input type="text" class="form-control" placeholder="Postal Code"
                                                        name="postalCode" formControlName="postalCode" required>
                                                    <div *ngIf="businessAddressSubmitted && f.businessAddressGroup['controls'].postalCode.invalid && (f.businessAddressGroup['controls'].postalCode.dirty || f.businessAddressGroup['controls'].postalCode.touched)"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="f.businessAddressGroup['controls'].postalCode.errors.required">
                                                            This is required</div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <label for="exampleFormControlInput1">Country*</label>
                                                    <select class="form-control" placeholder="Country" name="country"
                                                        formControlName="country" required #countriesAbout
                                                        (change)="getCountriesBusiness(countriesAbout.value);">
                                                        <option value='' selected>Select Country</option>
                                                        <option *ngFor="let item of countries" [value]="item.country.code" attr.isCode="{{item.requiresPostalCode}}">
                                                            {{item.country.name}}</option>
                                                    </select>
                                                    <div *ngIf="businessAddressSubmitted && f.businessAddressGroup['controls'].country.invalid && (f.businessAddressGroup['controls'].country.dirty || f.businessAddressGroup['controls'].country.touched)"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="f.businessAddressGroup['controls'].country.errors.required">
                                                            This is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" awPreviousStep
                                                class="btn btn-primary mb-2 float-left btn-without-bg">Back</button>
                                            <button type="button"
                                                class="btn btn-primary mb-2 float-right business-address-btn"
                                                [disabled]="f.businessAddressGroup.invalid || isLoading"
                                                (click)="onSaveBusinessAddress()"><span
                                                    class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true" *ngIf="isLoading"></span>Continue</button>
                                        </div>
                                    </aw-wizard-step>
                                    <aw-wizard-step stepTitle="Business Type" [navigationSymbol]="{ symbol: '3' }"
                                        formGroupName="businessTypeGroup">
                                        <div class="centered-content">
                                            <div class="form-group">
                                                <label for="exampleFormControlInput1">Type of Business*</label>
                                                <div class="form-group form-row business-type">
                                                    <div class="col">
                                                        <div class="button">
                                                            <input type="radio" name="businessType" value="restaurant"
                                                                id="restaurant" checked
                                                                formControlName="businessType" />
                                                            <label for="restaurant"><img
                                                                    src="assets/images/user/restaurant.png" /><br />Restaurant</label>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="button">
                                                            <input type="radio" name="businessType" value="coffee"
                                                                id="coffee-shop" formControlName="businessType" />
                                                            <label for="coffee-shop"><img
                                                                    src="assets/images/user/coffee-cup.png" /><br />Coffee
                                                                Shop</label>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="button">
                                                            <input type="radio" name="businessType" value="quickServe"
                                                                id="quickServeRestaurant"
                                                                formControlName="businessType" />
                                                            <label for="quickServeRestaurant"><img
                                                                    src="assets/images/user/quick-serve-restaurant.png" /><br />Quick
                                                                Serve Restaurant</label>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="button">
                                                            <input type="radio" name="businessType" value="cannabis"
                                                                id="cannabisStore" formControlName="businessType" />
                                                            <label for="cannabisStore"><img
                                                                    src="assets/images/user/cannabis.png" /><br />Cannabis
                                                                Store</label>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="button">
                                                            <input type="radio" name="businessType" value="others"
                                                                id="Others" formControlName="businessType" />
                                                            <label for="Others"><img
                                                                    src="assets/images/user/others.png" /><br />Others</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleFormControlInput1">Business Number</label>
                                                    <input type="text" class="form-control" id="businessNumber"
                                                        placeholder="Business Number (Optional)" name="businessNumber"
                                                        formControlName="businessNumber">
                                                </div>
                                                <ng-container *ngIf="docUrl">
                                                        <div class="form-group docUrl">
                                                    
                                                            <a (click)="openUrl(docUrl)">
                                                                docURL
                                                            </a>
                                                    
                                                        </div>
                                                </ng-container>
                                                <div class="form-group">
                                                    <label for="exampleFormControlInput1">Upload Kitchen/Store Licence</label>
                                                    <button class="upload-btn btn"
                                                        (click)="filePicker.click()">
                                                        <span *ngIf="file == null">
                                                                Upload Kitchen/Store Licence
                                                                Documents<br>
                                                                (pdf , doc or docx)
                                                        </span>
                                                        <span *ngIf="file != null">
                                                                {{file.name}}
                                                        </span></button>
                                                    <input type="file" #filePicker style="display:none" name="docURL"
                                                        (change)="onImagePicked($event)">
                                                </div>
                                            </div>
                                            <button type="button" awPreviousStep
                                                class="btn btn-primary mb-2 float-left btn-without-bg">Back</button>
                                            <button type="button" class="btn btn-primary mb-2 float-right"
                                                (click)="onSaveBusinessType()"
                                                [disabled]="f.businessTypeGroup.invalid || isLoading"><span
                                                    class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true" *ngIf="isLoading"></span>Continue</button>
                                        </div>
                                    </aw-wizard-step>
                                    <aw-wizard-step stepTitle="Contact Info" [navigationSymbol]="{ symbol: '4' }"
                                        formGroupName="aboutyouForm">
                                        <div class="centered-content">
                                            <div class="form-group form-row">
                                                <div class="col">
                                                    <label>First Name*</label>
                                                    <input type="text" class="form-control" placeholder="First Name"
                                                        name="firstname" formControlName="firstname" required>
                                                    <div *ngIf="f.aboutyouForm['controls'].firstname.invalid && (f.aboutyouForm['controls'].firstname.dirty || f.aboutyouForm['controls'].firstname.touched)"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="f.aboutyouForm['controls'].firstname.errors.required">
                                                            This is required</div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <label>Last Name*</label>
                                                    <input type="text" class="form-control" placeholder="Last Name"
                                                        name="lastname" formControlName="lastname" required>
                                                    <div *ngIf="f.aboutyouForm['controls'].lastname.invalid && (f.aboutyouForm['controls'].lastname.dirty || f.aboutyouForm['controls'].lastname.touched)"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="f.aboutyouForm['controls'].lastname.errors.required">
                                                            This is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row phone-numbe-label">
                                                    <label for="exampleFormControlInput1">Contact Phone*</label>
                                                </div>
                                                   <div class="row">
                                                    <div class="col-md-4">
                                                        <p-dropdown [options]="flags" formControlName="countryCode" optionLabel="name" [filter]="true" filterBy="name"
                                                        placeholder="Country">
                                                        <ng-template let-country pTemplate="item">
                                                            <div class="country-item">
                                                                <img class="flag" [src]="country.flag" />
                                                                {{country.name}}
                                                            </div>
                                                        </ng-template>
                                            </p-dropdown>
                                                    </div>
                                                    <div class="row col-md-8 ml-0">
                    
                                                        <input class="form-control col-md-3 disabled" 
                                                        [placeholder]="f.aboutyouForm['controls'].countryCode && f.aboutyouForm['controls'].countryCode.value ? f.aboutyouForm['controls'].countryCode.value.callingCode : 'Code'" disabled>
                                                        <input maxlength="10" type="text" class="form-control col-md-8" id="phoneNumber" placeholder="Mobile Number"
                                                    name="phoneNumber" formControlName="phoneNumber" required>
                                                        </div>
                                                   </div>
                    
                                                   <div *ngIf="f.aboutyouForm['controls'].countryCode.invalid && (f.aboutyouForm['controls'].countryCode.dirty || f.aboutyouForm['controls'].countryCode.touched)"
                                                   class="invalid-feedback">
                                                   <div *ngIf="f.aboutyouForm['controls'].countryCode.errors.required">Country is required</div>
                                                
                                               </div>
                                              <div *ngIf="f.aboutyouForm['controls'].phoneNumber.invalid && (f.aboutyouForm['controls'].phoneNumber.dirty || f.aboutyouForm['controls'].phoneNumber.touched)"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.aboutyouForm['controls'].phoneNumber.errors.required">Phone Number is required</div>
                                                    <div *ngIf="f.aboutyouForm['controls'].phoneNumber.errors.pattern">Please, Enter 10 digit
                                                        Mobile Number.</div>
                                                </div>
                                            </div>
        
                                            <div class="form-group">
                                                <label>Email*</label>
                                                <input type="text" class="form-control"
                                                    placeholder="Type your email address" name="email"
                                                    formControlName="email" required>
                                                <div *ngIf="f.aboutyouForm['controls'].email.invalid && (f.aboutyouForm['controls'].email.dirty || f.aboutyouForm['controls'].email.touched)"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.aboutyouForm['controls'].email.errors.required">Email
                                                        is required</div>
                                                    <div *ngIf="f.aboutyouForm['controls'].email.errors.email">Please,
                                                        Enter a valid email address</div>
                                                </div>
                                            </div>
                                            <button type="button" awPreviousStep
                                                class="btn btn-primary mb-2 float-left btn-without-bg">Back</button>
                                            <button type="button" class="btn btn-primary mb-2 float-right"
                                                (click)="onSubmitAboutYou()"
                                                [disabled]="f.aboutyouForm.invalid || isLoading"><span
                                                    class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true" *ngIf="isLoading"></span>Save</button>
                                        </div>
                                    </aw-wizard-step>
                                </aw-wizard>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>