import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountManagerService } from 'src/app/managers/account-manager.service';
import { PublicService } from 'src/app/public/public.service';

@Component({
  selector: 'app-sharedservices',
  templateUrl: './sharedservices.component.html',
  styleUrls: ['./sharedservices.component.css']
})
export class SharedservicesComponent implements OnInit {
  serviceForm: FormGroup
  selectedModalData = {
    steps : [],
    inputs : []
  };
  selectedModalInputs = {};
  selectedService = '';
  isLoading= false;
  isSubmit = false;
  @Input() projectId;
  serviceName = '';
  toggle = false;
  serviceForShop;
  project;
  services = []
  serviceFields;
  googleCloudId;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private accountManager: AccountManagerService,
    public publicService: PublicService) { }

  ngOnInit(): void {
    this.createForm({})
  }

  setServiceInfo(project, serviceFields) {
    this.project = project;
    this.serviceFields = serviceFields;
    let projectServices = project.platforms.services;
    this.googleCloudId = projectServices?.google?.googleCloudProjectId;
    this.services = Object.keys(projectServices); 
    this.createForm(projectServices);
  }
  
  createForm(serviceValues) {
    let formFields = {}
    for (let i = 0; i < this.services.length; i++) {
      let serviceName = this.services[i].toLowerCase();
      let fields = this.getField(serviceName);
      
      for (let j = 0; j < fields.length; j++) {
        let field = fields[j]
        let formKey = serviceName + j;
        this.selectedModalInputs[serviceName] = this.selectedModalInputs[serviceName] ? this.selectedModalInputs[serviceName] : [] ;
        this.selectedModalInputs[serviceName].push({id:field.id, name:field.name});
        let value = !this.isEmptyObject(serviceValues) ? serviceValues[serviceName][field.id] : '';
        formFields[formKey] = [value, [Validators.required]];
      }
    }
    this.serviceForm = this.fb.group(formFields)
  }

  changeIcon(serviceName){
    this.toggle = this.serviceName == serviceName ? !this.toggle : true
    this.serviceName = serviceName;
  }

  changeSelected(serviceName) {
    this.selectedService = serviceName;
    let serviceField = this.serviceFields.find(field => field.id == serviceName);
    if (serviceField.steps) {
      this.selectedModalData.steps = Object.values(serviceField.steps);
    } else {
      this.selectedModalData.steps = [];
    }
    

    for (let i = 0; i < this.selectedModalInputs[serviceName].length; i++) {
      let formKey = serviceName + i;
      let formValue = this.serviceForm.value;
      this.selectedModalInputs[serviceName][i]['value'] = formValue[formKey]; 
      this.selectedModalInputs[serviceName][i]['id'] = serviceField.fields[i]['id']; 
    }
    
    this.selectedModalData.inputs = this.selectedModalInputs[serviceName];
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  getField(serviceName) {
    let serviceField = this.serviceFields.find(field => field.id == serviceName);
    if(!serviceField){
      return []
    }
    let fields = serviceField.fields;
    return this.isEmptyObject(fields) ? [] : fields
  }

  submitService() {
    this.publicService.logAnalytics('service-submit-btn-clicked-in-shared-service')
    this.isSubmit = true;
    let serviceForm = this.serviceForm.value;
    let services = {}
    
    for (let i = 0; i < this.services.length; i++) {
      let serviceName = this.services[i].toLowerCase();
      let fields = this.getField(serviceName);
      services[serviceName] = {};
      for (let j = 0; j < fields.length; j++) {
        let field = fields[j]
        let formKey = serviceName + j;
        services[serviceName][field.id] = serviceForm[formKey]
      }
    }

    let updateSeviceObj = {
      projectId: this.projectId,
      service: services,
    }

    this.accountManager.updateService(updateSeviceObj).then(resp => {
      if (resp.status) {
        this.isSubmit = false;
        this.toastr.success('Your Sevices has been created', '');
        this.router.navigate([`home/shop-details/${this.projectId}`])
      }
    }, err => {
      this.isSubmit = false;
      this.toastr.error(err.error.message, '');
    });
  }

  submitServiceForShop(){
    this.isSubmit = true;
    let serviceForm = this.serviceForm.value;
    let services = {}
    
    for (let i = 0; i < this.services.length; i++) {
      let serviceName = this.services[i].toLowerCase();
      let fields = this.getField(serviceName);
      services[serviceName] = {};
      for (let j = 0; j < fields.length; j++) {
        let field = fields[j]
        let formKey = serviceName + j;
        services[serviceName][field.id] = serviceForm[formKey]
      }
    }

    let updateSeviceObj = {
      projectId: this.projectId,
      service:  services,
    }

    this.serviceForShop = updateSeviceObj;
  }

  dataEmit(data) {
    let formValue = {};
    for (let j = 0; j < data.length; j++) {
      formValue[this.selectedService+j] = data[j].value
    }

    this.serviceForm.patchValue(formValue)
    this.onCloseModalRemoveSelectedData();
  }

  closeModal(data){
    this.onCloseModalRemoveSelectedData();
  }

  onCloseModalRemoveSelectedData(){
    this.selectedModalData.steps = [];
    this.selectedModalData.inputs = []
  }
}
