import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-auth-action',
  templateUrl: './auth-action.component.html',
  styleUrls: ['./auth-action.component.css'],
})
export class AuthActionComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      switch (params.mode) {
        case 'resetPassword':
          this.router.navigate(['/changepassword', params.oobCode]);
          break;
        case 'verifyEmail':
          this.handleVerifyEmail(params.oobCode);
          break;
        default:
      }
    });
  }

  handleVerifyEmail(actionCode) {
      this.authService.confirmOobCodeForEmailVerfication(actionCode).subscribe({
        next: () => {
          this.router.navigate(['/confirmpage']);
        }, error: (error) => {
          this.toastr.error(error.message, 'Email Verify');
          this.router.navigate(['/login']);
        }
    })
  }
}
