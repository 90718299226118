import { Component, Input, OnInit, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountManagerService } from 'src/app/managers/account-manager.service';
import { PublicService } from 'src/app/public/public.service';
import { PopupService } from '../../service/popup.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {
  @Output() closeModal = new EventEmitter
  @Output() addProductIntoListItem = new EventEmitter;
  @ViewChild("myNameElem2") myNameElem: ElementRef;
  close = true;
  projectId;
  project;
  @Input() categoryToRec;
  @Input() storeToRec;
  @Input() comingProductName;
  @Input() parentProjectId;
  toggle = false;
  productName = '';
  isSelectedItem = '';
  isSelectedCategoryItem = '';
  showPopup = '';
  items;
  categories = [];
  stores = [];
  isSubmit = false;
  productForm: FormGroup;
  options: FormArray;
  selectedCategoryPopup = [];
  selectedStorePopup = []
  public coverlogoImage = '';
  public coverlogoImageError = false;
  selectedStoreError = false;
  selectedCategoryError = false;
  shopDetails = false;
  shopEdit;
  
  public images = {
    cover: {},
  }

  selectedCategories = [];
  selectedStores = [];
  cover = { heading: 'Cover Photo', description: '', index: 99, ControlName: "Zero", show: 'cover', checkSize: false }
  seeMoreCategory = false ;
  categoryDisplayCount = 4;
  seeMoreStore = false ;
  storeDisplayCount = 4;
  seeMoreCategoryCount = 0;
  seeMoreStoreCount = 0;
  productId;
  isEdit = false;
  productObj;
  arrow;
  isLoading = false;
  fromLink;
  productEdit;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private toastr: ToastrService,
    private accountManager: AccountManagerService,
    public publicService: PublicService,
    private popupService: PopupService,
    ) {
      
      if(this.comingProductName){
        this.productName = this.comingProductName;
      } else {
        this.productName = null;
      }
     }

  ngOnInit(): void {
   this.getProductForm();

    this.popupService.productProjectId.subscribe(result=>{
      this.projectId = result;
    });

    this.popupService.productEditId.subscribe(result=>{
      this.productId = result;
    });

    this.popupService.productName.subscribe(result=>{
      if(result != ''){
        this.productName = result;
      } else{
        this.productName = '';
      }
    });
    
    this.selectedCategories = [...this.categoryToRec];
    this.selectedStores = [...this.storeToRec];

    this.popupService.shopDetailsProduct.subscribe(result=>{
      this.shopDetails = result;
    });

    if(this.projectId != null && this.productId != null){
      this.showEditItem(this.projectId, this.productId);
    }

    if(this.activatedRoute.parent.snapshot.routeConfig.path == "product/:id"){
      this.fromLink = true;
      this.activatedRoute.params.subscribe(params => {
        this.projectId = params['id'];
        if(this.projectId){
          this.showEditItem(this.projectId, this.productId);
        }
      })
    }
  }

  ngAfterViewInit(){
    this.myNameElem.nativeElement.scrollIntoView({behavior : "auto"})
  }

  changeStatus(){
    for(let i=0; i<this.selectedCategories.length; i++){
      this.selectedCategories[i].selected = false;
      
    }

    for(let i=0; i<this.selectedStores.length; i++){
      this.selectedStores[i].selected = false;
    }
  }

  goBack(){
    this.popupService.productEdit.next(false);
  }

  cancel(){ 
    if(this.shopDetails){
      this.closeModal.emit(this.close);
    } else if(this.fromLink){
      this.router.navigate([`/home/shop-details/${this.projectId}`])
    } else{
      this.popupService.productEdit.next(false);
    }
  }

  OnOpen(){
    this.selectedCategories = [...this.categoryToRec];
    this.selectedStores = [...this.storeToRec];
    
    this.popupService.productProjectId.subscribe(result=>{
      this.projectId = result;
    });
  
    this.popupService.productEditId.subscribe(result=>{
      this.productId = result;
    });

    this.popupService.productEdit.subscribe(result=>{
      this.productEdit = result;
    });

    this.popupService.shopDetailsProduct.subscribe(result=>{
      this.shopDetails = result;
    });

    this.popupService.shopProductEdit.subscribe(result=>{
      this.shopEdit = result;
    });

    this.popupService.productName.subscribe(result=>{
      if(result != '') {
        this.productName = result;
      } else{
        this.productName = '';
      }
    })

    if(this.shopDetails == true && this.shopEdit == false && this.fromLink != true){
      this.getProductForm();
      this.changeStatus();
      this.coverlogoImageError = false;
      this.coverlogoImage = '';
    }

    if(this.productId == null && this.projectId != null && 
      this.productEdit == true && this.shopDetails == false && this.fromLink != true){
      this.changeStatus();
      this.coverlogoImageError = false;
      this.coverlogoImage = '';
    } else if(this.shopDetails == true && this.shopEdit == true){
      if(this.projectId != null && this.productId != null){
        this.showEditItem(this.projectId, this.productId);
      }
    }
  }

  ngOnChanges(){
    if(this.activatedRoute.parent.snapshot.routeConfig.path == 'product/:id/:productId'){
      this.selectedCategories = [...this.categoryToRec];
      this.selectedStores = [...this.storeToRec];
    }
    
    this.OnOpen();

    this.popupService.showProductArrow.subscribe(result=>{
      this.arrow = result;
      if(this.arrow == true){
      }
      this.coverlogoImageError = false;
    })

    this.popupService.productProjectId.subscribe(result=>{
      this.projectId = result;
    });

    this.popupService.productEditId.subscribe(result=>{
      this.productId = result;
    });

    this.popupService.shopDetailsProduct.subscribe(result=>{
      this.shopDetails = result;
    });
  
    this.popupService.productEdit.subscribe(result=>{
      this.productEdit = result;
    });

    this.popupService.shopProductEdit.subscribe(result=>{
      this.shopEdit = result;
    });
    
    if(this.shopDetails) {
      this.popupService.shopDetailsProduct.next(true);
    } else {
      this.popupService.shopDetailsProduct.next(false);
    }


    if(this.activatedRoute.parent.snapshot.routeConfig.path == 'product/:id/:productId'){
      this.fromLink = true;
      this.activatedRoute.params.subscribe(params => {
        this.projectId = params['id'];
        this.productId = params['productId']
        this.isLoading = true;
        if(this.projectId != null && this.productId != null){
          if(this.selectedCategories.length > 0 && this.selectedStores.length > 0){
            this.showEditItem(this.projectId, this.productId);
          }
        }
      })
    }
  }

  changeValue(value,i){
    let ValidatorsObj = (this.getLineOptionsControls().at(i).get('items') as FormArray)
    let items = this.getLineOptionsControls().at(i).value.items;
    if(this.getLineOptionsControls().at(i).value.name == ''){
      
      items.map((d,i) =>{
        ValidatorsObj.at(i).get('price').clearValidators();
        ValidatorsObj.at(i).get('price').updateValueAndValidity();
        
        ValidatorsObj.at(i).get('option').clearValidators();
        ValidatorsObj.at(i).get('option').updateValueAndValidity();
      })
    }else{
      items.map((d,i) =>{
        ValidatorsObj.at(i).get('price').setValidators([Validators.required])
        ValidatorsObj.at(i).get('price').updateValueAndValidity();
        
        ValidatorsObj.at(i).get('option').setValidators([Validators.required]);
        ValidatorsObj.at(i).get('option').updateValueAndValidity();
      })
    }
  }

  checkQueryParams() {
    this.activatedRoute.params.subscribe(params => {
      this.projectId = params['id'];
      this.productId = params['productId']
      this.project = this.accountManager.getProjectById(this.projectId);
      if (!this.project) {
        this.getUserProjectDetail();
      }
      this.getProductForm();
    })
  }

  showEditItem(projectId, productId){
    this.projectId = projectId;
    
    if (productId) {
      this.productId = productId;
      this.isLoading = true;
      this.isEdit = true;
      this.getProductData();
    }
    this.project = this.accountManager.getProjectById(projectId);
    if (!this.project)  {
      this.getUserProjectDetail();
    }
  }

  getProductData(){
    this.accountManager.getProductById(this.projectId, this.productId).then(
      resp => {
        if(resp['data']){
          this.productObj = resp['data'];
          this.initializeProductData();
      }
      },
      err => {
        this.isLoading = false;
        this.toastr.error(err.error.metadata.message, '');
        this.router.navigate([`home/shop-details/${this.projectId}`])
      },
    );
  }
  
  getProductForm() {
    this.productForm = new FormGroup({
      name: new FormControl(this.productName, [Validators.required]),
      description: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
      isAvailable: new FormControl(true),
      options: new FormArray([this.createOptionsItem()]),
    })
  }

  getUserProjectDetail() {
    // TODO: add loading
    this.isLoading = true;
    this.accountManager.getUserProject(this.projectId).then(resp => {
      if (resp.status) {
        this.isLoading = false;
      }
    }, err => {
      this.toastr.error(err.error.message, '');
      this.isLoading = false;
    });
  }

  async getItems() {
    this.items = await this.accountManager.getItems(this.projectId);

    this.isLoading = false;
  }

  async getStores() {
    this.stores = await this.accountManager.getStores(this.projectId);

    this.isLoading = false;
  }

  onItemPicker(color, i) {
    this.isSelectedItem = i;
  }

  selectedItem(index) {
    if (this.isSelectedItem === index) {
      return true;
    } else {
      return false;
    }
  }

  onCategoryPicker(color, i) {
    this.isSelectedCategoryItem = i;
  }

  selectedCategoryItem(index) {
    if (this.isSelectedCategoryItem === index) {
      return true;
    } else {
      return false;
    }
  }

  addProduct() {
    let analytics = this.isEdit ? 'edit-save-product' :'add-save-product'
    this.publicService.logAnalytics(`${analytics}-btn-clicked-from-product`)

    this.productForm.markAllAsTouched();
    this.coverlogoImageError = this.coverlogoImage == '' ? true : false;

    if (this.bothValidationCheck()) {
      this.isSubmit = true;
      let productData = {
        data: {
          projectId: this.fromLink ? this.parentProjectId : this.projectId,
          data: {
            "name": this.productForm.value['name'],
            "description": this.productForm.value['description'],
            "createdDate": Date.now() / 1000,
            "isAvailable": this.productForm.value['isAvailable'],
            "price": {
              //TODO: Add Currency beside price
              currencyCode: "CAD",
              value: +this.productForm.value['price'],
            },
            "sort": 3,
          }
        }
      }

      productData.data.data['storeIdsAvailable'] = [];
      if (this.selectedStorePopup.length > 0) {
        this.selectedStorePopup.map(d => {
          productData.data.data['storeIdsAvailable'].push(d.id);
        })
      }

      productData.data.data['categoryIdsAvailable'] = [];
      if (this.selectedCategoryPopup.length > 0) {
        this.selectedCategoryPopup.map(d => {
          productData.data.data['categoryIdsAvailable'].push(d.id);
        })
      }

      if (this.images.cover['name']) {
        productData['image1'] = this.images.cover;
      } else {
        productData.data.data['imageUrls'] = this.productObj.imageUrls;
      }

      if (this.productForm.value['options'].length > 0) {
        let options = [];
        this.productForm.value['options'].map(d => {
          let optionObj = {
            name: '',
            values: []
          }
          optionObj.name = d.name;
          if (d.items.length > 0) {

            d.items.map(item => {
              let itemObj = {
                price: {
                  currencyCode: "CAD",
                  value: +item.price
                },
                value: item.option

              }

              itemObj.value = item.option;
              optionObj.values.push(itemObj);
            })
          }
          options.push(optionObj);
        })
  
        productData.data.data['options'] = [...options];
      }

      if (this.isEdit && this.productId) {
        productData.data.data['id'] = this.productId;
      }
  
      let productObj = this.publicService.makeFormData(productData);
      this.accountManager.addProduct(productObj).then(
        resp => {
          this.addProductIntoList();
          
          this.isSubmit = false;
          if (this.isEdit && this.productId) {
            this.toastr.success('Your Product has been updated', '');
          } else {
            this.toastr.success('Your Product has been created', '');
          }


          if(this.shopDetails){
            this.closeModal.emit(this.close);
          } else if(this.fromLink){
            this.router.navigate([`/home/shop-details/${this.parentProjectId}`])
          } else{
            this.popupService.productEdit.next(false);
          }

        },
        err => {
          this.isSubmit = false;
          this.toastr.error(err.error.message, '');
        },
      );
    }
  }

  initializeProductData() {
    let projectItem = this.productObj;
    if (projectItem?.imageUrls?.length > 0) {
      this.coverlogoImage = projectItem.imageUrls[0];
      this.coverlogoImageError = false;
    }

    if(this.selectedCategories.length>0){
      this.selectedCategories.map(result=>{
        var check = projectItem?.categoryIdsAvailable.includes(result.id);
        if(check){
          result.selected = true;
          this.selectedCategoryPopup.push(result);
        } else {
          result.selected = false;
        }
      })
    }


    if(this.selectedStores.length>0) {
      this.selectedStores.map(result=>{
        var check = projectItem?.storeIdsAvailable.includes(result.id);
        if(check){
          result.selected = true;
          this.selectedStorePopup.push(result);
        }
        else{
          result.selected = false;
        }
      })
    }

    this.productForm.patchValue({
      name: projectItem?.name,
      description: projectItem?.description,
      isAvailable: projectItem?.isAvailable,
      price: projectItem?.price?.value
    });

    if(projectItem?.options?.length > 0){
      this.options = this.productForm.get('options') as FormArray;
      this.options.clear();
        projectItem.options.map(d =>{
          this.options.push(this.addOptionsItems(d));
        })
    }
    this.isLoading = false;
  }

  addOptionsItems(option){
    let itemsArr = [];
    for (const value of option.values) {
      itemsArr.push(this.addItem(value))
    }

    return new FormGroup({
      name: new FormControl(option.name),
      items: new FormArray([...itemsArr])
    });
  }
  
  addItem(item){
    return new FormGroup({
      option: new FormControl(item.value),
      price: new FormControl(item.price.value)
    });
  }

  getLineOptionsControls() {
    return this.productForm.get('options') as FormArray;
  }

  createOptionsItem(): FormGroup {
    return new FormGroup({
      name: new FormControl(''),
      items: new FormArray([this.createItems(), this.createItems()])
    });
  }

  addOptions() {
    this.options = this.productForm.get('options') as FormArray;
    this.options.push(this.createOptionsItem());
  }

  createItems(): FormGroup {
    return new FormGroup({
      option: new FormControl(''),
      price: new FormControl('')
    });
  }

  addItems(itemIndex: number) {
    this.getLineItemControls(itemIndex).push(this.createItems());
  }

  getLineItemControls(itemIndex: number): FormArray {
    return this.getLineOptionsControls().at(itemIndex).get('items') as FormArray;
  }

  removeOptions(index: number) {
    this.getLineOptionsControls().removeAt(index);
  }

  removeSubOptions(index: number, itemIndex: number) {
    this.getLineItemControls(index).removeAt(itemIndex);
  }

  deleteImage() {
    this.coverlogoImage = '';
    this.images.cover = {};
    this.coverlogoImageError = true;
  }

  onFileChangeslogoImage($event, type) {
    let image = $event.image[0].base64;
    this.coverlogoImage = image;
    this.images.cover = $event.rawFile;
    this.coverlogoImageError = false;
  }

  bothValidationCheck() {
    return this.productForm.valid && !this.coverlogoImageError && !this.selectedCategoryError && !this.selectedStoreError
  }

  onPopupOpen(type) {
    this.showPopup = type;
  }

  categoryConfirm(categories) {
    this.selectedCategories = [...categories];
    if(this.selectedCategoryError) {
      this.selectedCategoryError = this.selectedCategories.length == 0 ? true : false;
    }

    if(this.selectedCategories.length > 4 && !this.seeMoreCategory && this.seeMoreCategoryCount == 0){
      this.seeMoreCategoryCount = 1
      this.categoryDisplayCount = 3
      this.seeMoreCategory = true;
    } else {
      this.categoryDisplayCount = this.selectedCategories.length;
    }
  }

  storeConfirm(stores) {
    this.selectedStores = [...stores];
    if(this.selectedStoreError){
      this.selectedStoreError = this.selectedStores.length == 0 ? true : false;
    }

    if(this.selectedStores.length > 4 && !this.seeMoreStore && this.seeMoreStoreCount == 0){
      this.seeMoreStoreCount = 1
      this.storeDisplayCount = 3
      this.seeMoreStore = true;
    } else {
      this.storeDisplayCount = this.selectedStores.length;
    }
  }

  seeMoreBtn(type) {
    if(type == 'store'){
      this.storeDisplayCount = this.selectedStores.length;
      this.seeMoreStore = false;
    } else{
      this.categoryDisplayCount = this.selectedCategories.length;
      this.seeMoreCategory = false;
    }
  }

  callCategoryPopup(item, type) {
    var exist = this.selectedCategoryPopup.includes(item);

    if(exist) {
      let index = this.selectedCategoryPopup.indexOf(item);
      this.selectedCategoryPopup.splice(index, 1);

      this.selectedCategories.find(result => {
        if(result.id == item.id){
          result.selected = false;
        }
      });
    } else {
      this.selectedCategoryPopup.push(item);
      this.selectedCategories.find(result => {
        if(result.id == item.id){
          result.selected = true;
        }
      });
    }
  }

  callStorePopup(item, type) {
    var exist = this.selectedStorePopup.includes(item);

    if(exist){
      let index = this.selectedStorePopup.indexOf(item);
      this.selectedStorePopup.splice(index, 1);

      this.selectedStores.find(result => 
        {
          if(result.id == item.id){
            result.selected = false;

        }
      });
    }
    else{
      this.selectedStorePopup.push(item);
      this.selectedStores.find(result => {
        if(result.id == item.id){
          result.selected = true;

        }
      });
    }
  }

  addProductIntoList(){
    this.addProductIntoListItem.emit();
  }
}
