import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { switchMap } from 'rxjs/internal/operators';
import { IRefreshToken } from './userData.model'
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string = '';
    if (request.headers.get('skip')) {
      return next.handle(request);
    }
    return next.handle(this.setHeader(request)).pipe(catchError((err) => {
      if (err instanceof HttpErrorResponse && !request.url.includes('refreshToken')) {
        if ([401].includes(err.status)) return this.refreshToken(request, next);
      }
      return throwError(err);
    }));
  }

  private setHeader(request: HttpRequest<any>): HttpRequest<any> {
    request = request.clone({
      setHeaders: { 'Authorization': `Bearer ${localStorage.getItem('token') as string}` }
    });
    return request;
  }

  private updateLocalStorage(refreshToken) {
    localStorage.setItem('token', refreshToken.id_token);
    localStorage.setItem('refreshToken', refreshToken.refresh_token);
  }
  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.auth.refreshToken().pipe(switchMap((response: IRefreshToken) => {
      this.updateLocalStorage(response);
      return next.handle(this.setHeader(request));
    }), catchError(err => {
      localStorage.clear();
      this.router.navigate(['/login']);
      return next.handle(this.setHeader(request));
    })
    )
  }
}

