<div class="container">
    <div class="auth-box pwd-success-box">
        <div class="row no-gutters align-items-center justify-content-center">
            <div class="col-sm-12 row pwd-success-box-inside">
                <div class="col-sm-12 col-md-12 col-lg-6 bg-greay bg-image">
                    <div class="center-logo">
                        <div class="logo mb-5">
                            <img src="assets/images/logo.png" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 bg-white">
                    <div class="text-center">
                        <img src="../assets/images/auth/changepwdsuccess.png" />
                        <h2 class="mb-5">Your email has been verified.</h2>
                        <a routerLink="/login" class="btn btn-primary mb-2">Log in</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>