import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppService } from '../user/app.service';
import { MetaService } from '../meta-service/meta.service'

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  @ViewChild('contact') contactElement: ElementRef;
  @ViewChild('apps') appsElement: ElementRef;
  @ViewChild('home') homeElement: ElementRef;


  contactForm : FormGroup;
  activeLink = 'home';
  showNavbar = false;
  closeMenu : boolean = true;

  customerApps
  filteredproducts
  searchedProduct
  appsTypeValue = 'all';
  iOSAppExists = false;
  androidAppExists = false;

  public homeOffset: any;
  public contactOffset: any;
  public appsOffset: any;

  constructor(
    private appService:AppService,
    private ngxService: NgxUiLoaderService,
    public metaService:MetaService,
    private route: Router) { }


    ngAfterViewInit() {
      this.contactOffset = this.contactElement.nativeElement.offsetTop;
      this.appsOffset = this.appsElement.nativeElement.offsetTop;
      this.homeOffset = this.homeElement.nativeElement.offsetTop;
    }

    @HostListener('window:scroll', ['$event'])
    checkOffsetTop() {
      if (window.pageYOffset >= this.homeOffset && window.pageYOffset <= this.appsOffset-40) {
        this.activeLink = 'home';
      } else if (window.pageYOffset >= this.appsOffset-40 && window.pageYOffset < this.contactOffset+200) {
        this.activeLink = 'apps';
      } else if (window.pageYOffset >= this.contactOffset+200) {
        this.activeLink = 'contact';
      }
    }


    goToSection(type){
      if(type == "home"){
        this.activeLink = "home";
        var element = document.getElementById('home');
        var elementPosition = element.getBoundingClientRect().top;
        window.scrollTo({top :elementPosition, behavior : 'smooth'});
      }
      if(type == "apps"){
        this.activeLink = "apps";
        var element = document.getElementById('apps');
        var headerOffset = 82;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({top :offsetPosition, behavior : 'smooth'});
      }
      if(type == "contact"){
        this.activeLink = "contact";
        var element = document.getElementById('contact');
        var headerOffset = 82;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({top :offsetPosition, behavior : 'smooth'});
      }
    }

    showNav(){
      this.showNavbar = !this.showNavbar;
    }

    close(){
      if(!this.showNavbar) {
        this.closeMenu = false;
      } else if(this.showNavbar){
        this.closeMenu = true;
      } else if(this.showNavbar && this.closeMenu){
        this.closeMenu = true;
        this.showNavbar = false;
      }
    }

    ngOnInit(): void {
      this.setTitleAndDescription()
      this.appService.getCustomersApps("").then(result=>{
        if(result){ 
          this.customerApps = result;
          for(var i=0; i<this.customerApps.length; i++){
            if(this.customerApps[i].type == "iOS"){
              this.iOSAppExists = true;
            }
            if(this.customerApps[i].type == "android"){
              this.androidAppExists = true;
            }
          }
        }
      },err => {
        console.log(err);
      })
    }

  setTitleAndDescription(){
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, customers';
    let description = 'View a list of our Mobile Ordering App Customers with their name, image and link to the apple app store and google play store';
    this.metaService.setPageTitleAndMetaTags('Some of the Customer that uses our Restaurant ordering system', description, keywords, 'https://orderax.com/customers')
  }

  appsType(type){
    if(type == "all"){
      this.appsTypeValue = "all";
    }
    else if(type == "iOS"){
      this.appsTypeValue = "iOS";
    }
    else if(type == "android"){
      this.appsTypeValue = "android";
    }
  }

  goToLogin(){
    this.route.navigate([`/login`])
  }

  goToSocial(type) {
    if(type == 'instagram') {
      window.open(`https://www.instagram.com/orderaxapp/?hl=en`, '_blank', 'noopener');
    }
    if(type == 'facebook') {
      window.open(`https://web.facebook.com/orderax`, '_blank', 'noopener');
    }
    if(type == 'twitter') {
      window.open(`https://www.twitter.com/orderax1`, '_blank', 'noopener');
    }
    if(type == 'youtube') {
      window.open(`https://www.youtube.com/channel/UCnAEXlmsER86iLQwnysyHUQ`, '_blank', 'noopener');
    }
    if(type == 'google') {
      window.open(`https://g.page/r/CdxuoeNDxtG_EAE`, '_blank', 'noopener');
    }
  }

  getStarted(){
    this.route.navigate([]).then(result => {  window.open(`/registration`, '_blank'); });
  }

  goToHome(){
    this.route.navigate(['/']);
  }

  goToSingleApp(path){
    this.route.navigate([]).then(result => {  window.open(`${path}`, '_blank'); });
  }
}
