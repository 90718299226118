<div class="container">
    <div class="auth-box register-box">
        <div class="row no-gutters align-items-center justify-content-center">
            <div class="col-sm-12 row register-box-inside">
                <div class="col-sm-12 col-md-12 col-lg-6 bg-greay bg-image">
                    <div class="center-logo">
                        <div class="logo mb-5">
                            <img src="assets/images/logo.png" alt="Orderax Logo Image"/>
                        </div>
                    </div>
                    <!-- <div>
                        <h3 class="auth-title">Sign up Terms & Condition</h3>
                        <ul class="auth-list">
                            <li>Here will be demo instruction</li>
                            <li>Password should be minimum 8 digit</li>
                            <li>Upper & Lower case number</li>
                            <li>At least one number</li>
                        </ul>
                    </div>
                    <div class="mt-4">
                        <h3 class="auth-title">Sign up facility as a user</h3>
                        <ul class="auth-list">
                            <li>Demo facility will be here 1</li>
                            <li>Demo facility will be here 2</li>
                            <li>Demo facility will be here 3</li>
                            <li>Demo facility will be here 4</li>
                        </ul>
                    </div> -->
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 bg-white">
                    <div class="title mb-5">
                        <h1>Signup</h1>
                    </div>
                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit($event)">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Email</label>
                            <input type="email" class="form-control" id="email" placeholder="Your email address" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Password</label>
                            <input [type]="show ? 'text' : 'password'" class="form-control" id="password" placeholder="Type your password" name="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <span (click)="password()" class="toggle-password"><i class="fa" aria-hidden="true" [ngClass]="show ? 'fa-eye-slash' : 'fa-eye'"></i></span>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Confirm Password</label>
                            <input [type]="show ? 'text' : 'password'" class="form-control" id="confirmpassword" placeholder="Type your confirm password" name="confirmPassword" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                            <span (click)="password()" class="toggle-password"><i class="fa" aria-hidden="true" [ngClass]="show ? 'fa-eye-slash' : 'fa-eye'"></i></span>
                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                            </div>
                        </div>
                        <div class="form-group center">
                            <button type="submit" class="btn btn-primary mb-2" [disabled]="isLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isLoading"></span>Signup</button>
                            <p class="mb-2">Or Sign Up With</p>
                            <div class="d-flex justify-content-around">
                            <div (googleLogin)="googleLogin($event)" context="Register"></div>
                            </div>
                        </div>
                    </form>
                    <p>By signing up, I agree to the <button (click)="goToNewTab('https://www.iubenda.com/terms-and-conditions/65640587','terms-and-conditions')" class="btn link-btn">terms of use</button> and processing of my personal data as stated in the <button (click)="goToNewTab('https://www.iubenda.com/privacy-policy/65640587','privacy-policy')"
                        class="btn link-btn">privacy policy</button></p>
                        <br>
                    <p class="auth-bottom">Already have an Account? <a (click)="publicService.logAnalyticsAndRedirect('route-to-login-from-registeration','/login')" routerLink="">Login here</a></p>
                </div>
            </div>
        </div>
    </div>
</div>