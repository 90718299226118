import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/internal/operators'
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment.prod';
import { AccountManagerService } from '../managers/account-manager.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationDetails, AuthenticationResponse, IChangePasswordResponse, IGoogleLoginResponse, IRefreshToken } from './userData.model'
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public siteURL = '';
  public apiURL = '';
  public emailVerified = new Subject<boolean>();

  constructor(
    public router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private accountManager: AccountManagerService
  ) {
    this.siteURL = environment.siteBaseURL;
    this.apiURL = environment.backendBaseURL;
  }

  public getemailVerified(): Observable<boolean> {
    return this.emailVerified.asObservable();
  }

  signInWithIDP(credential: string) {
    return this.http.post<IGoogleLoginResponse>(this.apiURL + '/authApi/signInWithIDP', {'googleIdToken': credential}).pipe(switchMap((item: IGoogleLoginResponse) => {
        const loginResponse: AuthenticationResponse = {
            displayName : item.displayName,
            email       : item.email,
            expiresIn   : item.expiresIn,
            idToken     : item.idToken,
            kind        : item.kind,
            localId     : item.localId,
            refreshToken: item.refreshToken,
            registered  : `${false}`,
        }
        this.setDataToStorage(loginResponse);
        return this.accountManager.getUserInfo('signUp');
    }))
  }

  login(loginObj: AuthenticationDetails) {
    return this.http.post(this.apiURL + '/authApi/signIn', loginObj, { headers: { skip: "true" } }).pipe(
      switchMap((resp: AuthenticationResponse)  => {
        this.setDataToStorage(resp);
        return this.accountManager.getUserInfo('signUp');
      })
    );
  }

  register(signInObj: AuthenticationDetails) {
    return this.http.post(this.apiURL + '/authApi/signupUser', signInObj, { headers: { skip: "true" } }).pipe(
      switchMap((resp: AuthenticationResponse) => {
        this.setDataToStorage(resp);
        return this.accountManager.getUserInfo('signUp');
      })
    );
  }

  refreshToken(): Observable<IRefreshToken> {
    return this.http.post<IRefreshToken>(this.apiURL + '/authApi/refreshToken', {
      uid: localStorage['localId'],
      refreshToken: localStorage['refreshToken']
    });
  }

  forgetPassword(email : object){
    return this.http.post(this.apiURL + '/authApi/forgotPassword' , email);
  }

  resetPassword(actionCode: string, resetPassword: string) {
    const resetPasswordObj = {
      "oobCode": actionCode,
      "password": resetPassword
  }
    return this.http.post(this.apiURL + '/authApi/resetPassword' , resetPasswordObj );
  }

  changePassword(changePasswordObj) {
    return this.http.post<IChangePasswordResponse>(this.apiURL + '/authApi/changePassword' , changePasswordObj );
  }

  confirmOobCodeForEmailVerfication(code: string){
    const data = {
      "oobCode": code
  }
    return this.http.post<IChangePasswordResponse>(this.apiURL + '/authApi/confirmOobCode' , data );
  }
  verifyEmail(verifyEmailObj) {
    return this.http.post(this.apiURL + '/authApi/sendVerifyEmail' , verifyEmailObj );
  }

  logOut() {
    this.accountManager.removeStore();
    this.router.navigate(['/login']);
  }

  dealy(): Observable<string> {
    return new Observable((observer) => {
      setTimeout(() => {
        observer.next('result');
      }, 3000);
    });
  }

  routeAfterLogin(shouldRouteToHomePage?) {
    let userData = this.accountManager.getUserData()
    let user = userData.user;
    if (user) {
      if (user.business.info && user.business.info.brandName == '' && user.business.info.businessName == '') {
        this.router.navigate(['/businessinfo']);
      } else if (user.business.address &&
        user.business.address.line1 == '' &&
        user.business.address.postalCode == ''
      ) {
        this.router.navigate(['/businessinfo', 'businessaddress']);
      } else if (user.business.legal && user.business.legal.type == '') {
        this.router.navigate(['/businessinfo', 'businesstype']);
      } else if (user.contact && user.contact.firstName == '' && user.contact.lastName == '') {
        this.router.navigate(['/businessinfo', 'contactInfo']);
      } else {
        if (shouldRouteToHomePage) {
          this.router.navigate([
            '/home/dashboard'
          ]);
        }
      }
    }
  }

  checkProfileComplete() {
    let userData = this.accountManager.getUserData()
    let user = userData.user;
    if (user) {
      if (user.business.info && user.business.info.brandName == '' && user.business.info.businessName == '') {
        return 'businessinfo';
      } else if (user.business.address && user.business.address.line1 == '' && user.business.address.postalCode == '') {
        return 'businessaddress';
      } else if (user.business.legal && user.business.legal.type == '') {
        return 'businesstype';
      } else if (user.contact && user.contact.firstName == '' && user.contact.lastName == '') {
        return 'contactInfo';
      } else {
        return 'complete'
      }
    }
  }

  setDataToStorage(response: any) {
    if (response.displayName) localStorage.setItem('displayName', response.displayName);
    if (response.email) localStorage.setItem('email', response.email);
    if (response.expiresIn) localStorage.setItem('expiresIn', response.expiresIn);
    if (response.idToken) localStorage.setItem('token', response.idToken);
    if (response.kind) localStorage.setItem('kind', response.kind);
    if (response.localId) localStorage.setItem('localId', response.localId);
    if (response.refreshToken) localStorage.setItem('refreshToken', response.refreshToken);
    if (response.registered) localStorage.setItem('registered', response.registered);
  }
}
