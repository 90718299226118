<div class="container">
    <div class="auth-box forget-pwd-box">
        <div class="row no-gutters align-items-center justify-content-center">
            <div class="col-sm-12 row forget-pwd-box-inside">
                <div class="col-sm-12 col-md-12 col-lg-6 bg-greay bg-image">
                    <div class="center-logo">
                        <div class="logo mb-5">
                            <img src="assets/images/logo.png" alt="Orderax Logo Image"/>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 bg-white">
                    <div class="title mb-5">
                        <h1>Forgot password</h1>
                    </div>
                    <form [formGroup]="forgetpwdForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <h4>Type your email address</h4>
                            <p>Enter email to reset your password. You will receive an email with instructions on how to reset your password. If you are experiencing problems resetting your password contact us or send us an email</p>
                            <input type="email" class="form-control" id="email" placeholder="Your email address" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mb-2" [disabled]='isLoading'><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isLoading"></span>Reset Password</button>
                        </div>
                        <p class="auth-bottom"><a (click)="publicService.logAnalyticsAndRedirect('route-to-login-from-reset-password','/login')" routerLink="">Login here</a></p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>