import { Component, OnInit } from '@angular/core';
import { PublicService } from '../public.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css'],
})
export class PrivacyComponent implements OnInit {
  privacyData:any = [];
  constructor(private publicService: PublicService) {}

  ngOnInit(): void {
    this.publicService.getDetails(
      'https://www.iubenda.com/api/privacy-policy/65640587').then(privacy => {
        this.privacyData = privacy;
      });
  }
}
