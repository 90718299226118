<div class="fw-600">{{data.heading}}</div>
                    <div [ngClass]="error ? 'error-text' : ''" class="cl-dfdfdf">
                        {{data.description}}
</div>
<div class="pt-1 d-flex align-items-center">
    <div class="position-relative wfc cp">
            <label for="{{data.index}}">
                    <img [src]="error ? '../../../assets/images/theme/error-upload.png' : '../../../assets/images/theme/upload.png'" alt="Image Upload Placeholder" srcset="">
                </label>
            <input style="display:none" id="{{data.index}}" file type="file" alife-file-to-base64 multiple
            (onFileChanged)="onFileChanges($event, data.index)" [(rawFileModel)]="rawFiles" class="form-control"/>
    </div>
    <div class="position-relative wfc cp pl-3 mt" *ngIf="imageUrl">
            <img class="show-image" src="{{imageUrl}}" alt="upload image">
            <img (click)="delete($event, data.index)" src="../../../assets/images/theme/trash.png" class="deletelogo" alt="Remove image icon"
            srcset="">
    </div>
</div>

