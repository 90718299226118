<div class=" myaccount-box">
    <div class="row no-gutters align-items-center justify-content-center">
        <div class="col-lg-12 row myaccount-box-inside">
            <div class="col-sm-12 col-md-12 col-lg-12 bg-white">
                <div class="new-title mt-3">
                    <h3>Create Shop</h3>
                </div>
                <ng-container *ngIf="productLoader">
                    <div class="col-12 font-weight-bold">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>
                </ng-container>
            </div>
            <div class="col-lg-10" *ngIf="!productLoader">
                <div class="row">
                    <div class="shop-title col-sm-4">
                        Choose Product Type
                    </div>
                    <div class="col-sm-3 mobile-hidden">

                    </div>
                    <div id="custom-search-input" class="col-sm-5">
                        <div class="float-right w-100">
                            <input type="text" class="search-query p-2" [(ngModel)]='search'
                                placeholder="Search Store" />
                            <span class="input-group-btn">
                                <button class="btn btn-danger" type="button">
                                    <i class="ri-search-line"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mt-3 product">
                    <div class="product-display" id="bg" [ngClass]="{'selected': selectionClass(product)}"
                        (click)='addProducts(product)' *ngFor='let product of filteredProducts | ngSearchPipe:search'
                        [ngStyle]="{'background-image': 'url(' + product.urlImage + ')'}">
                        <div class="tick" id="tick">
                            <img src="assets/images/shop/tick.png" alt="Tick">
                        </div>
                        <ng-container *ngIf="!product.isAvailable">
                            <div id="comming-soon">
                                <p>COMING SOON</p>
                            </div>
                        </ng-container>
                        <h4>
                           <a> {{product.name}}
                            <span class="right-arrow"><img src="assets/images/shop/right.png" id="arrow"></span></a>
                        </h4>
                    </div>
                    <!-- <div [ngStyle]="{'background-image': 'url(' + product.urlImage + ')'}"
                            class="border box text-center pt-5">
                            <ng-container *ngIf="!product.isAvailable">
                                <span class="comming-soon">Coming Soon</span>
                                <br>
                            </ng-container>
                            <span>{{product.name}} <img src="assets/images/shop/right.png" /></span>
                        </div> -->
                    <!-- <div class="border mt-2 btn btn-secondary w-100 button text-center align-content-center">
                                Details</div> -->
                </div>
            </div>
            <div class="col-lg-10 pr-4 mt-3 store">
                <label class="store-name-title">Store Name</label>
                <input type="text" class="w-100 border form-control" [formControl]="storeName"
                maxlength="20" placeholder="Enter Store Name">
                <div *ngIf="storeName.dirty&&storeName.errors&&storeName.errors.required" class="text-danger">
                    Store Name is required</div>
                <div *ngIf="!(storeName.errors)"></div>
                <div *ngIf="storeName.errors&&storeName.errors.minlength" class="text-danger">Store name must be
                    of least 3 characters</div>
                <button type="button" class="btn btn-secondary btn-lg mt-5" (click)="createShop()"
                    [disabled]="isLoading"><span class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true" *ngIf="isLoading"></span>Next<span class="store-arrow"
                        style="margin-left: 20px" id="right-arrow"><img src="assets/images/shop/button-right.png"
                            alt="Right Arrow"></span></button>
            </div>
        </div>
    </div>
</div>