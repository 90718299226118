import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor( private titleService: Title,
    private metaService: Meta) { }

  setPageTitleAndMetaTags(title, description, keywords, url, ogDescription?){
    this.titleService.setTitle(title);
    this.metaService.addTags([
      {name: 'keywords', content: keywords},
      {name: 'description', content: description},
      {name: 'robots', content: 'index, follow'},

      {property: 'og:url', content: url},
      {property: 'og:type', content: 'website'},
      {property: 'og:title', content: `${title} | App Marketplace | Custom Mobile Ordering App | Restaurant | Cafe | Coffee | Store`},
      {property: 'og:description', content: ogDescription == null ? description : ogDescription},
      {property: 'og:site_name', content: 'Orderax'},
      {property: 'og:image', content: 'https://orderax.com/assets/images/headerImage.png'},
      {property: 'og:locale', content: 'en_US'},
      {property: 'twitter:card', content: 'summary'},
      {property: 'twitter:site', content: 'orderax1'},
      {property: 'twitter:creator', content: 'orderax1'}
    ]);
  }

  blockIndexing(title, description, keywords, url, ogDescription?){
    this.titleService.setTitle(title);
    this.metaService.addTags([
      {name: 'keywords', content: keywords},
      {name: 'description', content: description},
      {name: 'robots', content: 'noindex'},

      {property: 'og:url', content: url},
      {property: 'og:type', content: 'website'},
      {property: 'og:title', content: `${title} | App Marketplace | Custom Mobile Ordering App | Restaurant | Cafe | Coffee | Store`},
      {property: 'og:description', content: ogDescription == null ? description : ogDescription},
      {property: 'og:site_name', content: 'Orderax'},
      {property: 'og:image', content: 'https://orderax.com/assets/images/headerImage.png'},
      {property: 'og:locale', content: 'en_US'},
      {property: 'twitter:card', content: 'summary'},
      {property: 'twitter:site', content: 'orderax1'},
      {property: 'twitter:creator', content: 'orderax1'}
    ]);
  }
}
