import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ParamMap,ActivatedRoute } from '@angular/router';

import { MustMatch } from '../../_helper/must-match.validator';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { MetaService } from '../../meta-service/meta.service'
import { IChangePasswordResponse } from '../userData.model';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})

export class ChangepasswordComponent implements OnInit {
  changepwdForm: FormGroup;
  submitted = false;
  actionCode = '';
  showPassword: boolean;

  constructor(private formBuilder: FormBuilder, 
    private router: Router, 
    public route: ActivatedRoute, 
    private auth: AuthService,
    private toastr: ToastrService,
    private metaService: MetaService) { }

  ngOnInit(): void {
    this.setTitleAndDescription();
     this.changepwdForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required],
        }, {
            validator: MustMatch('password', 'confirmPassword')
     });
    
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.actionCode = paramMap.get('oobCode');
    });
  }
  
  setTitleAndDescription(){
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, password';
    let description = 'Reset your password by entering your new password and then start making mobile ordering App for your own business after login';
    this.metaService.setPageTitleAndMetaTags('Reset Password | OrderAx', description, keywords, 'https://orderax.com/')
  }
  get f() { return this.changepwdForm.controls; }

  maskPassword() {
    this.showPassword = !this.showPassword;
  }

   onSubmit() {
     this.submitted = true;
     // stop here if form is invalid
     if (this.changepwdForm.invalid) {
       return;
     }
     
      this.auth.resetPassword(this.actionCode,this.changepwdForm.value.password).subscribe({
        next: () => {
          this.toastr.success('Your password has been updated successfully','');
          this.router.navigate(['/']);
        }, error: (error) => {
          let errorMessage = error.error ? error.error.message :error.message;
          this.toastr.error(errorMessage, '');
        }
    })
    }
}
