import {
  Directive,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
  NgZone,
  ViewChild
} from "@angular/core";
import {} from "googlemaps";
/// <reference types="@types/googlemaps" />
import { MapsAPILoader } from '@agm/core';
declare var google: any;

@Directive({
  selector: "[google-places]"
})

export class GooglePlacesDirective implements OnInit {
  private element: HTMLInputElement;
  private geoCoder;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  constructor(private elRef: ElementRef,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {
   
    this.element = elRef.nativeElement;
  }
  ngOnInit() {
      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder;
  
        let autocomplete = new google.maps.places.Autocomplete(this.element);
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
  
            this.onSelect.emit(this.getFormattedAddress(place));
            
          });
        });
      });
  }

  getFormattedAddress(place) {
    
    let location_obj = {};
    location_obj["latitude"] = place.geometry.location.lat();
    location_obj["longitude"] = place.geometry.location.lng();
    location_obj["address"] = place.formatted_address;
  
    place.address_components.map(d=>{
      if(d.types.includes('country')){
        location_obj['country'] = d.long_name;
      }
      if(d.types.includes('administrative_area_level_1')){
        location_obj['state'] = d.long_name;
      }
      if(d.types.includes('postal_code')){
        location_obj['postalCode'] = d.long_name;
      }
      if(d.types.includes('locality') || d.types.includes('administrative_area_level_3')){
        location_obj['city'] = d.long_name;
      }
      if(d.types.includes('subpremise')){
        location_obj['line2'] = d.long_name;
      }
      if(d.types.includes('route')){
        location_obj['route'] = d.long_name;
      }
      if(d.types.includes('street_number')){
        location_obj['street_number'] = d.long_name;
      }
      if(d.types.includes('sublocality_level_2')){
        location_obj['sublocality_level_2'] = d.long_name;
      }
      if(d.types.includes('sublocality_level_1')){
        location_obj['sublocality_level_1'] = d.long_name;
      }
    })
    if(location_obj['route'] && location_obj['street_number'] != undefined) {
      location_obj['line1'] = location_obj['street_number'] + ' ' + location_obj['route'];
    } else {
      location_obj['line1'] = location_obj['sublocality_level_2'] == undefined ? location_obj['sublocality_level_1'] : location_obj['sublocality_level_2'] +' ' + location_obj['sublocality_level_1'];
    }
   
    if(location_obj['line1'] == undefined){
      location_obj['line1'] = place.formatted_address;
    }
    return location_obj;
  }
}
