import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MustMatch } from '../../_helper/must-match.validator';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsService } from 'src/app/auth/analytics.service';
import { PublicService } from 'src/app/public/public.service';
import { MetaService } from '../../meta-service/meta.service'

@Component({
  selector: 'app-forgetpwd',
  templateUrl: './forgetpwd.component.html',
  styleUrls: ['./forgetpwd.component.css']
})
export class ForgetpwdComponent implements OnInit {

  forgetpwdForm: FormGroup;
  submitted = false;
  isLoading = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router, 
    private auth: AuthService,
    private toastr: ToastrService,
    public analyticsService:AnalyticsService,
    public publicService:PublicService,
    private metaService: MetaService) { }

  ngOnInit(): void {
    this.setTitleAndDescription();
     this.forgetpwdForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
  }

  setTitleAndDescription(){
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, password';
    let description = 'Having trouble Logging in? Reset your password by entering your email address and then start making mobile ordering App for your own business';
    this.metaService.setPageTitleAndMetaTags('Forgot Password to change your password | OrderAx',description, keywords, 'https://orderax.com/forgetpwd')
  }
  get f() { return this.forgetpwdForm.controls; }

   onSubmit() {
     
     this.submitted = true;
     if (this.forgetpwdForm.invalid) {
       return;
     }
    this.analyticsService.googleTag('reset-password-btn-clicked')
    this.auth.forgetPassword(this.forgetpwdForm.value).subscribe({
      next: () => {
          this.isLoading = false;
          this.router.navigate(['/changepwdsuccess']);
      }, error: (error) => {
        this.isLoading = false;
        let errorMessage = error.error ? error.error.message :error.message;
        this.toastr.error(errorMessage, '');
      }
  })
  }
}
