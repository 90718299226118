
<div class="top-header">
    <button (click)="showNav()" type="button" id="sidebarCollapse" class="fade-btn">
        <img src="/assets/images/menuIcon.svg" alt="menuIcon" />
    </button>
    <div class="main-logo">
        <img (click)="goToHome()" src="/assets/images/OrderAx-1.png" alt="logo" />
    </div>
    <div appOutsideclick (outsideClick)="close()" #menu [ngClass]="{'show-nav' : showNavbar && !closeMenu}" class="top-navbar">
        <ul>
            <li (click)="goToSection('home')" [ngClass]="{'active' : activeLink == 'home'}">Home</li>
            <li (click)="goToSection('apps')" [ngClass]="{'active' : activeLink == 'apps'}">Apps</li>
            <li (click)="goToSection('customers')" [ngClass]="{'active' : activeLink == 'customers'}">Customers</li>
            <li (click)="goToSection('blog')" [ngClass]="{'active' : activeLink == 'blog'}">Blog</li>
            <li (click)="goToSection('social')" [ngClass]="{'active' : activeLink == 'social'}">Social Media</li>
            <li (click)="goToSection('tools')" [ngClass]="{'active' : activeLink == 'tools'}">Tools</li>
            <li (click)="goToSection('contact')" [ngClass]="{'active' : activeLink == 'contact'}">Contact Us</li>
        </ul>
    </div>
    <div class="side-login">
        <button (click)="getStarted()">Get Started</button>
        <button (click)="goToLogin()">Login</button>
    </div>
</div>
<div class="container-fluid cf-xs">
    <div class="header-body" id="header-body">
        <div class="row align-items-center pb-no">
            <div class="col-lg-12">
                <div #home id="home" class="header">
                    <div class="img-fluid" class="home-section">
                        <div class="home-section-layer"> </div>
                        <img src="../../assets/images/headerImage.png" alt="Orderax Header Image">
                        <div class="text-button">
                            <h1>The marketplace for businesses that want their own custom mobile ordering apps to sell their products online.</h1>
                            <button (click)="getStarted()">Get Started
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20" stroke="#11A6E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14 6L20 12L14 18" stroke="#11A6E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                    <div class="product-wrapper">
                        <div class="video-details">
                            <h3>Our Story</h3>
                            <div class="main-content">
                                <div class="video-section">
                                    <div class="video">
                                        <iframe src="https://www.youtube.com/embed/J_AWDX3WaUI?t=4?playlist=tgbNymZ7vqYcontrols=0&loop=1&mute=1"></iframe>
                                        <svg class="ytp-large-play-button ytp-button" aria-label="Play" width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M39 71.5C56.9493 71.5 71.5 56.9493 71.5 39C71.5 21.0507 56.9493 6.5 39 6.5C21.0507 6.5 6.5 21.0507 6.5 39C6.5 56.9493 21.0507 71.5 39 71.5Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M32.5 26L52 39L32.5 52V26Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <!-- <button class="ytp-large-play-button ytp-button" aria-label="Play"><svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%"><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></button> -->
                                    </div>
                                </div>
                                <div class="description">
                                    <div class="description-text">
                                        <p>Most businesses can’t afford to build their own mobile app so we have created the technology to get businesses the own custom app to sell their products and grow their business.
                                            <br><br>
                                            Whether you are a restaurant, cafe, coffee, cannabis or any store that wants to sell your products online, we are here to provide you with a custom mobile ordering app to serve your business needs.</p>
                                    </div>
                                    <span (click)="getStarted()">LEARN MORE</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div #apps class="apps-market" id="apps">
                        <div class="product-wrapper">
                            <div class="head-search">
                                <h4 id="apps-heading">App Marketplace</h4>
                                <div class="search">
                                    <input type="text" class="form-control" [(ngModel)]="searchedProduct" placeholder="Search Marketplace">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.875 13.5C10.9816 13.5 13.5 10.9816 13.5 7.875C13.5 4.7684 10.9816 2.25 7.875 2.25C4.7684 2.25 2.25 4.7684 2.25 7.875C2.25 10.9816 4.7684 13.5 7.875 13.5Z" stroke="#0073DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.7501 15.7501L11.8501 11.8501" stroke="#0073DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>  
                                </div>
                            </div>
                            <div class="top-panel">
                                <div class="border">
                                    <div (click)="changeMarket('featured')" [ngClass]="{'active' : activeMarket == 'featured'}" class="text">FEATURED</div>
                                    <div (click)="changeMarket('popular')" [ngClass]="{'active' : activeMarket == 'popular'}" class="text">POPULAR</div>
                                    <div (click)="changeMarket('newest')" [ngClass]="{'active' : activeMarket == 'newest'}" class="text">NEWEST APPS</div>
                                </div>
                            </div>
                            <div *ngIf="isProductsLoading" class="col-12 font-weight-bold ml-2 mt-5">
                                <div class="circle-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <p class="loading-text">Loading</p>
                            </div>
                            <div class="market-boxes">
                                <div *ngFor="let box of filteredmarketBoxes | filter:searchedProduct" class="market-box">
                                    <div (click)="goTodetails(box.code)" class="boxes"><img src="{{box.urlImage}}" alt="Orderax Product">
                                        <span *ngIf="!box.isAvailable" class="available-check">COMING SOON</span>
                                        <div class="box-text">
                                            <p class="white-text-name">{{box.name}}</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div (click)="goTodetails(box.code)" class="logo-price"><p>{{box.name}}</p>
                                        <span>{{box.price == 0 ? 'Free' : '$'+box.price}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="div-view">
                                <button *ngIf="!isProductsLoading" class="btn btn-primary" (click)="goToProductList()" class="view-more">View more</button>
                            </div>
                        </div>
                    </div>
    
                    <div #customers id="customers" class="customers-app">
                        <div class="product-wrapper">
                            <h4 id="customer-heading">Customer Apps</h4>
                        <div class="buttons-section">
                            <button (click)="appsType('all')" [ngClass]="{'customer-active' : appsTypeValue == 'all'}" class="customers-button all-apps">All Apps</button>
                            <button (click)="appsType('iOS')" [ngClass]="{'customer-active' : appsTypeValue == 'iOS'}" class="customers-button ios-apps">iOS Apps</button>
                            <button (click)="appsType('android')" [ngClass]="{'customer-active' : appsTypeValue == 'android'}" class="customers-button android-apps">Android Apps</button>
                        </div>

                        <div *ngIf="isCustomerAppsLoading" class="col-12 font-weight-bold ml-2 mt-5">
                            <div class="circle-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <p class="loading-text">Loading</p>
                        </div>
                        <div class="logo-section">
                            <div *ngFor="let app of customerApps">
                                <div class="single-logo-back" *ngIf="appsTypeValue == app.type || appsTypeValue == 'all'">
                                    <div (click)="goToSingleApp(app.link)" class="single-logo">
                                        <div class="circle-black">
                                            <svg *ngIf="app.type == 'iOS'" class="ios" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.81768 1.20889C8.40435 0.533333 9.36435 0.0355555 10.1643 0C10.271 0.942222 9.89768 1.88444 9.32879 2.56C8.77768 3.23555 7.85324 3.76889 6.96435 3.69778C6.85768 2.77333 7.30213 1.81333 7.81768 1.20889ZM12.3866 14.0089C11.7288 14.9867 11.0355 15.9467 9.95102 15.9822C8.88435 16 8.54657 15.3422 7.3199 15.3422C6.09324 15.3422 5.7199 15.9644 4.70657 16C3.65768 16.0355 2.85768 14.9511 2.18213 13.9733C0.813239 11.9822 -0.23565 8.35555 1.18657 5.92C1.87991 4.71111 3.12435 3.92889 4.47546 3.91111C5.50657 3.89333 6.48435 4.60444 7.10657 4.60444C7.72879 4.60444 8.9199 3.75111 10.1643 3.87555C10.6799 3.89333 12.1377 4.08889 13.0799 5.45778C13.0088 5.51111 11.3377 6.47111 11.3555 8.49777C11.3732 10.9156 13.471 11.7333 13.4888 11.7333C13.471 11.7867 13.151 12.8889 12.3866 14.0089Z" fill="white"/>
                                            </svg>
                                            <svg *ngIf="app.type == 'android'" class="android" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.30762 6.13257V13.1477H3.31162C3.36081 13.7403 3.90587 14.2111 4.56582 14.2111H11.6199C12.2798 14.2111 12.8248 13.7403 12.8741 13.1477H12.8781V6.13257H3.30762Z" fill="white"/>
                                                <path d="M2.9424 11.2706C2.9424 11.9626 2.46034 12.5287 1.87119 12.5287C1.28194 12.5287 0.799805 11.9626 0.799805 11.2706V7.15277C0.799805 6.46069 1.2819 5.89453 1.87119 5.89453C2.46034 5.89453 2.9424 6.46069 2.9424 7.15277V11.2706Z" fill="white"/>
                                                <path d="M7.4077 16.3812C7.4077 17.0732 6.92557 17.6394 6.33635 17.6394C5.7472 17.6394 5.26514 17.0732 5.26514 16.3812V12.2633C5.26514 11.5713 5.7472 11.0051 6.33635 11.0051C6.92557 11.0051 7.4077 11.5713 7.4077 12.2633V16.3812Z" fill="white"/>
                                                <path d="M10.9786 16.3812C10.9786 17.0732 10.4965 17.6394 9.90729 17.6394C9.31807 17.6394 8.83594 17.0732 8.83594 16.3812V12.2633C8.83594 11.5713 9.31807 11.0051 9.90729 11.0051C10.4965 11.0051 10.9786 11.5713 10.9786 12.2633V16.3812Z" fill="white"/>
                                                <path d="M15.4332 11.2706C15.4332 11.9626 14.9511 12.5287 14.3619 12.5287C13.7728 12.5287 13.2905 11.9626 13.2905 11.2706V7.15277C13.2905 6.46069 13.7728 5.89453 14.3619 5.89453C14.9511 5.89453 15.4332 6.46069 15.4332 7.15277V11.2706Z" fill="white"/>
                                                <path d="M10.4589 1.91409L11.1464 0.6807C11.2024 0.579943 11.186 0.462696 11.1096 0.420122C11.0332 0.377583 10.9247 0.42522 10.8687 0.525943L10.1653 1.78791C9.53832 1.54118 8.83537 1.40288 8.09291 1.40288C7.34489 1.40288 6.63702 1.54329 6.00625 1.79361L5.29978 0.525943C5.24367 0.425185 5.13521 0.377583 5.05893 0.420122C4.98253 0.462661 4.96608 0.579943 5.02219 0.6807L5.71297 1.92031C4.27579 2.59527 3.30762 3.8628 3.30762 5.31524C3.30762 5.46283 3.31799 5.60838 3.33736 5.75167H12.8483C12.8678 5.60838 12.8782 5.46286 12.8782 5.31524C12.8782 3.85823 11.9038 2.5874 10.4589 1.91409ZM5.92124 4.06695C5.69009 4.06695 5.50274 3.8796 5.50274 3.64845C5.50274 3.41733 5.69009 3.22992 5.92124 3.22992C6.15236 3.22992 6.33977 3.41733 6.33977 3.64845C6.33977 3.87964 6.15236 4.06695 5.92124 4.06695ZM10.2882 4.06695C10.0571 4.06695 9.86967 3.8796 9.86967 3.64845C9.86967 3.41733 10.0571 3.22992 10.2882 3.22992C10.5194 3.22992 10.7067 3.41733 10.7067 3.64845C10.7067 3.87964 10.5194 4.06695 10.2882 4.06695Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <img  src="{{app.url}}" alt="Custom App Image">
                                    </div>
                                    <div class="logo-price"><p (click)="goToSingleApp()">{{app.name  | titlecase }}</p>
                                        <span>{{app.price == 0 ? 'Free' : '$'+(app.price | number:'1.2') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="empty-state" *ngIf="!iOSAppExists && appsTypeValue == 'android'"><p>No apps available</p></div>
                            <div class="empty-state" *ngIf="!androidAppExists && appsTypeValue == 'iOS'"><p>No apps available</p></div>
                        </div>
                        <div class="div-view">
                            <button *ngIf="!isCustomerAppsLoading" class="btn btn-primary" (click)="goToCustomers()" class="view-more">View More</button>
                        </div>
                        </div>
                    </div>

                    <div class="blog-section" id="blog">
                        <div class="product-wrapper">
                            <div class="blogs-heading">
                                <h4 id="blog-heading">Latest Posts</h4>

                            <div *ngIf="isBlogsLoading" class="col-12 font-weight-bold ml-2 mt-5">
                                <div class="circle-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <p class="loading-text">Loading</p>
                            </div>
                                <div class="blog-posts"> 
                                    <div *ngFor="let post of blogPosts" class="single-post">
                                        <div class="logo">
                                            <img src="{{post.imageURL}}" alt="Blog Post Image">
                                        </div>
                                        <div class="text">
                                            <h5 (click)="openSingleBlog(post.link)">{{post.name}}</h5>
                                            <p>{{post.description}}.</p>
                                            <button class="read-more" (click)="openSingleBlog(post.link)" *ngIf="post.blogTextMore">Read More...</button>
                                        </div>
                                    </div>
                                    <div #blog class="div-view">
                                        <button *ngIf="!isBlogsLoading" (click)="viewBlog()" class="btn btn-primary" class="view-more">View Blog</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div id="social" #social class="product-wrapper">
                        <div class="instagram-section">
                            <h4 id="social-heading">Instagram Feed</h4>

                            <div *ngIf="isInstagramLoading" class="col-12 font-weight-bold ml-2 mt-5">
                                <div class="circle-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <p class="loading-text">Loading</p>
                            </div>
                            
                            <div class="box-section">
                                <div (click)="goToInstagram(box.permalink)" *ngFor="let box of instagramBoxes?.data" class="single-box">
                                    <img *ngIf="box.media_type == 'IMAGE'" src="{{box.media_url}}" alt="Instagram Post Image">
                                    <img *ngIf="box.media_type == 'CAROUSEL_ALBUM'" src="{{box.media_url}}" alt="Instagram Post Carousel Image">
                                    <img *ngIf="box.media_type == 'VIDEO'" src="{{box.thumbnail_url}}" alt="Instagram Post Video">
                                </div>
                            </div>
                            <div class="icons-section">
                                <h5>Follow us on</h5>
                                <div class="social-links">
                                    <a href="https://www.instagram.com/orderaxapp/?hl=en" target="_blank">
                                        <img src="assets/svg/instagram.svg" type="image/svg+xml" alt="Instagram Icon">
                                    </a>
                                    <a href="https://web.facebook.com/orderax" target="_blank">
                                        <img src="assets/svg/facebook.svg" type="image/svg+xml" alt="Facebook Icon">
                                    </a>
                                    <a href="https://www.twitter.com/orderax1" target="_blank">
                                        <img src="assets/svg/twitter.svg" type="image/svg+xml" alt="Twitter Icon">
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCnAEXlmsER86iLQwnysyHUQ" target="_blank">
                                        <img src="assets/svg/youtube.svg" type="image/svg+xml" alt="Youtube Icon">
                                    </a>
                                    <a href="https://g.page/r/CdxuoeNDxtG_EAE" target="_blank">
                                        <img src="assets/svg/google.svg" type="image/svg+xml" alt="Google Icon">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div #tools id="tools" class="tools-section">
                        <div class="product-wrapper-full py-2">
                        <!-- <div class="product-wrapper-full pt-5 pb-5"> -->
                            <div class="tools-resources">
                                    <div class="tools py-30">
                                        <div class="wrapper">
                                            <div class="header">
                                                <h5 id="tools-heading">Tools</h5>
                                                <button (click)="viewTools()">View Tools</button>
                                            </div>
                                            <div class="tool-boxes tool-boxes-grid">                                     
                                                <div (click)="openTool('theme')" class="box">
                                                    <img src="../../assets/images/home-tool/tool-theme.png" alt="Free Restaurant App theme" width="60" height="60" class="tool-icon">
                                                    <p>Free Restaurant App Theme</p>
                                                    <!-- <div class="img-filter background-green"></div> -->
                                                </div>
                                                <div (click)="openTool('screenshot')" class="box">
                                                    <img src="../../assets/images/home-tool/tool-web.png" alt="Free App Screenshot Generator" width="60" height="60" class="tool-icon">
                                                    <p>Free App Screenshot Generator</p>
                                                    <!-- <div class="img-filter background-yellow"></div> -->
                                                </div>
                                                <div (click)="openTool('calculator')" class="box">
                                                    <img src="../../assets/images/home-tool/tool-calculator.png" alt="Calculator" width="60" height="60" class="tool-icon">
                                                    <p>Calculator</p>
                                                    <!-- <div class="img-filter background-green"></div> -->
                                                </div>
                                                <div (click)="openTool('restaurant-startup-calculator')" class="box">
                                                    <img src="../../assets/images/home-tool/tool-store.png" alt="Restaurant Startup Calculator" width="60" height="60" class="tool-icon">
                                                    <p>Restaurant Startup Calculator</p>
                                                    <!-- <div class="img-filter background-yellow"></div> -->
                                                </div>
                                                <div (click)="openTool('sales-tax-calculator')" class="box salesCalculator">
                                                        <span class="comingSoon">Coming Soon</span>
                                                    <img src="../../assets/images/home-tool/tool-accounting.png" alt="Sales Tax Calculator" width="60" height="60" class="tool-icon">
                                                    <p class="w50">Sales Tax Calculator</p>
                                                    <!-- <div class="img-filter background-green"></div> -->
                                                </div>
                                                <div (click)="openTool('food-cost-calculator')" class="box salesCalculator">
                                                        <span class="comingSoon">Coming Soon</span>
                                                    <img src="../../assets/images/home-tool/tool-cost.png" alt="Food Cost Calculator" width="60" height="60" class="tool-icon">
                                                    <p class="w50">Food Cost Calculator</p>
                                                    <!-- <div class="img-filter background-yellow"></div> -->
                                                </div>
                                                <div (click)="openTool('equipment-cost-calculator')" class="box salesCalculator">
                                                        <span class="comingSoon">Coming Soon</span>
                                                    <img src="../../assets/images/home-tool/tool-budget.png" alt="Equipment Cost Calculator" width="60" height="60" class="tool-icon">
                                                    <p>Equipment Cost Calculator</p>
                                                    <!-- <div class="img-filter background-blue"></div> -->
                                                </div>
                                                <div (click)="openTool('online-menu-qr-code')" class="box salesCalculator">
                                                        <span class="comingSoon">Coming Soon</span>
                                                    <img src="../../assets/images/home-tool/tool-qr-code.png" alt="Online Menu QR code" width="60" height="60" class="tool-icon">
                                                    <p>Online Menu QR code</p>
                                                    <!-- <div class="img-filter background-blue"></div> -->
                                                </div>
                                                <div (click)="openTool('digital-menu-generator')" class="box salesCalculator">
                                                        <span class="comingSoon">Coming Soon</span>
                                                    <img src="../../assets/images/home-tool/tool-menu-equipment.png" alt="Free Digital Menu Generator" width="60" height="60" class="tool-icon">
                                                    <p>Free Digital Menu Generator</p>
                                                    <!-- <div class="img-filter background-blue"></div> -->
                                                </div>
                                                <div (click)="openTool('menu-template-generator')" class="box salesCalculator">
                                                        <span class="comingSoon">Coming Soon</span>
                                                    <img src="../../assets/images/home-tool/tool-menu-template.png" alt="Free Menu Template Generator" width="60" height="60" class="tool-icon">
                                                    <p>Free Menu Template Generator</p>
                                                     <!-- <div class="img-filter background-blue"></div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <div class="resources">
                                    <div class="wrapper  p-30">
                                        <div class="header">
                                            <h5 class="mr-2">Resources</h5>
                                            <button (click)="viewResource()">View Resources</button>
                                        </div>
                                        <div class="post-section">
                                            <div *ngFor="let box of resourcesPost | slice:0:[maxNum]" class="posts">
                                                <h6 (click)="openResource(box.id)">{{box.name}}</h6>
                                                <p>{{box.description}}.</p>
                                                <button (click)="openResource(box.id)">Read More...</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div #contact id="contact-heading" class="bottom-divisions">
    
                        <app-footerpublic></app-footerpublic>
                        <div #logos></div>
                    </div>
            </div>
        </div>
    </div>
</div>