import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CountryDetails, AppData, ContactUs, PhoneNumberDetails } from '../auth/userData.model';
@Injectable({
  providedIn: 'root',
})

export class AppService {
  public apiURL = ''
  
  constructor(
    private http: HttpClient) {
    this.apiURL = environment.backendBaseURL;
  }
  
  private getStore(key) {
    return localStorage.getItem(key);
  }

  private saveStore(key, value) {
    if (value != null) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  private saveAppData(data: AppData) {
    this.saveStore('appData', data)
  }

  private getStoredAppData(): AppData {
    return JSON.parse(this.getStore('appData'));
  }

  getCountriesData(): Promise<CountryDetails[]> {
    let appData = this.getStoredAppData()
  
    if (appData == null) {
      return this.http.get<AppData>(this.apiURL + '/appApi/appData')
      .pipe(map(response =>  {
        this.saveAppData(response)
        return response;
      }))
      .pipe(take(1)).toPromise()
      .then((appData) => {
        return this.getCountriesDetails(appData)
      })
    } else {
      let countriesDetails = this.getCountriesDetails(appData)
      return Promise.resolve(countriesDetails)
    }
  }

  private getCountriesDetails(appData: AppData): CountryDetails[] {
    return appData.countries.map( country => { 
      let countriesWithPostalCode = appData.addressValidation.requiresPostalCode
      let countryData = countriesWithPostalCode.find(countryWithPostalCode => countryWithPostalCode.countryCode == country.code);
      let requiresPostalCode = countryData == null ? false : true
      return { country: country, requiresPostalCode: requiresPostalCode }
    });
  }

  getLastSaveCountriesDetails(): CountryDetails[] {
    let appData = this.getStoredAppData()
    if (appData) {
      return this.getCountriesDetails(appData)
    } else {
      return []
    }
  }

  getAllProducts() {
    let products = this.getSavedProducts();
    if(products.length == 0) {
      return this.http.get(this.apiURL + '/productApi/products', { headers: { skip: "true" } })
      .pipe(map(response =>  {
        localStorage.setItem('products', JSON.stringify(response));
        return response;
      }))
      .pipe(take(1)).toPromise()
      .then((products) => {
        return products;
      })   
    } else{
      return Promise.resolve(products)
    }
  }

  getSavedProducts() {
    return localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [];
  }

  getProductById(id) {
    return this.getSavedProducts().find(product => product.id == id);
  }

  getAppServices() {
    let services = this.getSavedService();
    if(services.length == 0) {
      return this.http.get(this.apiURL + '/serviceApi/services/app')
      .pipe(map(response =>  {
        localStorage.setItem('appServices', JSON.stringify(response));
        return response;
      }))
      .pipe(take(1)).toPromise()
      .then((services) => {
        return services;
      })   
    } else {
      return Promise.resolve(services)
    }
  }

  getSavedService() {
    return localStorage.getItem('appServices') ? JSON.parse(localStorage.getItem('appServices')) : [];
  }

  getProduct(id: string) {
    return this.http.get(this.apiURL + '/productApi/product/?id=' + id, { headers: { skip: "true" } })
      .pipe(map(response =>  {
        // localStorage.setItem('appServices', JSON.stringify(response));
        return response;
      }))
      .pipe(take(1)).toPromise()
      .then((product) => {
        return product;
      }) 
  }

  getCustomersApps(id : string) {
    let apps = this.getSavedCustomersApps();
    if(apps.length == 0) {
      return this.http.get(this.apiURL + '/productApi/product/apps/?id=' + id , { headers: { skip: "true" } })
      .pipe(map(response =>  {
        localStorage.setItem('getProductApps', JSON.stringify(response));
        return response;
      }))
      .pipe(take(1)).toPromise()
      .then((apps) => {
        return apps;
      })
    } else {
      return Promise.resolve(apps)
    }
  }

  getSavedCustomersApps() {
    return localStorage.getItem('getProductApps') ? JSON.parse(localStorage.getItem('getProductApps')) : [];
  }

  getProductTestimonies(id : string){
    let testimonies = this.getSavedProductTestimonies();
    if(testimonies.length == 0) {
      return this.http.get(this.apiURL + '/productApi/product/testimonies/?id=' + id , { headers: { skip: "true" } })
      .pipe(map(response =>  {
        localStorage.setItem('getProductTestimonies', JSON.stringify(response));
        return response;
      }))
      .pipe(take(1)).toPromise()
      .then((testimonies) => {
        return testimonies;
      })
    } else {
      return Promise.resolve(testimonies)
    }
  }

  getSavedProductTestimonies() {
    return localStorage.getItem('getProductTestimonies') ? JSON.parse(localStorage.getItem('getProductTestimonies')) : [];
  }

  getSavedBlogPosts() {
    return localStorage.getItem('getBlogPosts') ? JSON.parse(localStorage.getItem('getBlogPosts')) : [];
  }

  getBlogPosts(){
    let posts = this.getSavedBlogPosts();
    if(posts.length == 0) {
      return this.http.get(this.apiURL + '/appApi/blogPosts', { headers: { skip: "true" } })
      .pipe(map(response =>  {
        localStorage.setItem('getBlogPosts', JSON.stringify(response));
        return response;
      }))
      .pipe(take(1)).toPromise()
      .then((posts) => {
        return posts;
      }) 
    } else {
      return Promise.resolve(posts)
    }
  }

  addPublicContact(body){
    return this.http
    .post<ContactUs>(this.apiURL + '/appApi/contactUs',  body, { headers: { skip: "true" } })
    .pipe(map(response =>  {
      return response;
    }))
    .pipe(take(1)).toPromise()
    .then((response) => {
      return response;
    }) 
  }

  getSavedInstagramPosts() {
    return localStorage.getItem('getInsragramPosts') ? JSON.parse(localStorage.getItem('getInsragramPosts')) : [];
  }

  getInstagramPosts(){
    let posts = this.getSavedInstagramPosts();
    if(posts.length == 0) {
      return this.http
      .get(this.apiURL + '/appApi/insragramPosts',  { headers: { skip: "true" } })
    .pipe(map(response =>  {
      localStorage.setItem('getInsragramPosts', JSON.stringify(response));
      return response;
    }))
    .pipe(take(1)).toPromise()
    .then((posts) => {
      return posts;
    }) 
    } else {
      return Promise.resolve(posts)
    }
  }

  getFlagDetails(): PhoneNumberDetails[]  {
      const appData = this.getStoredAppData()
      let callingCode = appData.callingCode;
      let PhoneNumberDetails = [];
      callingCode.map(code => { 
      let flags = appData.flags;
      let flag = flags.find(flag => flag.name.toLowerCase() == code.name.toLowerCase());
      code.flag = flag ? flag.flag : '';
      let codes = {
        flag :  flag ? flag.flag : '',
        countryCode:code.code,
        callingCode:code.dial_code,
        name:code.name
      }
      PhoneNumberDetails.push(codes);
    });
    return PhoneNumberDetails;
  }
}
