import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AndroidIosTabComponent } from './android-ios-tab/android-ios-tab.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { InstructionComponent } from './instruction/instruction.component';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { CarouselModule } from 'ngx-owl-carousel-o';
import {ProgressBarModule} from "angular-progress-bar";
import {NgbModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { ToastrModule } from 'ngx-toastr';
import { ArchwizardModule } from 'angular-archwizard';
import { NgSearchPipe } from 'ng-search-pipe';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component'
import { CategoryComponent } from './popups/category/category.component';
import { ProductComponent } from './popups/product/product.component';
import { StoreComponent } from './popups/store/store.component';
import { SharedthemeComponent } from './sharedtheme/sharedtheme.component';
import { GooglePlacesDirective } from '../directives/google-places.directive';
import { AgmCoreModule } from '@agm/core';
import { DragScrollModule } from 'ngx-drag-scroll';
import { FooterpublicComponent } from './footerpublic/footerpublic.component';
import { SharedscreenshotComponent } from './sharedscreenshot/sharedscreenshot.component';
import { MainthemeComponent } from './maintheme/maintheme.component';
import { SharedplatformInfoComponent } from './sharedplatform-info/sharedplatform-info.component';
import { SharedservicesComponent } from './sharedservices/sharedservices.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OutsideclickDirective } from '../directives/outsideclick.directive';
import { EditCategoryComponent } from './edit-items/edit-category/edit-category.component';
import { EditProductComponent } from './edit-items/edit-product/edit-product.component';
import { EditStoreComponent } from './edit-items/edit-store/edit-store.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import {MatSelectModule} from '@angular/material/select';
import { DropdownModule } from 'primeng/dropdown';
import { GoogleLoginDirective } from '../directives/google-login.directive';
@NgModule({
  declarations: [
    AndroidIosTabComponent,
    ImageUploadComponent,
    InstructionComponent,
    CompleteProfileComponent,
    CategoryComponent,
    ProductComponent,
    StoreComponent,
    SharedthemeComponent,
    GooglePlacesDirective,
    GoogleLoginDirective,
    FooterpublicComponent,
    SharedscreenshotComponent,
    MainthemeComponent,
    SharedplatformInfoComponent,
    SharedservicesComponent,
    EditCategoryComponent,
    EditProductComponent,
    EditStoreComponent,
    EmailVerificationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule, 
    FontAwesomeModule,
    ReactiveFormsModule,
    ArchwizardModule,
    AlifeFileToBase64Module,
    ColorPickerModule,
    ToastrModule.forRoot(),
    NgSearchPipe,
    DragScrollModule,
    ProgressBarModule,
    CarouselModule,
    NgxUiLoaderModule.forRoot({
      delay: 0,
      minTime: 0,
      fgsColor: "#0073de",
    }),
    MatCardModule,
    MatChipsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDdfLeFldTWZmiX3Fulzb2P--m8xGWwxTk',
      libraries: ['places'],
    }),
    Ng2SearchPipeModule,
    NgbModule,
    NgbNavModule,
    MatSelectModule,
    DropdownModule
  ],
  exports: [
    AndroidIosTabComponent,
    ImageUploadComponent,
    InstructionComponent,
    CompleteProfileComponent,
    CommonModule,
    FormsModule,
    FlexLayoutModule, 
    FontAwesomeModule,
    ReactiveFormsModule,
    ArchwizardModule,
    AlifeFileToBase64Module,
    ColorPickerModule,
    ToastrModule,
    NgSearchPipe,
    ProgressBarModule,
    CarouselModule,
    NgxUiLoaderModule,
    MatCardModule,
    MatChipsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    CategoryComponent,
    ProductComponent,
    StoreComponent,
    SharedthemeComponent,
    AgmCoreModule,
    GooglePlacesDirective,
    GoogleLoginDirective,
    FooterpublicComponent,
    SharedscreenshotComponent,
    MainthemeComponent,
    SharedplatformInfoComponent,
    SharedservicesComponent,
    Ng2SearchPipeModule,
    CategoryComponent,
    ProductComponent,
    StoreComponent,
    EditCategoryComponent,
    EditProductComponent,
    EditStoreComponent,
    EmailVerificationComponent,
    NgbModule,
    NgbNavModule
  ]
})
export class SharedModule { }
