import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})

export class ImageUploadComponent implements OnInit {
  @Input() data;
  @Output() sendImage = new EventEmitter();
  @Input() imageUrl;
  @Output() deleteImage = new EventEmitter();
  @Input() error;
  rawFiles: any;
  frontUrl;
  constructor(private toastr: ToastrService, ) { }

  ngOnInit(): void {
    // console.log("ImageUploadComponent", this.data)
  }

  async onFileChanges($event, data) {
    if (this.checkImageType($event[0]['type'])) {
      let obj = {
        index: data,
        image: $event,
        rawFile: this.rawFiles[0],
      }
      this.checkImageWidthAndHeight(this.rawFiles, obj)
    }
  }

  delete($event, data) {
    let obj = {
      index: data,
      image: $event
    }
    this.deleteImage.emit(obj);
  }

  checkImageType(type) {
    let imageType = type.toLowerCase();
    if (imageType != 'image/png' && imageType != 'image/jpeg' && imageType != 'image/jpg') {
      this.toastr.error('Please select png, jpeg or jpg images');
      return false;
    } else {
      return true
    }
  }

  async checkImageWidthAndHeight(event, obj) {
    if (this.data.checkSize) {
      const URL = window.URL;
      const Img = new Image();

      const filesToUpload = (event);
      Img.src = URL.createObjectURL(filesToUpload[0]);

      Img.onload = (e: any) => {
        const height = e.path[0].height;
        const width = e.path[0].width;
        if(width == this.data.width && height == this.data.height) { 
          this.sendImage.emit(obj);
          return true;
        } else{
          this.toastr.error(`Please select ${this.data.width} x ${this.data.height} image`);
          return false;
        }
      }
    } else{
      this.sendImage.emit(obj);
      return true;
    }
  }
}
