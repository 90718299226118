<div class="container-fluid cf-xs">
  <div class="header-body" id="header-body">
    <div class="row align-items-center py-4">
      <div class="col-lg-12">
        <div class="header-title pb-sm-4 pb-0">
          <h6 class="h2 d-inline-block mb-0 c-tag-1 bold-26">Create Shops
          </h6>
        </div>
        <ng-container *ngIf="isLoading">
          <div class="col-12 font-weight-bold">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </div>
        </ng-container>
        <ng-container *ngIf="!isLoading">
          <div>
            <h2 class="bold-16 my-sm-4 mt-2 mb-4">Please select your services
            </h2>
            <div id="accordion" class="accordion pb-3">
              <div class="card-item mb-3" *ngFor='let service of services'>
                <div class="card-header border-0" id="headingOne">
                  <h5 class="mb-0">
                    <button (click)='changeIcon(service)' id="accordionTitle1" class="accordionTitle btn collapsed" data-toggle="collapse"
                      [attr.data-target]="'#collapse'+service" aria-expanded="false" [attr.id]="'heading'+service">
                      <div class="card-item-name">

                        <img class="servicesImages" src='{{ "../../assets/images/services/" + service + ".png" }}' alt={{service}}>
                        <span class="ml-2 c-tag-2 bold-14">
                          {{service | titlecase}}
                        </span>
                      </div>
                      <span class="fa-stack fa-sm text-white">
                        <img class="image-icon"
                          [src]="serviceName == service && toggle == true ? '../../assets/images/services/minus.png' : '../../assets/images/services/plus.png'"
                          aria-hidden="true">
                      </span>
                    </button>
                  </h5>
                </div>



                <div *ngIf="project" [id]="'collapse' +service" [formGroup]='serviceForm' class="collapse" aria-labelledby="headingFour"
                  data-parent="#accordion">
                  <div class="card-body card-item-body pt-0 pt-0">
                    <div class="form-group" *ngFor='let serviceField of getField(service); let i = index'>
                      <label class="semi-14 pl-1 m-0 c-tag-3 mb-3">{{serviceField?.name}}
                      </label>
                      <input class="p-4 form-control-input reg-14" type="text"
                        [formControlName]="service.toLowerCase() + i" placeholder="{{serviceField?.name}}">

                    </div>

                    <p class="reg-12 text-sm-right text-center mr-sm-5 mt-4 mb-2">Don’t have the above info?
                      <a (click)='changeSelected(service)' class="bold-12 c-tag-1 pointer" data-toggle="modal"
                        data-target="#serviceModal">GET IT HERE
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="next-btn my-sm-5 my-2">
              <button [disabled]="isSubmit" class="btn px-5 py-3 text-white bold-16" (click)='submitService()'>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                <span>NEXT</span>
                <span class="ml-4 c-tag-5"><i class="fa fa-arrow-right" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<ng-container *ngIf="selectedModalData && selectedModalData.steps.length > 0">
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="serviceModal" tabindex="-1"
      aria-labelledby="serviceModalLabel">
      <app-instruction [googleCloudId]="googleCloudId" [data]="selectedModalData" (closeModal)="closeModal($event)" (sendData)="dataEmit($event)"></app-instruction>
    </div>
  </ng-container>

<!-- Modal End -->