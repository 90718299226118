import { Component, OnInit } from '@angular/core';
import { PublicService } from 'src/app/public/public.service';
import { MetaService } from '../../meta-service/meta.service'
@Component({
  selector: 'app-changepwdsuccess',
  templateUrl: './changepwdsuccess.component.html',
  styleUrls: ['./changepwdsuccess.component.css']
})
export class ChangepwdsuccessComponent implements OnInit {

  constructor(public publicService:PublicService,
    private metaService: MetaService) { }

  ngOnInit(): void {
    this.setTitleAndDescription();
  }
  setTitleAndDescription(){
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, password';
    let description = 'Getting your email password reset link sent to your email for the Orderax Mobile Online Ordering App Marketplace';
    this.metaService.blockIndexing('Change Password Success Page | OrderAx', description, keywords, 'https://orderax.com/changepwdsuccess')
  }
}
