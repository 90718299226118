import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgetpwdComponent } from './auth/forgetpwd/forgetpwd.component';
import { ChangepasswordComponent } from './auth/changepassword/changepassword.component';
import { ChangepwdsuccessComponent } from './auth/changepwdsuccess/changepwdsuccess.component';
import { HomeComponent } from './home/home/home.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { PrivacyComponent } from './public/privacy/privacy.component';
import { TermsConditionsComponent } from './public/terms-conditions/terms-conditions.component';
import { AuthActionComponent } from './public/auth-action/auth-action.component';
import { ConfirmPageComponent } from './auth/confirm-page/confirm-page.component';
import {SharedModule} from './shared/shared.module';
import { HomepageComponent } from './homepage/homepage.component';
import { ProductListComponent } from './product-list/product-list.component';
import { CustomerComponent } from './customer/customer.component';
import { ScreenshotpublicComponent } from './screenshotpublic/screenshotpublic.component';
import { ThemepublicComponent } from './themepublic/themepublic.component';
import { ToolsComponent } from './tools/tools.component';
import { ResourcesComponent } from './resources/resources.component';
import { ResourcesingleComponent } from './resourcesingle/resourcesingle.component';
import { OutsideclickDirective } from './directives/outsideclick.directive';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { CalculatorComponent } from './calculator/calculator.component';


@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
    LoginComponent,
    ForgetpwdComponent,
    ChangepasswordComponent,
    ChangepwdsuccessComponent,
    HomeComponent,
    PrivacyComponent,
    TermsConditionsComponent,
    AuthActionComponent,
    ConfirmPageComponent,
    HomepageComponent,
    ProductListComponent,
    CustomerComponent,
    ScreenshotpublicComponent,
    ThemepublicComponent,
    ResourcesComponent,
    ResourcesingleComponent,
    OutsideclickDirective,
    PagenotfoundComponent,
    CalculatorComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [
    /* { provide: UrlSerializer, useClass: CustomUrlSerializer }, */
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
