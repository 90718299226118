
<div class="top-header">
    <button (click)="showNav()" type="button" id="sidebarCollapse" class="fade-btn">
        <img src="/assets/images/menuIcon.svg" alt="menuIcon" />
    </button>
    <div class="main-logo">
        <img (click)="goToHome()" src="/assets/images/OrderAx-1.png"alt="logo" />
    </div>
    <div appOutsideclick (outsideClick)="close()" #menu [ngClass]="{'show-nav' : showNavbar && !closeMenu}" class="top-navbar">
        <ul>
            <li (click)="goToSection('home')" [ngClass]="{'active' : activeLink == 'home'}">Home</li>
            <li (click)="goToSection('tools')" [ngClass]="{'active' : activeLink == 'tools'}">Resources</li>
            <li (click)="goToSection('contact')" [ngClass]="{'active' : activeLink == 'contact'}">Contact Us</li>
        </ul>
    </div>
    <div class="side-login">
        <button (click)="getStarted()">Get Started</button>
        <button (click)="goToLogin()">Login</button>
    </div>
</div>
<div class="container-fluid cf-xs">
    <div class="header-body" id="header-body">
        <div class="row align-items-center pb-no">
            <div class="col-lg-12">
                <div #home id="home" class="header">
                    <div class="img-fluid" class="home-section">
                        <div class="home-section-layer"> </div>
                        <img src="../../assets/images/headerImage.png" alt="Orderax Header Image">
                        <div class="text-button">
                            <h1>The marketplace for businesses that want their own custom mobile ordering apps to sell their products online.</h1>
                            <button (click)="getStarted()">Get Started
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20" stroke="#11A6E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14 6L20 12L14 18" stroke="#11A6E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>


                    <div #tools id="tools" class="tools-section">
                        <div class="product-wrapper pt-5 pb-5">
                            <div class="tools-resources">
                                <div class="resources">
                                    <div class="wrapper">
                                        <div class="header">
                                            <h5 class="mr-2">Resources</h5>
                                        </div>
                                        <div class="post-section">
                                            <div *ngFor="let box of resourcesPost" class="posts">
                                                <h6 (click)="openResource(box.id)">{{box.name}}</h6>
                                                <p>{{box.description}}.</p>
                                                <button (click)="openResource(box.id)">Read More...</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

    
                    <div #contact class="bottom-divisions">
                        <app-footerpublic></app-footerpublic>
                    </div>
            </div>
        </div>
    </div>
</div>