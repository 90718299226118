<div class="top-header">
	<button (click)="showNav()" type="button" id="sidebarCollapse" class="fade-btn">
	  <img src="/assets/images/menuIcon.svg" alt="menuIcon" />
	</button>
	<div class="main-logo">
	  <img (click)="goToHome()" src="/assets/images/OrderAx-1.png" alt="logo" />
	</div>
	<div [ngClass]="{'show-nav' : showNavbar}" class="top-navbar">
	  <ul>
		<li (click)="goToSection('home')" [ngClass]="{'active' : activeLink == 'home'}">Home</li>
		<li (click)="goToSection('apps')" [ngClass]="{'active' : activeLink == 'apps'}">Calculator</li>
		<li (click)="goToSection('contact')" [ngClass]="{'active' : activeLink == 'contact'}">Contact Us</li>
	  </ul>
	</div>
	<div class="side-login">
	  <button (click)="getStarted()">Get Started</button>
	  <button (click)="goToLogin()">Login</button>
	</div>
  </div>
  
  <div class="container-fluid cf-xs">
	<div class="header-body" id="header-body">
	  <div class="align-items-center">
		<div #home id="home" class="header">
		  <div class="img-fluid" class="home-section">
			<div class="home-section-layer"> </div>
			<img src="../../assets/images/headerImage.png" alt="Orderax Header Image">
			<div class="text-button">
			  <h1>Free Kitchen Calculator Tool</h1>
			</div>
		  </div>
		</div>
		<div id="apps" #calculator class="calculator-wrapper">
		  <h6 class="mt-3 bold" id="click-heading">Kitchen Calculator</h6>
		  <input type="text" [ngModel]="formattedTokens" class="form-control-sm form-control text-right" readonly>
		  <input type="text" [ngModel]="input" class="form-control form-control-lg text-right" readonly>
  
		  <div class="row mt-3">
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="reset()">AC</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-primary btn-block" (click)="execOperator('(')">(</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-primary btn-block" (click)="execOperator(')')">)</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-primary btn-block" (click)="execOperator('/')">/</button>
			</div>
		  </div>
		  <div class="row mt-3">
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('1')">1</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('2')">2</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('3')">3</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-primary btn-block" (click)="execOperator('*')">x</button>
			</div>
		  </div>
		  <div class="row mt-3">
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('4')">4</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('5')">5</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('6')">6</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-primary btn-block" (click)="execOperator('+')">+</button>
			</div>
		  </div>
		  <div class="row mt-3">
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('7')">7</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('8')">8</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('9')">9</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-primary btn-block" (click)="execOperator('-')">-</button>
			</div>
		  </div>
		  <div class="row mt-3">
			<div class="col-6">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('0')">0</button>
			</div>
			<div class="col-3">
			  <button class="btn btn-secondary btn-block" (click)="insertChar('.')">,</button>
			</div>
			<div class="col-3">
			  <button autofocus class="btn btn-primary btn-block" (click)="evaluate()">=</button>
			</div>
		  </div>
		</div>
  
		<div #contact class="bottom-divisions pt-2">
		  <app-footerpublic></app-footerpublic>
		</div>
	  </div>
	</div>
  </div>