<div class="container-fluid cf-xs app-category" #myNameElem1>
    <div class="header-body" id="header-body">
        <div class="row align-items-center py-4">
            <div class="col-lg-12">
                <div class="header-title pb-sm-4 pb-0">
                    <h6 #point_3 class="h2 d-inline-block mb-0 c-tag-1 bold-26">
                        <span *ngIf="!isEdit">
                            <svg *ngIf="!arrow" [ngClass]="{'displayNone' : fromLink}" (click)="goBack()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"/>
                            </svg>Add New Category
                        </span>
                        <span *ngIf="isEdit">
                            <svg *ngIf="!arrow" [ngClass]="{'displayNone' : fromLink}" (click)="goBack()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"/>
                            </svg>Edit Category
                        </span>
                    </h6>
                </div>
                <div *ngIf="isLoading" class="col-12 font-weight-bold ml-2 mt-5">
                    <div class="circle-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div *ngIf="!isLoading" class="row mt-2">
                    <div class="col-md-12">
                        <form>
                            <div class="form-group">
                                <app-image-upload (deleteImage)="deleteImage()"
                                    (sendImage)="onFileChangeslogoImage($event,'cover')" [data]="cover"
                                    [error]="coverlogoImageError" [imageUrl]="coverlogoImage">
                                </app-image-upload>
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlFile1">Color</label>
                                <ul class="color-picker">
                                    <li *ngFor="let color of colors;let i=index;"
                                        [ngStyle]="{'background-color': color.color}"
                                        (click)="onColorPicker( color.color,i)"
                                        [ngClass]="{'selected-color': selectedColor(i) }"></li>
                                </ul>
                            </div>
                            <div class="form-group category-name mt-5">
                                <label for="exampleFormControlFile1" class="">Category Name</label>
                                <input [ngClass]="[category.name == '' && submit ? 'error' : '']" required type="text"
                                    class="form-control" maxlength="20" placeholder="Enter Category Name" name="name"
                                    [(ngModel)]="category.name">
                            </div>
                            <div class="form-group">
                                <label for="exampleFormControlFile1">Select Items</label>
                                <ul class="item-picker">
                                       
                                    <div *ngIf="!isLoading">
                                        <div *ngIf="categories.length<1" class="empty-state">
                                            <h3>You don't have any products to add to this category</h3>
                                        </div>
                                        <li class="mb-2 selected-card" [ngClass]="{'selected-item' : item.selected == true}"
                                            *ngFor="let item of categories; let i=index;">
                                            <img (click)="callProductPopup(item)" src="{{item['imageUrls'][0]}}" class="img-shadow">
                                            <img (click)="callProductPopup(item)" *ngIf="item.selected == false"
                                                src="../../assets/images/category/item_select.png" alt="image" class="app-add-category">
    
                                            <svg (click)="callProductPopup(item)" *ngIf="item.selected == true" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="18.4998" cy="18.4998" r="17.8552" fill="#0073DE"/>
                                                <path d="M26.5 12.5L15.5 23.5L10.5 18.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span class="item-title">{{item['description']}}</span>
                                        </li>
                                    
                                        <li *ngIf="seeMoreProduct" class="mb-2 item-see-more">
                                            <span class="item-title left">
    
                                                <span class="plus-item">
    
                                                    <p (click)="seeMoreBtn()" class="see-more">
                                                        See More ...
    
                                                    </p>
                                                </span>                                              
    
                                            </span>
    
                                        </li>
                                    </div>
                                </ul>
                            </div>
                            <div class="form-check form-switch">
                                <label for="exampleFormControlFile1">Available</label>
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked
                                    name="isAvailable" [(ngModel)]="category.isAvailable">
                            </div>
                            <div class="buttons">
                                <button type="submit" (click)="cancel()" class="btn cancel">Cancel  <i
                                    class="fas fa-times mx-1"></i></button>
                                <button [disabled]="isSubmit" type="submit" class="btn save"
                                    (click)="addCategory()"><span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true" *ngIf="isSubmit">
                                    </span> <span *ngIf="!isEdit">
                                            Add Category
                                    </span>
                                    <span *ngIf="isEdit">
                                            Save Category
                                    </span> <i
                                    class="fas fa-check mx-1"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

