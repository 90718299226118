<div class="container-fluid cf-xs">
    <div class="header-body header-screens" id="header-body">
      <div class="row align-items-center py-2">
        <div class="col-lg-12 single">
          <div class="header-title pb-sm-3 pb-0">
            <h6 class="h5 d-inline-block mb-0 c-tag-1 bold-26">Configure App Screenshot
            </h6>
          </div>
          <h6 class="mt-3 bold" id="click-heading">Click on items to add/edit</h6>
          <div class="view-buttons">
              <button (click)="showTablet()" *ngIf="mobile" class="btn tablet">
                <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.3333 1H2.53333C1.6865 1 1 1.6865 1 2.53333V22.4667C1 23.3135 1.6865 24 2.53333 24H16.3333C17.1802 24 17.8667 23.3135 17.8667 22.4667V2.53333C17.8667 1.6865 17.1802 1 16.3333 1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg width="6" height="3" viewBox="0 0 6 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.90002 1.93335H4.96669" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> View in Tablet Mode
            </button>
              <button (click)="showMobile()" *ngIf="tablet" class="btn tablet">
                <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.3333 1H2.53333C1.6865 1 1 1.6865 1 2.53333V22.4667C1 23.3135 1.6865 24 2.53333 24H16.3333C17.1802 24 17.8667 23.3135 17.8667 22.4667V2.53333C17.8667 1.6865 17.1802 1 16.3333 1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="6" height="3" viewBox="0 0 6 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.90002 1.93335H4.96669" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> View in Mobile Mode</button>
              <button data-toggle="modal" (click)="preview()" data-target="#preview" class="btn preview">Preview 
                 <div class="eye-icon">
                    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.25537 7.49999C1.25537 7.49999 4.76648 1.42592 10.9109 1.42592C17.0554 1.42592 20.5665 7.49999 20.5665 7.49999C20.5665 7.49999 17.0554 13.5741 10.9109 13.5741C4.76648 13.5741 1.25537 7.49999 1.25537 7.49999Z" stroke="#62D0FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.91092 5.77777C5.36527 5.77777 6.54425 4.75797 6.54425 3.49999C6.54425 2.24201 5.36527 1.22221 3.91092 1.22221C2.45657 1.22221 1.27759 2.24201 1.27759 3.49999C1.27759 4.75797 2.45657 5.77777 3.91092 5.77777Z" stroke="#62D0FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                 </div>                   
            </button>
            </div>
          <div class="main-body">
              <div class="screens mt-4">
                <div class="row mx-0">
                    <div class="col-md-12 col-lg-4 col-sm-12 p-0">
                        <div class="mobile-view" [ngClass]="{'mobile-view-no-border' : removeBorder}">
                            <div class="box">
                                <div data-toggle="modal" data-target="#slogan" (click)="slogan('1')" class="box-slogan box-background"><p>{{textSlogan1}}</p><p>{{slogan1lw}}</p></div>
                                <div data-toggle="modal" data-target="#logo" class="box-logo box-background"><p>Add Logo</p></div>
                                <div data-toggle="modal" data-target="#slogan" (click)="welcomeText('1')" class="box-welcome box-background"><p>{{textWelcome}}</p></div>
                                <div class="box-category d-flex">
                                    <div (click)="callCate()" *ngFor="let category of confirmCategories" [style.background-image]="'url('+category?.imageUrls+')'" data-toggle="modal" data-target="#updateCategory" [ngClass]="{'box-background2' : categoryAdded.status && category?.status}" class="category show box-background"><p>{{ category?.status ? "" : categoryAdded.value }}</p>
                                        <div *ngIf="categoryAdded?.status" [style.background]="category?.color" class="img-filter"></div>
                                <p class="text-light after-text">{{ category?.status ?  category?.name : ""}}</p></div>
                                </div>
                                <div class="box-product d-flex">
                                    <div data-toggle="modal" (click)="callProd()" *ngFor="let product of confirmProducts" [style.background-image]="'url('+product?.imageUrls+')'" [ngClass]="{'box-background2' : productAdded.status && product?.status}" data-target="#updateProduct" class="product box-background"><p>{{product?.status ? "" : productAdded.value}}</p>
                                        <span class="hide1" [ngClass]="{'detailText' : product?.status}"><p class="mt-1">{{product?.name}}</p>
                                        <div class="bottom-details-cards">
                                            <p>{{product?.price?.value}} {{product?.price?.currencyCode}}</p>
                                            <p><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                              </svg></p>
                                        </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showRightView" class="view-more-right">
                            <span (click)="scrollRight('main-body')"><i class="fas fa-arrow-left"></i> View More</span>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4 col-sm-12 p-0">
                        <h6 id="headerBox1" class="header-box">{{color2Added ? "Edit Background" : "Add Background"}}</h6>
                        <div [(colorPicker)]="color1" [cpPosition]="'left'" [(cpToggle)]="toggle1"
                        [cpPositionOffset]="'5%'"
                        [cpPositionRelativeToArrow]="true" (colorPickerChange)="onChangeColor($event, '1')" [style.background]="color1" class="mobile-view-background" id="mobile-view-background1">
                            <div class="box">
                                <div id="patch1"></div>
                                <div (click)="toggle1=!toggle1;" (click)="callProd()" data-toggle="modal" data-target="#updateProduct" *ngFor="let product of confirmProducts" [style.background-image]="'url('+product?.imageUrls+')'" [ngClass]="{'box-background3' : productAdded.status && product?.status}" class="product-add box-background"><p>{{product?.status ? "" : productAdded.value}}</p>
                                    <div [ngClass]="{'product-cards-text' : product?.status}" class="hide1">
                                        <div class="top-p-text mt-1">
                                            <span>{{product?.name}}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                              </svg>
                                        </div>
                                        <p class="proddescription">{{product?.description}}</p>
                                        <div class="text-buttons">
                                            <span class="text-price">{{product?.price?.value}} {{product?.price?.currencyCode}}</span>
                                            <div class="controls">
                                                <i class="fa fa-minus" aria-hidden="true"></i>
                                                <span>2</span>
                                                <i class="blue-circle"><svg class="blue-icon" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                                  </svg></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div (click)="toggle1=!toggle1;" (click)="welcomeText('2')" data-toggle="modal" data-target="#slogan" class="product-add-text box-background"><p>{{textBottom}}</p></div>
                                <div id="patch2"></div>
                                <div id="patch9"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4 col-sm-12 p-0 background-side">
                        <h6 id="headerBox2" class="header-box">{{color2Added ? "Edit Background" : "Add Background"}}</h6>
                         <div [(colorPicker)]="color2" [cpPosition]="'left'" [(cpToggle)]="toggle"
                         [cpPositionOffset]="'5%'"
                         [cpPositionRelativeToArrow]="true" (colorPickerChange)="onChangeColor($event, '2')" [style.background]="color2"  class="mobile-add-background" id="mobile-add-background1">
                            <div class="box">
                                <div id="patch3"></div>
                                <div (click)="toggle=!toggle;" data-toggle="modal" (click)="slogan('2')" data-target="#slogan" class="add-slogan box-background"><p>{{textSlogan2}}</p><p>{{slogan2lw}}</p></div>
                                <div (click)="toggle=!toggle;" (click)="calStore()" *ngFor="let store of confirmStores" [style.background-image]="'url('+store?.imageUrls+')'" data-toggle="modal" data-target="#updateStore" [ngClass]="{'box-background2' : storeAdded?.status && store?.status}" class="add-store box-background"><p>{{store?.status ? "" : storeAdded?.value}}</p>
                                    
                                    <div [ngClass]="{'store-text' : store?.status}" class="hide1">
                                        <span>{{store?.name}}</span>
                                        <span><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                          </svg>3.7km</span>
                                        <span>{{store?.address?.city}}, {{store?.address?.countryCode}}</span>
                                        
                                       <span>
                                            {{getStoreDeliveryNames(store?.deliveries)}}
                                        </span>
                                        <!-- <span>{{store?.deliveries[0]?.method}} & {{store?.deliveries[1]?.method}}</span> -->
                                    </div>
                                </div>
                                <div id="patch4"></div>
                            </div>
                        </div>
                        <div *ngIf="tablet" class="view-more-left">
                            <span (click)="scrollLeft('main-body')">View More <i class="fas fa-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
              </div>
              
            </div>
            <div class="confirm">
                <div class="text-danger">{{finalMessage}}</div>
                <button (click)="finalConfirm()" class="btn mt-1">Confirm <i class="fas fa-check"></i></button>
            </div>
        </div>
      </div>
    </div>
  </div>





  
  <!-- Modal for preview -->
  <div class="modal modal-backdrop1 fade" id="preview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-preview" id="modal" role="document">
        <div class="modal-content modal-content-preview">
            <button type="button" class="close modal-close-button" data-dismiss="modal" aria-label="Close">Close</button>
        <div class="modal-body pr-3">
            <div class="screens-modal">
                <div class="row">
                    <div class="col-md-12 col-lg-4 col-sm-12 p-0" id="screenshot1">
                        <svg (click)="showNext()" id="left-slide" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                        </svg>
                        <div class="mobile-view-tablet">
                            <div class="box">
                                <div id="slight-slogan" class="box-slogan box-background"><p>{{textSlogan1}}</p><p class="text-danger">{{slogan1lw}}</p></div>
                                <div id="patch11"></div>
                                <div id="patch12" *ngIf="mobile"></div>
                                <div id="patch13" *ngIf="tablet"></div>
                                <div class="slight-box">
                                    <div class="welcome-text">
                                        <p>{{textWelcome}}</p>
                                    </div>
                                    <div class="category-slight">
                                        <p class="category-s-h">Category</p>
                                        <div class="box-category slightCB1 d-flex">
                                            <div *ngFor="let category of confirmCategories" [style.background-image]="'url('+category?.imageUrls+')'" [ngClass]="{'box-background2' : categoryAdded.status && category?.status}" class="category1 slightC1 show box-background"><p>{{ category?.status ? "" : categoryAdded.value }}</p>
                                                <div *ngIf="category?.status" [style.background]="category?.color" class="img-filter"></div>
                                                <p class="text-light after-text1">{{category?.name}}</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-slight">
                                        <p class="product-s-h">Products</p>
                                        <div class="box-product slightB1">
                                            <div *ngFor="let product of confirmProducts" [style.background-image]="'url('+product?.imageUrls+')'" [ngClass]="{'box-background2' : productAdded.status && product?.status}" class="product slightP1 box-background"><p>{{product?.status ? "" : productAdded.value}}</p>
                                                <span class="hide1" [ngClass]="{'detailText' : product?.status}"><p class="mt-1">{{product?.name}}</p>
                                                    <div class="bottom-details-cards">
                                                        <p>{{product?.price?.value}} {{product?.price?.currencyCode}}</p>
                                                        <p>
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                                            </svg>
                                                        </p>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4 col-sm-12 p-0" id="screenshot2">
                        <div [style.background]="color1" class="mobile-view-background">
                            <div class="box">
                                <div id="patch5"></div>
                                        <div *ngFor="let product of confirmProducts" [style.background-image]="'url('+product?.imageUrls+')'" [ngClass]="{'box-background3' : productAdded.status && product?.status}" class="product-add product-add-preview box-background"><p>{{product?.status ? "" : productAdded.value}}</p>
                                            <div [ngClass]="{'product-cards-text' : product?.status}" class="hide1">
                                                <div class="top-p-text mt-1">
                                                    <span>{{product?.name}}</span>
                                                </div>
                                                <p class="proddescription">{{product?.description}}</p>
                                                <div class="text-buttons">
                                                    <span class="text1">{{product?.price?.value}} {{product?.price?.currencyCode}}</span>
                                                    <div class="controls">
                                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                                        <span>2</span>
                                                        <i class="blue-circle1"><svg class="blue-icon1" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                                        </svg></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                <div class="product-add-text product-add-text-preview box-background"><p class="text-light">{{textBottomfw}}</p><p>{{textBottom}}</p></div>
                                <div id="patch6"></div>
                                <div id="patch10"></div>

                                <div class="slight-box1 boxforss">
                                    <div class="welcome-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                          </svg>
                                    </div>
                                    <div class="product-slight">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4 col-sm-12 p-0" id="screenshot3">
                        <div [style.background]="color2" class="mobile-add-background">
                           <div class="box">
                            <div id="patch7"></div>
                            <img *ngIf="mobile" id="map" src="../../assets/img/map.jpg" alt="Phone Map Image">
                            <img *ngIf="tablet" id="map" src="../../assets/img/tableMap.PNG" alt="Tablet Map Image">
                            <p class="text-danger" id="location-text">Location</p>
                            <div class="add-slogan-preview box-background"><p>{{textSlogan2}}</p><p class="text-danger">{{slogan2lw}}</p></div>
                            <div *ngFor="let store of confirmStores" [style.background-image]="'url('+store?.imageUrls+')'" [ngClass]="{'box-background2' : storeAdded.status && store?.status}" class="add-store box-background"><p>{{store?.status ? "" : storeAdded.value}}</p>
                                <div [ngClass]="{'store-text' : store?.status}" class="hide1">
                                    <span>{{store?.name}}</span>
                                    <span><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                      </svg>3.7km</span>
                                      <span>{{store?.address?.city}}, {{store?.address?.countryCode}}</span>
                                      <!-- <span>{{store?.deliveries[0]?.method}} & {{store?.deliveries[1]?.method}}</span> -->
                                </div>
                            </div>
                            <div id="patch8"></div>
                            <div id="letsgo" class="lets-go">Let's Go</div>
                           </div>
                       </div>
                       <svg (click)="showNext()" id="right-slide" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </div>
                </div>
              </div>
              <div class="download-button">
                <span (click)="downloadPreview()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>Download
                    </span>
                  <!-- <button class="btn btn-primary">Download</button> -->
              </div>
        </div>
      </div>
    </div>
  </div>



  <app-store [limit]="1" [projectId]="projectId" (goToParent)="rec($event)" [obj]="goToChild"></app-store>
  <app-category [limit]="4" [projectId]="projectId" (goToParent2)="rec2($event)" [obj2]="goToChild2"></app-category>
  <app-product [limit]="6" [projectId]="projectId" (goToParent1)="rec1($event)" [obj1]="goToChild1"></app-product>



  <!-- Modal for logo -->
  <div class="modal fade" id="logo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-logo" id="modal" role="document">
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">Close</button> -->
      <div class="modal-content modal-content-logo">
        <div class="modal-body">
            <h6 class="d-inline-block mb-0 c-tag-1 bold uphead pl-2">Update Logo</h6>
            <div class="select-category-logo mt-4">
                    <app-image-upload (deleteImage)="deleteImage()" (sendImage)="onFileChangeScreenshot($event)" [data]="logoData"
                    [imageUrl]="logo"></app-image-upload>
                <p class="logo-note">Please note that if you add a new logo the previous logo would be removed.</p>
                <div class="final-buttons-logo">
                    <button data-dismiss="modal" aria-label="Close" class="btn cancel">Cancel <i class="fas fa-times mx-1"></i></button>
                    <button (click)="confirmLogo()" class="btn logoConfirm confirmbtn">Confirm <i class="fas fa-check mx-1"></i></button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>


  <!-- Modal for slogan -->
  <div class="modal fade" id="slogan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-logo" id="modal" role="document">
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">Close</button> -->
      <div class="modal-content modal-content-slogan">
        <div class="modal-body">
            <h6 class="d-inline-block mb-0 c-tag-1 bold uphead updateslogan pl-2"></h6>
            <div class="select-category-logo mt-4">
                <h6 class="bold selectcate sloganadd pl-2"></h6>
                <p class="countitem-logo pl-2"></p>
                <div class="selected-categories-slogan">
                    <input maxlength="30" placeholder="Enter your slogan" type="text" value="text" [(ngModel)]="text" class="form-control form-control-slogan input">
                </div>
                <div class="final-buttons-logo">
                    <button data-dismiss="modal" aria-label="Close" class="btn cancel">Cancel <i class="fas fa-times mx-1"></i></button>
                    <button (click)="addText('write')" id="modalClose" class="btn buttonConfirm confirmbtn">Confirm <i class="fas fa-check mx-1"></i></button>
                </div>
                <div class="text-danger text-center">{{textError}}</div>
            </div>
        </div>
      </div>
    </div>
  </div>