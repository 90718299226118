import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationComponent } from './auth/registration/registration.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgetpwdComponent } from './auth/forgetpwd/forgetpwd.component';
import { ChangepasswordComponent } from './auth/changepassword/changepassword.component';
import { ChangepwdsuccessComponent } from './auth/changepwdsuccess/changepwdsuccess.component';
import { HomeComponent } from './home/home/home.component';
import { PrivacyComponent } from './public/privacy/privacy.component';
import { TermsConditionsComponent } from './public/terms-conditions/terms-conditions.component';
import { AuthGuard } from './auth/auth.guard';
import { AdminGuard } from './auth/admin.guard';
import { AuthActionComponent } from './public/auth-action/auth-action.component';
import { ConfirmPageComponent } from './auth/confirm-page/confirm-page.component';
import { ShopComponent } from './shop/shop.component';
import { loginAndRegisterGuard } from './auth/loginAndRegisterGuard.guard';
import { HomepageComponent } from './homepage/homepage.component';
import { ProductListComponent } from './product-list/product-list.component';
import { CustomerComponent } from './customer/customer.component';
import { ScreenshotpublicComponent } from './screenshotpublic/screenshotpublic.component';
import { ThemepublicComponent } from './themepublic/themepublic.component';
import { ToolsComponent } from './tools/tools.component';
import { ResourcesComponent } from './resources/resources.component';
import { ResourcesingleComponent } from './resourcesingle/resourcesingle.component';
import { PagenotfoundComponent } from 
    './pagenotfound/pagenotfound.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { RestaurantStartupCalculatorComponent } from './tools/restaurant-startup-calculator/restaurant-startup-calculator.component';
 

const adminChildrenRoutes: Routes = [
  {
    path: 'user-listing',
    loadChildren: () => import('./admin/user-listing/user-listing.module').then(m => m.UserListingModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'product-listing',
    loadChildren: () => import('./admin/product-listing/product-listing.module').then(m => m.ProductListingModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'project-listing',
    loadChildren: () => import('./admin/project-listing/project-listing.module').then(m => m.ProjectListingModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'app-service-listing',
    loadChildren: () => import('./admin/app-service-listing/app-service-listing.module').then(m => m.AppServiceListingModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'user-details/:id',
    loadChildren: () => import('./admin/user-details/user-details.module').then(m => m.UserDetailsModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'product-details/:id',
    loadChildren: () => import('./admin/product-details/product-details.module').then(m => m.ProductDetailsModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'app-service-details/:id',
    loadChildren: () => import('./admin/app-service-details/app-service-details.module').then(m => m.AppServiceDetailsModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'project-details/:id',
    loadChildren: () => import('./admin/project-details/project-details.module').then(m => m.ProjectDetailsModule),
  },
  {
    path: 'product/:id',
    loadChildren: () => import('./admin/products/products.module').then(m => m.ProductsModule),
  },
  {
    path: 'project/:id',
    loadChildren: () => import('./admin/projects/projects.module').then(m => m.ProjectsModule),
  },
  {
    path: 'app-service/:id',
    loadChildren: () => import('./admin/app-service/app-service.module').then(m => m.AppServiceModule),
    canActivate: [AdminGuard],
  }, 
  { path: '', redirectTo: 'user-listing', pathMatch: 'full' },
]

const homeChildrenRoutes: Routes = [
  {
    path: 'myaccount',
    loadChildren: () => import('./user/myaccount/myaccount.module').then(m => m.MyaccountModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'shop',
    component: ShopComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'services/:id',
    loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'shops',
    loadChildren: () => import('./shop-listing/shop-listing.module').then(m => m.ShopListingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'shop-details/:id',
    loadChildren: () => import('./shop-detail/shop-detail.module').then(m => m.ShopDetailModule),
    
    canActivate: [AuthGuard],
  },
  {
    path: 'user-services',
    loadChildren: () => import('./user-services/user-services.module').then(m => m.UserServicesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'products',
    loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule),
    canActivate: [AuthGuard],
  },
];

  const homeRoutes: Routes = [
    {
      path: 'home',
      component: HomeComponent,
      canActivate: [AuthGuard],
      children: homeChildrenRoutes,
    }
  ];
  
  const adminRoutes: Routes = [
    {
      path: 'admin',
      component: HomeComponent,
      canActivate: [AdminGuard],
      children: adminChildrenRoutes,
    }
  ];
 
  var routes: Routes = 
  [
    { path: 'login', component: LoginComponent, canActivate: [loginAndRegisterGuard] },
    { path: 'registration', component: RegistrationComponent, canActivate: [loginAndRegisterGuard] },
    { path: 'forgetpwd', component: ForgetpwdComponent },
    { path: 'changepassword/:oobCode', component: ChangepasswordComponent },
    { path: 'changepwdsuccess', component: ChangepwdsuccessComponent },
    { path: 'confirmpage', component: ConfirmPageComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'authAction', component: AuthActionComponent },
    { path: 'termsAndConditions', component: TermsConditionsComponent },
    { path: 'products', component: ProductListComponent },
    { path: 'customers', component: CustomerComponent },
    { path: 'tools/free-restaurant-app-screenshot-generator', component: ScreenshotpublicComponent },
    { path: 'tools/free-restaurant-app-theme', component: ThemepublicComponent },
    { path: 'tools/free-kitchen-calculator', component: CalculatorComponent },
    { path: 'tools/restaurant-startup-calculator',
    loadChildren: () => import('./tools/restaurant-startup-calculator/restaurant-startup-calculator.module').then(m => m.RestaurantStartupCalculatorModule) },
    { path: 'tools',
    loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule) },
    { path: 'tools/:id',
    loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule)  },
    { path: 'resources', component: ResourcesComponent },
    { path: 'resources/:id', component: ResourcesingleComponent },
    {
      path: 'product/:name/:type',
      loadChildren: () => import('./product-detail/product-detail.module').then(m => m.ProductDetailModule)
    },
    {
      path: 'product/:name',
      loadChildren: () => import('./product-detail/product-detail.module').then(m => m.ProductDetailModule)
    },
    {
      path: 'businessinfo',
      loadChildren: () => import('./user/business-info/business-info.module').then(m => m.BusinessInfoModule),
      canActivate: [AuthGuard],
    },
    {
      path: 'businessinfo/:setup',
      loadChildren: () => import('./user/business-info/business-info.module').then(m => m.BusinessInfoModule),
      canActivate: [AuthGuard],
    },
    { path: '', component: HomepageComponent, pathMatch: 'full'},
  ];
  
  //Wild Card Route for 404 request
  const pageNotFoundRoute: Routes = [
    { path: '**', component: PagenotfoundComponent, pathMatch: 'full' }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes.concat(homeRoutes, adminRoutes, pageNotFoundRoute), { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
