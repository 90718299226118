import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';

import { rpn } from './rpn';
import { yard } from './yard';
import { format } from './format';
import { Operator, isOperator } from './model';
import { MetaService } from '../meta-service/meta.service'
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})

export class CalculatorComponent implements OnInit {
  @ViewChild('contact') contactElement: ElementRef;
  @ViewChild('calculator') toolsElement: ElementRef;
  @ViewChild('home') homeElement: ElementRef;

  tokens: string[] = [];
  showResult = false;
  showNavbar = false;
  closeMenu : boolean = true;
  activeLink = 'home';
  public homeOffset: any;
  public contactOffset: any;
  public toolsOffset: any;

  constructor(
    public metaService:MetaService,
    private route: Router) { }

  ngOnInit(): void {
    this.setTitleAndDescription();
  }

  setTitleAndDescription(){
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, screenshots, tools';
    let description = 'Use our Restaurant Calculator to calculate taxes, invoice, expenses. Get your Restaurant online Ordering app today.';
    this.metaService.setPageTitleAndMetaTags('Free Restaurant Kitchen Calculator', description, keywords, 'https://orderax.com/tools/free-kitchen-calculator')
  }

  ngAfterViewInit() {
    this.contactOffset = this.contactElement.nativeElement.offsetTop;
    this.toolsOffset = this.toolsElement.nativeElement.offsetTop;
    this.homeOffset = this.homeElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
    checkOffsetTop() {
      if (window.pageYOffset >= this.homeOffset && window.pageYOffset < this.toolsOffset-100) {
        this.activeLink = 'home';
      } else if (window.pageYOffset >= this.toolsOffset-100 && window.pageYOffset < this.contactOffset-100) {
        this.activeLink = 'apps';
      } else if (window.pageYOffset > this.contactOffset-100) {
        this.activeLink = 'contact';
      }
  }

  goToSection(type){
    if(type == "home"){
      this.activeLink = "home";
      var element = document.getElementById('home');
      var elementPosition = element.getBoundingClientRect().top;
      window.scrollTo({top :elementPosition, behavior : 'smooth'});
    }
    if(type == "apps"){
      this.activeLink = "apps";
      var element = document.getElementById('apps');
      var headerOffset = 82;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({top :offsetPosition, behavior : 'smooth'});
    }
    if(type == "contact"){
      this.activeLink = "contact";
      var element = document.getElementById('contact');
      var headerOffset = 82;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({top :offsetPosition, behavior : 'smooth'});
    }
  }

  goToHome(){
    this.route.navigate(['/']);
  }

  getStarted() {
    this.route.navigate(['/registration'])
  }

  goToLogin(){
    this.route.navigate(['/login'])
  }

  showNav(){
    this.showNavbar = !this.showNavbar;
  }
  
  close(){
    if(!this.showNavbar) {
      this.closeMenu = false;
    } else if(this.showNavbar) {
      this.closeMenu = true;
    } else if(this.showNavbar && this.closeMenu) {
      this.closeMenu = true;
      this.showNavbar = false;
    }
  }

  insertChar(character: string): void {
    const lastToken = this.lastToken;
    const doubleMin = lastToken === '-' && isOperator(this.beforeLastToken);

    if (lastToken === undefined || (isOperator(lastToken) && !doubleMin)) {
      if (character === '.') {
        character = '0' + character;
      }

      this.tokens.push(character);
    } else if (this.showResult) {
      this.tokens = [character];
    } else {
      this.tokens[this.tokens.length - 1] = lastToken + character;
    }

    this.showResult = false;
  }

  get lastToken(): string {
    return this.tokens[this.tokens.length - 1];
  }

  get beforeLastToken(): string {
    return this.tokens[this.tokens.length - 2];
  }

  get input(): string {
    if (this.showResult) {
      try {
        return format(rpn(yard(this.tokens)).toString());
      } catch (e) {
        return 'Invalid Input'
      }
    }

    return format(this.tokens
      .slice()
      .reverse()
      .find(t => !isOperator(t)) || '0');
  }

  get formattedTokens(): string {
    return this.tokens.map(format).join(' ').replace(/\*/g, 'x') || '0';
  }

  reset(): void {
    this.tokens = [];
    this.showResult = false;
  }

  evaluate(): void {
    // repeat last action
    if (this.showResult && this.tokens.length >= 2) {
      this.tokens = this.tokens.concat(this.tokens.slice(-2));
    }

    this.showResult = true;
  }

  execOperator(operator: Operator): void {
    // ANS support
    if (this.showResult) {
      this.tokens = [rpn(yard(this.tokens)).toString()];
    }

    if (!this.lastToken && operator !== '(') {
      this.tokens.push('0');
    }

    this.tokens.push(operator);
    this.showResult = false;
  }

  // KEYBOARD SUPPORT
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const key = event.key.toLowerCase();

    event.preventDefault();

    if (key === 'c' || key === 'backspace') {
      this.reset();
    } else if (key === ',' || key === '.') {
      this.insertChar('.');
    } else if (!isNaN(parseInt(key))) {
      this.insertChar(key);
    } else if (key === 'enter') {
      this.evaluate();
    } else if (isOperator(key)) {
      this.execOperator(key);
    }
  }
}
