import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { start } from 'repl';
import { AppService } from '../user/app.service';
import { MetaService } from '../meta-service/meta.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  @ViewChild('blog') blogElement: ElementRef;
  @ViewChild('contact') contactElement: ElementRef;
  @ViewChild('apps') appsElement: ElementRef;
  @ViewChild('customers') customersElement: ElementRef;
  @ViewChild('tools') toolsElement: ElementRef;
  @ViewChild('home') homeElement: ElementRef;
  @ViewChild('social') socialElement: ElementRef;
  @ViewChild('toolsSection') toolsSectionElement: ElementRef;
  @ViewChild('logos') logosElement: ElementRef;
  public homeOffset: any;
  public appsOffset: any;
  public customerOffset: any;
  public blogOffset: any;
  public toolsOffset: any;
  public contactOffset: any;
  public socialOffset: any;
  public toolsSectionOffset: any;
  public logosOffset: any;
  home = ' ';
  activeLink = 'home';
  activeMarket = 'featured';
  contactForm : FormGroup;
  productDetails;
  customerApps;
  testimonials;
  productPrice = 0;
  marketBoxes;
  filteredmarketBoxes;
  blogPosts;
  appsTypeValue = 'all';
  iOSAppExists = false;
  androidAppExists = false;
  maxNum
  showNavbar = false;
  closeMenu : boolean = true;
  screenshots = [];
  searchedProduct: [];
  isProductsLoading = false;
  isCustomerAppsLoading = false;
  isBlogsLoading = false;
  isInstagramLoading = false;
  toolBoxes = [1, 2];
  resourcesPost = [];
  products;
  instagramBoxes;
  browser;
  
  constructor(
    private appService:AppService,
    private fb: FormBuilder,
    private route: Router,
    private metaService: MetaService,) { }
    
    createContactForm() {
      this.contactForm = this.fb.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required]],
        mobile: ['', [Validators.required]],
        message: ['']
      })
    }
  
    sendContact() {
      this.contactForm.markAllAsTouched();
      if(this.contactForm.invalid){
        return; 
      }
    }
  
    contactvalidation(){
      return !this.contactForm.valid
    }

    ngOnInit(): void {
      this.setTitleAndDescription()
      this.createContactForm();
      this.getAllDetails();
      this.maxNum = 3;
      this.myBrowser();
    }

    setTitleAndDescription() {
      let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, web, listing, screenshot, theme, shopping, management, location, items, checkout, payment, shop, market, place, platform, business, delivery, custom, store';
      let description = 'The marketplace for businesses that want to create a custom mobile ordering app for their restaurant, cafe, coffee or online ordering store to sell online.';
      this.metaService.setPageTitleAndMetaTags('Create your Business Mobile Ordering App', description, keywords, 'https://orderax.com/')
    }

    myBrowser() {
      if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        this.browser = "Opera";
      } else if(navigator.userAgent.indexOf("Chrome") != -1 ){
        this.browser = "Chrome";
      } else if(navigator.userAgent.indexOf("Safari") != -1){
        this.browser = "Safari";
      } else if(navigator.userAgent.indexOf("Firefox") != -1 ) {
        this.browser = "Firefox";
      } else {
        this.browser = "unknown";
      }
    }

    getAllDetails() {
      this.isProductsLoading = true
      this.appService.getAllProducts().then( response => {
        if(response){
          this.marketBoxes = response;
          this.productResponeChanges();
          this.isProductsLoading = false;
        }
      },err => {
        console.log(err);
        this.isProductsLoading = false;
      })

      this.isCustomerAppsLoading = true
      this.appService.getCustomersApps("").then(result => {
        if(result){
          this.isCustomerAppsLoading = false;
          this.customerApps = result;
          for(var i=0; i<this.customerApps.length; i++){
            if(this.customerApps[i].type == "iOS"){
              this.iOSAppExists = true;
            }
            if(this.customerApps[i].type == "android"){
              this.androidAppExists = true;
            }
          }
        }
      },err => {
        console.log(err);
        this.isCustomerAppsLoading = false;
      })

      this.isBlogsLoading = true
      this.appService.getBlogPosts().then(result=> {
        if(result){
          this.isBlogsLoading = false;
          this.blogPosts = result.slice(0, 6);
          for(var i=0; i<this.blogPosts.length; i++){
            if(this.blogPosts[i]?.description.length > 148){
              this.blogPosts[i].blogTextMore = true;
              this.blogPosts[i].serial = 'id'+[i];
            }
          }
        }
      },err => {
        console.log(err);
        this.isBlogsLoading = false;
      });

      this.resourcesPost = [
        {id : 'custom-mobile-ordering-app-promo', name : "Custom Mobile Ordering App Promo", description : "Orderax Business Video Promo. If you are a Restaurant, Cafe, Coffee, Cannabis or store looking for a custom mobile ordering app for your business?", link : "https://www.youtube.com/watch?v=J_AWDX3WaUI"},
        {id : 'admin-app-for-businesses-looking-for-a-custom-mobile-ordering-app', name : "Admin App for Businesses looking for a Custom Mobile Ordering app", description : "A short demo of our Admin App. If you are a Restaurant, Cafe, Coffee, Cannabis or store looking for a custom mobile ordering app for your business?", link : "https://www.youtube.com/watch?v=iZjP9zSAAl0"},
        {id : 'customer-app-for-businesses-looking-for-a-custom-mobile-ordering-app', name : "Customer App for Businesses looking for a Custom Mobile Ordering app", description : "A short demo of our Customer App. If you are a Restaurant, Cafe, Coffee, Cannabis or store looking for a custom mobile ordering app for your business?", link : "https://www.youtube.com/watch?v=MOHomZc8BZ4"}
      ]

      this.isInstagramLoading = true
      this.appService.getInstagramPosts().then(result=>{
        this.isInstagramLoading = false
        if(result){
          this.instagramBoxes = result;
        }
      },err => {
        console.log(err);
        this.isInstagramLoading = false;
      });
    }

    productResponeChanges() {
      this.marketBoxes.map((d,i) =>{
        d['index'] = i
      })
      this.marketBoxes.sort(function(x, y) {
      return (x.isAvailable === y.isAvailable)? 0 : x? -1 : 1;
      });
      this.filteredmarketBoxes = this.marketBoxes
    }

    ngAfterViewInit() {
      this.socialOffset = this.socialElement.nativeElement.offsetTop;
      this.contactOffset = this.contactElement.nativeElement.offsetTop;
      this.toolsOffset = this.toolsElement.nativeElement.offsetTop;
      this.appsOffset = this.appsElement.nativeElement.offsetTop;
      this.blogOffset = this.blogElement.nativeElement.offsetTop;
      this.homeOffset = this.homeElement.nativeElement.offsetTop;
      this.customerOffset = this.customersElement.nativeElement.offsetTop;
      this.logosOffset = this.logosElement.nativeElement.offsetTop;
    }

    @HostListener('window:scroll', ['$event'])
    checkOffsetTop() {
        if (window.pageYOffset >= 0 && window.pageYOffset < 839) {
          this.activeLink = 'home';
        }
        else if (window.pageYOffset >= 839 && window.pageYOffset < 1355) {
          this.activeLink = 'apps';
        }
        else if (window.pageYOffset >= 1355 && window.pageYOffset < 2100) {
          this.activeLink = 'customers';
        }
        else if (window.pageYOffset >= 2100&& window.pageYOffset < 3100) {
          this.activeLink = 'blog';
        }
        else if (window.pageYOffset >=  3100 && window.pageYOffset < 3600) {
          this.activeLink = 'social';
        }
        else if (window.pageYOffset >= 3600  && window.pageYOffset < 4200) {
          this.activeLink = 'tools';
        }
        else if (window.pageYOffset >= 4200) {
          this.activeLink = 'contact';
        }
    }

    goToSection(type) {
      if(type == "home"){
        this.activeLink = "home";
        var element = document.getElementById('home');
        var elementPosition = element.getBoundingClientRect().top;;
        window.scrollTo({top :elementPosition, behavior : 'smooth'});
      }

      if(type == "apps"){
        this.activeLink = "apps";
        var element = document.getElementById('apps');
        var headerOffset = 82;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({top :offsetPosition, behavior : 'smooth'});
      }

      if(type == "customers"){
        this.activeLink = "customers";
        var element = document.getElementById('customers');
        var headerOffset = 82;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({top :offsetPosition, behavior : 'smooth'});
      }

      if(type == "blog"){
        this.activeLink = "blog";        
        var element = document.getElementById('blog');
        var headerOffset = 82;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({top : offsetPosition, behavior : 'smooth'});
      }

      if(type == "social"){
        this.activeLink = "social";
        var element = document.getElementById('social');
        var headerOffset = 82;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({top : offsetPosition, behavior : 'smooth'});
      }

      if(type == "tools"){
        this.activeLink = "tools";
        var element = document.getElementById('tools');
        var headerOffset = 82;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({top : offsetPosition, behavior : 'smooth'});
      }
      
      if(type == "contact"){
        this.activeLink = "contact";
        var element = document.getElementById('contact-heading');
        var headerOffset = 82;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({top : offsetPosition, behavior : 'smooth'});
      }
    }

    goTodetails(name){
      if(name == 'res'){
        this.route.navigate(['/product/restaurant']);
      }
      if(name == "blog"){
        this.route.navigate(['/product/blog']);
      }
      if(name == "cim"){
        this.route.navigate(['/product/chat&messenger']);
      }
      if(name == "pay"){
        this.route.navigate(['/product/payment']);
      }
      if(name == "vod"){
        this.route.navigate(['/product/videoOnDemand']);
      }
      if(name == "loy"){
        this.route.navigate(['/product/loyalty']);
      }
      if(name == "odd"){
        this.route.navigate(['/product/onDemandDelivery']);
      }
      if(name == "shp"){
        this.route.navigate(['/product/shopping']);
      }
    }

    appsType(type){
      if(type == "all"){
        this.appsTypeValue = "all";
      }
      else if(type == "iOS"){
        this.appsTypeValue = "iOS";
      }
      else if(type == "android"){
        this.appsTypeValue = "android";
      }
    }

    changeMax(){
      if(this.maxNum == this.testimonials.length){
        this.maxNum = 3;
      }
      else{
        this.maxNum = this.testimonials.length;
      }
    }

    showNav(){
      this.showNavbar = !this.showNavbar;
    }

    close(){
      if(!this.showNavbar){
        this.closeMenu = false;
      }else if(this.showNavbar){
        this.closeMenu = true;
      }
      else if(this.showNavbar && this.closeMenu){
        this.closeMenu = true;
        this.showNavbar = false;
      }
    }

    changeMarket(type){
      if(type == 'featured'){
        this.activeMarket = 'featured';
      }
      if(type == 'popular'){
        this.activeMarket = 'popular';
      }
      if(type == 'newest'){
        this.activeMarket = 'newest';
      }
    }

    goToSocial(type) {
      if(type == 'instagram') {
        window.open(`https://www.instagram.com/orderaxapp/?hl=en`, '_blank', 'noopener');
      }
      if(type == 'facebook') {
        window.open(`https://web.facebook.com/orderax`, '_blank', 'noopener');
      }
      if(type == 'twitter') {
        window.open(`https://www.twitter.com/orderax1`, '_blank', 'noopener');
      }
      if(type == 'youtube') {
        window.open(`https://www.youtube.com/channel/UCnAEXlmsER86iLQwnysyHUQ`, '_blank', 'noopener');
      }
      if(type == 'google') {
        window.open(`https://g.page/r/CdxuoeNDxtG_EAE`, '_blank', 'noopener');
      }
    }

    goToInstagram(path){
      window.open(`${path}`, '_blank');
    }

    goToLogin(){
      this.route.navigate(['/login'])
    }

    goToProductList(){
      this.route.navigate(['/products'])
    }

    goToSingleApp(path){
      this.route.navigate([]).then(result => {  window.open(`${path}`, '_blank'); });
    }

    openSingleBlog(link){
      this.route.navigate([]).then(result => {  window.open(`${link}`, '_blank'); });
    }

    getStarted(){
      this.route.navigate(['/registration'])
    }

    viewTools(){
      this.route.navigate(['/tools'])
    }

    openTool(type){
      if(type == 'theme'){
        this.route.navigate(['/tools/free-restaurant-app-theme'])
      } else if(type == 'screenshot'){
        this.route.navigate(['/tools/free-restaurant-app-screenshot-generator'])
      } else if(type == 'calculator'){
        this.route.navigate([`/tools/free-kitchen-calculator`]);
      } else{
        this.route.navigate([`/tools/${type}`])
      }
    }

    viewResource(){
      this.route.navigate(['/resources'])
    }

    openResource(id){
      this.route.navigate([`/resources/${id}`])
    }

    viewBlog(){
      this.route.navigate([]).then(result => {  window.open(`https://blog.orderax.com/`, '_blank'); });
    }

    goToHome(){
      window.location.reload();
    }

    goToCustomers(){
      this.route.navigate(['/customers'])
    }

}
function px(arg0: { behavior: "smooth"; inline: "nearest"; }, arg1: number, px: any) {
  throw new Error('Function not implemented.');
}

