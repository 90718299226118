import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-android-ios-tab',
  templateUrl: './android-ios-tab.component.html',
  styleUrls: ['./android-ios-tab.component.css']
})
export class AndroidIosTabComponent implements OnInit {
  @Output() selectedTabs = new EventEmitter();
  activeTab = 'android';
  constructor() { }

  ngOnInit(): void {
  }
  selectedTab(type){
    this.activeTab = type;
    this.selectedTabs.emit(type);
  }
}
