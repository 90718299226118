import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MetaService } from './meta-service/meta.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private metaService: MetaService
    ) {
    this.router.errorHandler = (error: any) => {
      let routerError = error.toString();
      if (routerError.indexOf('Cannot match any routes') >= 0) {
        this.router.navigate(['/']);
      } else {
        throw error;
      }
    };
  }

  ngOnInit(): void {}
}
