import { Component, Input, OnInit, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AccountManagerService } from 'src/app/managers/account-manager.service';
import { PublicService } from 'src/app/public/public.service';
import { AppService } from 'src/app/user/app.service';
import { StoreComponent } from '../../popups/store/store.component';
import { PopupService } from '../../service/popup.service';

@Component({
  selector: 'app-edit-store',
  templateUrl: './edit-store.component.html',
  styleUrls: ['./edit-store.component.css']
})
export class EditStoreComponent implements OnInit {
  @ViewChild(StoreComponent) callStore: StoreComponent;
  @Output() closeModal = new EventEmitter;
  @Output() addStoreIntoListItem = new EventEmitter;
  @ViewChild("myNameElem3") myNameElem: ElementRef;
  close = true;
  projectId;
  project;
  isSelectedItem = '';
  isSelectedCategoryItem = '';
  showPopup = '';
  items;
  categories = [];
  stores = [];
  countries = [];
  @Input() comingProjectId;
  @Input() comingStoreId;
  @Input() comingStoreName;
  @Input() parentProjectId;
  @Input() parentStoreId;
  isSubmit = false;
  storeForm: FormGroup;
  options: FormArray;
  addressObj;
  public coverlogoImage = '';
  public coverlogoImageError = false;
  shouldHidePostalCode = false;
  public images = {
    cover: {},
  };
  storeName;
  storeId;
  isEdit = false;
  storeObj;
  showDeliveryOptionError = false;
  checkValidationError = false;
  showPickupTimeError = false;
  showInstantDeliveryOrderError = false;
  showSameDayDeliveryOrderError = false;
  minTimeZeroErrorPickup = false;
  minTimeZeroErrorInstantDelivery = false;
  hours = [];
  mins = [];
  times = [
    {
      "availableTime": {
        "close": "22:00",
        "isAvailable": true,
        "mode": "time",
        "open": "08:00"
      },
      "day": "Sunday",
      "dayIndex": 1
    },
    {
      "availableTime": {
        "close": "22:00",
        "isAvailable": true,
        "mode": "time",
        "open": "08:00"
      },
      "day": "Monday",
      "dayIndex": 2
    },
    {
      "availableTime": {
        "close": "22:00",
        "isAvailable": true,
        "mode": "time",
        "open": "08:00"
      },
      "day": "Tuesday",
      "dayIndex": 3
    },
    {
      "availableTime": {
        "close": "22:00",
        "isAvailable": true,
        "mode": "time",
        "open": "08:00"
      },
      "day": "Wednesday",
      "dayIndex": 4
    },
    {
      "availableTime": {
        "close": "22:00",
        "isAvailable": true,
        "mode": "time",
        "open": "08:00"
      },
      "day": "Thursday",
      "dayIndex": 5
    },
    {
      "availableTime": {
        "close": "22:00",
        "isAvailable": true,
        "mode": "time",
        "open": "08:00"
      },
      "day": "Friday",
      "dayIndex": 6
    },
    {
      "availableTime": {
        "close": "22:00",
        "isAvailable": true,
        "mode": "time",
        "open": "08:00"
      },
      "day": "Saturday",
      "dayIndex": 7
    }
  ]
  shopEdit;
  shopDetails = false;

  // tslint:disable-next-line: max-line-length
  cover = { heading: 'Store Photo', description: '', index: 20, ControlName: 'Zero', show: 'cover', checkSize: false, width: 1024, height: 1024 };
  latitude: number;
  longitude: number;
  address: string;
  private geoCoder;
  arrow;
  isLoading = false;
  fromLink;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private toastr: ToastrService,
    private accountManager: AccountManagerService,
    private appService: AppService,
    private ngxLoader: NgxUiLoaderService,
    private publicService:PublicService,
    private popupService: PopupService) {
      
      if(this.comingStoreName){
        this.storeName = this.comingStoreName;
      }
      else{
        this.storeName = null;
      }
     }

  ngOnInit(): void {
    this.getStoreForm();

    if(this.parentProjectId != null){
      this.projectId = this.parentProjectId;
      this.comingProjectId = this.parentProjectId;
    }

    this.popupService.storeProjectId.subscribe(projectId => {
      this.projectId = projectId      
    })

    this.popupService.storeEditId.subscribe(storeId => {
      this.storeId = storeId
    })

    this.popupService.storeName.subscribe(result => {
      if(result != ''){
        this.storeName = result;
      } else {
        this.storeName = '';
      }
    })

    if(this.projectId != null && this.storeId != null){
      this.showEditItem(this.projectId, this.storeId);
    }
    
    this.hours = this.publicService.getHours();
    this.mins = this.publicService.getMintues();
    this.getAppData();
    this.valueChange();
    
    if(this.activatedRoute.parent.snapshot.routeConfig.path == "store/:id"){
      this.fromLink = true;
      this.activatedRoute.params.subscribe(params => {
        this.projectId = params['id'];
        if(this.projectId){
          this.showEditItem(this.projectId, this.storeId);
        }
      })
    }
    if(this.activatedRoute.parent.snapshot.routeConfig.path == 'store/:id/:storeId') {
      this.fromLink = true;
      this.activatedRoute.params.subscribe(params => {
      this.projectId = params['id'];
      this.parentStoreId = params['storeId'];
        if(this.projectId != null && this.parentStoreId != null){
          this.storeId = this.parentStoreId;
          this.showEditItem(this.projectId, this.storeId);
        }
      })
    }
  }

  ngAfterViewInit(): void{
    this.myNameElem.nativeElement.scrollIntoView({behavior : "auto"})
  }

  goBack(){
    this.popupService.storeEdit.next(false);
  }

  cancel(){
    this.popupService.shopDetailsStore.subscribe(result=>{
      this.shopDetails = result;
    })

    if(this.shopDetails){
      this.closeModal.emit(this.close);
    } else if(this.fromLink){
      this.router.navigate([`/home/shop-details/${this.projectId}`])
    } else{
      this.popupService.storeEdit.next(false);
    }
  }

  OnOpen(){
    this.popupService.shopDetailsStore.subscribe(result=>{
      this.shopDetails = result;
    })

    this.popupService.shopStoreEdit.subscribe(result=>{
      this.shopEdit = result;
    })

    this.popupService.storeProjectId.subscribe(projectId => {
      this.projectId = projectId      
    })

    this.popupService.storeEditId.subscribe(storeId => {
      this.storeId = storeId
    })

    this.popupService.storeName.subscribe(result => {
      if(result != ''){
        this.storeName = result;
      } else{
        this.storeName = '';
      }
    })

    if(this.shopDetails == true && this.shopEdit == false){
      this.coverlogoImage = '';
      this.getStoreForm()
      this.images.cover = {};
      this.hours = this.publicService.getHours();
      this.mins = this.publicService.getMintues();
      this.getAppData();
      this.valueChange();
  } else if(this.shopDetails == true && this.shopEdit == true) {
    if(this.projectId != null && this.storeId != null){
      this.showEditItem(this.projectId, this.storeId);
    }
  }
}

  ngOnChanges(){
    this.OnOpen();

    this.popupService.showStoreArrow.subscribe(result=>{
      this.arrow = result;
    })

    this.popupService.shopDetailsStore.subscribe(result=>{
      this.shopDetails = result;
    })

    if(this.parentProjectId != null){
      this.projectId = this.parentProjectId;
      this.comingProjectId = this.parentProjectId;
    }
  }

  get f() {
    return this.storeForm.controls;
  }

  valueChange() {
    this.storeForm.get("onDemandDeliveryTime").valueChanges.subscribe(x => {
      this.changeSameDeliveryTimeValidation(x); 
    })

    this.storeForm.get("sameDayDeliveryHours").valueChanges.subscribe(y => {
      this.changeSameDeliveryHoursValidation(y);
    })

    this.storeForm.get("isPickupHours").valueChanges.subscribe(z => {
      this.changePickupValidations(z);
    })

    this.storeForm.get("onDemandDeliveryTimeHours").valueChanges.subscribe(z => {
      this.showInstantDeliveryOrderError = false;
      this.minTimeZeroErrorInstantDelivery = false;
    })

    this.storeForm.get("onDemandDeliveryTimeMins").valueChanges.subscribe(z => {
      this.showInstantDeliveryOrderError = false;
      this.minTimeZeroErrorInstantDelivery = false;
    })

    this.storeForm.get("onDemandDeliveryTimeEndHours").valueChanges.subscribe(z => {
      this.showInstantDeliveryOrderError = false;
    })

    this.storeForm.get("onDemandDeliveryTimeEndMins").valueChanges.subscribe(z => {
      this.showInstantDeliveryOrderError = false;
    })

    this.storeForm.get("pickupStartHours").valueChanges.subscribe(z => {
      this.showPickupTimeError = false;
      this.minTimeZeroErrorPickup = false;
    })

    this.storeForm.get("pickupStartMins").valueChanges.subscribe(z => {
      this.showPickupTimeError = false;
      this.minTimeZeroErrorPickup = false;
    })

    this.storeForm.get("pickupEndHours").valueChanges.subscribe(z => {
      this.showPickupTimeError = false;
    })

    this.storeForm.get("pickupEndMins").valueChanges.subscribe(z => {
      this.showPickupTimeError = false;
    })

    this.storeForm.get("sameDayDeliveryHoursStart").valueChanges.subscribe(z => {
      this.showSameDayDeliveryOrderError = false;
    })

    this.storeForm.get("sameDayDeliveryHoursEnd").valueChanges.subscribe(z => {
      this.showSameDayDeliveryOrderError = false;
    })
  }

  changeSameDeliveryHoursValidation(value){
    if(value){
      this.showDeliveryOptionError = false
      this.storeForm.get('sameDayDeliveryHoursStart').setValidators([Validators.required])
      this.storeForm.get('sameDayDeliveryHoursStart').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursStart').enable();

      this.storeForm.get('sameDayDeliveryHoursEnd').setValidators([Validators.required])
      this.storeForm.get('sameDayDeliveryHoursEnd').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursEnd').enable();

      this.storeForm.get('sameDayDeliveryHoursDetails').setValidators([Validators.required])
      this.storeForm.get('sameDayDeliveryHoursDetails').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursDetails').enable();
  
      this.storeForm.get('sameDayDeliveryHoursRadius').setValidators([Validators.required])
      this.storeForm.get('sameDayDeliveryHoursRadius').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursRadius').enable();
  
      this.storeForm.get('sameDayDeliveryHoursFixedFee').setValidators([Validators.required])
      this.storeForm.get('sameDayDeliveryHoursFixedFee').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursFixedFee').enable();
  
      this.storeForm.get('sameDayDeliveryHoursRateFee').setValidators([Validators.required, Validators.max(100), Validators.min(0)])
      this.storeForm.get('sameDayDeliveryHoursRateFee').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursRateFee').enable();
    } else{
      this.storeForm.get('sameDayDeliveryHoursStart').clearValidators();
      this.storeForm.get('sameDayDeliveryHoursStart').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursStart').disable();

      this.storeForm.get('sameDayDeliveryHoursEnd').clearValidators();
      this.storeForm.get('sameDayDeliveryHoursEnd').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursEnd').disable();

      this.storeForm.get('sameDayDeliveryHoursDetails').clearValidators();
      this.storeForm.get('sameDayDeliveryHoursDetails').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursDetails').disable();

      this.storeForm.get('sameDayDeliveryHoursRadius').clearValidators();
      this.storeForm.get('sameDayDeliveryHoursRadius').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursRadius').disable();

      this.storeForm.get('sameDayDeliveryHoursFixedFee').clearValidators();
      this.storeForm.get('sameDayDeliveryHoursFixedFee').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursFixedFee').disable();

      this.storeForm.get('sameDayDeliveryHoursRateFee').clearValidators();
      this.storeForm.get('sameDayDeliveryHoursRateFee').updateValueAndValidity();
      this.storeForm.get('sameDayDeliveryHoursRateFee').disable();
    }
  }

  changeSameDeliveryTimeValidation(value){
    if(value){
      this.showDeliveryOptionError = false
      this.storeForm.get('onDemandDeliveryTimeHours').setValidators([Validators.required])
      this.storeForm.get('onDemandDeliveryTimeHours').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeHours').enable();

      this.storeForm.get('onDemandDeliveryTimeMins').setValidators([Validators.required])
      this.storeForm.get('onDemandDeliveryTimeMins').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeMins').enable();

      this.storeForm.get('onDemandDeliveryTimeDetails').setValidators([Validators.required])
      this.storeForm.get('onDemandDeliveryTimeDetails').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeDetails').enable();
  
      this.storeForm.get('onDemandDeliveryTimeEndHours').setValidators([Validators.required])
      this.storeForm.get('onDemandDeliveryTimeEndHours').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeEndHours').enable();

      this.storeForm.get('onDemandDeliveryTimeEndMins').setValidators([Validators.required])
      this.storeForm.get('onDemandDeliveryTimeEndMins').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeEndMins').enable();
  
      this.storeForm.get('onDemandDeliveryTimeRadius').setValidators([Validators.required])
      this.storeForm.get('onDemandDeliveryTimeRadius').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeRadius').enable();
  
      this.storeForm.get('onDemandDeliveryTimeFixedFee').setValidators([Validators.required])
      this.storeForm.get('onDemandDeliveryTimeFixedFee').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeFixedFee').enable();
  
      this.storeForm.get('onDemandDeliveryTimeRateFee').setValidators([Validators.required, Validators.max(100), Validators.min(0)])
      this.storeForm.get('onDemandDeliveryTimeRateFee').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeRateFee').enable();
    } else{
      this.storeForm.get('onDemandDeliveryTimeHours').clearValidators();
      this.storeForm.get('onDemandDeliveryTimeHours').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeHours').disable();

      this.storeForm.get('onDemandDeliveryTimeMins').clearValidators();
      this.storeForm.get('onDemandDeliveryTimeMins').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeMins').disable();
     
      this.storeForm.get('onDemandDeliveryTimeDetails').clearValidators();
      this.storeForm.get('onDemandDeliveryTimeDetails').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeDetails').disable();

      this.storeForm.get('onDemandDeliveryTimeEndHours').clearValidators();
      this.storeForm.get('onDemandDeliveryTimeEndHours').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeEndHours').disable();

      this.storeForm.get('onDemandDeliveryTimeEndMins').clearValidators();
      this.storeForm.get('onDemandDeliveryTimeEndMins').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeEndMins').disable();

      this.storeForm.get('onDemandDeliveryTimeRadius').clearValidators();
      this.storeForm.get('onDemandDeliveryTimeRadius').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeRadius').disable();

      this.storeForm.get('onDemandDeliveryTimeFixedFee').clearValidators();
      this.storeForm.get('onDemandDeliveryTimeFixedFee').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeFixedFee').disable();

      this.storeForm.get('onDemandDeliveryTimeRateFee').clearValidators();
      this.storeForm.get('onDemandDeliveryTimeRateFee').updateValueAndValidity();
      this.storeForm.get('onDemandDeliveryTimeRateFee').disable();
    }  
  }

  changePickupValidations(value){
    if(value){
      this.showDeliveryOptionError = false
      this.storeForm.get('pickupStartHours').setValidators([Validators.required])
      this.storeForm.get('pickupStartHours').updateValueAndValidity();
      this.storeForm.get('pickupStartHours').enable();

      this.storeForm.get('pickupStartMins').setValidators([Validators.required])
      this.storeForm.get('pickupStartMins').updateValueAndValidity();
      this.storeForm.get('pickupStartMins').enable();

      this.storeForm.get('pickupEndHours').setValidators([Validators.required])
      this.storeForm.get('pickupEndHours').updateValueAndValidity();
      this.storeForm.get('pickupEndHours').enable();

      this.storeForm.get('pickupEndMins').setValidators([Validators.required])
      this.storeForm.get('pickupEndMins').updateValueAndValidity();
      this.storeForm.get('pickupEndMins').enable();

      this.storeForm.get('pickupDetails').setValidators([Validators.required])
      this.storeForm.get('pickupDetails').updateValueAndValidity();
      this.storeForm.get('pickupDetails').enable();
    }else{
      this.storeForm.get('pickupStartHours').clearValidators();
      this.storeForm.get('pickupStartHours').updateValueAndValidity();
      this.storeForm.get('pickupStartHours').disable();

      this.storeForm.get('pickupStartMins').clearValidators();
      this.storeForm.get('pickupStartMins').updateValueAndValidity();
      this.storeForm.get('pickupStartMins').disable();

      this.storeForm.get('pickupEndHours').clearValidators();
      this.storeForm.get('pickupEndHours').updateValueAndValidity();
      this.storeForm.get('pickupEndHours').disable();

      this.storeForm.get('pickupEndMins').clearValidators();
      this.storeForm.get('pickupEndMins').updateValueAndValidity();
      this.storeForm.get('pickupEndMins').disable();

      this.storeForm.get('pickupDetails').clearValidators();
      this.storeForm.get('pickupDetails').updateValueAndValidity();
      this.storeForm.get('pickupDetails').disable();
    }
  }

  checkDeliveryValidations(){
    if(this.storeForm.value['onDemandDeliveryTime']){
      this.showDeliveryOptionError = false
    }
    else if(this.storeForm.value['sameDayDeliveryHours']){
      this.showDeliveryOptionError = false
    }
    else if(this.storeForm.value['isPickupHours']){
      this.showDeliveryOptionError = false
    }else{
      this.showDeliveryOptionError = true
    }
  }

  getStoreForm() {
    this.storeForm = new FormGroup({
      storeName: new FormControl(this.storeName || '', [Validators.required]),
      storeDec: new FormControl('', [Validators.required]),
      storePhoneNumber: new FormControl('', [Validators.required]),
      isOpen: new FormControl(false),
      isPhysicalStore: new FormControl(false),
      line1: new FormControl('', [Validators.required]),
      line2: new FormControl(''),
      state: new FormControl('', [Validators.required]),
      postalCode: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      onDemandDeliveryTime: new FormControl(false),
      onDemandDeliveryTimeHours: new FormControl(''),
      onDemandDeliveryTimeMins: new FormControl(''),
      onDemandDeliveryTimeEndHours: new FormControl(''),
      onDemandDeliveryTimeEndMins: new FormControl(''),
      onDemandDeliveryTimeDetails: new FormControl(''),
      onDemandDeliveryTimeRadius: new FormControl(''),
      onDemandDeliveryTimeFixedFee: new FormControl(''),
      onDemandDeliveryTimeRateFee: new FormControl(''),
      sameDayDeliveryHours: new FormControl(false),
      sameDayDeliveryHoursStart: new FormControl(''),
      sameDayDeliveryHoursEnd: new FormControl(''),
      sameDayDeliveryHoursDetails: new FormControl(''),
      sameDayDeliveryHoursRadius: new FormControl(''),
      sameDayDeliveryHoursFixedFee: new FormControl(''),
      sameDayDeliveryHoursRateFee: new FormControl(''),
      isPickupHours: new FormControl(false),
      pickupStartHours: new FormControl(''),
      pickupStartMins: new FormControl(''),
      pickupEndHours: new FormControl(''),
      pickupEndMins: new FormControl(''),
      pickupDetails: new FormControl(''),
    });
    this.changeSameDeliveryTimeValidation(false); 
    this.changeSameDeliveryHoursValidation(false);
    this.changePickupValidations(false);
  }

  // tslint:disable-next-line: typedef
  checkQueryParams() {
    this.activatedRoute.params.subscribe(params => {
      this.projectId = params['id'];
      this.storeId = params['storeId'];
      if (this.storeId) {
        // this.ngxLoader.start();
        this.isEdit = true;
        this.getStoreData();
      }
      this.project = this.accountManager.getProjectById(this.projectId);
      if (!this.project) {
        this.getUserProjectDetail();
      }
      this.getStoreForm();
    });
  }

  showEditItem(projectId, storeId){

    this.projectId = projectId;
    if (storeId) {
      this.storeId = storeId;
      this.isLoading = true;
      this.isEdit = true;
      this.getStoreData();
    }
    this.project = this.accountManager.getProjectById(projectId);
    if (!this.project) {
      this.getUserProjectDetail();
    }
  }
  
  getStoreData(){
    this.accountManager.getStoreById(this.projectId,this.storeId).then(
      resp => {
        if(resp['data']){
          this.storeObj = resp['data'];
          this.initializeStoreData();
          this.isLoading = false;
        }
      },
      err => {
        // this.ngxLoader.stop();
        this.toastr.error(err.error.metadata.message, '');
        this.router.navigate([`home/shop-details/${this.projectId}`])
        this.isLoading = false;
      },
    );
  }

  initializeStoreData() {
    this.storeObj.times.map(d=>{
      if(d.availableTime.close  == "0" || d.availableTime.open  == "0"){
        d.availableTime.close = "22:00";
        d.availableTime.open = "08:00";
      } else{
        d.availableTime.close = this.convertHMS(d.availableTime.close).toString();
        d.availableTime.open = this.convertHMS(d.availableTime.open).toString();
      }
    })
    this.times = [...this.storeObj.times];
    this.storeForm.patchValue({
      storeName: this.storeObj.name,
      storeDec: this.storeObj.details,
      storePhoneNumber: this.storeObj.phone,
      isOpen:this.storeObj.isAvailable,
      isPhysicalStore: this.storeObj.isPhysicalStore,
      line1:  this.storeObj.address.line1,
      line2:  this.storeObj.address.line2,
      state:  this.storeObj.address.province,
      postalCode:  this.storeObj.address.postalCode,
      country:  this.storeObj.address.countryCode,
      city:  this.storeObj.address.city,
      onDemandDeliveryTime: this.storeObj.deliveries[1].info.time.isAvailable,
      onDemandDeliveryTimeDetails: this.storeObj.deliveries[1].info.details || '',
      onDemandDeliveryTimeRadius: this.storeObj.deliveries[1].info.radius || '',
      onDemandDeliveryTimeFixedFee:  this.storeObj.deliveries[1].info.fees[0].value || '',
      onDemandDeliveryTimeRateFee:  this.storeObj.deliveries[1].info.fees[1].value || '',
      sameDayDeliveryHours: this.storeObj.deliveries[2].info.time.isAvailable,
      sameDayDeliveryHoursStart: this.storeObj.deliveries[2].info.time.open ? this.convertHMS(this.storeObj.deliveries[2].info.time.open) : '',
      sameDayDeliveryHoursEnd: this.storeObj.deliveries[2].info.time.close ? this.convertHMS(this.storeObj.deliveries[2].info.time.close) : '',
      sameDayDeliveryHoursDetails: this.storeObj.deliveries[2].info.details || '',
      sameDayDeliveryHoursRadius:  this.storeObj.deliveries[2].info.radius || '',
      sameDayDeliveryHoursFixedFee:  this.storeObj.deliveries[2].info.fees[0].value || '',
      sameDayDeliveryHoursRateFee: this.storeObj.deliveries[2].info.fees[1].value || '',
      isPickupHours:this.storeObj.deliveries[0].info.time.isAvailable,
      pickupDetails:this.storeObj.deliveries[0].info.details || '',
    })
    
    this.setHoursAndMins();
    if (this.storeObj.imageUrls.length > 0) {
      this.coverlogoImage = this.storeObj.imageUrls[0];
      this.coverlogoImageError = false;
    }
    this.changeSameDeliveryTimeValidation(this.storeObj.deliveries[1].info.time.isAvailable); 
    this.changeSameDeliveryHoursValidation(this.storeObj.deliveries[2].info.time.isAvailable);
    this.changePickupValidations(this.storeObj.deliveries[0].info.time.isAvailable);
    this.ngxLoader.stop();
  }

  setHoursAndMins(){
    let DeliveryTimeOpen = this.storeObj.deliveries[1].info.time.open ? this.convertHMS(this.storeObj.deliveries[1].info.time.open) : '';
    let DeliveryTimeClose = this.storeObj.deliveries[1].info.time.close ? this.convertHMS(this.storeObj.deliveries[1].info.time.close) : '';
    let pickupTimeOpen = this.storeObj.deliveries[0].info.time.open ? this.convertHMS(this.storeObj.deliveries[0].info.time.open) : '';
    let pickupTimeClose = this.storeObj.deliveries[0].info.time.close ? this.convertHMS(this.storeObj.deliveries[0].info.time.close) : '';
    
    this.storeForm.patchValue({
      onDemandDeliveryTimeHours: DeliveryTimeOpen ? +DeliveryTimeOpen.split(':')[0] * 60 * 60 : '',
      onDemandDeliveryTimeMins: DeliveryTimeOpen ? +DeliveryTimeOpen.split(':')[1] * 60 : '',
      onDemandDeliveryTimeEndHours: DeliveryTimeClose ? +DeliveryTimeClose.split(':')[0] * 60 * 60 : '',
      onDemandDeliveryTimeEndMins: DeliveryTimeClose ? +DeliveryTimeClose.split(':')[1] * 60  : '',

      pickupStartHours: pickupTimeOpen ? +pickupTimeOpen.split(':')[0] * 60 * 60 : '',
      pickupStartMins: pickupTimeOpen ? +pickupTimeOpen.split(':')[1] * 60 : '',
      pickupEndHours: pickupTimeClose ? +pickupTimeClose.split(':')[0] * 60 * 60 : '',
      pickupEndMins: pickupTimeClose ? +pickupTimeClose.split(':')[1] * 60  : '',
    })  
  }

  // tslint:disable-next-line: typedef
  getUserProjectDetail() {
    // TODO: add loading
    this.isLoading = true;
    this.accountManager.getUserProject(this.projectId).then(resp => {
      if (resp.status) {
        this.isLoading = false;
      }
    }, err => {
      this.toastr.error(err.error.message, '');
      this.isLoading = false;
    });
  }

  // tslint:disable-next-line: typedef
  deleteImage() {
    this.coverlogoImage = '';
    this.images.cover = {};
    this.coverlogoImageError = true;
  }

  // tslint:disable-next-line: typedef
  onFileChangeslogoImage($event, type) {
    const image = $event.image[0].base64;
    this.coverlogoImage = image;
    this.images.cover = $event.rawFile;
    this.coverlogoImageError = false;
  }

  // tslint:disable-next-line: typedef
  bothValidationCheck() {
    this.checkValidationError = this.storeForm.valid && !this.coverlogoImageError ? false : true
    return this.storeForm.valid && !this.coverlogoImageError && !this.showSameDayDeliveryOrderError && !this.showPickupTimeError && !this.showInstantDeliveryOrderError && !this.minTimeZeroErrorInstantDelivery
    && !this.minTimeZeroErrorPickup ;
  }

  // tslint:disable-next-line: typedef
  getAppData() {
    this.appService.getCountriesData()
    .then((res) => {
      this.countries = res;
    });
  } 

  // tslint:disable-next-line: typedef
  addStore() {
    let analytics = this.isEdit ? 'edit-save-store' :'add-save-store'
    this.publicService.logAnalytics(`${analytics}-btn-clicked-from-store`)
    this.checkDeliveryValidations();
    this.storeForm.markAllAsTouched();
    if(this.storeForm.value.onDemandDeliveryTime){
      let minTime = (+this.storeForm.value.onDemandDeliveryTimeHours + +this.storeForm.value.onDemandDeliveryTimeMins);
      let maxTime = (+this.storeForm.value.onDemandDeliveryTimeEndHours + +this.storeForm.value.onDemandDeliveryTimeEndMins);

      if(minTime == 0) {
        this.minTimeZeroErrorInstantDelivery = true;
      }

      if(maxTime <= minTime) {
        this.showInstantDeliveryOrderError = true;
      }
    }

    if(this.storeForm.value.isPickupHours){
      let minTime =  (+this.storeForm.value.pickupStartHours + +this.storeForm.value.pickupStartMins);
      let maxTime = (+this.storeForm.value.pickupEndHours + +this.storeForm.value.pickupEndMins);
      
      if(minTime == 0) {
        this.minTimeZeroErrorPickup = true;
      }

      if(maxTime <= minTime) {
        this.showPickupTimeError = true;
      }
    }

    if(this.storeForm.value.sameDayDeliveryHours){
      let minTime =  this.storeForm.value.sameDayDeliveryHoursStart ? this.convertToSeconds(this.storeForm.value.sameDayDeliveryHoursStart) : this.storeForm.value.sameDayDeliveryHoursStart
      let maxTime = this.storeForm.value.sameDayDeliveryHoursEnd ? this.convertToSeconds(this.storeForm.value.sameDayDeliveryHoursEnd) : this.storeForm.value.sameDayDeliveryHoursEnd
      if(maxTime <= minTime){
        this.showSameDayDeliveryOrderError = true;
      } 
    }

    // tslint:disable-next-line: triple-equals
    this.coverlogoImageError = this.coverlogoImage == '' ? true : false;
    if (this.bothValidationCheck() && !this.showDeliveryOptionError) {
      this.isSubmit = true;
      let times = JSON.parse(JSON.stringify(this.times)) 
      times.map(d=>{
          d.availableTime.close = this.convertToSeconds(d.availableTime.close);
          d.availableTime.open = this.convertToSeconds(d.availableTime.open);
      })
      let storeData = {
        data: {
          projectId: this.projectId,
          data: {
            name: this.storeForm.value.storeName,
            createdDate: Date.now() / 1000,
            phone:this.storeForm.value.storePhoneNumber.toString(),
            details:this.storeForm.value.storeDec,
            isPhysicalStore:this.storeForm.value.isPhysicalStore,
            isAvailable:this.storeForm.value.isOpen,
            times: times,
            address: {
              countryCode: this.storeForm.value.country,
              line1: this.storeForm.value.line1,
              line2:this.storeForm.value.line2,
              postalCode: this.storeForm.value.postalCode,
              province: this.storeForm.value.state,
              city: this.storeForm.value.city,
            },
            deliveries:this.getDeliveryData(),
            "location": {
              "lat": this.addressObj?.latitude || 0,
              "long": this.addressObj?.longitude || 0,
              "timeZoneOffset": 3600
            },
          }
        }
      }

      if (this.images.cover['name']) {
        storeData['image1'] = this.images.cover;
      } else {
        storeData.data.data['imageUrls'] = this.storeObj.imageUrls;
      }

      if (this.isEdit && this.storeId) {
        storeData.data.data['id'] = this.storeId;
      }
      
      
      let storeObj = this.publicService.makeFormData(storeData);
      this.accountManager.addStore(storeObj).then(
        resp => {
          this.isSubmit = false;
          this.addStoreIntoList()
          if (this.isEdit && this.storeId) { 
            this.toastr.success('Your Store has been updated', '');
          } else {
            this.toastr.success('Your Store has been created', '');
          }
          // this.router.navigate([`home/shop-details/${this.projectId}`])

          if(this.shopDetails){
            this.closeModal.emit(this.close);
          }
          else if(this.fromLink){
            this.router.navigate([`/home/shop-details/${this.projectId}`])
          }
          else{
            this.popupService.storeEdit.next(false);
          }
        },
        err => {
          this.isSubmit = false;
          this.toastr.error(err.error.message, '');
        },
      );
    }
  }

  getDeliveryData() {
    const deliveryobj =  [
      {
        "info": {
          "details": this.storeForm.value.pickupDetails,
          "radius": 0,
          "time": {
            "close": (+this.storeForm.value.pickupEndHours + +this.storeForm.value.pickupEndMins)  || 1320,
            "isAvailable": this.storeForm.value.isPickupHours,
            "mode": "interval",
            "open": (+this.storeForm.value.pickupStartHours + +this.storeForm.value.pickupStartMins) || 480,
          }
        },
        "method": "pickUp"
      },
      {
        "info": {
          "details": this.storeForm.value.onDemandDeliveryTimeDetails,
          "radius": +this.storeForm.value.onDemandDeliveryTimeRadius || 0,
          "time": {
            "close":  (+this.storeForm.value.onDemandDeliveryTimeEndHours + +this.storeForm.value.onDemandDeliveryTimeEndMins) || 1320,
            "isAvailable": this.storeForm.value.onDemandDeliveryTime,
            "mode": "interval",
            "open":  (+this.storeForm.value.onDemandDeliveryTimeHours + +this.storeForm.value.onDemandDeliveryTimeMins) || 480,
          },
          "fees": [
            {
              "name": "Fixed Fee",
              "type": "fixed",
              "value": this.storeForm.value.onDemandDeliveryTimeFixedFee || 0,
            },
            {
              "name": "Rate Fee",
              "type": "rate",
              "value": this.storeForm.value.onDemandDeliveryTimeRateFee || 0,
            }
          ],
        },
        "method": "onDemandDelivery"
      },
      {
        "info": {
          "details":this.storeForm.value.sameDayDeliveryHoursDetails,
          "fees": [
            {
              "name": "Fixed Fee",
              "type": "fixed",
              "value": this.storeForm.value.sameDayDeliveryHoursFixedFee || 0,
            },
            {
              "name": "Rate Fee",
              "type": "rate",
              "value": this.storeForm.value.sameDayDeliveryHoursRateFee || 0,
            }
          ],
          "radius": +this.storeForm.value.sameDayDeliveryHoursRadius || 0,
          "time": {
            "close": this.storeForm.value.sameDayDeliveryHoursEnd ? this.convertToSeconds(this.storeForm.value.sameDayDeliveryHoursEnd) : 1320,
            "isAvailable": this.storeForm.value.sameDayDeliveryHours,
            "mode": "time",
            "open": this.storeForm.value.sameDayDeliveryHoursStart ? this.convertToSeconds(this.storeForm.value.sameDayDeliveryHoursStart) : 480,
          }
        },
        "method": "sameDayDelivery"
      }
    ];
    return deliveryobj;
  }

  setAddress(data) {
    this.addressObj = data
    this.storeForm.get('line1').setValue(data.address || '');
    this.storeForm.get('line2').setValue(data.line2 || '');
    this.storeForm.get('state').setValue(data.state || '');
    
    this.getCountries(data.country,data);
    this.storeForm.get('postalCode').setValue(data.postalCode || '');
    this.storeForm.get('city').setValue(data.city || '');
  }

  getCountries(countryName, data?) {
    let countryData = this.countries.find(countryData => countryData.country.name == countryName);

    if (countryData) {
      this.storeForm.get('country').setValue(countryData.country.code || '');
      if (countryData.requiresPostalCode) {
        this.storeForm.get('postalCode').enable();
        this.shouldHidePostalCode = false;
      } else {
        this.storeForm.get('postalCode').disable();
        this.shouldHidePostalCode = true;
      }
    }
  }

  convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    // add 0 if value < 10; Example: 2 => 02
    let sendHours = hours   < 10 ? `0${hours}` : hours;
    let sendMins = minutes   < 10 ? `0${minutes}` : minutes;
    return sendHours+':'+sendMins ; // Return is HH : MM : SS
  }

  convertToSeconds(value){
    if(!value || value == 0){
      return 0
    } else{
      let time = value.split(":");
      return (+time[0] * 60 * 60) + (+time[1] * 60);
    }
  }

  addStoreIntoList(){
    this.addStoreIntoListItem.emit();
  }

}
