import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserData, ProjectsData, ProjectData, ProjectUpdateDataSuccess, ServiceData, PhoneNumberDetails } from '../auth/userData.model';
import { map, take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})

export class UserService {
  public apiURL = '';
  constructor(private http: HttpClient) {
    this.apiURL = environment.backendBaseURL;
  }
  
  updateBusinessInfo(businessName: string, brandName: string, websiteURL:string, phoneNumber: PhoneNumberDetails) {
    const businsssInfoData = {
      businessName: businessName,
      brandName: brandName,
      websiteURL:websiteURL,
      phone: phoneNumber
    };
    
    return this.http
      .post<UserData>(this.apiURL + '/usersApi/user/business/info', businsssInfoData);
  }

  updateBusinessAddress(
    line1: string,
    line2: string,
    city: string,
    state: string,
    postalCode: string,
    country: string
  ) {
    
    const businessAddressData = {
      line1: line1,
      line2: line2,
      city: city,
      province: state,
      postalCode: postalCode,
      countryCode: country,
    };

    return this.http
      .post<UserData>(this.apiURL + '/usersApi/user/business/address', businessAddressData);
  }

  updateBusinessType(body) {
    return this.http
      .post<UserData>(this.apiURL + '/usersApi/user/business/legal', body)
  }

  updateAboutYou(
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber?:PhoneNumberDetails) {
      
    const businsssAboutYouData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phoneNumber
    };
    
    return this.http
    .post<UserData>(this.apiURL + '/usersApi/user/contact/info', businsssAboutYouData)
  }

  getUserInfo(flow?) {
    const userInfoUrl = flow ? `/usersApi/user/info/?flow=${flow}` : `/usersApi/user/info`
    return this.http
    .get<UserData>(this.apiURL + userInfoUrl)
  }
   
  getUserServices() {
    return this.http.get<ServiceData>(this.apiURL + '/serviceApi/services/user')
  }

  updateUserService(body) {
    return this.http
    .post<ServiceData>(this.apiURL + '/serviceApi/services/user', body)
  }
  
  createShop(appName: string, productId: string) {
    const shopData = {
      name: appName,
      productId: productId
    };
    
    return this.http
    .post<ProjectData>(this.apiURL + '/projectApi/project', shopData)
  }

  submitApp(projectId: string) {
    const submitAppData = {
      projectId: projectId
    };

    return this.http
    .post(this.apiURL + '/projectApi/project/submit', submitAppData)
  }

  updateSrvice(body) {
    return this.http
    .patch<ProjectData>(this.apiURL + '/projectApi/project/service', body)
  }

  getUserProjects() {
    return this.http.get<ProjectsData>(this.apiURL + '/projectApi/projects')
  }
  
  getUserProject(id: String){
    return this.http.get<ProjectData>(this.apiURL + `/projectApi/project?id=${id}`); 
  }

  updateAgreement(projectId: String) {
    let agreementObj = {
      "projectId": projectId
    };
    return this.http
    .post<ProjectData>(this.apiURL + '/projectApi/project/acceptAgreement ', agreementObj)
  }

  updatePlatformInfo(body) {
    return this.http
    .patch<ProjectData>(this.apiURL + '/projectApi/project/platforms', body)
  }

  updateClientAppTheme(body) {
    return this.http
    .patch<ProjectData>(this.apiURL + '/projectApi/project/theme', body)
  }

  updateScreenshot(body) {
    return this.http
    .patch<ProjectData>(this.apiURL + '/projectApi/project/screenshot', body)
  }

  continueProjectCreation(id: string) {
    const body = {
      projectId: id
    }
    return this.http.patch<ProjectUpdateDataSuccess>(this.apiURL + '/projectApi/project/continueProjectCreation', body); 
  }

  getProjectAnalyticsInfo(id) { 
    return this.http.get(this.apiURL + '/projectApi/project/analytics/?id=' + id)
  }

  getShopAll(projectId){
    return this.http.get(this.apiURL + '/shopApi/getAll/?projectId=' + projectId);
  }

  getItems(projectId){
    return this.http.get(this.apiURL + '/shopApi/getData/items/?projectId=' + projectId);
  }

  getCategories(projectId){
    return this.http.get(this.apiURL + '/shopApi/getData/categories/?projectId=' + projectId);
  }

  getStores(projectId){
    return this.http.get(this.apiURL + '/shopApi/getData/stores/?projectId=' + projectId);
  }

  addCategory(body) {
    return this.http
    .post<ProjectData>(this.apiURL + '/shopApi/updateData/categories', body)
  }

  addProduct(body){
    return this.http
    .post<ProjectData>(this.apiURL + '/shopApi/updateData/items', body)
  }

  addStore(body){
    return this.http.post(this.apiURL + '/shopApi/updateData/stores', body)
  }

  getCategoryById(projectId,id){
    return this.http.get(this.apiURL + `/shopApi/getData/categories/${id}/?projectId=${projectId}`);
  }

  getProductById(projectId, id) {
    return this.http.get(this.apiURL + `/shopApi/getData/items/${id}/?projectId=${projectId}`);
  }

  getStoreById(projectId,id){
    return this.http.get(this.apiURL + `/shopApi/getData/stores/${id}/?projectId=${projectId}`);
  }
}