<div class="product-wrapper">
    <div id="contact" class="testimonials" >
        <div class="contact-section">
            <div class="forms">
                <h4>Contact Us</h4>
                <form [formGroup]="contactForm">
                    <div class="input-fields">
                        <input type="text" 
                        [ngClass]="[contactForm.get('name').hasError('required') && contactForm.get('name').touched ? 'error' : '']"
                        formControlName="name" placeholder="Name" class="form-control">
                    </div>
                    <div class="input-fields">
                        <input type="text" formControlName="email" placeholder="Email"
                        [ngClass]="[contactForm.get('email').hasError('required') && contactForm.get('email').touched ? 'error' : '']"
                        [ngClass]="[contactForm.get('email').hasError('email') && contactForm.get('email').touched ? 'error' : '']"
                        class="form-control">
                        <div class="text-danger" *ngIf="contactForm.get('email').hasError('email')">Please enter a valid email</div>
                    </div>
                    <div class="input-fields">
                        <input type="text" formControlName="mobile" placeholder="Mobile"
                        [ngClass]="[contactForm.get('mobile').hasError('required') && contactForm.get('mobile').touched ? 'error' : '']"
                        [ngClass]="[contactForm.get('mobile').hasError('minlength') && contactForm.get('mobile').touched ? 'error' : '']"
                        class="form-control">
                        <div class="text-danger" *ngIf="contactForm.get('mobile').hasError('minlength')">Mobile number require 10 digits</div>
                    </div>
                    <div class="input-fields">
                        <textarea rows="5" formControlName="message" placeholder="Message" class="form-control"
                    [ngClass]="[contactForm.get('message').hasError('required') && contactForm.get('message').touched ? 'error' : '']"></textarea>
                    </div>
                    <button [disabled]="disableButton" (click)="sendContact()" type="button" class="submit">
                        SEND
                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm justify-content-center" role="status"
                        aria-hidden="true"></span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 12H20" stroke="#62D0FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14 6L20 12L14 18" stroke="#62D0FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </form>
            </div>
        </div>
        <div class="testimonials-section">
            <div class="inner-testimonial">
                <div class="customer-details">
                    <h3>Address</h3>
                    <p>Toronto </p>
                    <p>Canada, ON</p>
                </div>
                <div class="customer-details">
                    <h3>Email</h3>
                    <p>sales@orderax.com</p>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="items-logo" class="items-logo-section">
    <div class="footer-wrapper">
        <div class="main-logo-footer">
            <img src="/assets/images/OrderAx-1.png"alt="logo" />
        </div>
    <div class="items-logo">
        <div class="items">
            <div class="single-items">
                <h6>Marketplace</h6>
                <p><a href="/products">Products</a></p>
                <p><a href="/product/restaurant">Restaurant</a></p>
                <p><a href="/customers">Customer Apps</a></p>
                <!-- <p><a href="#">Item 5</a></p> -->
            </div>
            <div class="single-items">
                <h6>My Account</h6>
                <p><a href="/home/dashboard">Dashboard</a></p>
                <p><a href="/home/myaccount">Profile</a></p>
                <p><a href="/home/shops">Shops</a></p>
                <p><a href="/home/user-services">Services</a></p>
                <p><a href="/home/products">Products</a></p>
            </div>
            <div class="single-items">
                <h6>Resources</h6>
                <p><a href="https://blog.orderax.com/" target="_blank">Blog</a></p>
                <p><a href="/tools">Tools</a></p>
                <p><a href="/tools/free-restaurant-app-theme">Free Restaurant App Theme</a></p>
                <p><a href="/tools/free-restaurant-app-screenshot-generator">Free App Screenshot Generator</a></p>
            </div>
            <div class="single-items">
                <h6>Company</h6>
                <p><a href="https://www.iubenda.com/privacy-policy/65640587" target="_blank">Privacy Policy</a></p>
                <p><a href="https://www.iubenda.com/terms-and-conditions/65640587" target="_blank">Terms and Conditions</a></p>
                <!-- <p><a href="#">Item 3</a></p>
                <p><a href="#">Item 4</a></p>
                <p><a href="#">Item 5</a></p> -->
            </div>
        </div>
        <div #toolsSection class="social-logo">
            <div class="bottom-wrapper">
                <div class="social-icons">
                    <a href="https://www.instagram.com/orderaxapp/?hl=en" target="_blank">
                        <img src="assets/svg/instagram.svg" type="image/svg+xml" alt="Instagram Icon">
                    </a>
                    <a href="https://web.facebook.com/orderax" target="_blank">
                        <img src="assets/svg/facebook.svg" type="image/svg+xml" alt="Facebook Icon">
                    </a>
                    <a href="https://www.twitter.com/orderax1" target="_blank">
                        <img src="assets/svg/twitter.svg" type="image/svg+xml" alt="Twitter Icon">
                    </a>
                    <a href="https://www.youtube.com/channel/UCnAEXlmsER86iLQwnysyHUQ" target="_blank">
                        <img src="assets/svg/youtube.svg" type="image/svg+xml" alt="Youtube Icon">
                    </a>
                    <a href="https://g.page/r/CdxuoeNDxtG_EAE" target="_blank">
                        <img src="assets/svg/google.svg" type="image/svg+xml" alt="Google Icon">
                    </a>
                </div>

                <p>Most businesses can’t afford to build their own mobile app so we have created the technology to get businesses the own custom app to sell their products and grow their business. 
                    <br>
                    <br>
                    Whether you are a restaurant, cafe, coffee, cannabis or any store that wants to sell your products online, we are here to provide you with a custom mobile ordering app to serve your business needs.</p>
            </div>
        </div>
    </div>
    <span class="rights-text">All Rights Reserved 2021</span>
    </div>
</div>