import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { map, take } from 'rxjs/operators';
import { BusinessInfo, BusinessLegal, ContactInfo, Address, Project, UserData, User, PhoneNumberDetails } from '../auth/userData.model';

@Injectable({
  providedIn: 'root',
})

export class AccountManagerService {
  constructor(
    private userService: UserService) { }

  private getStore(key) {
    return localStorage.getItem(key);
  }

  private saveStore(key, value) {
    if (value != null) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  saveEmail(email) {
    localStorage.setItem('email', email);
  }

  saveEmailStatus(data) {
    localStorage.setItem('emailStatus', data);
  }

  private saveUser(data: User) {
    this.saveStore('user', data)
  }

  private saveUserProjects(data: [Project]) {
    this.saveStore('userProjects', data)
  }

  private saveUserProject(data: Project) {
    let projects = this.getCurrentUserProjects()
    if (projects == null) {
      this.saveUserProjects([data])
    } else {
      projects.push(data);
      this.saveUserProjects(projects)
    }
  }

  private saveUserServices(services) {
    localStorage.setItem('userServices', JSON.stringify(services));
  }

  saveProjectTheme(data) {
    this.saveStore('color', data)
  }

  getCurrentEmail() {
    return this.getStore('email');
  }

  getCurrentEmailStatus() {
    return JSON.parse(this.getStore('emailStatus'));
  }

  getCurrentBusinessInfo(): BusinessInfo {
    return this.getUser().business.info
  }

  getCurrentBusinessAddress(): Address {
    return this.getUser().business.address
  }

  getCurrentBusinessLegal(): BusinessLegal {
    return this.getUser().business.legal
  }

  getCurrentAboutYou(): ContactInfo {
    return this.getUser().contact
  }

  getCurrentUserProjects(): [Project] {
    return JSON.parse(this.getStore('userProjects'))
  }

  getCurrentUserServices() {
    return JSON.parse(this.getStore('userServices'))
  }

  getProjectTheme() {
    return JSON.parse(this.getStore('color'))
  }
  
  getUser(): User {
    return JSON.parse(this.getStore('user'))
  }

  getUserData(): UserData {
    let status = true;
    let user = this.getUser();
    let projects = this.getCurrentUserProjects();

    return {
      status,
      user,
      projects
    }
  }

  getSavedCategories(){
    return localStorage.getItem('categories') ? JSON.parse(localStorage.getItem('categories')) : {};
  }

  getSavedStores(){
    return localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : {};
  }

  getSavedItems(){
    return localStorage.getItem('items') ? JSON.parse(localStorage.getItem('items')) : {};
  }

  removeStore() {
    localStorage.clear();
  }

  getUserInfo(flow?) {
    return this.userService.getUserInfo(flow)
      .pipe(map(response => {
        this.saveUser(response.user)
        this.saveUserProjects(response.projects);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  updateBusinessInfo(businessName: string, brandName: string, websiteURL: string, phone: PhoneNumberDetails) {
    return this.userService.updateBusinessInfo(businessName, brandName,websiteURL,phone)
      .pipe(map(response => {
        this.saveUser(response.user);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  updateBusinessAddress(
    line1: string,
    line2: string,
    city: string,
    state: string,
    postalCode: string,
    countryCode: string) {
    return this.userService.updateBusinessAddress(line1, line2, city, state, postalCode, countryCode)
      .pipe(map(response => {
        this.saveUser(response.user);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  updateBusinessType(obj) {
    return this.userService.updateBusinessType(obj)
      .pipe(map(response => {
        this.saveUser(response.user);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  updateAboutYou(
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: PhoneNumberDetails,
    ) {
    return this.userService.updateAboutYou(firstName, lastName, email, phoneNumber)
      .pipe(map(response => {
        this.saveUser(response.user);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  createShop(appName, productId) {
    return this.userService.createShop(appName, productId)
      .pipe(map(response => {
        this.saveUserProject(response.project);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  submitApp(projectId) {
    return this.userService.submitApp(projectId)
  }

  getUserProjects() {
    return this.userService.getUserProjects()
      .pipe(map(response => {
        this.saveUserProjects(response.projects);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  updateUserProjects(project) {
    let projects = this.getSavedProjects();
    const index = projects.findIndex(proj => proj.id === project.id);
    projects[index] = project;
    localStorage.setItem('userProjects', JSON.stringify(projects));
  }

  getUserProject(id) {
    return this.userService.getUserProject(id)
      .pipe(map(response => {
        this.updateUserProjects(response.project);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  getSavedProjects(): [Project] {
    return localStorage.getItem('userProjects') ? JSON.parse(localStorage.getItem('userProjects')) : [];
  }

  getProjectById(id: string) {
    return this.getSavedProjects().find(project => project.id == id);
  }

  getProjectAnalyticsInfo(id) {
    return this.userService.getProjectAnalyticsInfo(id)
    .pipe(map(response =>  {
      // localStorage.setItem('projectAnalyticsInfo', JSON.stringify(response));
      return response;
    }))
    .pipe(take(1)).toPromise()
    .then((info) => {
      return info;
    });
  }

  updateAgreement(id) {
    return this.userService.updateAgreement(id)
      .pipe(map(response => {
        this.updateUserProjects(response.project);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  updatePlatformInfo(body) {
    return this.userService.updatePlatformInfo(body)
      .pipe(map(response => {
        this.updateUserProjects(response.project);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  updateService(body) {
    return this.userService.updateSrvice(body)
      .pipe(map(response => {
        this.updateUserProjects(response.project);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  getUserServices() {
    return this.userService.getUserServices()
      .pipe(map(response => {
        this.saveUserServices(response.services);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  updateUserService(body) {
    return this.userService.updateUserService(body)
      .pipe(map(response => {
        this.saveUserServices(response.services);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  updateClientAppTheme(body) {
    return this.userService.updateClientAppTheme(body)
      .pipe(map(response => {
        this.updateUserProjects(response.project);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  updateScreenshot(body) {
    return this.userService.updateScreenshot(body)
      .pipe(map(response => {
        this.updateUserProjects(response.project);
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  getShopAllData(projectId) {
    return this.userService.getShopAll(projectId)
      .pipe(map(response => {
        return response['data'];
      }))
      .pipe(take(1)).toPromise();
  }

  updateUserProjectItem(data,id) {
    let item = this.getSavedItems();
    item[id] = data
    localStorage.setItem('items', JSON.stringify(item));
  }

  getItems(projectId) {
    return this.userService.getItems(projectId)
      .pipe(map(response => {
        this.updateUserProjectItem(response['data'],projectId);
        return response['data'];
      }))
      .pipe(take(1)).toPromise();
  }

  updateUserProjectCategory(data,id) {
    let category = this.getSavedCategories();
    category[id] = data
    localStorage.setItem('categories', JSON.stringify(category));
  }

  updateUserProjectStore(data,id) {
    let store = this.getSavedStores();
    store[id] = data
    localStorage.setItem('stores', JSON.stringify(store));
  }

  getCategories(projectId) {
    return this.userService.getCategories(projectId)
      .pipe(map(response => {
        this.updateUserProjectCategory(response['data'],projectId);
        return response['data'];
      }))
      .pipe(take(1)).toPromise();
  }

  getStores(projectId) {
    return this.userService.getStores(projectId)
      .pipe(map(response => {
        this.updateUserProjectStore(response['data'],projectId);
        return response['data'];
      }))
      .pipe(take(1)).toPromise();
  }

  addCategory(body) {
    return this.userService.addCategory(body)
      .pipe(map(response => {
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  getCategoryById(projectId,id) {
    return this.userService.getCategoryById(projectId,id)
      .pipe(map(response => {
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  addProduct(body) {
    return this.userService.addProduct(body)
      .pipe(map(response => {
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  getProductById(projectId, id) {
    return this.userService.getProductById(projectId,id)
      .pipe(map(response => {
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  getStoreById(projectId,id) {
    return this.userService.getStoreById(projectId,id)
      .pipe(map(response => {
        return response;
      }))
      .pipe(take(1)).toPromise();
  }

  addStore(body) {
    return this.userService.addStore(body)
      .pipe(map(response => {
        return response;
      }))
      .pipe(take(1)).toPromise();
  }
  
}
