import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccountManagerService } from '../managers/account-manager.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router, public accountManager:AccountManagerService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let currentUser = JSON.parse(localStorage.getItem('user'));
    let token = localStorage.getItem('token');
    if (token && currentUser && currentUser['type'] == 1) {
      return true
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}