import { Component, OnInit, Input, ViewChild,OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Output, EventEmitter } from '@angular/core';
import { AccountManagerService } from 'src/app/managers/account-manager.service';
import { PublicService } from 'src/app/public/public.service';
import { PopupService } from '../../service/popup.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit,OnChanges {
  @Output() goToParent1 = new EventEmitter;
  @Input() obj1;
  @Input() projectId;
  @Input() limit;
  project;
  confirmProducts = [];
  selectedItems = [];
  comingProductId;
  comingProjectId;
  comingProductName;
  message: string;
  error: string;
  aditional: string;
  selectMessage: string;
  items = []; 
  isLoading = false;
  addNew = false;
  storeToSend = [];
  categoryToSend = [];
  colors =
    [
      { color: '#1ABC9C' },
      { color: '#3498DB' },
      { color: '#2ECC71' },
      { color: '#9B59B6' },
      { color: '#34495E' },
      { color: '#16A085' },
      { color: '#F1C40F' },
      { color: '#F39C12' },
      { color: '#E67E22' },
      { color: '#7F8C8D' }
    ];
  constructor(private router: Router,
    public publicService: PublicService,
    private accountManagerService: AccountManagerService,
    private popupService: PopupService) { }

  ngOnInit(): void {
    this.popupService.productEdit.subscribe(result => {
      this.addNew = result;
    });
    this.getCategories();
    this.getStoresPopup();
  }

  ngOnChanges() {}
  
  getItems() {
    this.isLoading = true;
    this.accountManagerService.getItems(this.projectId).then(resp => {
      if (resp) {
          this.isLoading = false;
          this.items = resp;
      }
    }, err => {
      this.isLoading = false;
    });
  }


  getCategories() {
    this.accountManagerService.getCategories(this.projectId).then(resp => {
      if (resp) {
        for(var i=0; i<resp.length; i++){
          for(var j=0; j<this.colors.length; j++){
            if(resp[i].colorIndex == j){
              resp[i].color = this.colors[j].color;
            }
          }
          resp[i].selected = false;
        }
        this.categoryToSend = resp;
      }
    }, err => {
      this.isLoading = false;
    });
  }

  getStoresPopup() {
    this.accountManagerService.getStores(this.projectId).then(resp => {
      if (resp) {
          for(var i=0; i<resp.length; i++){
            resp[i].selected = false;
          }
          this.storeToSend = resp;
      }
    }, err => {
      this.isLoading = false;
    });
  }

  showProduct() {
    this.getItems();
    this.confirmProducts = this.obj1;
    this.selectedItems = [];
    for (var i = 0; i < this.confirmProducts.length; i++) {
      if(this.confirmProducts[i].id) {
        this.selectedItems.push(this.confirmProducts[i]);
      }
    }
    this.message = '';
    this.error = '';
    this.aditional = '';
    this.selectMessage = "";
  }

  add(id) {
    this.publicService.logAnalytics('add-btn-clicked-in-products')
    if(this.limit){
      if (this.selectedItems.length < this.limit) {
       this.addItem(id);
      } else {
        this.selectMessage = `You can only select ${this.limit} products`;
        return;
      }
    } else{
      this.addItem(id);
    }
  }

  addItem(id){
    if (this.selectedItems.some(d=> d.id == id.id)) {
      this.selectMessage = "This product is already added";
      return;
    } else {
      this.selectedItems.push(id);
      this.selectMessage = "";
    }
  }

  delete(id) {
    this.publicService.logAnalytics('delete-btn-clicked-from-selected-product-in-products')
    let index = this.selectedItems.indexOf(id);
    this.selectedItems.splice(index, 1);
  }

  confirm() {
    this.publicService.logAnalytics('confirm-btn-clicked-in-products')
    if(this.selectedItems != this.limit) {
      this.goToParentScreenShot();
    } else{
      this.goToParentCategory();
    }
  }

  redirectProduct(id?) {
    this.addNew = !this.addNew;
    this.popupService.showProductArrow.next(false);
    this.popupService.shopDetailsProduct.next(false);
    this.popupService.productEdit.next(true);
    this.popupService.shopProductEdit.next(false);
    if(id) {
      this.publicService.logAnalytics('edit-product-btn-clicked-in-products')
      this.popupService.productProjectId.next(this.projectId);
      this.popupService.productEditId.next(id);
      this.popupService.productName.next('');
    } else if (this.aditional) {
      this.publicService.logAnalytics('add-new-product-btn-clicked-in-products-with-product-name')
      this.popupService.productProjectId.next(this.projectId);
      this.popupService.productName.next(this.aditional);
      this.popupService.productEditId.next(null);
    } else {
      this.publicService.logAnalytics('add-new-product-btn-clicked-in-products-without-product-name')
      this.popupService.productProjectId.next(this.projectId);
      this.popupService.productEditId.next(null);
      this.popupService.productName.next('');
    }
  }

  goToParentScreenShot(){
    if (this.selectedItems.length >= this.limit + 1) {
      this.error = `Please select ${this.limit} products`;
      this.message = '';
      document.getElementsByClassName("confirmbtn")[0].setAttribute("data-dismiss", "modal2");
      return;
    } else {
      this.confirmProducts = [];
      for (var i = 0; i < this.selectedItems.length; i++) {
        this.selectedItems[i].status = true;
        this.confirmProducts.push(this.selectedItems[i]);
      }
      
      if (this.confirmProducts.length == this.limit) {
        this.error = '';
        document.getElementsByClassName("confirmbtn")[0].setAttribute("data-dismiss", "modal");
      } else {
        this.error = '';
        document.getElementsByClassName("confirmbtn")[0].setAttribute("data-dismiss", "modal");
      }
      this.goToParent1.emit(this.confirmProducts);
    }
  }

  goToParentCategory(){
    this.goToParent1.emit(this.confirmProducts);
  }

  addProductToList($event){
    this.getItems();
  }
}
