import {
  Component,
  OnInit,
  ViewContainerRef,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Cmyk } from 'ngx-color-picker';
import { ToastrService } from 'ngx-toastr';
import { AccountManagerService } from 'src/app/managers/account-manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { PublicService } from '../../public/public.service';

@Component({
  selector: 'app-sharedtheme',
  templateUrl: './sharedtheme.component.html',
  styleUrls: ['./sharedtheme.component.css']
})
export class SharedthemeComponent implements OnInit {
  public toggle: boolean = false;

  public primaryColor: string;
  public secondaryColor: string ;
  public headerTextColor: string ;
  public subHeaderTextColor: string ;
  public textColor: string ;
  public subTextColor: string ;
  public subText2Color: string ;
  public gradient1Color: string ;
  public gradient2Color: string ;
  public startUpGradient1Color: string ;
  public startUpGradient2Color: string ;
  public pageIndicatorColor: string ;
  public currentPageIndicatorColor: string ;
  public navigationTintColor: string ;
  public navigationTitleColor: string ;

  public cmykValue: string = '';

  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);

  dotsNumber = 1;
  tab = "ios"
  projectId;
  isLoading = false;
  isSubmit = false;
  project ;
  themeForShop
  index = 0;
  
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  lastTiles: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    margin : 25,
    navSpeed: 700,
    stagePadding: 50,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  }
  
  constructor(
    public vcRef: ViewContainerRef,
    private toastr: ToastrService,
    private accountManager: AccountManagerService,
    private route: Router,
    public publicService:PublicService
  ) {}

  ngOnInit(){
    this.tab = 'ios';
    this.setThemeColor(this.project);
  }

  getData(data: SlidesOutputData) {
    if(data.slides.length > 0){
      data.slides[0].width = 250;
    }
    return data;
  }

leftBoundStat(reachesLeftBound: boolean) {
  var carousel = document.getElementById("carouselOne").getBoundingClientRect();
  if(this.tab == 'ios'){
    var screen1 = document.getElementById("oneScreen").getBoundingClientRect();
    var screen2 = document.getElementById("twoScreen").getBoundingClientRect();
    var screen3 = document.getElementById("threeScreen").getBoundingClientRect();
    
    if(carousel.left > screen1.left && carousel.left <= screen2.left){
      this.dotsNumber = 2;
    } else if(carousel.left > screen2.left && carousel.left <= screen3.left+70){
      this.dotsNumber = 3;
    } else{
      this.dotsNumber = 1;
    }
  } else if(this.tab == 'android') {
    var screen4 = document.getElementById("fourScreen").getBoundingClientRect();
    var screen5 = document.getElementById("fiveScreen").getBoundingClientRect();
    var screen6 = document.getElementById("sixScreen").getBoundingClientRect();
    if(carousel.left > screen4.left && carousel.left <= screen5.left){
      this.dotsNumber = 2;
    } else if(carousel.left > screen5.left && carousel.left <= screen6.left+70){
      this.dotsNumber = 3;
    } else{
      this.dotsNumber = 1;
    }
  }
}

  public onEventLog(event: string, data: any): void {
    console.log(event, data);
  }

  public onChangeColor(color: string): void {
    console.log('Color changed:', color);
  }

  getRgbaColorFormatFromHexCode(color,alpha){
    let removeHashColor = color.split('#')[1];
    let aRgbHex = removeHashColor.match(/.{1,2}/g);
    let RgbaColor = {
            "alpha": alpha,
            "blue":  parseInt(aRgbHex[2], 16),
            "green": parseInt(aRgbHex[1], 16),
            "red":parseInt(aRgbHex[0], 16)
          }
    return RgbaColor;
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  
  rgbToHex(color) {
    let rgb = color.replace(/^(rgb|rgba)\(/,'').replace(/\)$/,'').replace(/\s/g,'').split(',');
    let obj = {
      color:  "#" + this.componentToHex(+rgb[0]) + this.componentToHex(+rgb[1]) + this.componentToHex(+rgb[2]),
      alpha : +rgb[3]
    }
    return obj;
  }
  
  getColorAccordingToFormat(color){
    if(color.includes('rgb')){
      let rgbToHex = this.rgbToHex(color);
      return this.getRgbaColorFormatFromHexCode(rgbToHex.color, rgbToHex.alpha);
    } else{
      return this.getRgbaColorFormatFromHexCode(color, 1);
    }
  }

  setThemeColor(project){
      this.primaryColor = project?.primaryColor ? 
      `rgb(${project.primaryColor.red},${project.primaryColor.green},${project.primaryColor.blue},${project.primaryColor.alpha})` : '#2889e9'
      this.secondaryColor = project?.secondaryColor ? 
      `rgb(${project.secondaryColor.red},${project.secondaryColor.green},${project.secondaryColor.blue},${project.secondaryColor.alpha})` : '#e920e9'
      this.headerTextColor = project?.headerTextColor ? 
      `rgb(${project.headerTextColor.red},${project.headerTextColor.green},${project.headerTextColor.blue},${project.headerTextColor.alpha})` : '#2B2D42'
      this.subHeaderTextColor = project?.subHeaderTextColor ? 
      `rgb(${project.subHeaderTextColor.red},${project.subHeaderTextColor.green},${project.subHeaderTextColor.blue},${project.subHeaderTextColor.alpha})` : 'rgb(236,64,64,1)'
      this.textColor = project?.textColor ? 
      `rgb(${project.textColor.red},${project.textColor.green},${project.textColor.blue},${project.textColor.alpha})` : '#2B2D42'
      this.subTextColor = project?.subTextColor ? 
      `rgb(${project.subTextColor.red},${project.subTextColor.green},${project.subTextColor.blue},${project.subTextColor.alpha})` : '#0063DC'
      this.subText2Color = project?.subText2Color ? 
      `rgb(${project.subText2Color.red},${project.subText2Color.green},${project.subText2Color.blue},${project.subText2Color.alpha})` : '#f200bd'
      this.gradient1Color = project?.gradient1Color ? 
      `rgb(${project.gradient1Color.red},${project.gradient1Color.green},${project.gradient1Color.blue},${project.gradient1Color.alpha})` : '#0063DC'
      this.gradient2Color = project?.gradient2Color ? 
      `rgb(${project.gradient2Color.red},${project.gradient2Color.green},${project.gradient2Color.blue},${project.gradient2Color.alpha})` : '#00ADE5'
      this.startUpGradient1Color = project?.startUpGradient1Color ? 
      `rgb(${project.startUpGradient1Color.red},${project.startUpGradient1Color.green},${project.startUpGradient1Color.blue},${project.startUpGradient1Color.alpha})` : '#0a6211'
      this.startUpGradient2Color = project?.startUpGradient2Color ? 
      `rgb(${project.startUpGradient2Color.red},${project.startUpGradient2Color.green},${project.startUpGradient2Color.blue},${project.startUpGradient2Color.alpha})` : '#f2ff00'
      this.pageIndicatorColor = project?.pageIndicatorColor ? 
      `rgb(${project.pageIndicatorColor.red},${project.pageIndicatorColor.green},${project.pageIndicatorColor.blue},${project.pageIndicatorColor.alpha})` : '#f200bd'
      this.currentPageIndicatorColor = project?.currentPageIndicatorColor ? 
      `rgb(${project.currentPageIndicatorColor.red},${project.currentPageIndicatorColor.green},${project.currentPageIndicatorColor.blue},${project.currentPageIndicatorColor.alpha})` : 'rgba(0,255,0,0.5)'
      this.navigationTintColor = project?.navigationTintColor ? 
      `rgb(${project.navigationTintColor.red},${project.navigationTintColor.green},${project.navigationTintColor.blue},${project.navigationTintColor.alpha})` : 'rgb(0,255,255,1)'
      this.navigationTitleColor = project?.navigationTitleColor ? 
      `rgb(${project.navigationTitleColor.red},${project.navigationTitleColor.green},${project.navigationTitleColor.blue},${project.navigationTitleColor.alpha})` : 'rgb(0,255,255,1)'
      this.isLoading = false;
  }

  onSave() {
    this.isSubmit = true;
    let themeDetails = this.updateThemeObj();
    
    let obj = this.publicService.makeFormData(themeDetails);
    this.accountManager.updateClientAppTheme(obj).then(
      resp => {
        this.isSubmit = false;
        this.toastr.success('Your Theme has been updated Successfully', '');
        this.route.navigate([`home/shop-details/${this.projectId}`])
      },
      err => {
        this.isSubmit = false;
        this.toastr.error(err.error.message, '');
        console.log(err)
      },
    );
  }

  updateThemeObj(){
    let themeDetails = {
      data : {
        projectId: this.projectId,
        theme: {
          primaryColor: this.getColorAccordingToFormat(this.primaryColor),
          secondaryColor: this.getColorAccordingToFormat(this.secondaryColor),
          headerTextColor: this.getColorAccordingToFormat(this.headerTextColor),
          subHeaderTextColor: this.getColorAccordingToFormat(this.subHeaderTextColor),
          textColor: this.getColorAccordingToFormat(this.textColor),
          subTextColor: this.getColorAccordingToFormat(this.subTextColor),
          subText2Color: this.getColorAccordingToFormat(this.subText2Color),
          gradient1Color: this.getColorAccordingToFormat(this.gradient1Color),
          gradient2Color: this.getColorAccordingToFormat(this.gradient2Color),
          startUpGradient1Color: this.getColorAccordingToFormat(this.startUpGradient1Color),
          startUpGradient2Color: this.getColorAccordingToFormat(this.startUpGradient2Color),
          pageIndicatorColor: this.getColorAccordingToFormat(this.pageIndicatorColor),
          currentPageIndicatorColor: this.getColorAccordingToFormat(this.currentPageIndicatorColor),
          navigationTintColor: this.getColorAccordingToFormat(this.navigationTintColor),
          navigationTitleColor: this.getColorAccordingToFormat(this.navigationTitleColor),
        }
      }
    };

    return themeDetails;
  }

  selectedTab(type){
    this.tab = type;
    this.dotsNumber = 1;
  }
  
  getGradientColor(color1,color2){
    let styles = {
      'background': `linear-gradient(45deg, ${color1}, ${color2} 100%)`
    }
    return styles;
  }

  slidethescreen(value){
    var screens = document.getElementsByClassName("drag-scroll-content");
    if(this.route.url == '/tools/free-restaurant-app-theme'){
      var screens1 = screens[0] as HTMLElement;
    }
    else{
      var screens1 = screens[1] as HTMLElement;
    }
    if(value == "oneScreen" || value == "fourScreen"){
      screens1.scrollLeft = 0;
    }
    if(value == "twoScreen" || value == "fiveScreen"){
      screens1.scrollLeft = 300;
    }
    if(value == "threeScreen" || value == "sixScreen"){
      screens1.scrollLeft = 600;
    }
  }
}
