<div class="container-fluid cf-xs">
    <div class="header-body" id="header-body">
        <div class="row align-items-center shared-theme py-4">
            <div class="col-lg-12 margin">
                <div>
                    <h2 class="bold-16 my-sm-4 mt-2 mb-4">Customize your App Theme
                    </h2>
                </div>
                <ng-container *ngIf="isLoading">
                    <div class="col-12 font-weight-bold ml-5">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isLoading">
                <div class="row m-0 flex-column-reverse1">
                    <!-- Colors Section Start -->
                    <div class="colors-section col-12 mt-4 mt-sm-4 mt-lg-4s screen-starts col-md-12 col-lg-12 col-xl-6 col-sm-12 px-0">
                        <div class="row color-row">
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Primary Color</div>
                                    <input type="button" class="color-height" [style.background]="primaryColor"
                                        [(colorPicker)]="primaryColor" [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Secondary Color</div>
                                    <input type="button" class="color-height" [style.background]="secondaryColor"
                                        [(colorPicker)]="secondaryColor" [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Header Text Color</div>
                                    <input type="button" class="color-height" [style.background]="headerTextColor"
                                        [(colorPicker)]="headerTextColor" [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Sub Header Text Color</div>
                                    <input type="button" class="color-height" [style.background]="subHeaderTextColor"
                                        [(colorPicker)]="subHeaderTextColor" [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Text Color</div>
                                    <input type="button" class="color-height" [style.background]="textColor"
                                        [(colorPicker)]="textColor" [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Sub Text Color</div>
                                    <input type="button" class="color-height" [style.background]="subTextColor"
                                        [(colorPicker)]="subTextColor" [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Sub Text 2 Color</div>
                                    <input type="button" class="color-height" [style.background]="subText2Color"
                                        [(colorPicker)]="subText2Color" [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Gradient 1 Color</div>
                                    <input type="button" class="color-height" [style.background]="gradient1Color"
                                        [(colorPicker)]="gradient1Color " [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Gradient 2 Color</div>
                                    <input type="button" class="color-height" [style.background]="gradient2Color"
                                        [(colorPicker)]="gradient2Color " [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Start Up Gradient Color</div>
                                    <input type="button" class="color-height" [style.background]="startUpGradient1Color"
                                        [(colorPicker)]="startUpGradient1Color " [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Page Indicator Color</div>
                                    <input type="button" class="color-height" [style.background]="pageIndicatorColor"
                                        [(colorPicker)]="pageIndicatorColor " [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Current Page Indicator Color</div>
                                    <input type="button" class="color-height" [style.background]="currentPageIndicatorColor"
                                        [(colorPicker)]="currentPageIndicatorColor " [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Navigation Title Color</div>
                                    <input type="button" class="color-height" [style.background]="navigationTitleColor"
                                        [(colorPicker)]="navigationTitleColor " [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                            <div class="col-6 px-1 mb-2">
                                <div class="colorBox">
                                    <div class="text-white color-text px-2 mb-2">Navigation Tint Color</div>
                                    <input type="button" class="color-height" [style.background]="navigationTintColor"
                                        [(colorPicker)]="navigationTintColor " [cpPosition]="'bottom'"
                                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="screens-section col-12 col-md-12 col-lg-12 col-xl-6 col-sm-12 px-sm-0 px-0 px-lg-3 px-md-0">
                            <ul class="nav nav-tabs" id="protoTypeID" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active border-0" id="app-store-tab" data-toggle="tab"
                                        (click)="selectedTab('ios')" role="tab" aria-controls="app-store" aria-selected="true">App
                                        Store</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="playstore-tab" data-toggle="tab" (click)="selectedTab('android')"
                                        role="tab" aria-controls="playstore" aria-selected="false">Playstore</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="website-tab" data-toggle="tab" (click)="selectedTab('web')" role="tab"
                                        aria-controls="website" aria-selected="false">Website</a>
                                </li>
                            </ul>
                            <div class="carousel">

                                <ng-container *ngIf="tab == 'ios' || tab == 'android'">
                            <!-- <owl-carousel-o (initialized)="getData($event)" [options]="lastTiles"> -->
<ng-container *ngIf="tab == 'ios'">

<div id="carouselOne" class="wholeCarousel">
    <drag-scroll drag-scroll-y-disabled="true" scrollbar-hidden="true"
      (reachesLeftBound)="leftBoundStat($event)">
        <!-- <div class="d-flex" >
            
        </div> -->
    <div class="threeScreens" drag-scroll-item>
        <div class="onetwothree" id="oneScreen">
            <div class="apple-screen">
                <div class="card bg-transparent border-0">
                    <img src="../../../assets/images/theme/ios.png" class="ios-main-image" alt="iOS phone placeholder">
                    <div class="card-img-overlay paddindMockuoOne">
                       <div class="screen-main-body">
                            <div class="bg-3498DB mt-1 phonebgColor phoneCover" [ngStyle]="getGradientColor(gradient1Color,gradient2Color)"
                            id="phonebgColor">
                            <div class="header-ios">
                                <div class="header-time">
                                    <p>12:45</p>
                                </div>
                                <div class="side-icons">
                                    <svg class="signal" enable-background="new 0 0 128 128" height="128px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="128px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M18.863,86.36h-5.974C7.98,86.36,4,90.326,4,95.218v19.915c0,4.897,3.98,8.86,8.889,8.86h5.974   c4.909,0,8.889-3.963,8.889-8.86V95.218C27.751,90.326,23.771,86.36,18.863,86.36z" fill="#fff"/><path d="M51.299,63.884h-7.034c-4.907,0-8.887,3.966-8.887,8.858v1.405v39.586v1.404   c0,4.896,3.98,8.859,8.887,8.859h7.034c4.909,0,8.889-3.963,8.889-8.859v-1.404V74.147v-1.405   C60.188,67.85,56.208,63.884,51.299,63.884z" fill="#fff"/><path d="M83.206,41.401H76.17c-4.905,0-8.886,3.967-8.886,8.86v2.833v59.213v2.833c0,4.897,3.98,8.86,8.886,8.86   h7.036c4.909,0,8.888-3.963,8.888-8.86v-2.833V53.094v-2.833C92.094,45.369,88.115,41.401,83.206,41.401z" fill="#fff"/><path d="M115.114,18.922h-7.036c-4.906,0-8.886,3.967-8.886,8.86v25.312v36.733v25.313   c0,4.897,3.979,8.86,8.886,8.86h7.036c4.909,0,8.886-3.963,8.886-8.86V89.827V53.094V27.782   C124,22.889,120.023,18.922,115.114,18.922z" fill="#fff"/></g></svg>
                                    <svg class="wifi"  width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-wifi" fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.385 6.115a.485.485 0 0 0-.048-.736A12.443 12.443 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.717 2.164.204.148.489.13.668-.049z" />
                                        <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.472 6.472 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z" />
                                    </svg>
                                    <svg class="battery"  xmlns="http://www.w3.org/2000/svg"width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-battery-full" fill="currentColor">
                                        <path d="M2 6h10v4H2V6z"/>
                                        <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
                                    </svg>
                                </div>
                            </div>
    
                        <div class="d-flex justify-content-between pt-3 header-down">
                            <div class="text-white f-12">
                                <span class="order">Order</span>
                                <span class="ax">Ax</span>
                            </div>
                            <div class="search">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                        </div>
                        <div class="bottom-text">
                            <p>Welcome back,<br>Johnny Bravo.<p>
                        </div>
                    </div>
                    <div class="overflow">
                        <div class="category-part">
                            <div class="category-heading">
                                <span [style.color]="headerTextColor">Category</span>
                            </div>
                            <div class="category-items">
                                <div class="category-box" style="background-color: rgba(255, 60, 79, 0.8);">
                                    <p>Seafoods</p>
                                </div>
                                <div class="category-box" style="background-color:rgba(252,208,0,0.8);">
                                    <p>Pastas</p>
                                </div>
                                <div class="category-box" style="background-color:rgba(31,148,62,1);">
                                    <p>Vegan</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="product-part">
                            <div class="prod-headers">
                                <div class="product-heading">
                                    <span [style.color]="headerTextColor">Products</span>
                                </div>
                                <div class="see-all">
                                    see all
                                </div>
                            </div>
                            <div class="product-section">
                                <div class="product-cards">
                                    <div class="image-prod">
                                        <img src="../../../assets/images/theme/product1.jpeg" alt="Restautant Food image1" 
                                            class="img-fluid">
                                    </div>
                                    <div class="text-prod">
                                        <p [style.color]="textColor" class="product-name">
                                            Rice and Cury
                                        </p>
                                        <div class="img-text">
                                            <div [style.color]="subTextColor" class="price">
                                                $6
                                            </div>
                                             <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                    src="../../../assets/images/order.png"> -->
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-cards">
                                    <div class="image-prod">
                                        <img src="../../../assets/images/theme/product2.jpeg" alt="Restautant Food image1" 
                                            class="img-fluid">
                                    </div>
                                    <div class="text-prod">
                                        <p [style.color]="textColor" class="product-name">
                                            Sushi
                                        </p>
                                        <div class="img-text">
                                            <div [style.color]="subTextColor" class="price">
                                                $6
                                            </div>
                                             <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                    src="../../../assets/images/order.png"> -->
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       </div>
    
                    </div>
                            <div class="position-relative">
                                <div class="grid-item">
                                    <!-- <i>
                                        <img [ngStyle]="getImageSyle(primaryColor,'footer')" class="home-icon" src="../../../assets/images/home_on2.png">
                                    </i> -->
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129">
                                        <g>
                                            <g>
                                            <path d="m121.7,45.1l-.2-.7c0,0-0.1-0.2-0.1-0.3l-12.6-35c-0.6-1.6-2.1-2.7-3.9-2.7h-80.8c-1.7,0-3.3,1.1-3.9,2.7l-12.5,34.8c-0.1,0.2-0.1,0.4-0.2,0.6l-.4,1.2c-0.1,0.3-0.2,0.6-0.2,1-0.4,1.5-0.6,2.8-0.6,4.2 0,7.6 4.8,14.1 11.6,16.5v51.2c0,2.3 1.8,4.1 4.1,4.1h86.7c2.3,0 4.1-1.8 4.1-4.1v-51.9c5.9-2.9 10-8.9 10-15.8 0-1.2-0.2-2.5-0.5-3.8-0.1-0.8-0.3-1.4-0.6-2zm-17.2,69.4h-78.4v-46.2c0.2,0 0.5-0.1 0.7-0.1 4.3-0.7 8-2.9 10.7-6.1 3.2,3.9 8.1,6.3 13.5,6.3 5.4,0 10.3-2.5 13.5-6.3 3.2,3.9 8.1,6.3 13.5,6.3s10.3-2.5 13.5-6.3c2.3,2.8 5.4,4.8 9,5.7 0.1,0 0.2,0.1 0.3,0.1 0.4,0.1 0.8,0.2 1.3,0.3 0.2,0 0.5,0.1 0.7,0.1 0.3,0 0.7,0.1 1,0.1 0.2,0 0.5,0.1 0.7,0.1v46zm3.2-54.6c-0.1,0-0.1,0 0,0-0.5,0.1-0.9,0.2-1.3,0.3-0.4,0.1-0.9,0.1-1.3,0.1-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.1 0-0.2 0-0.2-0.1-0.3-0.1-0.5 0-2.3-1.8-4.1-4.1-4.1-2.3,0-4.1,1.8-4.1,4.1 0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.5-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-0.9,0.3-1.4,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.3,0-0.7-0.1-1-0.1-0.1,0-0.2,0-0.4-0.1-0.1,0-0.2,0-0.3,0-4.4-0.8-7.8-4.6-7.8-9.3 0-0.8 0.1-1.6 0.4-2.6 0-0.1 0.1-0.3 0.1-0.4l12-33.3h75l11.7,32.5c0,0 0.1,0.2 0.1,0.3l.2,.6c0,0.1 0.1,0.3 0.1,0.4 0.3,1 0.4,1.8 0.4,2.6 0,4.2-2.9,7.8-6.8,9z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="onetwothree" id="twoScreen">
            <div>
                <div class="card bg-transparent border-0">
                    <img src="../../../assets/images/theme/ios.png" class="ios-main-image" alt="iOS phone placeholder">
                    <div class="card-img-overlay p-10">
                        <div class="bgImage2">
                            <div class="d-flex justify-content-between phoneCoverTwo mockupInnerpadding pt-1">
                                <div class="header-ios">
                                    <div class="header-time">
                                        <p>09:41</p>
                                    </div>
                                    <div class="side-icons">
                                        <svg class="signal" enable-background="new 0 0 128 128" height="128px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="128px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M18.863,86.36h-5.974C7.98,86.36,4,90.326,4,95.218v19.915c0,4.897,3.98,8.86,8.889,8.86h5.974   c4.909,0,8.889-3.963,8.889-8.86V95.218C27.751,90.326,23.771,86.36,18.863,86.36z" fill="#fff"/><path d="M51.299,63.884h-7.034c-4.907,0-8.887,3.966-8.887,8.858v1.405v39.586v1.404   c0,4.896,3.98,8.859,8.887,8.859h7.034c4.909,0,8.889-3.963,8.889-8.859v-1.404V74.147v-1.405   C60.188,67.85,56.208,63.884,51.299,63.884z" fill="#fff"/><path d="M83.206,41.401H76.17c-4.905,0-8.886,3.967-8.886,8.86v2.833v59.213v2.833c0,4.897,3.98,8.86,8.886,8.86   h7.036c4.909,0,8.888-3.963,8.888-8.86v-2.833V53.094v-2.833C92.094,45.369,88.115,41.401,83.206,41.401z" fill="#fff"/><path d="M115.114,18.922h-7.036c-4.906,0-8.886,3.967-8.886,8.86v25.312v36.733v25.313   c0,4.897,3.979,8.86,8.886,8.86h7.036c4.909,0,8.886-3.963,8.886-8.86V89.827V53.094V27.782   C124,22.889,120.023,18.922,115.114,18.922z" fill="#fff"/></g></svg>
                                        <svg class="wifi"  width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-wifi" fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.385 6.115a.485.485 0 0 0-.048-.736A12.443 12.443 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.717 2.164.204.148.489.13.668-.049z" />
                                            <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.472 6.472 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z" />
                                        </svg>
                                        <svg class="battery"  xmlns="http://www.w3.org/2000/svg"width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-battery-full" fill="currentColor">
                                            <path d="M2 6h10v4H2V6z"/>
                                            <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-between pt-3 mockupInnerpadding px-3">
                                <div>
                                    <div class="text-white f-12 d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 back-button" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                                        </svg>
                                        <span [style.color]="navigationTitleColor" class="pl-1">Back</span>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex align-items-end pb-2">
                                <div class="px-3 mockupInnerpadding h5 text-white font-weight-bolder">

                                </div>
                            </div>
                        </div>
                        <div class="carousel-dummy">
                            <p [style.background-color]="currentPageIndicatorColor"></p>
                            <p [style.background-color]="pageIndicatorColor"></p>
                            <p [style.background-color]="pageIndicatorColor"></p>
                        </div>

                        <div class="back-white">
                            <div [style.color]="textColor" class="px-3 screens-two-text py-1 f-12 font-weight-bold">
                                Rice and Cury</div>
                            <div class="d-flex justify-content-between screens-two-text px-3">
    
                                <div [style.color]="subTextColor" class="Blue colorSetting">
                                    $6</div>
                                <div class="Blue d-flex align-items-center ">
                                    <img src="../../../assets/images/theme/minus_off.png"
                                        class="w-13" alt="Minus Off Icon"
                                        srcset="">
                                    <span
                                    [style.color]="textColor" class="px-2  f-12">2</span><img
                                        src="../../../assets/images/theme/plus_on.png"
                                        class="w-13" alt="Plus On Icon"
                                        srcset="">
                                </div>
                            </div>
    
    
                            <div class="py-1 screens-two-text bg-white">
                                <p class="steam-text">
                                    Steam rice and cury with your choice of protien.</p>
                                <div class="input-fields">
                                    <div class="input-back">
                                        <input type="text"
                                            class="form-control mockupInputHeight mockupText"
                                            id="exampleInputEmail1"
                                            placeholder="Brown rice"
                                            aria-describedby="emailHelp">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 input-arrow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </div>
                                    <div class="input-back mt-2">
                                        <input type="text"
                                            class="form-control mockupInputHeight mockupText"
                                            id="exampleInputEmail1"
                                            placeholder="Beef"
                                            aria-describedby="emailHelp">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 input-arrow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
    
                            <div class="add-to-order">
                                <div>
                                    <button class="mockupInputHeight d-flex align-items-center justify-content-center text-white f-12 addToOrder" [ngStyle]="getGradientColor(gradient1Color,gradient2Color)">
                                        Add to
                                        order&nbsp;&nbsp;
                                        <img src="../../../assets/images/theme/Arrow.png" class="w-24" alt="Arrow Icon" srcset="">
                                    </button>
                                </div>
                            </div>
                            <div class="position-relative">
                                <div class="grid-item">
                                    <!-- <i>
                                        <img [ngStyle]="getImageSyle(primaryColor,'footer')" class="home-icon" src="../../../assets/images/home_on2.png">
                                    </i> -->
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129">
                                        <g>
                                            <g>
                                            <path d="m121.7,45.1l-.2-.7c0,0-0.1-0.2-0.1-0.3l-12.6-35c-0.6-1.6-2.1-2.7-3.9-2.7h-80.8c-1.7,0-3.3,1.1-3.9,2.7l-12.5,34.8c-0.1,0.2-0.1,0.4-0.2,0.6l-.4,1.2c-0.1,0.3-0.2,0.6-0.2,1-0.4,1.5-0.6,2.8-0.6,4.2 0,7.6 4.8,14.1 11.6,16.5v51.2c0,2.3 1.8,4.1 4.1,4.1h86.7c2.3,0 4.1-1.8 4.1-4.1v-51.9c5.9-2.9 10-8.9 10-15.8 0-1.2-0.2-2.5-0.5-3.8-0.1-0.8-0.3-1.4-0.6-2zm-17.2,69.4h-78.4v-46.2c0.2,0 0.5-0.1 0.7-0.1 4.3-0.7 8-2.9 10.7-6.1 3.2,3.9 8.1,6.3 13.5,6.3 5.4,0 10.3-2.5 13.5-6.3 3.2,3.9 8.1,6.3 13.5,6.3s10.3-2.5 13.5-6.3c2.3,2.8 5.4,4.8 9,5.7 0.1,0 0.2,0.1 0.3,0.1 0.4,0.1 0.8,0.2 1.3,0.3 0.2,0 0.5,0.1 0.7,0.1 0.3,0 0.7,0.1 1,0.1 0.2,0 0.5,0.1 0.7,0.1v46zm3.2-54.6c-0.1,0-0.1,0 0,0-0.5,0.1-0.9,0.2-1.3,0.3-0.4,0.1-0.9,0.1-1.3,0.1-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.1 0-0.2 0-0.2-0.1-0.3-0.1-0.5 0-2.3-1.8-4.1-4.1-4.1-2.3,0-4.1,1.8-4.1,4.1 0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.5-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-0.9,0.3-1.4,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.3,0-0.7-0.1-1-0.1-0.1,0-0.2,0-0.4-0.1-0.1,0-0.2,0-0.3,0-4.4-0.8-7.8-4.6-7.8-9.3 0-0.8 0.1-1.6 0.4-2.6 0-0.1 0.1-0.3 0.1-0.4l12-33.3h75l11.7,32.5c0,0 0.1,0.2 0.1,0.3l.2,.6c0,0.1 0.1,0.3 0.1,0.4 0.3,1 0.4,1.8 0.4,2.6 0,4.2-2.9,7.8-6.8,9z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="onetwothree" id="threeScreen">
            <div class="play-store-screens androidTwo screen-three">
                <div class="card bg-transparent border-0">
                    <img src="../../../assets/images/theme/ios.png" class="ios-main-image" alt="iOS phone placeholder">
                    <div class="card-img-overlay p-10">
                        <div>
                            <div class="d-flex justify-content-between phoneCoverTwo mockupInnerpadding pt-1">
                                <div class="header-ios header-ios-three">
                                    <div class="header-time">
                                        <p>09:41</p>
                                    </div>
                                    <div class="side-icons">
                                        <svg class="signal" enable-background="new 0 0 128 128" height="128px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="128px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M18.863,86.36h-5.974C7.98,86.36,4,90.326,4,95.218v19.915c0,4.897,3.98,8.86,8.889,8.86h5.974   c4.909,0,8.889-3.963,8.889-8.86V95.218C27.751,90.326,23.771,86.36,18.863,86.36z" fill="#fff"/><path d="M51.299,63.884h-7.034c-4.907,0-8.887,3.966-8.887,8.858v1.405v39.586v1.404   c0,4.896,3.98,8.859,8.887,8.859h7.034c4.909,0,8.889-3.963,8.889-8.859v-1.404V74.147v-1.405   C60.188,67.85,56.208,63.884,51.299,63.884z" fill="#fff"/><path d="M83.206,41.401H76.17c-4.905,0-8.886,3.967-8.886,8.86v2.833v59.213v2.833c0,4.897,3.98,8.86,8.886,8.86   h7.036c4.909,0,8.888-3.963,8.888-8.86v-2.833V53.094v-2.833C92.094,45.369,88.115,41.401,83.206,41.401z" fill="#fff"/><path d="M115.114,18.922h-7.036c-4.906,0-8.886,3.967-8.886,8.86v25.312v36.733v25.313   c0,4.897,3.979,8.86,8.886,8.86h7.036c4.909,0,8.886-3.963,8.886-8.86V89.827V53.094V27.782   C124,22.889,120.023,18.922,115.114,18.922z" fill="#fff"/></g></svg>
                                        <svg class="wifi"  width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-wifi" fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.385 6.115a.485.485 0 0 0-.048-.736A12.443 12.443 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.717 2.164.204.148.489.13.668-.049z" />
                                            <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.472 6.472 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z" />
                                        </svg>
                                        <svg class="battery"  xmlns="http://www.w3.org/2000/svg"width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-battery-full" fill="currentColor">
                                            <path d="M2 6h10v4H2V6z"/>
                                            <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="product-part">
                            <div class="prod-headers">
                                <div class="f-12 d-flex align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 back-button-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                                    </svg>
                                    <span class="pl-1 three-back">Back</span>
                                </div>
                                <div class="product-heading">
                                    <span [style.color]="headerTextColor">Products</span>
                                </div>
                                <div class="three-search">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div class="product-section product-section-three">
                                <div class="product-cards">
                                    <div class="image-prod">
                                        <img src="../../../assets/images/theme/product1.jpeg" alt="Restautant Food image1" 
                                            class="img-fluid">
                                    </div>
                                    <div class="text-prod">
                                        <p [style.color]="textColor" class="product-name">
                                            Rice and Cury
                                        </p>
                                        <div class="img-text">
                                            <div [style.color]="subTextColor" class="price">
                                                $6
                                            </div>
                                             <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                    src="../../../assets/images/order.png"> -->
                                            <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-cards">
                                    <div class="image-prod">
                                        <img src="../../../assets/images/theme/product2.jpeg" alt="Restautant Food image2" 
                                            class="img-fluid">
                                    </div>
                                    <div class="text-prod">
                                        <p [style.color]="textColor" class="product-name">
                                            Sushi
                                        </p>
                                        <div class="img-text">
                                            <div [style.color]="subTextColor" class="price">
                                                $6
                                            </div>
                                             <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                    src="../../../assets/images/order.png"> -->
                                            <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-cards">
                                    <div class="image-prod">
                                        <img src="../../../assets/images/theme/product1.jpeg" alt="Restautant Food image1" 
                                            class="img-fluid">
                                    </div>
                                    <div class="text-prod">
                                        <p [style.color]="textColor" class="product-name">
                                            Rice and Cury
                                        </p>
                                        <div class="img-text">
                                            <div [style.color]="subTextColor" class="price">
                                                $6
                                            </div>
                                             <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                    src="../../../assets/images/order.png"> -->
                                            <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-cards">
                                    <div class="image-prod">
                                        <img src="../../../assets/images/theme/product2.jpeg" alt="Restautant Food image2" 
                                            class="img-fluid">
                                    </div>
                                    <div class="text-prod">
                                        <p [style.color]="textColor" class="product-name">
                                            Sushi
                                        </p>
                                        <div class="img-text">
                                            <div [style.color]="subTextColor" class="price">
                                                $6
                                            </div>
                                             <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                    src="../../../assets/images/order.png"> -->
                                             <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>

                                        </div>
                                    </div>
                                </div>
                                <div class="product-cards">
                                    <div class="image-prod">
                                        <img src="../../../assets/images/theme/product1.jpeg" alt="Restautant Food image1" 
                                            class="img-fluid">
                                    </div>
                                    <div class="text-prod">
                                        <p [style.color]="textColor" class="product-name">
                                            Rice and Cury
                                        </p>
                                        <div class="img-text">
                                            <div [style.color]="subTextColor" class="price">
                                                $6
                                            </div>
                                             <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                    src="../../../assets/images/order.png"> -->
                                             <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>

                                        </div>
                                    </div>
                                </div>
                                <div class="product-cards">
                                    <div class="image-prod">
                                        <img src="../../../assets/images/theme/product2.jpeg" alt="Restautant Food image2" 
                                            class="img-fluid">
                                    </div>
                                    <div class="text-prod">
                                        <p [style.color]="textColor" class="product-name">
                                            Sushi
                                        </p>
                                        <div class="img-text">
                                            <div [style.color]="subTextColor" class="price">
                                                $6
                                            </div>
                                             <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                    src="../../../assets/images/order.png"> -->
                                        <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>

                                        </div>
                                    </div>
                                </div>
                                <div class="product-cards">
                                    <div class="image-prod">
                                        <img src="../../../assets/images/theme/product1.jpeg" alt="Restautant Food image1" 
                                            class="img-fluid">
                                    </div>
                                    <div class="text-prod">
                                        <p [style.color]="textColor" class="product-name">
                                            Rice and Cury
                                        </p>
                                        <div class="img-text">
                                            <div [style.color]="subTextColor" class="price">
                                                $6
                                            </div>
                                             <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                    src="../../../assets/images/order.png"> -->
                                            <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>

                                        </div>
                                    </div>
                                </div>
                                <div class="product-cards">
                                    <div class="image-prod">
                                        <img src="../../../assets/images/theme/product2.jpeg" alt="Restautant Food image2" 
                                            class="img-fluid">
                                    </div>
                                    <div class="text-prod">
                                        <p [style.color]="textColor" class="product-name">
                                            Sushi
                                        </p>
                                        <div class="img-text">
                                            <div [style.color]="subTextColor" class="price">
                                                $6
                                            </div>
                                             <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                    src="../../../assets/images/order.png"> -->
                                            <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        


                        

                        <div class="position-relative">
                            <div class="icons-back d-flex">
                                <div class="grid-item">
                                    <!-- <i>
                                        <img [ngStyle]="getImageSyle(primaryColor,'footer')" class="home-icon" src="../../../assets/images/home_on2.png">
                                    </i> -->
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129">
                                        <g>
                                            <g>
                                            <path d="m121.7,45.1l-.2-.7c0,0-0.1-0.2-0.1-0.3l-12.6-35c-0.6-1.6-2.1-2.7-3.9-2.7h-80.8c-1.7,0-3.3,1.1-3.9,2.7l-12.5,34.8c-0.1,0.2-0.1,0.4-0.2,0.6l-.4,1.2c-0.1,0.3-0.2,0.6-0.2,1-0.4,1.5-0.6,2.8-0.6,4.2 0,7.6 4.8,14.1 11.6,16.5v51.2c0,2.3 1.8,4.1 4.1,4.1h86.7c2.3,0 4.1-1.8 4.1-4.1v-51.9c5.9-2.9 10-8.9 10-15.8 0-1.2-0.2-2.5-0.5-3.8-0.1-0.8-0.3-1.4-0.6-2zm-17.2,69.4h-78.4v-46.2c0.2,0 0.5-0.1 0.7-0.1 4.3-0.7 8-2.9 10.7-6.1 3.2,3.9 8.1,6.3 13.5,6.3 5.4,0 10.3-2.5 13.5-6.3 3.2,3.9 8.1,6.3 13.5,6.3s10.3-2.5 13.5-6.3c2.3,2.8 5.4,4.8 9,5.7 0.1,0 0.2,0.1 0.3,0.1 0.4,0.1 0.8,0.2 1.3,0.3 0.2,0 0.5,0.1 0.7,0.1 0.3,0 0.7,0.1 1,0.1 0.2,0 0.5,0.1 0.7,0.1v46zm3.2-54.6c-0.1,0-0.1,0 0,0-0.5,0.1-0.9,0.2-1.3,0.3-0.4,0.1-0.9,0.1-1.3,0.1-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.1 0-0.2 0-0.2-0.1-0.3-0.1-0.5 0-2.3-1.8-4.1-4.1-4.1-2.3,0-4.1,1.8-4.1,4.1 0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.5-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-0.9,0.3-1.4,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.3,0-0.7-0.1-1-0.1-0.1,0-0.2,0-0.4-0.1-0.1,0-0.2,0-0.3,0-4.4-0.8-7.8-4.6-7.8-9.3 0-0.8 0.1-1.6 0.4-2.6 0-0.1 0.1-0.3 0.1-0.4l12-33.3h75l11.7,32.5c0,0 0.1,0.2 0.1,0.3l.2,.6c0,0.1 0.1,0.3 0.1,0.4 0.3,1 0.4,1.8 0.4,2.6 0,4.2-2.9,7.8-6.8,9z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</drag-scroll>

    <div class="indicators">
        <p class="indCircles" [ngClass]="{'add-active' : dotsNumber == 1}" (click)="slidethescreen('oneScreen')"></p>
        <p class="indCircles" [ngClass]="{'add-active' : dotsNumber == 2}" (click)="slidethescreen('twoScreen')"></p>
        <p class="indCircles" [ngClass]="{'add-active' : dotsNumber == 3}" (click)="slidethescreen('threeScreen')"></p>
    </div>
    
   </div>

 </ng-container>   




<ng-container *ngIf="tab == 'android'">

   
        <div id="carouselOne" class="wholeCarousel">
        <drag-scroll drag-scroll-y-disabled="true" scrollbar-hidden="true"
        (reachesLeftBound)="leftBoundStat($event)">
            <div class="threeScreens" drag-scroll-item>

                <div class="onetwothree" id="fourScreen">
                    <div class="play-store-screens">
                        <div class="card bg-transparent border-0">
                            <img src="./assets/images/android.png" class="android-main-image" alt="Android phone placeholder">
                            <div class="card-img-overlay paddindMockuoOne">
                <div class="screen-main-body">
                        <div class="bg-3498DB mt-1 phonebgColor androidCover" [ngStyle]="getGradientColor(gradient1Color,gradient2Color)"
                        id="phonebgColor">
                        <div class="header-android">
                            <div class="side-icons">
                                <svg class="wifi"  width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-wifi" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.385 6.115a.485.485 0 0 0-.048-.736A12.443 12.443 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.717 2.164.204.148.489.13.668-.049z" />
                                    <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.472 6.472 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z" />
                                </svg>
                                <svg class="signal" enable-background="new 0 0 128 128" height="128px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="128px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M18.863,86.36h-5.974C7.98,86.36,4,90.326,4,95.218v19.915c0,4.897,3.98,8.86,8.889,8.86h5.974   c4.909,0,8.889-3.963,8.889-8.86V95.218C27.751,90.326,23.771,86.36,18.863,86.36z" fill="#fff"/><path d="M51.299,63.884h-7.034c-4.907,0-8.887,3.966-8.887,8.858v1.405v39.586v1.404   c0,4.896,3.98,8.859,8.887,8.859h7.034c4.909,0,8.889-3.963,8.889-8.859v-1.404V74.147v-1.405   C60.188,67.85,56.208,63.884,51.299,63.884z" fill="#fff"/><path d="M83.206,41.401H76.17c-4.905,0-8.886,3.967-8.886,8.86v2.833v59.213v2.833c0,4.897,3.98,8.86,8.886,8.86   h7.036c4.909,0,8.888-3.963,8.888-8.86v-2.833V53.094v-2.833C92.094,45.369,88.115,41.401,83.206,41.401z" fill="#fff"/><path d="M115.114,18.922h-7.036c-4.906,0-8.886,3.967-8.886,8.86v25.312v36.733v25.313   c0,4.897,3.979,8.86,8.886,8.86h7.036c4.909,0,8.886-3.963,8.886-8.86V89.827V53.094V27.782   C124,22.889,120.023,18.922,115.114,18.922z" fill="#fff"/></g></svg>
                                <svg class="battery"  xmlns="http://www.w3.org/2000/svg"width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-battery-full" fill="currentColor">
                                    <path d="M2 6h10v4H2V6z"/>
                                    <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
                                </svg>
                            </div>
                            <div class="header-time">
                                <p>12:45</p>
                            </div>
                        </div>
    
                    <div class="d-flex justify-content-between pt-3 header-down">
                        <div class="text-white f-12">
                            <span class="order">Order</span>
                            <span class="ax">Ax</span>
                        </div>
                        <div class="search">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>
                    </div>
                    <div class="bottom-text">
                        <p>Welcome back,<br>Johnny Bravo.<p>
                    </div>
                </div>
                <div class="overflow">
                    <div class="category-part">
                        <div class="category-heading">
                            <span [style.color]="headerTextColor">Category</span>
                        </div>
                        <div class="category-items">
                            <div class="category-box" style="background-color: rgba(255, 60, 79, 0.8);">
                                <p>Seafoods</p>
                            </div>
                            <div class="category-box" style="background-color:rgba(252,208,0,0.8);">
                                <p>Pastas</p>
                            </div>
                            <div class="category-box" style="background-color:rgba(31,148,62,1);">
                                <p>Vegan</p>
                            </div>
                        </div>  
                    </div>
                </div>
                <div>
                    <div class="product-part">
                        <div class="prod-headers">
                            <div class="product-heading">
                                <span [style.color]="headerTextColor">Products</span>
                            </div>
                            <div class="see-all">
                                see all
                            </div>
                        </div>
                        <div class="product-section">
                            <div class="product-cards">
                                <div class="image-prod">
                                    <img src="../../../assets/images/theme/product1.jpeg" alt="Restautant Food image1" 
                                        class="img-fluid">
                                </div>
                                <div class="text-prod">
                                    <p [style.color]="textColor" class="product-name">
                                        Rice and Cury
                                    </p>
                                    <div class="img-text">
                                        <div [style.color]="subTextColor" class="price">
                                            $6
                                        </div>
                                        <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                src="../../../assets/images/order.png"> -->
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                            </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="product-cards">
                                <div class="image-prod">
                                    <img src="../../../assets/images/theme/product2.jpeg" alt="Restautant Food image2" 
                                        class="img-fluid">
                                </div>
                                <div class="text-prod">
                                    <p [style.color]="textColor" class="product-name">
                                        Sushi
                                    </p>
                                    <div class="img-text">
                                        <div [style.color]="subTextColor" class="price">
                                            $6
                                        </div>
                                        <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                src="../../../assets/images/order.png"> -->
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                            </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                </div>
                        <div class="position-relative">
                            <div class="icons-back d-flex">
                                <div class="grid-item">
                                    <!-- <i>
                                        <img [ngStyle]="getImageSyle(primaryColor,'footer')" class="home-icon" src="../../../assets/images/home_on2.png">
                                    </i> -->
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129">
                                        <g>
                                            <g>
                                            <path d="m121.7,45.1l-.2-.7c0,0-0.1-0.2-0.1-0.3l-12.6-35c-0.6-1.6-2.1-2.7-3.9-2.7h-80.8c-1.7,0-3.3,1.1-3.9,2.7l-12.5,34.8c-0.1,0.2-0.1,0.4-0.2,0.6l-.4,1.2c-0.1,0.3-0.2,0.6-0.2,1-0.4,1.5-0.6,2.8-0.6,4.2 0,7.6 4.8,14.1 11.6,16.5v51.2c0,2.3 1.8,4.1 4.1,4.1h86.7c2.3,0 4.1-1.8 4.1-4.1v-51.9c5.9-2.9 10-8.9 10-15.8 0-1.2-0.2-2.5-0.5-3.8-0.1-0.8-0.3-1.4-0.6-2zm-17.2,69.4h-78.4v-46.2c0.2,0 0.5-0.1 0.7-0.1 4.3-0.7 8-2.9 10.7-6.1 3.2,3.9 8.1,6.3 13.5,6.3 5.4,0 10.3-2.5 13.5-6.3 3.2,3.9 8.1,6.3 13.5,6.3s10.3-2.5 13.5-6.3c2.3,2.8 5.4,4.8 9,5.7 0.1,0 0.2,0.1 0.3,0.1 0.4,0.1 0.8,0.2 1.3,0.3 0.2,0 0.5,0.1 0.7,0.1 0.3,0 0.7,0.1 1,0.1 0.2,0 0.5,0.1 0.7,0.1v46zm3.2-54.6c-0.1,0-0.1,0 0,0-0.5,0.1-0.9,0.2-1.3,0.3-0.4,0.1-0.9,0.1-1.3,0.1-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.1 0-0.2 0-0.2-0.1-0.3-0.1-0.5 0-2.3-1.8-4.1-4.1-4.1-2.3,0-4.1,1.8-4.1,4.1 0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.5-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-0.9,0.3-1.4,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.3,0-0.7-0.1-1-0.1-0.1,0-0.2,0-0.4-0.1-0.1,0-0.2,0-0.3,0-4.4-0.8-7.8-4.6-7.8-9.3 0-0.8 0.1-1.6 0.4-2.6 0-0.1 0.1-0.3 0.1-0.4l12-33.3h75l11.7,32.5c0,0 0.1,0.2 0.1,0.3l.2,.6c0,0.1 0.1,0.3 0.1,0.4 0.3,1 0.4,1.8 0.4,2.6 0,4.2-2.9,7.8-6.8,9z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </div>
                                <div class="grid-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
    
                <div class="onetwothree" id="fiveScreen">
                    <div class="play-store-screens androidTwo">
                        <div class="card bg-transparent border-0">
                            <img src="./assets/images/android.png" class="android-main-image" alt="Android phone placeholder">
                            <div class="card-img-overlay p-10">
                                <div class="bgImage2">
                                    <div>
                                        <div class="header-android">
                                            <div class="side-icons">
                                                <svg class="wifi"  width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-wifi" fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.385 6.115a.485.485 0 0 0-.048-.736A12.443 12.443 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.717 2.164.204.148.489.13.668-.049z" />
                                                    <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.472 6.472 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z" />
                                                </svg>
                                                <svg class="signal" enable-background="new 0 0 128 128" height="128px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="128px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M18.863,86.36h-5.974C7.98,86.36,4,90.326,4,95.218v19.915c0,4.897,3.98,8.86,8.889,8.86h5.974   c4.909,0,8.889-3.963,8.889-8.86V95.218C27.751,90.326,23.771,86.36,18.863,86.36z" fill="#fff"/><path d="M51.299,63.884h-7.034c-4.907,0-8.887,3.966-8.887,8.858v1.405v39.586v1.404   c0,4.896,3.98,8.859,8.887,8.859h7.034c4.909,0,8.889-3.963,8.889-8.859v-1.404V74.147v-1.405   C60.188,67.85,56.208,63.884,51.299,63.884z" fill="#fff"/><path d="M83.206,41.401H76.17c-4.905,0-8.886,3.967-8.886,8.86v2.833v59.213v2.833c0,4.897,3.98,8.86,8.886,8.86   h7.036c4.909,0,8.888-3.963,8.888-8.86v-2.833V53.094v-2.833C92.094,45.369,88.115,41.401,83.206,41.401z" fill="#fff"/><path d="M115.114,18.922h-7.036c-4.906,0-8.886,3.967-8.886,8.86v25.312v36.733v25.313   c0,4.897,3.979,8.86,8.886,8.86h7.036c4.909,0,8.886-3.963,8.886-8.86V89.827V53.094V27.782   C124,22.889,120.023,18.922,115.114,18.922z" fill="#fff"/></g></svg>
                                                <svg class="battery"  xmlns="http://www.w3.org/2000/svg"width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-battery-full" fill="currentColor">
                                                    <path d="M2 6h10v4H2V6z"/>
                                                    <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
                                                </svg>
                                            </div>
                                            <div class="header-time">
                                                <p>12:45</p>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="d-flex justify-content-between pt-3 mockupInnerpadding px-3">
                                        <div>
                                            <div class="text-white f-12 d-flex align-items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 back-button" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                                                </svg>
                                                <span [style.color]="navigationTitleColor" class="pl-1">Back</span>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="d-flex align-items-end pb-2">
                                        <div class="px-3 mockupInnerpadding h5 text-white font-weight-bolder">
    
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-dummy">
                                    <p [style.background-color]="currentPageIndicatorColor"></p>
                                    <p [style.background-color]="pageIndicatorColor"></p>
                                    <p [style.background-color]="pageIndicatorColor"></p>
                                </div>
    
                                <div [style.color]="textColor" class="px-3 screens-two-text py-1 f-12 font-weight-bold">
                                    Rice and Cury</div>
                                <div class="d-flex justify-content-between screens-two-text px-3">
    
                                    <div [style.color]="subTextColor" class="Blue colorSetting">
                                        $6</div>
                                    <div class="Blue d-flex align-items-center ">
                                        <img src="../../../assets/images/theme/minus_off.png"
                                            class="w-13" alt="Minus Off Icon"
                                            srcset="">
                                        <span
                                        [style.color]="textColor" class="px-2  f-12">2</span><img
                                            src="../../../assets/images/theme/plus_on.png"
                                            class="w-13" alt="Plus On Icon"
                                            srcset="">
                                    </div>
                                </div>
    
    
                                <div class="py-1 screens-two-text bg-white">
                                    <p class="steam-text">
                                        Steam rice and cury with your choice of protien.</p>
                                    <div class="input-fields">
                                        <div class="input-back">
                                            <input type="text"
                                                class="form-control mockupInputHeight mockupText"
                                                id="exampleInputEmail1"
                                                placeholder="Brown rice"
                                                aria-describedby="emailHelp">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 input-arrow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </div>
                                        <div class="input-back mt-2">
                                            <input type="text"
                                                class="form-control mockupInputHeight mockupText"
                                                id="exampleInputEmail1"
                                                placeholder="Beef"
                                                aria-describedby="emailHelp">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 input-arrow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="add-to-order">
                                    <div><button
                                            class="mockupInputHeight d-flex align-items-center justify-content-center text-white f-12 addToOrder" [ngStyle]="getGradientColor(gradient1Color,gradient2Color)">
                                            Add to
                                            order&nbsp;&nbsp;
                                            <img src="../../../assets/images/theme/Arrow.png"
                                                class="w-24"
                                                alt="Arrow Icon"
                                                srcset="">
                                        </button></div>
                                </div>
                                <div class="position-relative">
                                    <div class="grid-item">
                                        <!-- <i>
                                            <img [ngStyle]="getImageSyle(primaryColor,'footer')" class="home-icon" src="../../../assets/images/home_on2.png">
                                        </i> -->
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129">
                                            <g>
                                                <g>
                                                <path d="m121.7,45.1l-.2-.7c0,0-0.1-0.2-0.1-0.3l-12.6-35c-0.6-1.6-2.1-2.7-3.9-2.7h-80.8c-1.7,0-3.3,1.1-3.9,2.7l-12.5,34.8c-0.1,0.2-0.1,0.4-0.2,0.6l-.4,1.2c-0.1,0.3-0.2,0.6-0.2,1-0.4,1.5-0.6,2.8-0.6,4.2 0,7.6 4.8,14.1 11.6,16.5v51.2c0,2.3 1.8,4.1 4.1,4.1h86.7c2.3,0 4.1-1.8 4.1-4.1v-51.9c5.9-2.9 10-8.9 10-15.8 0-1.2-0.2-2.5-0.5-3.8-0.1-0.8-0.3-1.4-0.6-2zm-17.2,69.4h-78.4v-46.2c0.2,0 0.5-0.1 0.7-0.1 4.3-0.7 8-2.9 10.7-6.1 3.2,3.9 8.1,6.3 13.5,6.3 5.4,0 10.3-2.5 13.5-6.3 3.2,3.9 8.1,6.3 13.5,6.3s10.3-2.5 13.5-6.3c2.3,2.8 5.4,4.8 9,5.7 0.1,0 0.2,0.1 0.3,0.1 0.4,0.1 0.8,0.2 1.3,0.3 0.2,0 0.5,0.1 0.7,0.1 0.3,0 0.7,0.1 1,0.1 0.2,0 0.5,0.1 0.7,0.1v46zm3.2-54.6c-0.1,0-0.1,0 0,0-0.5,0.1-0.9,0.2-1.3,0.3-0.4,0.1-0.9,0.1-1.3,0.1-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.1 0-0.2 0-0.2-0.1-0.3-0.1-0.5 0-2.3-1.8-4.1-4.1-4.1-2.3,0-4.1,1.8-4.1,4.1 0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.5-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-0.9,0.3-1.4,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.3,0-0.7-0.1-1-0.1-0.1,0-0.2,0-0.4-0.1-0.1,0-0.2,0-0.3,0-4.4-0.8-7.8-4.6-7.8-9.3 0-0.8 0.1-1.6 0.4-2.6 0-0.1 0.1-0.3 0.1-0.4l12-33.3h75l11.7,32.5c0,0 0.1,0.2 0.1,0.3l.2,.6c0,0.1 0.1,0.3 0.1,0.4 0.3,1 0.4,1.8 0.4,2.6 0,4.2-2.9,7.8-6.8,9z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="grid-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                    </div>
                                    <div class="grid-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                        </svg>
                                    </div>
                                    <div class="grid-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                            </svg>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                </div>
    
                <div class="onetwothree" id="sixScreen">
                    <div class="play-store-screens androidTwo screen-three android-three">
                        <div class="card bg-transparent border-0">
                            <img src="./assets/images/android.png" class="android-main-image" alt="Android phone placeholder">
                            <div class="card-img-overlay p-10">
                                <div>
                                    <div class="d-flex justify-content-between phoneCoverTwo mockupInnerpadding pt-1">
                                        <div class="header-android header-ios-three">
                                            <div class="side-icons">
                                                <svg class="wifi"  width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-wifi" fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.385 6.115a.485.485 0 0 0-.048-.736A12.443 12.443 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.717 2.164.204.148.489.13.668-.049z" />
                                                    <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.472 6.472 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z" />
                                                </svg>
                                                <svg class="signal" enable-background="new 0 0 128 128" height="128px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="128px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M18.863,86.36h-5.974C7.98,86.36,4,90.326,4,95.218v19.915c0,4.897,3.98,8.86,8.889,8.86h5.974   c4.909,0,8.889-3.963,8.889-8.86V95.218C27.751,90.326,23.771,86.36,18.863,86.36z" fill="#fff"/><path d="M51.299,63.884h-7.034c-4.907,0-8.887,3.966-8.887,8.858v1.405v39.586v1.404   c0,4.896,3.98,8.859,8.887,8.859h7.034c4.909,0,8.889-3.963,8.889-8.859v-1.404V74.147v-1.405   C60.188,67.85,56.208,63.884,51.299,63.884z" fill="#fff"/><path d="M83.206,41.401H76.17c-4.905,0-8.886,3.967-8.886,8.86v2.833v59.213v2.833c0,4.897,3.98,8.86,8.886,8.86   h7.036c4.909,0,8.888-3.963,8.888-8.86v-2.833V53.094v-2.833C92.094,45.369,88.115,41.401,83.206,41.401z" fill="#fff"/><path d="M115.114,18.922h-7.036c-4.906,0-8.886,3.967-8.886,8.86v25.312v36.733v25.313   c0,4.897,3.979,8.86,8.886,8.86h7.036c4.909,0,8.886-3.963,8.886-8.86V89.827V53.094V27.782   C124,22.889,120.023,18.922,115.114,18.922z" fill="#fff"/></g></svg>
                                                <svg class="battery"  xmlns="http://www.w3.org/2000/svg"width="1em" height="1em" viewBox="0 0 16 16" [ngClass]="tab == 'ios' ? 'header-font-ios': ''" class="bi bi-battery-full" fill="currentColor">
                                                    <path d="M2 6h10v4H2V6z"/>
                                                    <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
                                                </svg>
                                            </div>
                                            <div class="header-time">
                                                <p>12:45</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
    
    
                                <div class="product-part">
                                    <div class="prod-headers">
                                        <div class="f-12 d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 back-button-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                                            </svg>
                                            <span class="pl-1 three-back">Back</span>
                                        </div>
                                        <div class="product-heading">
                                            <span [style.color]="headerTextColor">Products</span>
                                        </div>
                                        <div class="three-search">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="product-section product-section-three">
                                        <div class="product-cards">
                                            <div class="image-prod">
                                                <img src="../../../assets/images/theme/product1.jpeg" alt="Restautant Food image1" 
                                                    class="img-fluid">
                                            </div>
                                            <div class="text-prod">
                                                <p [style.color]="textColor" class="product-name">
                                                    Rice and Cury
                                                </p>
                                                <div class="img-text">
                                                    <div [style.color]="subTextColor" class="price">
                                                        $6
                                                    </div>
                                                    <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                            src="../../../assets/images/order.png"> -->
                                                    <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-cards">
                                            <div class="image-prod">
                                                <img src="../../../assets/images/theme/product2.jpeg" alt="Restautant Food image2" 
                                                    class="img-fluid">
                                            </div>
                                            <div class="text-prod">
                                                <p [style.color]="textColor" class="product-name">
                                                    Sushi
                                                </p>
                                                <div class="img-text">
                                                    <div [style.color]="subTextColor" class="price">
                                                        $6
                                                    </div>
                                                    <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                            src="../../../assets/images/order.png"> -->
                                                    <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-cards">
                                            <div class="image-prod">
                                                <img src="../../../assets/images/theme/product1.jpeg" alt="Restautant Food image1" 
                                                    class="img-fluid">
                                            </div>
                                            <div class="text-prod">
                                                <p [style.color]="textColor" class="product-name">
                                                    Rice and Cury
                                                </p>
                                                <div class="img-text">
                                                    <div [style.color]="subTextColor" class="price">
                                                        $6
                                                    </div>
                                                    <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                            src="../../../assets/images/order.png"> -->
                                                    <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-cards">
                                            <div class="image-prod">
                                                <img src="../../../assets/images/theme/product2.jpeg" alt="Restautant Food image2" 
                                                    class="img-fluid">
                                            </div>
                                            <div class="text-prod">
                                                <p [style.color]="textColor" class="product-name">
                                                    Sushi
                                                </p>
                                                <div class="img-text">
                                                    <div [style.color]="subTextColor" class="price">
                                                        $6
                                                    </div>
                                                    <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                            src="../../../assets/images/order.png"> -->
                                                    <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-cards">
                                            <div class="image-prod">
                                                <img src="../../../assets/images/theme/product1.jpeg" alt="Restautant Food image1" 
                                                    class="img-fluid">
                                            </div>
                                            <div class="text-prod">
                                                <p [style.color]="textColor" class="product-name">
                                                    Rice and Cury
                                                </p>
                                                <div class="img-text">
                                                    <div [style.color]="subTextColor" class="price">
                                                        $6
                                                    </div>
                                                    <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                            src="../../../assets/images/order.png"> -->
                                                <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-cards">
                                            <div class="image-prod">
                                                <img src="../../../assets/images/theme/product2.jpeg" alt="Restautant Food image2" 
                                                    class="img-fluid">
                                            </div>
                                            <div class="text-prod">
                                                <p [style.color]="textColor" class="product-name">
                                                    Sushi
                                                </p>
                                                <div class="img-text">
                                                    <div [style.color]="subTextColor" class="price">
                                                        $6
                                                    </div>
                                                    <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                            src="../../../assets/images/order.png"> -->
                                                    <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-cards">
                                            <div class="image-prod">
                                                <img src="../../../assets/images/theme/product1.jpeg" alt="Restautant Food image1" 
                                                    class="img-fluid">
                                            </div>
                                            <div class="text-prod">
                                                <p [style.color]="textColor" class="product-name">
                                                    Rice and Cury
                                                </p>
                                                <div class="img-text">
                                                    <div [style.color]="subTextColor" class="price">
                                                        $6
                                                    </div>
                                                    <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                            src="../../../assets/images/order.png"> -->
                                                    <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-cards">
                                            <div class="image-prod">
                                                <img src="../../../assets/images/theme/product2.jpeg" alt="Restautant Food image2" 
                                                    class="img-fluid">
                                            </div>
                                            <div class="text-prod">
                                                <p [style.color]="textColor" class="product-name">
                                                    Sushi
                                                </p>
                                                <div class="img-text">
                                                    <div [style.color]="subTextColor" class="price">
                                                        $6
                                                    </div>
                                                    <!-- <img [ngStyle]="getImageSyle(primaryColor,'cart')" class="order-icon"
                                                            src="../../../assets/images/order.png"> -->
                                                <svg class="shopping-bag" enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                
    
    
                                
    
                                <div class="position-relative">
                                    <div class="icons-back d-flex">
                                        <div class="grid-item">
                                            <!-- <i>
                                                <img [ngStyle]="getImageSyle(primaryColor,'footer')" class="home-icon" src="../../../assets/images/home_on2.png">
                                            </i> -->
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129">
                                                <g>
                                                    <g>
                                                    <path d="m121.7,45.1l-.2-.7c0,0-0.1-0.2-0.1-0.3l-12.6-35c-0.6-1.6-2.1-2.7-3.9-2.7h-80.8c-1.7,0-3.3,1.1-3.9,2.7l-12.5,34.8c-0.1,0.2-0.1,0.4-0.2,0.6l-.4,1.2c-0.1,0.3-0.2,0.6-0.2,1-0.4,1.5-0.6,2.8-0.6,4.2 0,7.6 4.8,14.1 11.6,16.5v51.2c0,2.3 1.8,4.1 4.1,4.1h86.7c2.3,0 4.1-1.8 4.1-4.1v-51.9c5.9-2.9 10-8.9 10-15.8 0-1.2-0.2-2.5-0.5-3.8-0.1-0.8-0.3-1.4-0.6-2zm-17.2,69.4h-78.4v-46.2c0.2,0 0.5-0.1 0.7-0.1 4.3-0.7 8-2.9 10.7-6.1 3.2,3.9 8.1,6.3 13.5,6.3 5.4,0 10.3-2.5 13.5-6.3 3.2,3.9 8.1,6.3 13.5,6.3s10.3-2.5 13.5-6.3c2.3,2.8 5.4,4.8 9,5.7 0.1,0 0.2,0.1 0.3,0.1 0.4,0.1 0.8,0.2 1.3,0.3 0.2,0 0.5,0.1 0.7,0.1 0.3,0 0.7,0.1 1,0.1 0.2,0 0.5,0.1 0.7,0.1v46zm3.2-54.6c-0.1,0-0.1,0 0,0-0.5,0.1-0.9,0.2-1.3,0.3-0.4,0.1-0.9,0.1-1.3,0.1-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.4-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.1 0-0.2 0-0.2-0.1-0.3-0.1-0.5 0-2.3-1.8-4.1-4.1-4.1-2.3,0-4.1,1.8-4.1,4.1 0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-1,0.3-1.5,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.6,0-1.2-0.1-1.8-0.2-0.5-0.1-1-0.3-1.5-0.4-0.1,0-0.1,0-0.2-0.1-3.2-1.3-5.6-4.4-5.9-8 0-0.1 0-0.2 0-0.2 0-0.2 0-0.3 0-0.5 0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,0.2 0,0.4-0.1,0.6 0,0 0,0.1 0,0.1-0.3,3.7-2.7,6.7-5.9,8-0.1,0-0.1,0-0.2,0.1-0.5,0.2-0.9,0.3-1.4,0.4-0.6,0.1-1.2,0.2-1.8,0.2-0.3,0-0.7-0.1-1-0.1-0.1,0-0.2,0-0.4-0.1-0.1,0-0.2,0-0.3,0-4.4-0.8-7.8-4.6-7.8-9.3 0-0.8 0.1-1.6 0.4-2.6 0-0.1 0.1-0.3 0.1-0.4l12-33.3h75l11.7,32.5c0,0 0.1,0.2 0.1,0.3l.2,.6c0,0.1 0.1,0.3 0.1,0.4 0.3,1 0.4,1.8 0.4,2.6 0,4.2-2.9,7.8-6.8,9z"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="grid-item">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                        </div>
                                        <div class="grid-item">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                            </svg>
                                        </div>
                                        <div class="grid-item">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                                </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                </div>
            </div>
        </drag-scroll>


            <div class="indicators">
                <p class="indCircles" [ngClass]="{'add-active' : dotsNumber == 1}" (click)="slidethescreen('fourScreen')"></p>
                <p class="indCircles" [ngClass]="{'add-active' : dotsNumber == 2}" (click)="slidethescreen('fiveScreen')"></p>
                <p class="indCircles" [ngClass]="{'add-active' : dotsNumber == 3}" (click)="slidethescreen('sixScreen')"></p>
            </div>
        </div>
    

</ng-container>                          
<!-- </owl-carousel-o> -->
</ng-container>
                            <ng-container *ngIf="tab == 'web'">
                            <div class="tab-pane" id="website" role="tabpanel" aria-labelledby="website-tab">
                                <div class="text-primary h3 m-0 pt-5 text-center border-0 font-weight-bold createShopBorder">Coming Soon</div>
                                <div class="pt-1  text-center">Please check back for this feature</div>
                            </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
</div>

