import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';

//declare var gtag: Function;
// export const gtag = (window as any).gtag;
@Injectable({
    providedIn: 'root',
  })
export class AnalyticsService {
  private window;  
  constructor(private _windowRef: WindowReferenceService) {
    this.window = _windowRef.nativeWindow; 
  }
    

   /*  public googleTag(event){
        gtag('event', event, event);
         gtag('event', 'conversion', {
          'send_to': 'UA-164227328-1/RYxnCP-tzP4BEI2mzMEB',
          'event_callback': event
        });
    } */

    public googleTag(event)
    {
        const hit = {
            event:event,
            category:'btn-clicked',
            action:event,
            label: 'clicked'
        }
         this.pushEvent(hit);
    }
    private pushEvent(obj)
    {
        if(obj)  this.window.dataLayer.push(obj);
    }
}