<div class="container-fluid cf-xs app-store" #myNameElem3>
    <div class="header-body" id="header-body">
        <div class="row align-items-center py-4">
            <div class="col-lg-12">
                <div class="header-title pb-sm-4 pb-0">
                    <h6 class="h2 d-inline-block mb-0 c-tag-1 bold-26">
                        <span *ngIf="!isEdit">
                            <svg *ngIf="!arrow" [ngClass]="{'displayNone' : fromLink}" (click)="goBack()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"/>
                            </svg>Add New Store
                        </span>
                        <span *ngIf="isEdit">
                            <svg *ngIf="!arrow" [ngClass]="{'displayNone' : fromLink}" (click)="goBack()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"/>
                            </svg>Edit Store
                        </span>
                    </h6>
                </div>
                <div *ngIf="isLoading" class="col-12 font-weight-bold ml-2 mt-5">
                    <div class="circle-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div *ngIf="!isLoading" class="row mt-2">
                    <div class="col-md-12">
                        <form [formGroup]="storeForm">
                            <div class="form-group store-name">
                                <label class="">Store Name</label>
                                <input [ngClass]="[storeForm.value.storeName != '' ? 'add-background-image' : '']"
                                    type="text" class="form-control" maxlength="20" placeholder="Enter Store Name" name="storeName"
                                    formControlName="storeName">
                            </div>
                            <div class="form-group store-name">
                                <label class="">Store Details</label>
                                <textarea [ngClass]="[storeForm.value.storeDec != '' ? 'add-background-image' : '']"
                                    class="form-control h-148" placeholder="Enter Store Details" rows="3"
                                    formControlName="storeDec"></textarea>
                            </div>
                            <div class="form-group store-name">
                                <label class="">Store Phone number</label>
                                <input
                                    [ngClass]="[storeForm.value.storePhoneNumber != '' ? 'add-background-image' : '']"
                                    type="number" class="form-control" placeholder="Enter Store Phone number"
                                    name="storePhoneNumber" formControlName="storePhoneNumber">
                            </div>
                            <div class="form-group upload-img mt-4">
                                <app-image-upload (deleteImage)="deleteImage()"
                                    (sendImage)="onFileChangeslogoImage($event,'cover')" [data]="cover"
                                    [error]="coverlogoImageError" [imageUrl]="coverlogoImage">
                                </app-image-upload>
                            </div>
                            <div class="form-check form-switch mb-3">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <label for="exampleFormControlFile1">Is Open</label>
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                                            checked name="isOpen" formControlName="isOpen">
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <label for="exampleFormControlFile1">Is a Physical Store</label>
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                                            checked name="isPhysicalStore" formControlName="isPhysicalStore">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group store-name">
                                <label class="">Store Timings</label>
                                <div class="table-container">

                                
                                <table class="store-time">
                                    <tr *ngFor="let time of times; let i=index;">
                                        <td class="store-time-chk">
                                            <div class="store-time-chk-in">
                                                <input type="checkbox" ngModel value="None" [id]="i+1" checked
                                                    [(ngModel)]="time.availableTime.isAvailable"
                                                    [ngModelOptions]="{standalone: true}" />
                                                <label [for]="i+1"></label>
                                            </div>
                                        </td>
                                        <td class="store-time-label">{{time.day}}</td>
                                        <td><input type="time" class="form-control w-80" placeholder="Enter Open Time"
                                                [(ngModel)]="time.availableTime.open"
                                                [ngModelOptions]="{standalone: true}"
                                                [disabled]="!time.availableTime.isAvailable"></td>
                                        <td><input type="time" class="form-control w-80" placeholder="Enter Close Time"
                                                [(ngModel)]="time.availableTime.close"
                                                [ngModelOptions]="{standalone: true}"
                                                [disabled]="!time.availableTime.isAvailable"></td>

                                    </tr>
                                </table>
                            </div>
                            </div>

                            <div class="form-group store-name">

                                <label class="">Address</label>
                                <input type="text" class="form-control mb-2" placeholder="Line 1" autocorrect="off"
                                    autocapitalize="off" spellcheck="off" type="text" google-places
                                    formControlName="line1" autocomplete="off"
                                    [ngClass]="[storeForm.value.line1 != '' ? 'add-background-image' : '']"
                                    (onSelect)="setAddress($event)">
                                
                                <input type="text"
                                    [ngClass]="[storeForm.value.line2 != '' ? 'add-background-image' : '']"
                                    class="form-control mb-2" placeholder="Line 2" name="line2" formControlName="line2">
                                <input type="text"
                                    [ngClass]="[storeForm.value.state != '' ? 'add-background-image' : '']"
                                    class="form-control mb-2" placeholder="Province/State" name="state"
                                    formControlName="state">
                                <input [class.hide]="shouldHidePostalCode"
                                    [ngClass]="[storeForm.value.postalCode != '' ? 'add-background-image' : '']"
                                    type="text" class="form-control mb-2" placeholder="Postal Code" name="postalCode"
                                    formControlName="postalCode">
                                <input type="text"
                                    [ngClass]="[storeForm.value.city != '' ? 'add-background-image' : '']"
                                    class="form-control mb-2" placeholder="City" name="city" formControlName="city">
                                <select [ngClass]="[storeForm.value.country != '' ? 'add-background-image' : '']"
                                    class="form-control" placeholder="Country" name="country" formControlName="country"
                                    #country (change)="getCountries(country.value)">
                                    <option value='' selected>Select Country</option>
                                    <option *ngFor="let item of countries" [value]="item.country.code">
                                        {{item.country.name}}</option>
                                </select>
                            </div>
                            <div class="form-group store-name">
                                <label class="">Instant Delivery Order</label>
                                <table class="store-time">
                                    <tr>
                                        <td class="store-time-chk">
                                            <div class="store-time-chk-in">
                                                <input type="checkbox" value="None" id="delivery-time"
                                                    name="onDemandDeliveryTime" formControlName="onDemandDeliveryTime"
                                                    checked />
                                                <label for="delivery-time"></label>
                                            </div>
                                        </td>
                                        <td class="store-time-label">Delivery Time</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                                <div class="form-group store-name">
                                    <div class="row">
                                        <div class="col-sm-4 col-md-4">
                                            <td class="time-label">Min Time</td>
                                        </div>
                                        <div class="col-sm-4 col-md-4">
                                            <select formControlName="onDemandDeliveryTimeHours" class="form-control w-80"
                                            [ngClass]="[storeForm.value.onDemandDeliveryTimeHours != '' && storeForm.value.onDemandDeliveryTime ? 'add-background-image' : '']">
                                                <option disabled [value]="''"><span class="disable-color">Hours</span>
                                                </option>
                                                <option [value]="hour.value" *ngFor='let hour of hours'>
                                                    {{hour.key}}</option>

                                            </select>
                                        </div>
                                        <div class="col-sm-4 col-md-4">
                                            <select formControlName="onDemandDeliveryTimeMins" class="form-control w-80"
                                            [ngClass]="[storeForm.value.onDemandDeliveryTimeMins != '' && storeForm.value.onDemandDeliveryTime ? 'add-background-image' : '']">
                                                <option disabled [value]="''"><span class="disable-color">Mins</span>
                                                </option>
                                                <option [value]="min.value"
                                                    *ngFor='let min of mins'>
                                                    {{min.key}}</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group store-name">
                                    <div class="row">
                                        <div class="col-sm-4 col-md-4">
                                            <td class="time-label">Max Time</td>
                                        </div>
                                        <div class="col-sm-4 col-md-4">
                                            <select formControlName="onDemandDeliveryTimeEndHours" class="form-control w-80"
                                            [ngClass]="[storeForm.value.onDemandDeliveryTimeEndHours != '' && storeForm.value.onDemandDeliveryTime ? 'add-background-image' : '']">
                                                <option disabled [value]="''"><span class="disable-color">Hours</span>
                                                </option>
                                                <option [value]="hour.value"
                                                    *ngFor='let hour of hours'>
                                                    {{hour.key}}</option>

                                            </select>
                                        </div>
                                        <div class="col-sm-4 col-md-4">
                                            <select formControlName="onDemandDeliveryTimeEndMins" class="form-control w-80"
                                            [ngClass]="[storeForm.value.onDemandDeliveryTimeEndMins != '' && storeForm.value.onDemandDeliveryTime ? 'add-background-image' : '']">
                                                <option disabled [value]="''"><span class="disable-color">Mins</span>
                                                </option>
                                                <option [value]="hour.value"
                                                    *ngFor='let hour of mins'>
                                                    {{hour.key}}</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <input type="text" class="form-control mb-2"
                                    [ngClass]="[storeForm.value.onDemandDeliveryTimeDetails != '' && storeForm.value.onDemandDeliveryTime ? 'add-background-image' : '']"
                                    placeholder="Delivery Details" name="onDemandDeliveryTimeDetails"
                                    formControlName="onDemandDeliveryTimeDetails">
                                <input type="number" class="form-control mb-2"
                                    [ngClass]="[storeForm.value.onDemandDeliveryTimeRadius != '' && storeForm.value.onDemandDeliveryTime ? 'add-background-image' : '']"
                                    placeholder="Delivery Radius (km)" name="onDemandDeliveryTimeRadius"
                                    formControlName="onDemandDeliveryTimeRadius">
                                <small class="small-text mt-4 mb-2">Please enter at least one of the fees below or both
                                    if you have</small>
                                <input type="number" class="form-control mb-2"
                                    [ngClass]="[storeForm.value.onDemandDeliveryTimeFixedFee != '' && storeForm.value.onDemandDeliveryTime ? 'add-background-image' : '']"
                                    placeholder="Fixed Fee ($)" name="onDemandDeliveryTimeFixedFee"
                                    formControlName="onDemandDeliveryTimeFixedFee">
                                <input type="number" class="form-control mb-2"
                                    [ngClass]="[storeForm.value.onDemandDeliveryTimeRateFee != '' && storeForm.value.onDemandDeliveryTime ? 'add-background-image' : '']"
                                    placeholder="Rate Fee (%)" name="onDemandDeliveryTimeRateFee"
                                    formControlName="onDemandDeliveryTimeRateFee">
                                    <p class="mb-1 error-field" *ngIf="f.onDemandDeliveryTimeRateFee.errors?.min || f.onDemandDeliveryTimeRateFee.errors?.max">Rate Fee(%) should be between 0 to 100</p>
                                
                            </div>
                            <div class="form-group store-name">
                                <label class="">Same Day Delivery Order</label>
                                <div class="table-container">

                                
                                <table class="store-time">
                                    <tr>
                                        <td class="store-time-chk">
                                            <div class="store-time-chk-in">
                                                <input type="checkbox" value="None" id="delivery-hours"
                                                    name="sameDayDeliveryHours" formControlName="sameDayDeliveryHours"
                                                    checked />
                                                <label for="delivery-hours"></label>
                                            </div>
                                        </td>
                                        <td class="store-time-label">Delivery Hours</td>
                                        <td><input type="time"
                                                [ngClass]="[storeForm.value.sameDayDeliveryHoursStart != '' && storeForm.value.sameDayDeliveryHours ? 'add-background-image' : '']"
                                                class="form-control w-80" placeholder="Start Time"
                                                name="sameDayDeliveryHoursStart"
                                                formControlName="sameDayDeliveryHoursStart"></td>
                                        <td><input type="time"
                                                [ngClass]="[storeForm.value.sameDayDeliveryHoursEnd != '' && storeForm.value.sameDayDeliveryHours ? 'add-background-image' : '']"
                                                class="form-control w-80" placeholder="End Time"
                                                name="sameDayDeliveryHoursEnd"
                                                formControlName="sameDayDeliveryHoursEnd"></td>
                                    </tr>
                                </table>
                            </div>
                                <input type="text"
                                    [ngClass]="[storeForm.value.sameDayDeliveryHoursDetails != '' && storeForm.value.sameDayDeliveryHours ? 'add-background-image' : '']"
                                    class="form-control mb-2" placeholder="Delivery Details"
                                    name="sameDayDeliveryHoursDetails" formControlName="sameDayDeliveryHoursDetails">
                                <input type="number"
                                    [ngClass]="[storeForm.value.sameDayDeliveryHoursRadius != '' && storeForm.value.sameDayDeliveryHours ? 'add-background-image' : '']"
                                    class="form-control mb-2" placeholder="Delivery Radius (km)"
                                    name="sameDayDeliveryHoursRadius" formControlName="sameDayDeliveryHoursRadius">
                                <small class="small-text mt-4 mb-2">Please enter at least one of the fees below or both
                                    if you have</small>
                                <input type="number"
                                    [ngClass]="[storeForm.value.sameDayDeliveryHoursFixedFee != '' && storeForm.value.sameDayDeliveryHours ? 'add-background-image' : '']"
                                    class="form-control mb-2" placeholder="Fixed Fee ($)"
                                    name="sameDayDeliveryHoursFixedFee" formControlName="sameDayDeliveryHoursFixedFee">
                                <input type="number"
                                    [ngClass]="[storeForm.value.sameDayDeliveryHoursRateFee != '' && storeForm.value.sameDayDeliveryHours ? 'add-background-image' : '']"
                                    class="form-control mb-2" placeholder="Rate Fee (%)"
                                    name="sameDayDeliveryHoursRateFee" formControlName="sameDayDeliveryHoursRateFee">
                                    <p class="mb-1 error-field" *ngIf="f.sameDayDeliveryHoursRateFee.errors?.min || f.sameDayDeliveryHoursRateFee.errors?.max">Rate Fee(%) should be between 0 to 100</p>
                                </div>
                            <div class="form-group store-name">
                                <label class="">Pickup Order</label>
                                <table class="store-time">
                                    <tr>
                                        <td class="store-time-chk">
                                            <div class="store-time-chk-in">
                                                <input type="checkbox" value="None" id="pickup-time"
                                                    name="isPickupHours" formControlName="isPickupHours" checked />
                                                <label for="pickup-time"></label>
                                            </div>
                                        </td>
                                        <td class="store-time-label">Pickup Time</td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>


                                    </tr>
                                </table>
                                <div class="form-group store-name">
                                    <div class="row">
                                        <div class="col-sm-4 col-md-4">
                                            <td class="time-label">Min Time</td>
                                        </div>
                                        <div class="col-sm-4 col-md-4">
                                            <select formControlName="pickupStartHours" class="form-control w-80"
                                            [ngClass]="[storeForm.value.pickupStartHours != '' && storeForm.value.isPickupHours ? 'add-background-image' : '']">
                                                <option disabled [value]="''"><span class="disable-color">Hours</span>
                                                </option>
                                                <option [value]="hour.value" *ngFor='let hour of hours'>
                                                    {{hour.key}}</option>

                                            </select>
                                        </div>
                                        <div class="col-sm-4 col-md-4">
                                            <select formControlName="pickupStartMins" class="form-control w-80"
                                            [ngClass]="[storeForm.value.pickupStartMins != '' && storeForm.value.isPickupHours ? 'add-background-image' : '']">
                                                <option disabled [value]="''"><span class="disable-color">Mins</span>
                                                </option>
                                                <option [value]="min.value"
                                                    *ngFor='let min of mins'>
                                                    {{min.key}}</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group store-name">
                                    <div class="row">
                                        <div class="col-sm-4 col-md-4">
                                            <td class="time-label">Max Time</td>
                                        </div>
                                        <div class="col-sm-4 col-md-4">
                                            <select formControlName="pickupEndHours" class="form-control w-80"
                                            [ngClass]="[storeForm.value.pickupEndHours != '' && storeForm.value.isPickupHours ? 'add-background-image' : '']">
                                                <option disabled [value]="''"><span class="disable-color">Hours</span>
                                                </option>
                                                <option [value]="hour.value"
                                                    *ngFor='let hour of hours'>
                                                    {{hour.key}}</option>

                                            </select>
                                        </div>
                                        <div class="col-sm-4 col-md-4">
                                            <select formControlName="pickupEndMins" class="form-control w-80"
                                            [ngClass]="[storeForm.value.pickupEndMins != '' && storeForm.value.isPickupHours ? 'add-background-image' : '']">
                                                <option disabled [value]="''"><span class="disable-color">Mins</span>
                                                </option>
                                                <option [value]="hour.value"
                                                    *ngFor='let hour of mins'>
                                                    {{hour.key}}</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <input type="text"
                                    [ngClass]="[storeForm.value.pickupDetails != '' && storeForm.value.isPickupHours ? 'add-background-image' : '']"
                                    class="form-control mb-2" placeholder="Pickup Details" name="pickupDetails"
                                    formControlName="pickupDetails">
                            </div>
                            <div class="buttons">
                                <button type="submit" (click)="cancel()" class="btn cancel">Cancel <i
                                    class="fas fa-times mx-1"></i></button>
                                <button [disabled]="isSubmit" type="submit"
                                    class="btn save" (click)="addStore()"><span
                                        class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="isSubmit">
                                    </span> <span *ngIf="!isEdit">
                                        Add Store
                                    </span>
                                    <span *ngIf="isEdit">
                                        Save Store
                                    </span> <i
                                    class="fas fa-check mx-1"></i></button>
                            </div>
                            <div class="error-block">
                                <p class="error-field" *ngIf="checkValidationError">
                                    - Field Marked in Red are required
                                </p>
                                <p class="error-field" *ngIf="showDeliveryOptionError">
                                    - At least one options needs to be added in Delivery Options (Instant, Same Day
                                    Delivery or Pickup)
                                </p>
                                <p class="error-field" *ngIf="minTimeZeroErrorInstantDelivery">
                                    - Instant Delivery Order Min Time must not be 0
                                </p>
                                <p class="error-field" *ngIf="minTimeZeroErrorPickup">
                                    - Pick Up Order Min Time must not be 0
                                </p>
                                <p class="error-field" *ngIf="showInstantDeliveryOrderError">
                                    - Instant Delivery Order Max Time should be greater then Min Time
                                </p>
                                <p class="error-field" *ngIf="showSameDayDeliveryOrderError">
                                    - Same Day Delivery Order Max Time should be greater then Min Time
                                </p>
                                <p class="error-field" *ngIf="showPickupTimeError">
                                    - Pick Up Order Max Time should be greater then Min Time
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>