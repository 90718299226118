<div class="container-nav">
    <nav class="navbar navbar-expand-lg">
        <button type="button" id="sidebarCollapse" class="navbar-btn fade-btn d-inline-block d-lg-none" (click)="openMenu = !openMenu">
            <img src="/assets/images/menuIcon.svg" alt="menuIcon" />
        </button>

        <div class="d-flex justify-content-between w-100-lg-only">
            <div class="admin-logo d-flex justify-content-center align-items-center">
                <img src="/assets/images/OrderAx.png" alt="logo" />
            </div>

            <div class="avatar d-none d-lg-flex dropdown pointer">
                <div class="flex avatar-text">
                    <p>Welcome</p>
                    <p class="name bold">{{businessName ? businessName['businessName'] : ''}}</p>
                </div>
                <img src="/assets/images/avatar-user.png" alt="avatar" />
                <div class="dropdown-content">
                    <a (click)="publicService.logAnalyticsAndRedirect('home-myaccount-from-header-dropdown','/home/myaccount')">Account</a>
                    <a [routerLink]="" (click)="logOut('header-dropdown')"><span class="logout">Logout</span></a>
                  </div>
            </div>
        </div>

        <div class="avatar-dropdown d-lg-none dropdown-mobile pointer">
            <img src="/assets/images/avatar-user.png" alt="avatar" />
            <div class="dropdown-content-mobile">
                <a (click)="publicService.logAnalyticsAndRedirect('home-myaccount-from-header-dropdown','/home/myaccount')">Account</a>
                <a [routerLink]="" (click)="logOut('header-dropdown')"><span class="logout">Logout</span></a>
              </div>
        </div>

        <!-- </div> -->
    </nav>
</div>

<div class="wrapper" [ngClass]="{'page-width' : route.url != '/home/products-detail'}">
    <div [ngClass]="{'grid-body' : route.url != '/home/products-detail', 'grid-body-nologin' : route.url == '/home/products-detail'}">
        <!-- Sidebar  -->
        <div *ngIf="route.url != '/home/products-detail'">
            <nav id="sidebar" appOutsideclick (outsideClick)="close()" [ngClass]="[openMenu && !closeMenu ? 'active' : '']" #menu>
                <ul class="list-unstyled components" *ngIf="userInfo && userInfo.type == 0">
                    <li class="first" routerLinkActive="active">
                        <a (click)="publicService.logAnalytics('home-dashboard-from-side-menu')" [routerLink]="['/home/dashboard']"><img src="/assets/images/home.png"> <span>Home</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a (click)="publicService.logAnalytics('home-myaccount-from-side-menu')" [routerLink]="['/home/myaccount']"><img src="/assets/images/account.png"> <span>Account</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a (click)="publicService.logAnalytics('home-shops-from-side-menu')" [routerLink]="['/home/shops']"><img src="/assets/images/shops.png"> <span>Shops</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a class="pointer" data-toggle="modal" (click)="checkProfileComplete()" data-target="#shopPopup"><img src="/assets/images/services.png"> <span>Services</span></a>
                    </li> 
                    <li routerLinkActive="active">
                        <a (click)="publicService.logAnalytics('home-products-from-side-menu')" [routerLink]="['/home/products']"><img src="/assets/images/products.png"> <span>Products</span></a>
                    </li> 
                    <li>
                        <a [routerLink]="" (click)="logOut('side-menu')"><img src="/assets/images/logout.png"> <span>Log out</span></a>
                    </li>
                </ul>
                <ul class="list-unstyled components" *ngIf="userInfo && userInfo.type == 1">
                    <li routerLinkActive="active">
                        <a (click)="publicService.logAnalytics('home-admin-user-listing-from-side-menu')" [routerLink]="['/admin/user-listing']"><img src="/assets/images/account.png"> <span>Users</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a (click)="publicService.logAnalytics('home-shops-from-side-menu')" [routerLink]="['/admin/project-listing']"><img src="/assets/images/shops.png"> <span>Projects</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a (click)="publicService.logAnalytics('home-products-from-side-menu')" [routerLink]="['/admin/product-listing']"><img src="/assets/images/products.png"> <span>Products</span></a>
                    </li> 
                    <li routerLinkActive="active">
                        <a (click)="publicService.logAnalytics('home-app-service-from-side-menu')" [routerLink]="['/admin/app-service-listing']"><img src="/assets/images/services.png"> <span>App Service</span></a>
                    </li>
                </ul>
            </nav>
        </div>
        <!-- Main content -->
        <div id="{{route.url != '/home/products-detail' ? 'content': ''}}" [ngClass]="[openMenu ? 'active' : '']">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<ng-container *ngIf="showPopup">
    <div class="modal fade" id="shopPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
<app-complete-profile [selectedWizard]="selectedWizard" [routeFrom]="'home'" (sendData)="dataEmit($event)" ></app-complete-profile>
</div>
</ng-container>