import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountManagerService } from 'src/app/managers/account-manager.service';
import { PublicService } from 'src/app/public/public.service';

@Component({
  selector: 'app-sharedplatform-info',
  templateUrl: './sharedplatform-info.component.html',
  styleUrls: ['./sharedplatform-info.component.css']
})
export class SharedplatformInfoComponent implements OnInit {
  selectedTab= 'ios';
  isSubmit = false;
  @Input() projectId;
  @Input() imagesData;
  isLoading = false;
  platformForShop

  iosForm: FormGroup;
  androidForm: FormGroup;
  iosCategories = ['Books', 'Business', 'Developer Tools', 'Education', 'Entertainment', 'Finance', 'Food & Drinks', 'Games',
    'Graphics & Design', 'Health & Fitness', 'Lifestyle', 'Magazines & Newspappers', 'Medical, Music'
    , 'Navigation', 'News', 'Photo & Video', 'Productivity', 'Reference', 'Shopping', 'Social Networking', 'Sports',
    'Stickers', 'Travel', 'Utilities', 'Weather']
  androidCategory = ['Art & Design', 'Auto & Vehicles', 'Beauty', 'Books & Reference', 'Business', 'Comics',
    'Communication', 'Dating', 'Education', 'Entertainment', 'Events', 'Finance', 'Food & Drinks', 'Health & Fitness', 'House & Home',
    'Libraries & Demo', 'Lifestyle', 'Maps & Navigation', 'Medical', 'Music & Audio', 'News & Magezines',
    'Parenting', 'Personalization', 'Photography', 'Productivity', 'Shopping', 'Social', 'Sports', 'Tools',
    'Travel & Local', 'Video Players & Editors', 'Weather'];
  iosformErrors = {
    releaseNotes: '',
    promotionalText: '',
    description: '',
    appName: '',
    subtitle: '',
    primaryCategory: '',
    secondaryCategory: '',
    keywords: ''
  }
  androidformErrors = {
    shortDescription: '',
    fullDescription: '',
    appName: 'name is required',
    category: '',
    videoURL: '',
    tags: '',
    appType: '',
    costType: '',
    website: '',
    featureGraphicsUrl: ''
  }
  public ioslogoImage = '';
  public androidlogoImage = '';
  public featureIcon = '';
  public feactureIconError = false;
  public androidlogoImageError = false;
  public ioslogoImageError = false;
  public images = {
    ios : {},
    android: {},
    feature: {}
  }
  tagify;
  validateIOSForm = false;
  validateAndroidForm  = false;  
  //Platform - iOS 
  //appicon
  //name max max 30 TextField
  //language - English
  //subtitle max 30 TextField

  //primary category - Books, Business, Developer Tools, Education, Entertainment, Finance, Food & Drinks, Games, Graphics & Design, Health & Fitness, Lifestyle, Magazines & Newspappers, Medical, Music, Navigation, News, Photo & Video, Productivity, Reference, Shopping, Social Networking, Sports, Stickers, Travel, Utilities, Weather
  //secondary category 

  //release notes max 4000 if already released 
  //promotionalTexttional Text max 170
  //description max 4000
  //keywords max 100

  //supportURL not requried
  //marketingURL 

  //version not requried
  //copyright not requried
  //privacy not requried


  //Platform - Android 
  //appname max 50
  //languge - en-US- not requried
  //App or game drop down
  //Free or paid - drop down

  //shortDescription  max 80 
  //fullDescription max 4000

  //iconURL
  // Your app icon as shown on Google Play. Don’t use badges or text that suggest store ranking, price or Google Play categories (such as "top", "new", or "sale").
  // Transparent PNG or JPEG
  // 512 px by 512 px
  // Up to 1 MB

  //feature gRaphics URL
  // This will help promotionalTextte your app in different places on Google Play. In case of cropping, avoid placing text near the edges, and center key visuals.
  // PNG or JPEG
  // 1,024 px by 500 px
  // Up to 1 MB

  //videoURL

  //phoneScreenshotsURL 
  //serenInchScreenshotsURL

  //title not requried
  //change log not requried
  //changelogNumber not requried

  //iconURL not requried
  //versionNumber not requried

  //Category - Art & Design, Auto & Vehicles, Beauty, Books & Reference, Business, Comics, Communication, Dating, Education, Entertainment, Events, Finance, Food & Drink, Health & Fitness, House & Home, Libraries & Demo, Lifestyle, Maps & Navigation, Medical, Music & Audio, News & Magezines, Parenting, Personalization, Photography, Productivity, Shopping, Social, Sports, Tools, Travel & Local, Video Players & Editors, Weather
  //Tags

  //email address not requried
  //phone number not requried
  //website
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  tagsIos = [];
  iosTagError = false;
  tagsAndroid = [];
  androidTagError = false;
  project;

  constructor(private fb: FormBuilder,
    private route: Router,
    private accountManager: AccountManagerService,
    private toastr: ToastrService,
    public publicService:PublicService ) { }

  ngOnInit(): void {
    this.createiosForm()
    this.createandroidForm();
  }

  add(event,type): void {
    const value = (event.value || '').trim();
    if (value) {
      if(type == 'ios'){
        this.tagsIos.push(value);
        this.iosForm.get('keywords').setValue('');
        this.iosTagError = this.tagsIos.length == 0 ? true : false; 
      } else{
        this.tagsAndroid.push(value);
        this.androidForm.get('tags').setValue('');
        this.androidTagError = this.tagsAndroid.length == 0 ? true : false; 
      }
    }
  }

  remove(index,type): void {
    if(type == 'ios'){
      this.tagsIos.splice(index, 1);
      this.iosTagError = this.tagsIos.length == 0 ? true : false;
    }else{
      this.tagsAndroid.splice(index, 1);
      this.androidTagError = this.tagsAndroid.length == 0 ? true : false; 
    }
  }

  setPlatformInfo(project){
    this.setIosForm(project.platforms.iOS);
    this.setAndroidForm(project.platforms.android);
  }

  setIosForm(obj){
    this.iosForm.patchValue(obj);
    this.ioslogoImage = obj.appIcon;
    let tags = obj.keywords;
    if(tags?.length > 0){
      this.tagsIos = [...tags]
    }
    this.iosForm.get('keywords').setValue('');
    this.iosForm.get('primaryCategory').setValue('Food & Drinks');
    this.iosForm.get('secondaryCategory').setValue('Business');
  }

  setAndroidForm(obj){
    this.androidForm.patchValue(obj);
    this.androidlogoImage = obj.appIcon;
    this.featureIcon = obj.featureGraphics;
    let tags = obj.tags;
    if(tags?.length > 0){
      this.tagsAndroid = [...tags];
    }
    this.androidForm.get('category').setValue('Food & Drinks');
    this.androidForm.get('appType').setValue('App');
    this.androidForm.get('costType').setValue('Free');
    this.androidForm.get('tags').setValue('');
  }

  createiosForm() {
    this.iosForm = this.fb.group({
      releaseNotes: ['', [Validators.required]],
      promotionalText: ['', [Validators.required]],
      description: ['', [Validators.required]],
      appName: ['', [Validators.required]],
      subtitle: ['', [Validators.required]],
      primaryCategory: ['Food & Drinks', [Validators.required]],
      secondaryCategory: ['Business', [Validators.required]],
      keywords: [''],
      marketingURL: ['', [Validators.required,Validators.pattern(this.validateUrl())]]
    })
  }

  validateUrl(){
    return new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i');
  }

  createandroidForm() {
    this.androidForm = this.fb.group({
      shortDescription: ['', [Validators.required]],
      title: ['', [Validators.required]],
      fullDescription: ['', [Validators.required]],
      category: ['Food & Drinks', [Validators.required]],
      videoURL: ['', [Validators.required,Validators.pattern(this.validateUrl())]],
      tags: [''],
      appType: ['App', [Validators.required]],
      costType: ['Free', [Validators.required]],
      website: ['', [Validators.required,Validators.pattern(this.validateUrl())]],
    })
  }

  switch(value) {
    this.publicService.logAnalytics(`${value}-tab-btn-clicked-in-shared-platform`)
    this.selectedTab = value;
  }

  next() {
    this.publicService.logAnalytics('next-btn-clicked-in-shared-platform')
    this.androidForm.markAllAsTouched();
    this.iosForm.markAllAsTouched();
   
    this.ioslogoImageError = this.ioslogoImage == '' ? true : false;
    this.androidlogoImageError = this.androidlogoImage == '' ? true : false;
    this.feactureIconError = this.featureIcon == '' ? true : false;
    this.iosTagError = this.tagsIos.length == 0 ? true : false; 
    this.androidTagError = this.tagsAndroid.length == 0 ? true : false;

    this.validateAndroidForm = this.androidValidation();
    this.validateIOSForm = this.iOSvalidation();
    if(this.iOSvalidation()){
      this.switch('ios');
      return; 
    }
    if(this.androidValidation()){
      this.switch('android');
      return; 
    }
    
    if (this.bothValidationCheck()) {
      this.isSubmit = true;
      let ios = this.iosForm.value;
      let android = this.androidForm.value;
      ios['keywords'] = this.tagsIos.join(',');
      android['tags'] = this.tagsAndroid.join(',');
      
      let shopDetails = {
        data: {
          projectId: this.projectId,
          details: {
          iOS: ios,
          android: android},
        },
      }

      if(this.images.ios['name']){
        shopDetails['iOSAppIcon'] = this.images.ios;
      }else{
        shopDetails.data.details.iOS['appIcon'] =this.ioslogoImage;
      }

      if(this.images.android['name']){
        shopDetails['androidAppIcon'] = this.images.android;
      }else{
        shopDetails.data.details.android['appIcon'] = this.androidlogoImage;
      }

      if(this.images.feature['name']){
        shopDetails['androidFeatureGraphics'] = this.images.feature;
      }else{
        shopDetails.data.details.android['featureGraphicURL'] = this.featureIcon;
      }
    
      let obj = this.publicService.makeFormData(shopDetails);
      this.accountManager.updatePlatformInfo(obj).then(
        resp => {
          this.isSubmit = false;
          this.toastr.success('Your Shop has been created', '');
          this.route.navigate([`home/shop-details/${this.projectId}`])
        },
        err => {
          this.isSubmit = false;
          this.toastr.error(err.error.message, '');
          console.log(err)
        },
      );
    }
  }

  nextForShop() {
    this.androidForm.markAllAsTouched();
    this.iosForm.markAllAsTouched();
   
    this.ioslogoImageError = this.ioslogoImage == '' ? true : false;
    this.androidlogoImageError = this.androidlogoImage == '' ? true : false;
    this.feactureIconError = this.featureIcon == '' ? true : false;
    this.iosTagError = this.tagsIos.length == 0 ? true : false; 
    this.androidTagError = this.tagsAndroid.length == 0 ? true : false;


    if(this.iOSvalidation()){
      this.switch('ios');
      return; 
    }
    if(this.androidValidation()){
      this.switch('android');
      return; 
    }
    this.validateAndroidForm = this.androidValidation();
    this.validateIOSForm = this.iOSvalidation();
    if (this.bothValidationCheck()) {
      this.isSubmit = true;
      let ios = this.iosForm.value;
      let android = this.androidForm.value;
      ios['keywords'] = this.tagsIos;
      android['tags'] = this.tagsAndroid;
        
      let shopDetails = {
        data: {
          projectId: this.projectId,
          details: {
          iOS: ios,
          android: android},
        },
      }

      if(this.images.ios['name']){
        shopDetails['iOSAppIcon'] = this.images.ios;
      }else{
        shopDetails.data.details.iOS['appIcon'] =this.ioslogoImage;
      }

      if(this.images.android['name']){
        shopDetails['androidAppIcon'] = this.images.android;
      }else{
        shopDetails.data.details.android['appIcon'] = this.androidlogoImage;
      }

      if(this.images.feature['name']){
        shopDetails['androidFeatureGraphics'] = this.images.feature;
      }else{
        shopDetails.data.details.android['featureGraphicURL'] = this.featureIcon;
      }
    
      this.platformForShop = this.publicService.makeFormData(shopDetails);
    }
  }

  onFileChangeslogoImage($event, type) {
    let image = $event.image[0].base64;
    if(type == 'ios'){
      this.ioslogoImage = image;
      this.images.ios = $event.rawFile;
      this.ioslogoImageError = false;
    }
    else if(type == 'android'){
      this.androidlogoImage = image;
      this.images.android = $event.rawFile;
      this.androidlogoImageError = false;
    }
    else if(type == 'feature'){
      this.featureIcon = image;
      this.images.feature = $event.rawFile;
      this.feactureIconError = false;
    }
  }

  deleteImage(type) {
  
    if(type == 'ios'){
      this.ioslogoImage = '';
      this.images.ios = {};
      this.ioslogoImageError = true;
    }
    else if(type == 'android'){
      this.androidlogoImage = '';
      this.images.android = {};
      this.androidlogoImageError = true;
    }
    else if(type == 'feature'){
      this.featureIcon = '';
      this.images.feature = {};
      this.feactureIconError = true;
    }
  }
  iOSvalidation(){
    return !this.iosForm.valid && this.androidForm.valid || this.ioslogoImageError || this.iosTagError
  }

  androidValidation(){
      return this.iosForm.valid && !this.androidForm.valid || this.androidlogoImageError || this.feactureIconError || this.androidTagError
  }

  bothValidationCheck(){
      return this.androidForm.valid && this.iosForm.valid && !this.androidlogoImageError && !this.ioslogoImageError && !this.feactureIconError &&  !this.iosTagError && !this.androidTagError
  }
}
