<div class="container">
    <div class="auth-box pwd-success-box">
        <div class="row no-gutters align-items-center justify-content-center">
            <div class="col-sm-12 row pwd-success-box-inside">
                <div class="col-sm-12 col-md-12 col-lg-6 bg-greay bg-image">
                    <div class="center-logo">
                        <div class="logo mb-5">
                            <img src="assets/images/logo.png" alt="Orderax Logo Image"/>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 bg-white">
                    <div class="text-center">
                        <img src="../assets/images/auth/changepwdsuccess.png" alt="Success Checkmark Image"/>
                        <h1 class="mb-5">Email Reset link has been sent to your email.</h1>
                        <a (click)="publicService.logAnalyticsAndRedirect('route-to-login-from-reset-password-success','/login')" routerLink="" class="btn btn-primary mb-2">Log in</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>