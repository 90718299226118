import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AccountManagerService } from 'src/app/managers/account-manager.service';
import { PublicService } from 'src/app/public/public.service';
import { PopupService } from '../../service/popup.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {
  @Output() closeModal = new EventEmitter;
  @Output() addCategoryIntoListItem = new EventEmitter;
  @ViewChild("myNameElem1") myNameElem: ElementRef;
  el: HTMLElement;
  close = true;
  colors =
    [
      { color: '#1ABC9C' },
      { color: '#3498DB' },
      { color: '#2ECC71' },
      { color: '#9B59B6' },
      { color: '#34495E' },
      { color: '#16A085' },
      { color: '#F1C40F' },
      { color: '#F39C12' },
      { color: '#E67E22' },
      { color: '#7F8C8D' }
    ];

  projectId;
  categoryId;
  categoryObj;
  isLoading
  @Input() itemsRec;
  isEdit = false;
  project;
  categoryName = '';
  isSelectedColor = 0;
  isSelectedItem = '';
  categories = [];
  items;
  isSubmit = false;
  @Output() goToParent = new EventEmitter;
  setAddNew = false;
  category = {
    name: '',
    description: 'test description',
    createdDate: Date.now() / 1000,
    isAvailable: true,
    colorIndex: 0,
    sort: 0
  }
  coverlogoImage = '';
  coverlogoImageError = false;
  categoryNameError = false;
  selectedProductError = false;
  image = {};
  selectedProducts = [];
  cover = { heading: 'Cover Photo', description: '', index: 0, ControlName: "Zero", show: 'cover', checkSize: false }
  seeMoreProduct = false;
  productDisplayCount = 5;
  seeMoreProductCount = 0;
  arrow;
  selectedItemPopup = [];
  submit = false;
  shopDetails = false;
  shopEdit;
  fromLink
  categoryEdit

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private toastr: ToastrService,
    private accountManager: AccountManagerService,
    public publicService: PublicService,
    private ngxLoader: NgxUiLoaderService,
    private popupService: PopupService,
    private accountManagerService: AccountManagerService) {
     }

  ngOnInit(): void {
    this.categories = [...this.itemsRec];
    if(this.categories.length > 0){
      this.changeStatus()
    }
    
    this.popupService.categoryEdit.subscribe(result => {
      this.categoryEdit = result;
    })

    this.popupService.categoryProjectId.subscribe(projectId => {
      this.projectId = projectId      
    })

    this.popupService.categoryEditId.subscribe(categoryId => {
      this.categoryId = categoryId
    })

    this.popupService.categoryName.subscribe(result=>{
      if(result != ''){
        this.category.name = result;
      }
      else{
        this.category.name = '';
      }
    });

    if(this.projectId != null && this.categoryId != null){
      this.showEditItem(this.projectId, this.categoryId);
      if(this.categories.length>0){
        this.changeStatus();
      }
    }
    
    
    if(this.activatedRoute.parent.snapshot.routeConfig.path == "category/:id"){
      this.fromLink = true;
      this.activatedRoute.params.subscribe(params => {
        this.projectId = params['id'];
        if(this.projectId){
          this.showEditItem(this.projectId, this.categoryId);
          if(this.categories.length>0){
            this.changeStatus();
          }
        }
      })
    }
    if(this.activatedRoute.parent.snapshot.routeConfig.path == 'category/:id/:categoryId'){
      this.fromLink = true;
      this.activatedRoute.params.subscribe(params => {
        this.projectId = params['id'];
        this.categoryId = params['categoryId']
        if(this.projectId != null && this.categoryId != null){
          this.showEditItem(this.projectId, this.categoryId);
          if(this.categories.length>0){
            this.changeStatus();
          }
        }
      })
    }
    
  }

  ngAfterViewInit(){
    this.myNameElem.nativeElement.scrollIntoView({behavior : "auto"});
  }

  ngOnChanges(){
    this.OnOpen();
    this.changeStatus();

    if(this.projectId != null && this.categoryId != null){
      this.showEditItem(this.projectId, this.categoryId);
      if(this.categories.length>0){
        this.changeStatus();
      }
    }

    this.popupService.showCategoryArrow.subscribe(result=>{
      this.arrow = result;
      if(this.arrow == true){}
      this.coverlogoImageError = false;
    })

    this.popupService.shopDetailsCategory.subscribe(result=>{
      this.shopDetails = result;
    })

    if(this.shopDetails){
      this.popupService.shopDetailsCategory.next(true);
    } else{
      this.popupService.shopDetailsCategory.next(false);
    }
  }

  OnOpen(){
    this.categories = [...this.itemsRec];
    if(this.categories.length > 0){
      this.changeStatus()
    }

    this.popupService.shopDetailsCategory.subscribe(result=>{
      this.shopDetails = result;
    })
    this.popupService.shopCategoryEdit.subscribe(result=>{
      this.shopEdit = result;
    })

    this.popupService.categoryName.subscribe(result=>{
      if(result != ''){
        this.category.name = result;
      }
      else{
        this.category.name = '';
      }
    });

    if(this.shopDetails == true && this.shopEdit == false){
      this.category.name = '';
      this.submit = false;
      this.selectedItemPopup = [];
      this.coverlogoImage = '';
      this.image = {};
      this.isSelectedColor = 0;
      this.category.colorIndex = 0;
      this.changeStatus();
      for(var i=0; i<this.categories.length; i++){
        this.categories[i].new_id = 'show';
      }
      this.category.isAvailable = true;
    } else if(this.shopDetails == true && this.shopEdit == true){
      if(this.projectId != null && this.categoryId != null){
        this.showEditItem(this.projectId, this.categoryId);
      }
    }
  }

  changeStatus(){
    for(let i=0; i<this.categories.length; i++){
      this.categories[i].selected = false;
    }
  }

  goBack(){
    this.popupService.categoryEdit.next(false);
  }

  cancel(){
    if(this.shopDetails){
      this.closeModal.emit(this.close);
    }
    else if(this.fromLink){
      this.router.navigate([`/home/shop-details/${this.projectId}`])
    }
    else{
      this.popupService.categoryEdit.next(false);
    }
  }

  checkQueryParams() {
    this.activatedRoute.params.subscribe(params => {

      this.projectId = params['id'];
      this.categoryId = params['categoryId']
      if (this.categoryId) {
        this.isEdit = true;
        this.getCategoryData();
        this.isLoading = true;
      }
      this.project = this.accountManager.getProjectById(this.projectId);
      
      if (!this.project) {
        this.getUserProjectDetail();
      }
    })
  }

  showEditItem(projectId, categoryId){
    this.projectId = projectId
    if (categoryId) {
      this.categoryId = categoryId;
      this.isEdit = true;
      this.getCategoryData();
      this.isLoading = true;
    }
    this.project = this.accountManager.getProjectById(projectId);
      if (!this.project) {
        this.getUserProjectDetail();
      }
  }

  getUserProjectDetail() {
    this.accountManager.getUserProject(this.projectId).then(resp => {
      if (resp.status) {
       /*  this.checkUserId(resp.project.userId) */
      }
    }, err => {
      this.toastr.error(err.error.message, '');
    });
  }
 
  getCategoryData(){
    this.accountManager.getCategoryById(this.projectId, this.categoryId).then(
      resp => {
        if(resp['data']){
          this.categoryObj = resp['data'];
    
          this.initializeCategoryData();
      }
      },
      err => {
        this.isSubmit = false;
        this.isLoading = false;
        this.toastr.error(err.error.metadata.message, '');
        this.router.navigate([`home/shop-details/${this.projectId}`])
      },
    );
  }

  initializeCategoryData() {
    let projectCategory = this.categoryObj;
    
    this.category.colorIndex = projectCategory.colorIndex;
    this.isSelectedColor = projectCategory.colorIndex;
    this.category.name = projectCategory.name;
    this.category.isAvailable = projectCategory.isAvailable;
    if (projectCategory.imageUrls.length > 0) {
      this.coverlogoImage = projectCategory.imageUrls[0];
      this.coverlogoImageError = false;
    }


    this.categories.map(result=>{
      var check = projectCategory?.itemIdsAvailable.includes(result.id);
      if(check){
        result.selected = true;
        this.selectedItemPopup.push(result);
      }
    })


    this.isLoading = false;
  }

  onColorPicker(color, i) {
    this.isSelectedColor = i;
    this.category.colorIndex = i;
  }

  selectedColor(index) {
    if (this.isSelectedColor === index) {
      return true;
    } else {
      return false;
    }
  }

  onItemPicker(color, i) {
    this.isSelectedItem = i;
  }

  selectedItem(index) {
    if (this.isSelectedItem === index) {
      return true;
    } else {
      return false;
    }
  }

  addCategory() {
    this.submit = true;
    let analytics = this.isEdit ? 'edit-save-category' :'add-save-category'
    this.publicService.logAnalytics(`${analytics}-btn-clicked-from-category`)
    this.coverlogoImageError = this.coverlogoImage == '' ? true : false;
    this.categoryNameError = this.category.name == '' ? true : false;

    if (this.coverlogoImageError || this.categoryNameError) {
      return;
    }
    
    let categoryData = {
      data: {
        projectId: this.projectId,
        data: this.category
      }
    }

    categoryData.data.data['itemIdsAvailable'] = [];
    if (this.selectedItemPopup.length > 0) {
      this.selectedItemPopup.map(d => {
        categoryData.data.data['itemIdsAvailable'].push(d.id);
      })
    }

    if (this.image['name']) {
      categoryData['image1'] = this.image;
    } else {
      categoryData.data.data['imageUrls'] = this.categoryObj.imageUrls;
    }

    if (this.isEdit && this.categoryId) {
      categoryData.data.data['id'] = this.categoryId;
    }

    let categoryObj = this.publicService.makeFormData(categoryData);

    this.isSubmit = true;
    this.accountManager.addCategory(categoryObj).then(
      resp => {
        this.addCategoryIntoList()
        this.isSubmit = false;
        
        if (this.isEdit && this.categoryId) {
          this.toastr.success('Your category has been updated', '');
        } else {
          this.toastr.success('Your category has been created', '');
        }

        if(this.shopDetails){
          this.closeModal.emit(this.close)
        } else if(this.fromLink){
          this.router.navigate([`/home/shop-details/${this.projectId}`])
        } else{
          this.popupService.categoryEdit.next(false);
        }
      },
      err => {
        this.isSubmit = false;
        this.toastr.error(err.error.message, '');
      },
    );
  }

  confirmProducts(products) {
    this.selectedProducts = [...products];
    if (this.selectedProducts.length > 5 && !this.seeMoreProduct && this.seeMoreProductCount == 0) {
      this.seeMoreProductCount = 1
      this.productDisplayCount = 4
      this.seeMoreProduct = true;
    } else {
      this.productDisplayCount = this.selectedProducts.length;
    }
  }

  deleteImage() {
    this.coverlogoImage = '';
    this.image = {};
    this.coverlogoImageError = true;
  }

  onFileChangeslogoImage($event, type) {
    let image = $event.image[0].base64;
    this.coverlogoImage = image;
    this.image = $event.rawFile;
    this.coverlogoImageError = false;
  }

  seeMoreBtn() {
    this.productDisplayCount = this.selectedProducts.length;
    this.seeMoreProduct = false;
  }

  callProductPopup(item, type) {
    var exist = this.selectedItemPopup.includes(item);
    if(exist){
      let index = this.selectedItemPopup.indexOf(item);
      this.selectedItemPopup.splice(index, 1);
      this.categories.find(result => 
        {
          if(result.id == item.id){
            result.selected = false;
        }
      });
    }
    else{
      this.selectedItemPopup.push(item);
      this.categories.find(result => {
        if(result.id == item.id){
          result.selected = true;
        }
      });
    }
  }

  addCategoryIntoList(){
    this.addCategoryIntoListItem.emit();
  }
}
