<div class="container">
    <div class="auth-box change-pwd-box">
        <div class="row no-gutters align-items-center justify-content-center">
            <div class="col-sm-12 row change-pwd-inside">
                <div class="col-sm-12 col-md-12 col-lg-6 bg-greay bg-image">
                    <div class="center-logo">
                        <div class="logo mb-5">
                            <img src="assets/images/logo.png" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 bg-white">
                    <div class="title mb-5">
                        <h1>Reset Password</h1>
                    </div>
                    <form [formGroup]="changepwdForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">New Password</label>
                            <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="password" placeholder="Type your new password" name="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <span (click)="maskPassword()" class="toggle-password"><i class="fa" aria-hidden="true" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i></span>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Confirm Password</label>
                            <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="confirmpassword" placeholder="Type your confirm password" name="confirmPassword" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                            <span (click)="maskPassword()" class="toggle-password"><i class="fa" aria-hidden="true" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i></span>
                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mb-2">Confirm</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>