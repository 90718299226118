import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MustMatch } from '../../_helper/must-match.validator';
import { AuthService } from '../auth.service';
import { AnalyticsService } from '../../auth/analytics.service';
import { PublicService } from 'src/app/public/public.service';
import { MetaService } from '../../meta-service/meta.service'

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  isLoading = false;
  show: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private analyticsService: AnalyticsService,
    public publicService:PublicService,
    private metaService: MetaService
  ) {
    this.show = false;
  }

  ngOnInit(): void {
    this.setTitleAndDescription();
    this.registerForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required]
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
  }
  setTitleAndDescription(){
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, signin, register';
    let description = 'Sign Up to OrderAx for businesses that want their own mobile ordering App, built and published to the iOS App and Android Play Store for them';
    this.metaService.setPageTitleAndMetaTags('Sign Up to Create your business apps | OrderAx',description,keywords, 'https://orderax.com/registration')
  }

  get f() {
    return this.registerForm.controls;
  }

   onSubmit(event) {
    event.preventDefault();
    let url = window.location;
    this.analyticsService.googleTag('signup-btn-clicked');
     this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.isLoading = true;
    const signInObj = {
      email: this.registerForm.value.email,
      password: this.registerForm.value.password
    }
    this.auth.register(signInObj).subscribe({
      next: () => {
        this.afterLoginSuccess();
      }, error: (error) => {
        this.isLoading = false;
        let errorMessage = error.error ? error.error.message :error.message;
        if(errorMessage === 'EMAIL_EXISTS') {
          errorMessage = 'Email Already Exists'
        }
        this.toastr.error(errorMessage, '')
      }
  })
  }

  password() {
    this.show = !this.show;
  }
  
  goToNewTab(url: string,type) {
    this.analyticsService.googleTag(type);
    window.open(url, '_blank');
  }

  googleLogin(credential: string){
    console.log(credential,"credentails")
    this.isLoading = true;
    this.auth.signInWithIDP(credential).subscribe({
      next: () => {
         this.afterLoginSuccess();
      }, error: (error) => {
        this.isLoading = false;
        this.toastr.error('Something went wrong. Please try again later.', '');
      }
  })
}

afterLoginSuccess () {
  this.isLoading = false;
  this.toastr.success('Sign Up Successful', '');
  this.router.navigate(['/businessinfo']);
}

}
