import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import * as htmlToImage from 'html-to-image';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderConfig, NgxUiLoaderService, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { CategoryComponent } from 'src/app/shared/popups/category/category.component';
import { ProductComponent } from 'src/app/shared/popups/product/product.component';
import { StoreComponent } from 'src/app/shared/popups/store/store.component';
import html2canvas from 'html2canvas';
import { PublicService } from '../public/public.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaService } from '../meta-service/meta.service'

@Component({
  selector: 'app-screenshotpublic',
  templateUrl: './screenshotpublic.component.html',
  styleUrls: ['./screenshotpublic.component.css']
})

export class ScreenshotpublicComponent implements OnInit {
  @ViewChild(CategoryComponent) callCategory: CategoryComponent;
  @ViewChild(ProductComponent) callProduct: ProductComponent;
  @ViewChild(StoreComponent) callStore: StoreComponent;
  @ViewChild('contact') contactElement: ElementRef;
  @ViewChild('screenshot') toolsElement: ElementRef;
  @ViewChild('home') homeElement: ElementRef;

  projectId;
  project;
  isLoading = false;
  mobile: boolean = true;
  tablet: boolean = false;
  showRightView: boolean = false;
  toggle = false;
  public color1: any = 'rgba(17, 166, 229, 0.1)';
  public color2: any = 'rgba(17, 166, 229, 0.1)';
  confirmProducts = new Array(6).fill(null)
  checkProducts = []
  confirmCategories = new Array(4).fill(null)
  checkCategories = []
  confirmStores = new Array(1).fill(null)
  checkStores = []
  selectedItems = []
  totalItems = []
  typeName;
  updateHeader: string;
  category = false;
  product = false;
  store = false;
  message: string;
  error: string;
  aditional: string;
  selectMessage: string;
  toggle1 = false
  finalMessage: string;
  textWelcome = 'Add Welcome Text';
  textSlogan1 = 'Add Slogan';
  slogan1lw = '';
  textSlogan2 = 'Add Slogan';
  slogan2lw = '';
  textBottom = "Add Text";
  textBottomfw = '';
  text = "";
  textItems = "";
  textPrice = "";
  textAddress = "";
  textDetails = "";
  textCurreny = "";
  editItems = false;
  editIndex;
  textRequired = false;
  slogan1 = false;
  slogan2 = false;
  welcome = false;
  textAdd = false;
  slogan1Added = true;
  slogan2Added = true;
  welcomeAdded = true;
  dTextAdded = true;
  logoAdded = false;
  textError: string;
  color = false;
  color1Added = false;
  color2Added = false;
  rgba1
  rgba2
  finaldata
  logoName
  rawFiles: any;
  removeBorder
  productAdded = { value: "Add Product", status: false };
  categoryAdded = { value: "Add Category", status: false };;
  storeAdded = { value: "Add Store", status: false };
  logo: string;
  itemsImage: '';
  goToChild1 = []
  goToChild2 = []
  goToChild = []
  logoData = { heading: 'Add Logo', description: 'Make sure the image is 350 * 350 resolution', index: 10, ControlName: "tenth", checkSize: true, width: 350, height: 350 }
  itemsProd = { heading: 'Add image', index: 11, ControlName: "eleventh"}
  itemsCate = { heading: 'Add image', index: 12, ControlName: "twelve"}
  itemsStore = { heading: 'Add image', index: 13, ControlName: "thirteen"}
  logoObj = {};
  itemsObj = {};
  screenshotforShop
  showNavbar = false;
  closeMenu : boolean = true;
  activeLink = 'home';
  public homeOffset: any;
  public contactOffset: any;
  public toolsOffset: any;
  staticCategories = [];
  staticProducts = [];
  staticStores = [];
  itemsType = 'category';
  isSelectedColor;
  categoryColor;
  colors =
  [
    { color: '#1ABC9C' },
    { color: '#3498DB' },
    { color: '#2ECC71' },
    { color: '#9B59B6' },
    { color: '#34495E' },
    { color: '#16A085' },
    { color: '#F1C40F' },
    { color: '#F39C12' },
    { color: '#E67E22' },
    { color: '#7F8C8D' }
  ];

  constructor(public vcRef: ViewContainerRef,
    private cpService: ColorPickerService,
    private ngxLoader: NgxUiLoaderService,
    public publicService: PublicService,
    public metaService:MetaService,
    private route: Router) { }

  ngOnInit(): void {
    this.setTitleAndDescription();
  }

  setTitleAndDescription(){
    let keywords = 'mobile, ordering, food, restaurant, online, app, store, play, google, iOS, listing, screenshot, theme, shopping, management, location, items, checkout, payment, screenshots, tools';
    let description = 'Generate your free Restaurant Mobile Ordering App Screenshot for the iOS App Store and Android Play Store using our Screenshot tool';
    this.metaService.setPageTitleAndMetaTags('Free Restaurant Ordering App Screenshot Generator', description, keywords, 'https://orderax.com/tools/free-restaurant-app-screenshot-generator')
  }

  showTablet() {
    this.mobile = !this.mobile;
    this.tablet = !this.tablet;
    document.getElementsByClassName("screens")[0].classList.add("screens-big")
    document.getElementsByClassName("main-body")[0].classList.add("main-body-over")
 
    if (window.innerWidth < 391) {
      document.getElementsByClassName("modal-dialog")[0].setAttribute("style", "max-width : 84%;")
    } else if (window.innerWidth > 450 && window.innerWidth < 991) {
      document.getElementsByClassName("modal-dialog")[0].setAttribute("style", "max-width : 129%;")
    } else {
      document.getElementsByClassName("modal-dialog")[0].setAttribute("style", "max-width : 129%;")
    }

    document.getElementsByClassName("box-slogan")[0].setAttribute("style", "width : 92%;")
    document.getElementsByClassName("box-slogan")[1].setAttribute("style", "width : 92%;")
    document.getElementsByClassName("box-welcome")[0].setAttribute("style", "width : 92%;")
    document.getElementsByClassName("box-category")[0].setAttribute("style", "width : 92%;")
    document.getElementsByClassName("box-category")[1].setAttribute("style", "width : 92%;")
    document.getElementsByClassName("category")[3].classList.remove("show")
    document.getElementsByClassName("category")[3].classList.add("hide")
    document.getElementsByClassName("category1")[3].classList.remove("show")
    document.getElementsByClassName("category1")[3].classList.add("hide")
    document.getElementById("patch4").style.bottom = '-10.5rem';
    document.getElementById("patch4").style.left = '-11.5rem';
    document.getElementById("patch8").style.bottom = '-10.5rem';
    document.getElementById("patch8").style.left = '-11.5rem';
    document.getElementsByClassName("boxforss")[0].classList.add("slight-box2");
    document.getElementById("letsgo").classList.add("lets-go1");

    var b1 = document.getElementsByClassName("blue-icon");
    for (var i = 0; i < b1.length; i++) {
      b1[i].classList.add("blue-icon1");
    }

    var b2 = document.getElementsByClassName("blue-circle");
    for (var i = 0; i < b2.length; i++) {
      b2[i].classList.add("blue-circle1");
    }

    var b3 = document.getElementsByClassName("text-price");
    for (var i = 0; i < b2.length; i++) {
      b3[i].classList.add("text2");
    }

    var b4 = document.getElementsByClassName("bottom-details-cards");
    for (var i = 0; i < b2.length; i++) {
      b4[i].classList.add("bottom-details-cards1");
    }

    var prods3 = document.getElementsByClassName("slightC1");
    for (var i = 0; i < prods3.length; i++) {
      prods3[i].classList.add("slightC2");
    }

    var prod4 = document.getElementsByClassName("product-add");
    for (var i = 0; i < prod4.length; i++) {
      prod4[i].classList.add("product-add-tab");
    }

    document.getElementsByClassName("modal-content-preview")[0].setAttribute("style", "max-width : 1202px");
    document.getElementsByClassName("slightCB1")[0].classList.add("slightCB2");
    document.getElementsByClassName("slightB1")[0].classList.add("slightB2");
    var cat1 = document.getElementsByClassName("slightP1")
    for (var i = 0; i < cat1.length; i++) {
      cat1[i].classList.add("slightP2");
    }

    var store = document.getElementsByClassName("add-store");
    var store2 = document.getElementsByClassName("store-text");
    for (var i = 0; i < store.length; i++) {
      store[i].classList.add("add-store-short");
    }
    for (var i = 0; i < store2.length; i++) {
      store2[i].classList.add("store-text-long");
    }

    if (this.color == true) {
      document.getElementById("patch2").style.left = "0rem";
      document.getElementById("patch6").style.left = "0rem";
      document.getElementById("patch2").style.bottom = "1.5rem";
      document.getElementById("patch6").style.bottom = "1.5rem";
      document.getElementById("patch9").style.display = "inline-block";
      document.getElementById("patch10").style.display = "inline-block";
      document.getElementById("patch2").style.height = "31%";
      document.getElementById("patch2").style.width = "45%";
      document.getElementById("patch6").style.height = "31%";
      document.getElementById("patch6").style.width = "45%";
      document.getElementById("patch1").style.top = "-3rem";
      document.getElementById("patch5").style.top = "-3rem";
    }

    if (this.productAdded.status == true) {
      var prods = document.getElementsByClassName("product-cards-text");
      var prods1 = document.getElementsByClassName("text-buttons");
      var prods2 = document.getElementsByClassName("controls");
      for (var i = 0; i < prods.length; i++) {
        prods[i].setAttribute("style", "width : 83%;");
        if (window.innerWidth < 390) {
          prods1[i].setAttribute("style", "right : -8px;");
        } else {
          prods1[i].setAttribute("style", "right : -18px;");
        }

        if (window.innerWidth < 390) {
          prods2[i].setAttribute("style", "width : 36%;");
        } else {
          prods2[i].setAttribute("style", "width : 25%;");
        }
      }
      document.getElementsByClassName("text-buttons")[1].setAttribute("style", "left : 1.3rem")
      document.getElementsByClassName("product-add-preview")[1].classList.add("product-add-preview-tab");
      if (window.innerWidth < 390) {
        document.getElementsByClassName("product-cards-text")[1].setAttribute("style", "width : 75%;");
      } else {
        document.getElementsByClassName("product-cards-text")[1].setAttribute("style", "width : 81%;");
      }
      document.getElementsByClassName("product-add")[1].classList.add("product-add-for-backg");
      if (window.innerWidth < 390) {
        document.getElementsByClassName("text-buttons")[1].setAttribute("style", "right : -8px;");
      } else {
        document.getElementsByClassName("text-buttons")[1].setAttribute("style", "right : -19px;");
      }
    }
    document.getElementById("slight-slogan").style.fontSize = "22px";
    document.getElementById("slight-slogan").style.fontWeight = "bolder";
    document.getElementById("slight-slogan").style.top = "30px";
    document.getElementById("slight-slogan").style.width = "73%";
  }

  scrollLeft(className: string): void {
    const elementList = document.querySelectorAll("." + className);
    const element = elementList[0] as HTMLElement;
    this.showRightView = !this.showRightView;
    element.scrollLeft = 300;
    document.getElementsByClassName("view-more-left")[0].setAttribute("style", "display : none");
  }

  scrollRight(className: string): void {
    const elementList = document.querySelectorAll("." + className);
    const element = elementList[0] as HTMLElement;
    this.showRightView = !this.showRightView;
    element.scrollLeft = -200;
    document.getElementsByClassName("view-more-left")[0].removeAttribute("style");
  }

  showMobile() {
    this.mobile = !this.mobile;
    this.tablet = !this.tablet;
    document.getElementsByClassName("screens-big")[0].classList.remove("screens-big");
    document.getElementsByClassName("main-body")[0].classList.remove("main-body-over");
    document.getElementsByClassName("view-more-left")[0].setAttribute("style", "display : none");
    document.getElementById("modal").setAttribute("style", "max-width : 65%;");
    document.getElementsByClassName("box-slogan")[0].setAttribute("style", "width : 88%;")
    document.getElementsByClassName("box-welcome")[0].setAttribute("style", "width : 88%;")
    document.getElementsByClassName("box-category")[0].setAttribute("style", "width : 100%;")
    document.getElementsByClassName("category")[3].classList.remove("hide");
    document.getElementsByClassName("category")[3].classList.add("show");
    document.getElementsByClassName("category1")[3].classList.remove("hide");
    document.getElementsByClassName("category1")[3].classList.add("show");
    document.getElementById("patch4").style.bottom = '-13.5rem';
    document.getElementById("patch4").style.left = '-4.1rem';
    document.getElementById("patch8").style.bottom = '-13.5rem';
    document.getElementById("patch8").style.left = '-4.1rem';
    document.getElementById("patch9").style.display = 'none';
    document.getElementById("patch10").style.display = 'none';
    document.getElementById("patch2").style.left = "1.1rem";
    document.getElementById("patch6").style.left = "1.1rem";
    document.getElementById("patch2").style.bottom = "0rem";
    document.getElementById("patch6").style.bottom = "0rem";
    document.getElementById("patch2").style.height = "25%";
    document.getElementById("patch2").style.width = "60%";
    document.getElementById("patch6").style.height = "25%";
    document.getElementById("patch6").style.width = "60%";
    document.getElementById("patch1").style.top = "0.4rem";
    document.getElementsByClassName("boxforss")[0].classList.remove("slight-box2");
    document.getElementById("letsgo").classList.remove("lets-go1");

    document.getElementsByClassName("modal-content-preview")[0].setAttribute("style", "max-width : 755px");

    var prods3 = document.getElementsByClassName("slightC1");
    for (var i = 0; i < prods3.length; i++) {
      prods3[i].classList.remove("slightC2");
    }

    var cat1 = document.getElementsByClassName("slightP1")
    for (var i = 0; i < cat1.length; i++) {
      cat1[i].classList.remove("slightP2");
    }

    var b1 = document.getElementsByClassName("blue-icon");
    for (var i = 0; i < b1.length; i++) {
      b1[i].classList.remove("blue-icon1");
    }
    var b2 = document.getElementsByClassName("blue-circle");
    for (var i = 0; i < b2.length; i++) {
      b2[i].classList.remove("blue-circle1");
    }
    var b3 = document.getElementsByClassName("text-price");
    for (var i = 0; i < b2.length; i++) {
      b3[i].classList.remove("text2");
    }
    var b4 = document.getElementsByClassName("bottom-details-cards");
    for (var i = 0; i < b2.length; i++) {
      b4[i].classList.remove("bottom-details-cards1");
    }

    var prod4 = document.getElementsByClassName("product-add");
    for (var i = 0; i < prod4.length; i++) {
      prod4[i].classList.remove("product-add-tab");
    }

    var store = document.getElementsByClassName("add-store");
    var store1 = document.getElementsByClassName("store-text");
    for (var i = 0; i < store.length; i++) {
      store[i].classList.remove("add-store-short");
    }
    for (var i = 0; i < store1.length; i++) {
      store1[i].classList.remove("store-text-long");
    }

    document.getElementsByClassName("slightCB1")[0].classList.remove("slightCB2");
    document.getElementsByClassName("slightB1")[0].classList.remove("slightB2")

    if (this.productAdded.status == true) {
      var prods = document.getElementsByClassName("product-cards-text");
      var prods1 = document.getElementsByClassName("text-buttons");
      var prods2 = document.getElementsByClassName("controls");
      for (var i = 0; i < prods.length; i++) {
        prods[i].setAttribute("style", "width : 71%;");
        prods1[i].setAttribute("style", "right : -9px;");
        prods2[i].setAttribute("style", "width : 45%;");
      }
      document.getElementsByClassName("product-cards-text")[1].setAttribute("style", "width : 75%;");
      document.getElementsByClassName("text-buttons")[1].setAttribute("style", "right : -9px;");
      document.getElementsByClassName("text-buttons")[1].setAttribute("style", "left : 0.8rem;");
      document.getElementsByClassName("product-add-preview")[1].classList.remove("product-add-preview-tab");
      document.getElementsByClassName("product-add")[1].classList.remove("product-add-for-backg");
    }
    document.getElementById("slight-slogan").style.width = "80%";
  }

  showNext() {
    if (this.mobile == true) {
      this.showTablet()
    }
    else if (this.tablet == true) {
      this.showMobile()
    }
  }

  hexToRgbA(color) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
      c = color.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
    }
    throw new Error('Bad Hex');
  }


  downloadPreview() {
    htmlToImage.toPng(document.getElementById('screenshot1'))
      .then((dataUrl) => {
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.download = 'screenshot-1.png';
        link.href = dataUrl;
        link.click();
        htmlToImage.toPng(document.getElementById('screenshot2'))
          .then((dataUrl) => {
            var link1 = document.createElement('a');
            link1.download = 'screenshot-2.png';
            link1.href = dataUrl;
            link1.click();
            htmlToImage.toPng(document.getElementById('screenshot3'))
              .then((dataUrl) => {
                var link2 = document.createElement('a');
                link2.download = 'screenshot-3.png';
                link2.href = dataUrl;
                link2.click();
              });
          });
      });
  }

  preview() {
    document.getElementById("slight-slogan").style.fontSize = "22px";
    document.getElementById("slight-slogan").style.fontWeight = "bolder";
    document.getElementById("slight-slogan").style.top = "30px";
  }

  public onChangeColor(color: string, type: string): Cmyk {
    const hsva = this.cpService.stringToHsva(color);
    const rgba = this.cpService.hsvaToRgba(hsva);
    
    var finalColor = [];
    finalColor.push(Math.round(rgba.r * 255));
    finalColor.push(Math.round(rgba.g * 255));
    finalColor.push(Math.round(rgba.b * 255));
    finalColor.push(rgba.a);
    
    if (type == "1") {
      document.getElementById("patch1").style.display = "inline-block";
      document.getElementById("patch2").style.display = "inline-block";
      document.getElementById("patch5").style.display = "inline-block";
      document.getElementById("patch6").style.display = "inline-block";
      document.getElementById("patch2").style.backgroundColor = color;
      document.getElementById("patch2").style.boxShadow = "inset 5px 3px 53px " + color;
      document.getElementById("patch6").style.boxShadow = "inset 5px 3px 53px " + color;
      document.getElementById("headerBox1").style.color = "#000"
      var mob = document.getElementsByClassName("mobile-view-background");
      for (var i = 0; i < mob.length; i++) {
        mob[i].setAttribute("style", "border : none");
      }
      document.getElementById("mobile-view-background1").classList.remove("mobile-view-background-red");
      this.color = true;
      this.rgba1 = finalColor;
      this.color1Added = true;
    } else if (type == "2") {
      document.getElementById("patch3").style.display = "inline-block";
      document.getElementById("patch4").style.display = "inline-block";
      document.getElementById("patch7").style.display = "inline-block";
      document.getElementById("patch8").style.display = "inline-block";
      var mob = document.getElementsByClassName("mobile-add-background");
      document.getElementById("headerBox2").style.color = "#000"
      for (var i = 0; i < mob.length; i++) {
        mob[i].setAttribute("style", "border : none");
      }
      document.getElementById("mobile-add-background1").classList.add("mobile-add-background-red");
      this.color = true;
      this.color2Added = true;
      this.rgba2 = finalColor;
    }

    return this.cpService.rgbaToCmyk(rgba);
  }

  onFileChangeScreenshot($event) {
    let image = $event.image[0].base64;
    this.logo = image;
    this.logoObj = $event.rawFile;
  }

  onFileChangeitems($event){
    let image = $event.image[0].base64;
    this.itemsImage = image;
    this.itemsObj = $event.rawFile;
  }

  deleteImage() {
    this.logo = '';
    this.logoObj = {};
  }

  deleteItemsImage() {
    this.itemsImage = '';
    this.itemsObj = {};
  }

  addLogo(event) {
    var file1 = event.target.files[0];
    this.logoName = file1.name;

    const reader = new FileReader();
    reader.readAsDataURL(file1);
    reader.onload = () => {
      this.logo = reader.result as string;
      document.getElementById("uploadedLogo").setAttribute("src", reader.result as string);
      document.getElementsByClassName("uploadedBack")[0].setAttribute("style", "display : inline-block");
    };
  }

  confirmLogo() {
    document.getElementsByClassName("box-logo")[0].classList.remove("box-background1");
    var logos = document.getElementsByClassName("box-logo");
    for (var i = 0; i < logos.length; i++) {
      logos[i].innerHTML = "<img class='logoBox' src=" + this.logo + ">";
      document.getElementsByClassName("logoBox")[i].setAttribute("style", "height: 100%; width: 100%");
      logos[i].classList.remove("box-background");
    }
    document.getElementsByClassName("logoConfirm")[0].setAttribute("data-dismiss", "modal");
    this.logoAdded = true;
    if (this.categoryAdded.status == true && this.welcomeAdded == true && this.productAdded.status == true
      && this.slogan1Added == true && this.logoAdded == true) {
      document.getElementsByClassName("mobile-view")[0].setAttribute("style", "border : none")
    }
  }

  confirmitems(type) {
    if(type == "category"){
      if(this.itemsImage == '' || this.textItems == ''){
        this.textRequired = true;
        return;
      } else {
        if(this.editItems == true) {
          if(this.confirmCategories[this.editIndex].status == true){
            if(this.itemsImage == '' || this.textItems == ''){
              this.textRequired = true;
              return;
            } else{
              this.confirmCategories[this.editIndex].name = this.textItems;
              this.confirmCategories[this.editIndex].imageUrls = this.itemsImage;
              for(var j=0; j<this.colors.length; j++){
                if(j == this.isSelectedColor){
                  this.categoryColor = this.colors[j].color;
                }
              }
              this.confirmCategories[this.editIndex].color = this.categoryColor;
            }
          }
        } else {
          this.confirmCategories = [];
          document.getElementById("textConfirmCate").setAttribute("data-dismiss", "modal");
          for(var j=0; j<this.colors.length; j++){
            if(j == this.isSelectedColor) {
              this.categoryColor = this.colors[j].color;
            }
          }
          let obj = {"imageUrls": [this.itemsImage],"name": this.textItems, status : true, "color" : this.categoryColor};
          this.staticCategories.push(obj);  
          this.confirmCategories = this.staticCategories.slice();
          if (this.confirmCategories.length <= 4) {
            var len = this.confirmCategories.length;
            var remaining = 4 - len;
            for (var i = 0; i < remaining; i++) {
              obj['id'] = i;
              this.confirmCategories.push(i);
            }
            this.categoryAdded.status = true;
          } else {
            this.confirmCategories = new Array(4).fill(null);
            this.itemsImage = '';
            this.textItems = '';
            this.isSelectedColor = null;
          }
        }
      }
    }
    if(type == "products"){
      if(this.itemsImage == '' || this.textItems == '' || this.textPrice == ''){
        this.textRequired = true;
        return;
      } else {
        if(this.editItems == true){
          if(this.confirmProducts[this.editIndex].status == true){
            if(this.itemsImage == '' || this.textItems == '' || this.textPrice == ''){
              this.textRequired = true;
              return;
            } else {
              this.confirmProducts[this.editIndex].name = this.textItems;
              this.confirmProducts[this.editIndex].price.value = this.textPrice;
              this.confirmProducts[this.editIndex].price.currencyCode = this.textCurreny;
              this.confirmProducts[this.editIndex].imageUrls = this.itemsImage;
              if(this.textCurreny == ''){
                this.textCurreny = 'USD'
              }
            }
          }
        } else {
          this.confirmProducts = [];
          if(this.textCurreny == ''){
            this.textCurreny = 'USD'
          }
          document.getElementById("textConfirmProd").setAttribute("data-dismiss", "modal");
          let obj =  {
            "imageUrls": [this.itemsImage],
            "name": this.textItems,
            "price": { 
              "currencyCode": this.textCurreny.toUpperCase(), 
              "value": this.textPrice
            }, 
            status: true
          };

          this.staticProducts.push(obj);
          this.confirmProducts = this.staticProducts.slice();
          if(this.confirmProducts.length <= 6){
            var len = this.confirmProducts.length;
            var remaining = 6 - len;
            for (var i = 0; i < remaining; i++) {
              obj['id'] = i;
              this.confirmProducts.push(i);
            }
            this.productAdded.status = true;
          } else {
            this.confirmProducts = new Array(6).fill(null);
            this.itemsImage = '';
            this.textItems = '';
            this.textPrice = '';
            this.textCurreny = '';
          }
        }
      }
    }
    if(type == "store"){
      if(this.itemsImage == '' || this.textItems == '' || this.textAddress == '' || this.textDetails == ''){
        this.textRequired = true;
        return;
      } else{
        if(this.editItems == true){
          if(this.confirmStores[this.editIndex].status == true){
            if(this.itemsImage == '' || this.textItems == ''){
              this.textRequired = true;
              return;
            }else{
                this.confirmStores[this.editIndex].name = this.textItems;
                this.confirmStores[this.editIndex].details = this.textDetails;
                this.confirmStores[this.editIndex].address = this.textAddress;
                this.confirmStores[this.editIndex].imageUrls = this.itemsImage;
            }
          }
        } else{
          this.confirmStores = [];
          document.getElementById("textConfirmStore").setAttribute("data-dismiss", "modal");
          let obj = {"address": this.textAddress, "details": this.textDetails, "imageUrls": [this.itemsImage],
          "name": this.textItems, status: true};
          if (this.staticStores.length < 1) {
            obj['id'] = 1;
            this.staticStores.push(obj);
            this.confirmStores = this.staticStores.slice();
            this.storeAdded.status = true;
          } else {
            this.confirmStores = new Array(1).fill(null);
            this.itemsImage = '';
            this.textItems = '';
            this.textDetails = '';
            this.textAddress = '';
          }
        }
      }
    }
  }

  slogan(type) {
    this.publicService.logAnalytics('confirm-slogan-btn-clicked-in-screenshot-public')
    this.text = "";
    this.textError = "";
    document.getElementsByClassName("updateslogan")[0].innerHTML = "Update Slogan";
    document.getElementsByClassName("sloganadd")[0].innerHTML = "Add slogan";
    document.getElementById("slogan").getElementsByClassName("form-control-slogan")[0].setAttribute("placeholder", "Enter your slogan");
    if (type == "1") {
      if (this.textSlogan1 == 'Add Slogan') {
        this.text = "";
      } else {
        this.text = this.textSlogan1 + ' ' + this.slogan1lw;
      }
      this.slogan1 = true;
      this.slogan2 = false;
      this.welcome = false;
      this.textAdd = false;
    } else if (type == "2") {
      if (this.textSlogan2 == 'Add Slogan') {
        this.text = "";
      } else {
        this.text = this.textSlogan2 + ' ' + this.slogan2lw;
      }
      this.slogan2 = true;
      this.slogan1 = false;
      this.welcome = false;
      this.textAdd = false;
    }
  }

  welcomeText(type) {
    this.publicService.logAnalytics('confirm-welcome-text-btn-clicked-in-screenshot-public')
    
    if (type == '1') {
      if (this.textWelcome == 'Add Welcome Text') {
        this.text = "";
      } else {
        this.text = this.textWelcome;
      }
      this.textError = "";
      document.getElementsByClassName("updateslogan")[0].innerHTML = "Update Welcome Text";
      document.getElementsByClassName("sloganadd")[0].innerHTML = "Add welcome text";
      document.getElementById("slogan").getElementsByClassName("form-control-slogan")[0].setAttribute("placeholder", "Enter welcome text");

      this.welcome = true;
      this.textAdd = false;
      this.slogan1 = false;
      this.slogan2 = false;
    } else if (type == '2') {
      if (this.textBottom == "Add Text") {
        this.text = "";
      } else {
        this.text = this.textBottomfw + ' ' + this.textBottom;
      }
      this.textError = "";
      document.getElementsByClassName("updateslogan")[0].innerHTML = "Update Text";
      document.getElementsByClassName("sloganadd")[0].innerHTML = "Add a text";
      document.getElementById("slogan").getElementsByClassName("form-control-slogan")[0].setAttribute("placeholder", "Enter text");
      this.textAdd = true;
      this.slogan1 = false;
      this.slogan2 = false;
      this.welcome = false;
    }
  }

  addText(type) {
    if (this.slogan1 == true) {
      if (this.text == "") {
        this.textError = "Slogan is required";
        document.getElementsByClassName("buttonConfirm")[0].setAttribute("data-dismiss", "modal3");
        return;
      } else {
        this.textSlogan1 = this.text;
        var box1 = document.getElementsByClassName("box-slogan");
        this.slogan1 = false;
        this.slogan1Added = true;
        document.getElementsByClassName("box-slogan")[0].classList.remove("box-background1");

        if (type == 'write') {
          document.getElementsByClassName("buttonConfirm")[0].setAttribute("data-dismiss", "modal");
        }

        for (var i = 0; i < box1.length; i++) {
          box1[i].innerHTML = this.text;
          box1[i].classList.remove("box-background");
          box1[i].setAttribute("style", "width : 80%; font-size: 16px;");
        }
        if (this.categoryAdded.status == true && this.welcomeAdded == true && this.productAdded.status == true
          && this.slogan1Added == true && this.logoAdded == true) {
          document.getElementsByClassName("mobile-view")[0].setAttribute("style", "border : none")
        }
        let array = this.textSlogan1.split(" ");
        if (array.length > 1) {
          this.slogan1lw = array.pop();
          this.textSlogan1 = array.join(' ');
        } else {
          this.slogan1lw = '';
        }
      }
    } else if (this.slogan2 == true) {
      if (this.text == "") {
        this.textError = "Slogan is required";
        document.getElementsByClassName("buttonConfirm")[0].setAttribute("data-dismiss", "modal3");
        return;
      } else {
        this.textSlogan2 = this.text;
        const box2 = document.getElementsByClassName("add-slogan");
        this.slogan2 = false;
        this.slogan2Added = true;
        document.getElementsByClassName("add-slogan")[0].classList.remove("box-background1");
        document.getElementsByClassName("add-slogan-preview")[0].classList.remove("box-background");
        if (type == 'write') {
          document.getElementsByClassName("buttonConfirm")[0].setAttribute("data-dismiss", "modal");
        }

        for (var i = 0; i < box2.length; i++) {
          box2[i].innerHTML = this.text;
          box2[i].classList.remove("box-background");
          box2[i].setAttribute("style", "width : 33%; left : 9%");
        }

        let array = this.textSlogan2.split(" ");
        if (array.length > 1) {
          this.slogan2lw = array.pop();
          this.textSlogan2 = array.join(' ')
        }
        else {
          this.slogan2lw = '';
        }
      }
    } else if (this.welcome == true) {
      if (this.text == "") {
        this.textError = "Welcome text is required";
        document.getElementsByClassName("buttonConfirm")[0].setAttribute("data-dismiss", "modal3");
        return;
      } else {
        this.textWelcome = this.text;
        const box3 = document.getElementsByClassName("box-welcome");
        document.getElementsByClassName("box-welcome")[0].classList.remove("box-background1");
        if (type == 'write') {
          document.getElementsByClassName("buttonConfirm")[0].setAttribute("data-dismiss", "modal");
        }

        for (var i = 0; i < box3.length; i++) {
          box3[i].innerHTML = this.text;
          box3[i].classList.remove("box-background");
          box3[i].setAttribute("style", "text-align: left");
          this.welcome = false;
          this.welcomeAdded = true;
        }
      }
    } else if (this.textAdd == true) {
      if (this.text == "") {
        this.textError = "Text is required";
        document.getElementsByClassName("buttonConfirm")[0].setAttribute("data-dismiss", "modal3");
        return;
      } else {
        this.textBottom = this.text;
        const box4 = document.getElementsByClassName("product-add-text");
        document.getElementsByClassName("product-add-text")[0].classList.remove("box-background1");
        if (type == 'write') {
          document.getElementsByClassName("buttonConfirm")[0].setAttribute("data-dismiss", "modal");
        }

        document.getElementsByClassName("product-add-text-preview")[0].classList.remove("box-background");
        for (var i = 0; i < box4.length; i++) {
          box4[i].innerHTML = this.text;
          box4[i].classList.remove("box-background");
          box4[i].setAttribute("style", "justify-content : left; color: #545454; top: 3rem; width: 67%");
          this.textAdd = false;
          this.dTextAdded = true;
        }
        let array = this.textBottom.split(" ");
        if (array.length > 1) {
          this.textBottomfw = array.shift();
          this.textBottom = array.join(' ')
        } else {
          this.textBottomfw = '';
        }
      }
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      document.getElementById("modalClose").click();
    }
  }
  @HostListener("window:resize", []) updateDays() {
    if (window.innerWidth < 992) {
      var colpic1 = document.getElementsByClassName("color-picker");
      for (var i = 0; i < colpic1.length; i++) {
        colpic1[i].setAttribute("style", "top: 0px; left: 0px; display : none");
      }
    }
    else {
      var colpic2 = document.getElementsByClassName("color-picker")
      for (var i = 0; i < colpic2.length; i++) {
        colpic2[i].setAttribute("style", "top: 37.5px; left: -238px; display : none");
      }
    }

    if (window.innerWidth < 1067 && window.innerWidth > 991) {
      var imgBack = document.getElementsByClassName("product-add");
      for (var i = 0; i < imgBack.length; i++) {
        imgBack[i].classList.add("product-add-imgBack");
      }
    }
    else {
      var imgBack = document.getElementsByClassName("product-add");
      for (var i = 0; i < imgBack.length; i++) {
        imgBack[i].classList.remove("product-add-imgBack");
      }
    }

  }

  ngAfterViewInit() {
    this.contactOffset = this.contactElement.nativeElement.offsetTop;
    this.toolsOffset = this.toolsElement.nativeElement.offsetTop;
    this.homeOffset = this.homeElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
    checkOffsetTop() {
      if (window.pageYOffset >= this.homeOffset && window.pageYOffset < this.toolsOffset-100) {
        this.activeLink = 'home';
      }
      else if (window.pageYOffset >= this.toolsOffset-100 && window.pageYOffset < this.contactOffset-100) {
        this.activeLink = 'apps';
      }
      else if (window.pageYOffset > this.contactOffset-100) {
        this.activeLink = 'contact';
      }
  }

  goToSection(type){
    if(type == "home"){
      this.activeLink = "home";
      var element = document.getElementById('home');
      var elementPosition = element.getBoundingClientRect().top;
      window.scrollTo({top :elementPosition, behavior : 'smooth'});
    }
    if(type == "apps"){
      this.activeLink = "apps";
      var element = document.getElementById('apps');
      var headerOffset = 82;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({top :offsetPosition, behavior : 'smooth'});
    }
    if(type == "contact"){
      this.activeLink = "contact";
      var element = document.getElementById('contact');
      var headerOffset = 82;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({top :offsetPosition, behavior : 'smooth'});
    }
  }

  goToHome(){
    this.route.navigate(['/']);
  }

  getStarted(){
    this.route.navigate(['/registration'])
  }

  goToLogin(){
    this.route.navigate(['/login'])
  }

  showNav(){
    this.showNavbar = !this.showNavbar;
  }
  
  close(){
    if(!this.showNavbar){
      this.closeMenu = false;
    }else if(this.showNavbar){
      this.closeMenu = true;
    }
    else if(this.showNavbar && this.closeMenu){
      this.closeMenu = true;
      this.showNavbar = false;
    }
  }

  goToSocial(type) {
    if(type == 'instagram') {
      window.open(`https://www.instagram.com/orderaxapp/?hl=en`, '_blank', 'noopener');
    }
    if(type == 'facebook') {
      window.open(`https://web.facebook.com/orderax`, '_blank', 'noopener');
    }
    if(type == 'twitter') {
      window.open(`https://www.twitter.com/orderax1`, '_blank', 'noopener');
    }
    if(type == 'youtube') {
      window.open(`https://www.youtube.com/channel/UCnAEXlmsER86iLQwnysyHUQ`, '_blank', 'noopener');
    }
    if(type == 'google') {
      window.open(`https://g.page/r/CdxuoeNDxtG_EAE`, '_blank', 'noopener');
    }
  }

  calStore(index) {
    if(this.storeAdded.status == true){
      if(this.confirmStores[index].status == true){
        this.textItems = this.confirmStores[index].name;
        this.textDetails = this.confirmStores[index].details;
        this.textAddress = this.confirmStores[index].address;
        this.itemsImage = this.confirmStores[index].imageUrls;
        this.editItems = true;
        this.editIndex = index;
        this.textRequired = false;
      } else{
        document.getElementById("textConfirmStore").removeAttribute("data-dismiss");
        this.itemsType = 'store';
        this.itemsImage = '';
        this.textItems = '';
        this.textDetails = '';
        this.textAddress = '';
        this.textRequired = false;
      }
    } else{
      document.getElementById("textConfirmStore").removeAttribute("data-dismiss");
      this.itemsType = 'store';
      this.itemsImage = '';
      this.textItems = '';
      this.textDetails = '';
      this.textAddress = '';
      this.textRequired = false;
    }
  }

  callProd(index) {    
    if(this.productAdded.status == true){
      if(this.confirmProducts[index].status == true){
        this.textItems = this.confirmProducts[index].name;
        this.textPrice = this.confirmProducts[index].price.value;
        this.textCurreny = this.confirmProducts[index].price.currencyCode;
        this.itemsImage = this.confirmProducts[index].imageUrls;
        this.editItems = true;
        this.editIndex = index;
        this.textRequired = false;
      }
      else{
        document.getElementById("textConfirmProd").removeAttribute("data-dismiss");
        this.itemsType = 'products';
        this.itemsImage = '';
        this.textItems = '';
        this.textPrice = '';
        this.textCurreny = '';
        this.textRequired = false;
      }
    } else{
      document.getElementById("textConfirmProd").removeAttribute("data-dismiss");
      this.itemsType = 'products';
      this.itemsImage = '';
      this.textItems = '';
      this.textPrice = '';
      this.textCurreny = '';
      this.textRequired = false;
    }
  }

  callCate(index) {
    if(this.categoryAdded.status == true){
      if(this.confirmCategories[index].status == true){
        this.textItems = this.confirmCategories[index].name;
        this.itemsImage = this.confirmCategories[index].imageUrls;
        for(var j=0; j<this.colors.length; j++){
          if(this.colors[j].color == this.confirmCategories[index].color){
            this.isSelectedColor = j;
          }
        }
        this.editItems = true;
        this.editIndex = index;
        this.textRequired = false;
      } else{
        document.getElementById("textConfirmCate").removeAttribute("data-dismiss");
        this.itemsType = 'category';
        this.itemsImage = '';
        this.textItems = '';
        this.isSelectedColor = null;
        this.textRequired = false;
      }
    } else{
      document.getElementById("textConfirmCate").removeAttribute("data-dismiss");
      this.itemsType = 'category';
      this.itemsImage = '';
      this.textItems = '';
      this.isSelectedColor = null;
      this.textRequired = false;
    }
  }

  onColorPicker(color, i) {
    this.isSelectedColor = i;
  }

  selectedColor(index) {
    if (this.isSelectedColor === index) {
      return true;
    } else {
      return false;
    }
  }
}
